import React, { useContext, useCallback, useEffect, useRef } from "react";

import { useAuth } from "../auth/auth-provider";
import { AnalyticsContext } from "./analytics-context";

import { getAnalytics, logEvent } from "firebase/analytics";

export function AnalyticsProvider({children}){
    const authState = useAuth();
    const analytics = getAnalytics(authState?.firebaseApp);
    
    const logCustomEvent = useCallback((eventName) => {
        console.log("Logging the event: " + eventName);
        logEvent(analytics, eventName);
    }, []);

    const logCustomEventWithParams = useCallback((eventName, param) => {
        console.log("Logging the event: " + eventName);
        console.log("Logging parameter...");
        console.log(param);

        logEvent(analytics, eventName, param);
    }, []);

    const value = {
        logCustomEvent,
        logCustomEventWithParams
    }

    return (
        <AnalyticsContext.Provider value={value}>
            {children}
        </AnalyticsContext.Provider>
    );
}

export const useAnalytics = () => {
    return useContext(AnalyticsContext);
};