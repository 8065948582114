import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../profile-settings-header";
import BlockedProfileBody from "./blocked-profile-body";
import PaginationComponent from "../../../components/pagination/pagination-component";

import Footer from '../../footer/footer';
import { useCallback, useEffect, useState } from "react";
import { useGetAllBlockedProfilesMutation } from "../../../services/block-api";
import APIErrorLayout from "../../error/api-error/api-error-layout";

const BlockedProfileLayout = () => {
    const { t } = useTranslation();
    const blocked_profile_locale = "profile_settings.blocked_profile";

    const [blockedData, setBlockedData] = useState([]);

    const [selectedPage, setSelectedPage] = useState(1);

    const [
        blockedUsers,
        {
            isLoading, 
            isSuccess, 
            data, 
            isError, 
            error
        }
    ] = useGetAllBlockedProfilesMutation();

    const callGetAllBlockedUsersAPI = useCallback(async () => {
        await blockedUsers(selectedPage - 1);
    }, [selectedPage]);

    useEffect(() => {
        callGetAllBlockedUsersAPI();
    }, [selectedPage]);

    useEffect(() => {
        if(isSuccess && data){
            console.log("Blocked data...");
            console.log(data);

            setBlockedData(data.data.content);
        }
    }, [isSuccess, data]);

    const changePage = useCallback((event, page) => {
        setSelectedPage(page);
    }, []);

    let body;

    if(isLoading){
        body = <BlockedProfileBody isLoading={true} />
    }
    else if(isError && error){
        console.log(error);
        body = <APIErrorLayout error={error} />
    }
    else if(isSuccess){
        body = <BlockedProfileBody 
            data={blockedData} 
            successCallback={() => {
                callGetAllBlockedUsersAPI();
            }}
        />
    }

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${blocked_profile_locale}.heading`)} 
            />

            {body}

            {
                !isError && blockedData.length > 0
                ?
                <PaginationComponent 
                    totalPageCount={data?.data.page.totalPages} 
                    onChange={changePage}
                    page={selectedPage}
                />
                :
                <></>
            }

            <Footer />
        </>
    );
}

export default BlockedProfileLayout;