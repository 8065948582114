export function validateMobileNumber(mobile){
    const phoneNumberRegex = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
      
    return phoneNumberRegex.test(mobile);
}

export function validateCountryCode(code){
  const countryCodeRegex = /^(\+?\d{1,3}|\d{1,4})$/;
    
  return countryCodeRegex.test(code);
}

export function validateEmail(value){
    return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function shuffleArray(array){
  const customArray = [...array];

  let currentIndex = customArray.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [customArray[currentIndex], customArray[randomIndex]] = [customArray[randomIndex], customArray[currentIndex]];
  }

  return customArray;
}

export  function getProfileFilledPercentage(obj){
  let totalAttributes = 0;
  let missingAttributes = 0;

  for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
          totalAttributes++;

          if(!obj[key] || obj[key].length === 0){
              missingAttributes++;
          }
      }
  }

  if(totalAttributes === 0){
    return 100;
  }
  else{
    return Math.round(((totalAttributes - missingAttributes)/totalAttributes)*100);
  }
}