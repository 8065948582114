import {
    Box, 
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Link,
    Grow,
    Stack,
    Avatar,
    Chip,
    IconButton,
    Badge
} from '@mui/material';

import DownArrow from '../../assets/icons/down-arrow';
import { useEffect, useState } from 'react';

import ArrowLeft from '../../assets/icons/arrow-left';
import { useCallback } from 'react';
import { useTheme } from '@emotion/react';
import { useChat } from '../../contexts/chat/chat-provider';
import ChatBoxTabLayout from './chat-box-tab-layout';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth/auth-provider';

const ChatBoxPcLayout = () => {
    const theme = useTheme();

    const [initiated, setInitiated] = useState(false);

    const useAuthContext = useAuth();
    const useChatContext = useChat();

    const navigate = useNavigate();

    const handleExpansion = useCallback(() => {
        useChatContext.toggleChatBox();
    }, [useChatContext]);

    useEffect(() => {
        if(!useAuthContext.currentUser){
            if(useChatContext?.isChatBoxExpanded){
                handleExpansion();
            }
        
            useChatContext.setChatBoxBody(<ChatBoxTabLayout />);
        }
    }, [useAuthContext]);

    useEffect(() => {
        if(useChatContext && !initiated){
            useChatContext.setChatBoxBody(<ChatBoxTabLayout />);

            setInitiated(true);
        }
    }, [useChatContext, initiated]);

    return (
        <>
            <Box
                sx={{
                    width: 530,
                    borderRadius: '16px 16px 0px 0px',
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.25)',
                    display: {xs: 'none', md: useAuthContext?.currentUser ? 'block' : 'none'}
                }}  
            >
                <Accordion
                    square={true}
                    expanded={useChatContext?.isChatBoxExpanded}
                    onChange={handleExpansion}
                    slots={{ transition: Grow }}
                    slotProps={{ transition: { timeout: 400 } }}
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '16px 16px 0px 0px',
                        '&:before': {
                            display: 'none',
                        },
                        '& .MuiAccordion-region': { height: useChatContext?.isChatBoxExpanded ? 'auto' : 0 },
                        '& .MuiAccordionDetails-root': { display: useChatContext?.isChatBoxExpanded ? 'block' : 'none' }
                    }}
                >
                    <AccordionSummary
                        expandIcon={
                            <Link underline='none' onClick={() => {}}>
                                <DownArrow 
                                    style={{
                                        backgroundColor: '#E7E7E7',
                                        color: 'white',
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%'
                                    }}
                                />
                            </Link>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        {
                            useChatContext?.chatBoxBar?.back && useChatContext?.isChatBoxExpanded
                            ?
                            <IconButton 
                                aria-label="back" 
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevents the Accordion from expanding/collapsing
                                    useChatContext?.chatBoxBar?.back();
                                }} 
                                size="small"
                                disableRipple
                            >
                                <ArrowLeft style={{color: '#121212'}}/>
                            </IconButton>
                            :
                            <></>
                        }

                        <Stack direction='row' alignItems='center' spacing={1}>
                            {
                                useChatContext?.chatBoxBar?.memberID
                                ?
                                <IconButton 
                                    aria-label="back" 
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevents the Accordion from expanding/collapsing
                                        navigate(`/profile/${useChatContext?.chatBoxBar?.memberID}`);
                                    }} 
                                    size="small"
                                    disableRipple
                                >
                                    <Avatar 
                                        alt="profile"
                                        src={useChatContext?.chatBoxBar?.avatar ? useChatContext.chatBoxBar.avatar : '/assets/images/profile/profile.jpg'}
                                        sx={{ width: 50, height: 50, ml: 2 }}
                                    />
                                </IconButton>
                                :
                                <></>
                            }

                            <Stack direction='column'>
                                <Stack direction='row' alignItems='center' spacing={2}>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: 18,
                                            color: '#121212',
                                            py: 1,
                                            pl: 1
                                        }}
                                    >
                                        {useChatContext?.chatBoxBar?.heading}
                                    </Typography>

                                    {
                                        !useChatContext || useChatContext?.isChatBoxExpanded || useChatContext?.chatBoxBar?.back
                                        ?
                                        <></>
                                        :
                                        <Chip 
                                            size='small' 
                                            label={useChatContext.getTotalUnreadMessagesCount()} 
                                            sx={{
                                                backgroundColor: theme.palette.primary.main, color: 'white',
                                                display: useChatContext.getTotalUnreadMessagesCount() === 0 ? 'none': 'flex'
                                            }}
                                        />
                                    }
                                </Stack>

                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: 12,
                                        color: '#6D6D6D',
                                        pl: 1
                                    }}
                                >
                                    {useChatContext?.chatBoxBar?.caption}
                                </Typography>
                            </Stack>
                        </Stack>
                    </AccordionSummary>

                    <AccordionDetails sx={{p: 0}}>
                        {useChatContext?.chatBoxBody}
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
}

export default ChatBoxPcLayout;