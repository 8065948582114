import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const chatAPI = createApi({
    reducerPath: "chatAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllChatsList: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `chat/user?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["chats"]
        }),
        getAllChatsFromUser: builder.mutation({
            query: ({page, chatID}) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `chat/user/${chatID}?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        getUserInboxDetails: builder.mutation({
            query: ({userID}) => {
                const queryParams = new URLSearchParams({
                    'page': '0',
                    'size': '10'
                });

                const apiUrl = `chat/user/inbox/${userID}?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        replyToMessage: builder.mutation({
            query: ({chatId, data}) => {
                return {
                    url: `chat/user/${chatId}`,
                    method: "POST",
                    body: data
                };
            },
            transformResponse: (response) => response
        }),
        readUserMessage: builder.mutation({
            query: (chatID) => {
                return {
                    url: `chat/user/read/${chatID}`,
                    method: "POST"
                };
            },
            transformResponse: (response) => response
        }),


        getAllChatsFromAdmin: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `chat/user/admin?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["chats_admin"]
        }),
        replyToAdmin: builder.mutation({
            query: (data) => {
                return {
                    url: `chat/user/admin`,
                    method: "POST",
                    body: data
                };
            },
            transformResponse: (response) => response
        }),
        readAdminMessage: builder.mutation({
            query: () => {
                return {
                    url: `chat/user/read/admin`,
                    method: "POST"
                };
            },
            transformResponse: (response) => response
        })
    }),
});

export const { 
    useGetAllChatsListMutation,
    useGetAllChatsFromUserMutation,
    useGetUserInboxDetailsMutation,
    useReplyToMessageMutation,
    useGetAllChatsFromAdminMutation,
    useReplyToAdminMutation,
    useReadAdminMessageMutation,
    useReadUserMessageMutation
} = chatAPI;

export default chatAPI;