import PropTypes from 'prop-types';

import { 
    Grid,
    Box,
    Typography
} from "@mui/material";

import LoadingButtonComponent from '../../../components/form/loading-button-component';
import ButtonComponent from '../../../components/form/button-component';
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import Footer from '../../footer/footer';
import { useVerifyEmailMutation } from '../../../services/verify-email-api';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useAuth } from '../../../contexts/auth/auth-provider';

const VerifyEmailLayout = ({verifiedCallback, skipCallback, userEmail}) => {
    const { t } = useTranslation();
    const verify_email_locale = "profile_verification.verify_email";

    const navigate = useNavigate();

    const [email, setEmail] = useState('');

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    useEffect(() => {
        if(userEmail){
            setEmail(userEmail);
        }
    }, [userEmail]);

    const auth = useAuth();

    useEffect(() => {
        if(auth && auth.currentUser){
            console.log(auth.currentUser.email);
            setEmail(auth.currentUser.email);
        }
    }, [auth]);

    const [
        verifyEmail,
        {
            isLoading: isLoading,
            isSuccess: isSuccess,
            data: data,
            isError: isError,
            error: error
        }
    ] = useVerifyEmailMutation();

    useEffect(() => {
        if(isSuccess){
            setShowSnackBar({
                state: true,
                message: t(`${verify_email_locale}.verification_email_sent`),
                color: "green"
            });
    
            setTimeout(() => {
                if(verifiedCallback) {
                    verifiedCallback();
                }
                else{
                    navigate(-1);
                }
            }, 3000);
        }
        else if(isError && error){
            console.log(error);

            setShowSnackBar({
                state: true,
                message: `${error.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccess, isError, error, verifiedCallback]);

    const callVerification = useCallback(async () => {
        await verifyEmail(email);
    }, [email]);

    const skipVerification = useCallback(() => {
        if(skipCallback){
            skipCallback();
        }
        else{
            navigate(-1);
        }
    }, [navigate, skipCallback]);

    return(
        <>
            <Grid
                xs
                container
                sx={{height: '100vh', overflow: 'hidden'}}
            >
                {/* Arrow Illustration */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='row'
                    justifyContent={{xs: 'center', md: 'flex-end'}}
                >
                    <Box
                        component="img"
                        sx={{
                            content: `url('/assets/icons/authentication-2.png')`,
                            width: {xs: 150, md: 260, lg: 336}
                        }}
                        alt="icon"
                    />
                </Grid>

                {/* Body */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{px: {xs: 5, md: 25, lg: 50}}}
                >
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: 30, lg: 48}}
                        letterSpacing={-2}
                    >
                        {t(`${verify_email_locale}.heading`)}
                    </Typography>

                    <Typography
                        fontWeight={400}
                        fontSize={14}
                        sx={{pt: 3}}
                    >
                        {t(`${verify_email_locale}.instruction1`)} <span style={{fontWeight: 700, color: '#475569'}}>{email}</span> {t(`${verify_email_locale}.instruction2`)}
                    </Typography>

                    <LoadingButtonComponent
                        text={t(`${verify_email_locale}.verify_action`)}
                        inLoading={isLoading}
                        onClick={callVerification}
                        sx={{mt: 4}}
                        disabled={email === ''}
                    />

                    <ButtonComponent 
                        text={t(`${verify_email_locale}.skip_action`)}
                        outlined
                        onClick={skipVerification}
                        sx={{mt: 3}}
                    />
                </Grid>

                {/* Heart Illustration */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='row'
                    justifyContent={{xs: 'center', md: 'start'}}
                >
                    <Box
                        component="img"
                        sx={{
                            content: `url('/assets/icons/authentication-7.png')`,
                            width: {xs: 277, lg: 407}
                        }}
                        alt="icon"
                    />
                </Grid>
            </Grid>

            <Footer />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />  
        </>
    );
}

export default VerifyEmailLayout;

VerifyEmailLayout.propType = {
    verifiedCallback: PropTypes.func, 
    skipCallback: PropTypes.func
}