import PropTypes from 'prop-types';

import { 
    Box, 
    Stack, 
    Typography, 
    useTheme
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useAuth } from '../../contexts/auth/auth-provider';

const ChatMessageBody = ({chats, changePage, chatRef, height='150px'}) => {
    const theme = useTheme();

    const authState = useAuth();

    const getMessageTime = useCallback((createdAt) => {
        const split = new Date(createdAt);
        const date = split.toDateString();
        const time = split.toLocaleTimeString();

        return date.concat(" ").concat(time);
    }, []);

    const getMessageBox = (message, createdAt, side) => 
    <Stack
        direction='column'
        alignItems={side === -1 ? 'start' : 'end'}
        sx={{
            width: '100%',
            my: 1
        }}
    >
        <Box
            sx={{
                width: '50%',
                backgroundColor: side === -1 ? '#F6F6F6' : theme.palette.primary.main,
                borderRadius: side === -1 ? '50px 50px 50px 0px' : '50px 50px 0px 50px',
                p: 2
            }}
        >
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    textAlign: side === -1 ? 'left' : 'right',
                    color: side === -1 ? '#121212' : 'white'
                }}
            >
                {message}
            </Typography>
        </Box>

        <Typography
            sx={{
                fontWeight: 400,
                fontSize: 12,
                textAlign: side === -1 ? 'left' : 'right',
                color: '#6D6D6D',
                pt: 0.5
            }}
        >
            {getMessageTime(createdAt)}
        </Typography>
    </Stack>

    const handleScroll = (e) => {
        if (e.target.scrollTop === 0 && changePage) { 
            changePage();
        }
    }

    return(
        <>
            <Stack
                direction='column'
                alignItems='center'
                mx={2}
                my={0}
                spacing={2}
                onScroll={handleScroll}
                className='hideScroll'
                sx={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    height: {xs: '100%', md: `calc(60vh - ${height})`}
                }}
            >
                {
                    chats.map((item, index) => {
                        return <Stack
                            key={index}
                            direction='column'
                            sx={{
                                width: '100%'
                            }}
                        >
                            {
                                getMessageBox(item.content, item.createdAtTimestamp, item.senderId === authState.currentUser.uuid ? 1 : -1)
                            }

                            {index === chats.length - 1 ? <div ref={chatRef}/> : <></>}
                        </Stack>
                    })
                }
            </Stack>
        </>
    );
}

export default ChatMessageBody;

ChatMessageBody.propType = {
    chat: PropTypes.array,
    changePage: PropTypes.func,
    height: PropTypes.number
}