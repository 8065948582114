import { Helmet } from "react-helmet";
import AuthorizeEmailLayout from "../../sections/profile-verification/verify-email/authorize-email-layout";

const AuthorizeEmailPage = () => {
    return(
        <>
            <Helmet>
                <title>Authorize Email</title>
            </Helmet>

            <AuthorizeEmailLayout />
        </>
    );
}

export default AuthorizeEmailPage;