import { Helmet } from "react-helmet";
import NotFoundLayout from "../../sections/error/not-found/not-found-layout";

const NotFoundPage = () => {
    return(
        <>
            <Helmet>
                <title>Page Not Found</title>
            </Helmet>

            <NotFoundLayout />
        </>
    );
}

export default NotFoundPage;