import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const searchAPI = createApi({
    reducerPath: "searchAPI",
    baseQuery,
    endpoints: (builder) => ({
        search: builder.mutation({
            query: ({filterRequest, page}) => {
                const apiUrl = `search?page=${page}&size=10`;

                return {
                    url: apiUrl,
                    method: "PUT",
                    body: filterRequest
                };
            },
            transformResponse: (response) => response,
            providesTags: ["search_results"]
        }),
        getSearchFilters: builder.mutation({
            query: () => {
                const apiUrl = `search/filter`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["search_results"]
        }),
        saveSearch: builder.mutation({
            query: (body) => ({
                url: `search`,
                method: "POST",
                body: body
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['search_filters']
        }),
        deleteSearchFilter: builder.mutation({
            query: (id) => ({
                url: `search/${id}`,
                method: "DELETE"
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['search_filters']
        }),
        updateSearchFilter: builder.mutation({
            query: ({id, body}) => ({
                url: `search/${id}`,
                method: "PUT",
                body: body
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['search_filters']
        })
    })
});

export const { 
    useSearchMutation,
    useGetSearchFiltersMutation,
    useSaveSearchMutation,
    useDeleteSearchFilterMutation,
    useUpdateSearchFilterMutation
} = searchAPI;

export default searchAPI;