import PropTypes from 'prop-types';

import { 
    Box,
    List,
    ListItem
} from "@mui/material";
import ProfileSearchCard from './profile-search-card';
import SkeletonComponent from '../../../components/loading/skeleton-component';
import EmptyLayout from '../../error/empty/empty-layout';

const ProfileSearchBody = ({data, successCallback, isLoading=false}) => {
    return (
        <>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                mx={{xs: 1, md: 5}}
            >
                <List sx={{width: '100%'}}>
                    {
                        isLoading
                        ?
                        <>
                            {
                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                                    return <ListItem 
                                        key={index}
                                        sx={{my: 1}}
                                    >
                                        <Box sx={{width: '100%'}}>
                                            <SkeletonComponent 
                                                key={index} 
                                                variant='rounded' 
                                                height='200px'
                                            />
                                        </Box>
                                    </ListItem>
                                })
                            }
                        </>
                        :
                        <>
                            {
                                data.length > 0
                                ?
                                data.map((item, index) => {
                                    return <ListItem 
                                        key={index}
                                    >
                                        <ProfileSearchCard data={item} successCallback={successCallback}/>
                                    </ListItem>
                                })
                                :
                                <Box sx={{my: 20}}>
                                    <EmptyLayout />
                                </Box>
                            }
                        </>
                    }
                </List>
            </Box>
        </>
    );
}

export default ProfileSearchBody;

ProfileSearchBody.propType = {
    successCallback: PropTypes.func,
    data: PropTypes.array,
    isLoading: PropTypes.bool
}