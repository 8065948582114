import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { 
    Grid,
    Box,
    Typography,
    Stack,
    useTheme
} from "@mui/material";

import LoadingButtonComponent from '../../../components/form/loading-button-component';
import TextFieldComponent from '../../../components/form/text-field-component';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ButtonComponent from '../../../components/form/button-component';
import ArrowLeft from '../../../assets/icons/arrow-left';

import FormProviderComponent from "../../../components/form/form-provider-component";
import { useForgotPasswordMutation } from '../../../services/auth-api';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';

import ReCAPTCHA from "react-google-recaptcha";
import { useCallback } from 'react';
import ParkSolidIcon from '../../../assets/icons/park-solid-icon';

const ResetPasswordLayout = () => {
    const theme = useTheme();

    const { t } = useTranslation();
    const reset_password_locale = "authentication.reset_password";

    const [emailSent, setEmailSent] = useState(false);

    const navigate = useNavigate();

    const [recaptchaValidated, setRecaptchaValidated] = useState(true);
    
    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [
        forgotPassword,
        {
            isLoading,
            isSuccess,
            data,
            isError,
            error
        }
    ] = useForgotPasswordMutation();

    const formEmailSchema = yup.object().shape({
        email: yup.string().required(t(`${reset_password_locale}.errors.email_is_required`)).email(t(`${reset_password_locale}.errors.invalid_email`))
    });

    const defaultEmailValues = {
        email: ''
    };

    const formEmailMethods = useForm({
        resolver: yupResolver(formEmailSchema),
        defaultValues: defaultEmailValues
    });

    const {
        reset: resetEmailForm,
        handleSubmit: handleEmailSubmit,
        formState: { isSubmitting: isSubmittingEmail },
    } = formEmailMethods;

    const onEmailSubmit = handleEmailSubmit(async(data) => {
        console.log(data);

        try{
            await forgotPassword(data.email);
        }
        catch(error){
            console.log(error);
        }
    });

    useEffect(() => {
        if(isSuccess){
            setEmailSent(true);

            resetEmailForm();
        }
    }, [isSuccess, resetEmailForm]);

    useEffect(() => {
        if(isError && error){
            console.log(error);

            setShowSnackBar({
                state: true,
                message: `${error.data.error.message}`,
                color: "red"
            });
        }
    }, [isError, error]);

    const formPasswordSchema = yup.object().shape({
        newPassword: yup.string().required('New password is required').test('isValidPassword', 'Passwords are not matching', () => {
            const isValid = IsPasswordsValid();
            return isValid;
        }),
        confirmPassword: yup.string().required('Confirm password is required').test('isValidUsername', 'Passwords are not matching', () => {
            const isValid = IsPasswordsValid();
            return isValid;
        })
    });

    const defaultPasswordValues = {
        newPassword: '',
        confirmPassword: ''
    };

    const formPasswordMethods = useForm({
        resolver: yupResolver(formPasswordSchema),
        defaultValues: defaultPasswordValues
    });

    const {
        reset: resetPasswordForm,
        handleSubmit: handlePasswordSubmit,
        formState: { isSubmitting: isSubmittingPassword },
        watch: watchPassword
    } = formPasswordMethods;

    const valuesPasswordForm = watchPassword();

    const IsPasswordsValid = () => {
        return valuesPasswordForm.newPassword === valuesPasswordForm.confirmPassword;
    }

    const onPasswordSubmit = handlePasswordSubmit(async(data) => {
        console.log(data);

        try{
            
        }
        catch(error){
            console.log(error);
        }
    });

    const recaptchaOnChange = useCallback((value) => {
        console.log("Captcha value:", value);
    }, []);

    const emailForm = () => <>
        <FormProviderComponent methods={formEmailMethods} onSubmit={onEmailSubmit}>
            <Typography
                fontWeight={400}
                fontSize={14}
                sx={{pt: 3}}
            >
                {t(`${reset_password_locale}.email_instruction`)}
            </Typography>

            <TextFieldComponent 
                name='email'
                label={t(`${reset_password_locale}.email_label`)}
                placeholder='example@email.com'
                sx={{mt: 3}}
            />

            <LoadingButtonComponent
                text={t(`${reset_password_locale}.email_action`)}
                inLoading={isSubmittingEmail}
                type="submit"
                sx={{mt: 2}}
                disabled={!recaptchaValidated}
            />

            {/* <Stack mt={2} direction='row' display='flex' justifyContent='center'>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_ID}
                    onChange={recaptchaOnChange}
                />
            </Stack> */}
        </FormProviderComponent>
    </>

    const passwordForm = () => <>
        <FormProviderComponent methods={formPasswordMethods} onSubmit={onPasswordSubmit}>
            <Typography
                fontWeight={400}
                fontSize={14}
                sx={{pt: 3}}
            >
                {t(`${reset_password_locale}.password_instruction`)}
            </Typography>

            <TextFieldComponent 
                name='newPassword'
                label={t(`${reset_password_locale}.new_password_label`)}
                sx={{mt: 3}}
            />

            <TextFieldComponent 
                name='confirmPassword'
                label={t(`${reset_password_locale}.confirm_password_label`)}
            />

            <LoadingButtonComponent
                text={t(`${reset_password_locale}.password_action`)}
                inLoading={isSubmittingPassword}
                type="submit"
                sx={{mt: 2}}
            />
        </FormProviderComponent>
    </>

    const emailSentView = () => <>
       <Typography
            fontWeight={400}
            fontSize={14}
            sx={{py: 3, textAlign: 'center'}}
        >
            {t(`${reset_password_locale}.check_your_email`)}
        </Typography>
 
        <ParkSolidIcon style={{color: 'green', width: '100px', height: '100px', marginBottom: '20px'}}/>
    </>

    return(
        <>
            <Grid
                xs
                container
                sx={{height: '100vh'}}
            >
                {/* Body */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{px: {xs: 5, md: 25, lg: 50}, textAlign: 'center'}}
                >
                    {/* <Stack direction='row' justifyContent='start' sx={{width: '100%', ml: {xs: 1, md: 10}}}>
                        <ButtonComponent 
                            text={t(`${reset_password_locale}.back`)}
                            onClick={() => navigate(-1)}
                            width={124}
                            startIcon={<ArrowLeft style={{width: '22px', color: theme.palette.primary.main}}/>}
                            variant='text'
                            sx={{
                                color: theme.palette.primary.main,
                                textTransform: 'capitalize',
                                mb: 2,
                                textSize: 16
                            }}
                        />
                    </Stack> */}

                    {/* Hand Illustration */}
                    <Box
                        component="img"
                        sx={{

                            content: `url('/assets/icons/authentication-1.png')`,
                            width: {xs: 200, lg: 313},
                            position: 'relative',
                            right: 0,
                            top: 0,
                            zIndex: -1,
                            opacity: '100%'
                        }}
                        alt="icon"
                    />

                    {/* Title */}
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: 30, lg: 48}}
                        letterSpacing={-2} 
                    >
                        {t(`${reset_password_locale}.heading`)}
                    </Typography>

                    {
                        emailSent
                        ?
                        emailSentView()
                        :
                        emailForm()
                    }

                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: '#6D6D6D',
                            textAlign: 'center',
                            pt: 4
                        }}
                    >
                        {t(`${reset_password_locale}.return_to`)}

                        <span>
                            <button 
                                style={{
                                    fontFamily: 'Inter', 
                                    fontWeight: 600, 
                                    fontSize: 14,
                                    color: theme.palette.primary.main,
                                    backgroundColor: '#ffffff',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate('/login')}
                            >
                                {t(`${reset_password_locale}.signin_action`)}
                            </button>
                        </span>
                    </Typography>
                </Grid>
            </Grid>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default ResetPasswordLayout;