import PropTypes from 'prop-types';

import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { FormHelperText, Stack, Typography, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const DatePickerComponent = ({
    name,
    label, 
    required=false, 
    minDate='',
    maxDate=''
}) => {
    const theme = useTheme();

    const { control } = useFormContext();
    
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Stack sx={{my: 1.5}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                                label={
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                            color: '#637381'
                                        }}
                                    >
                                        {required ? `${label} *` : label}
                                    </Typography>
                                }
                                onChange={(newValue) => {
                                    field.onChange(newValue);
                                }}
                                defaultValue={field.value ? dayjs(field.value) : null} //2022-04-17
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: error ? 'rgba(255, 0, 0, 1)' : 'rgba(145, 158, 171, 0.4)',
                                            borderWidth: "1px",
                                            borderRadius: '8px'
                                        },
                                        "&.Mui-focused": {
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: theme.palette.primary.main,
                                                borderWidth: "2px",
                                            },
                                        },
                                    }
                                }}
                                minDate={dayjs(minDate)}
                                maxDate={dayjs(maxDate)}
                            />
                        </LocalizationProvider>

                        <FormHelperText sx={{color: 'rgba(255, 0, 0, 1)', px: 1.5}}>{error?.message}</FormHelperText>
                    </Stack>
                )}
            />
        </>
    );
}

export default DatePickerComponent;

DatePickerComponent.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool
}