import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const uploadAPI = createApi({
    reducerPath: "uploadAPI",
    baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_BASE_URL}),
    endpoints: (builder) => ({
        getUploadNDownloadURL: builder.mutation({
            query: ({type, extension}) => {
                let apiUrl = `images/upload-and-download-url?type=${type}&extension=${extension}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        uploadToS3: builder.mutation({
            query: ({ uploadUrl, file }) => ({
                url: `${uploadUrl}`,
                method: "PUT",
                body: file
            })
        })
    })
});

export const { 
    useGetUploadNDownloadURLMutation,
    useUploadToS3Mutation
} = uploadAPI;

export default uploadAPI;