import PropTypes from 'prop-types';

import { 
    Box,
    Card,
    Grid,
    Link,
    Stack,
    Typography
} from "@mui/material";

import CheckDecagramIcon from '../../../assets/icons/check-decagram-icon';
import CalendarMonthOutlineIcon from '../../../assets/icons/calender-month-outline-icon';
import HumanHeightIcon from '../../../assets/icons/human-height-icon';
import HandsPrayIcon from '../../../assets/icons/hands-pray-icon';
import SchoolOutlineIcon from '../../../assets/icons/school-outline-icon';
import BriefcaseOutlineIcon from '../../../assets/icons/briefcase-outline-icon';
import MapMarkerOutlineIcon from '../../../assets/icons/map-marker-outline-icon';

import LoadingButtonComponent from '../../../components/form/loading-button-component';
import { useTranslation } from 'react-i18next';
import DialogBox from '../../../components/Dialog/dialog-box';
import { useCallback, useEffect, useState } from 'react';
import BookAccountOutlineIcon from '../../../assets/icons/book-account-outline-icon';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useUnblockMutation } from '../../../services/block-api';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from '../../../contexts/analytics/analytics-provider';

const BlockedProfileCard = ({data, successCallback}) => {
    const { t } = useTranslation();
    const blocked_locale = "profile_settings.blocked_profile";

    const navigate = useNavigate();

    const {logCustomEvent, logCustomEventWithParams} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const {
        userId,
        profileImages,
        firstName,
        memberId,
        verified,
        age,
        height,
        religion,
        education,
        occupation,
        countryOfLiving,
        caste,
        blocked
    } = data;

    const iconStyle = {width: '20px', height: '20px', color: '#6D6D6D'};

    const [openDialog, setOpenDialog] = useState(false);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const userOtherDetail = (customIcon, value, needBullet=true) => <Stack direction='row' alignItems='center' spacing={1}>
        {customIcon}
        
        <Typography
            sx={{
                fontWeight: 500,
                fontSize: 14,
                color: '#6D6D6D'
            }}
        >
            {value}
        </Typography>
    </Stack>

    const [
        unblockUser,
        {
            isLoading: isLoadingUnblockInterest,
            isSuccess: isSuccessUnblockInterest, 
            isError: isErrorUnblockInterest, 
            error: errorUnblockInterest
        }
    ] = useUnblockMutation();

    useEffect(() => {
        if(isSuccessUnblockInterest){
            logAnalytics('USER_UNBLOCKED');

            setShowSnackBar({
                state: true,
                message: t(`${blocked_locale}.unblocked`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1000);
        }
    }, [isSuccessUnblockInterest]);

    useEffect(() => {
        if(isErrorUnblockInterest && errorUnblockInterest){
            console.log(errorUnblockInterest);

            setShowSnackBar({
                state: true,
                message: `${errorUnblockInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorUnblockInterest, errorUnblockInterest]);

    const requestUnblock = useCallback(() => {
        setOpenDialog(true);
    }, []);

    const confirmUnblock = useCallback(async () => {
        setOpenDialog(false);
        await unblockUser(userId);
    }, [userId]);

    const viewProfile = useCallback(() => {
        console.log(memberId);
        navigate(`/profile/${memberId}`);
    }, [memberId]);

    return (
        <>
            <Card 
                sx={{
                    width: '100%', 
                    my: 1,
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)'
                }}
            >
                <Grid
                    xs
                    container
                    direction={{xs: 'column', md: 'row'}}
                    p={2}
                >
                    <Stack alignItems='center'>
                        <Box 
                            sx={{
                                width: {xs: '300px', md: '200px'}, 
                                height: {xs: '300px', md: '200px'}
                            }}
                        >
                            <img 
                                src={profileImages.length > 0 ? profileImages[0]: '/assets/images/profile/profile.jpg'} 
                                alt={firstName} 
                                style={{ 
                                    width: '100%', 
                                    height: '100%', 
                                    borderRadius: '4px'
                                }}
                            />
                        </Box>
                    </Stack>

                    <Stack
                        display='flex'
                        direction='column'
                        justifyContent='start'
                        alignItems='start'
                        spacing={1.5}
                        sx={{
                            py: 2,
                            pl: {xs: 0, md: 2},
                        }}
                    >
                        <Link onClick={() => viewProfile()} underline='none' sx={{cursor: 'pointer'}}>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#121212'
                                }}
                            >
                                {firstName} <CheckDecagramIcon style={{color: '#00AAC4', marginBottom: -4, display: verified ? 'inline-block' : 'none'}}/>
                            </Typography>
                        </Link>

                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: '#6D6D6D'
                            }}
                        >
                            {memberId}
                        </Typography>

                        <Stack 
                            direction='row' 
                            spacing={3} 
                            sx={{pb: 2}} 
                            useFlexGap 
                            flexWrap="wrap"
                            gap={1}
                        >
                            {userOtherDetail(<CalendarMonthOutlineIcon style={iconStyle}/>, age, false)}
                            {userOtherDetail(<HumanHeightIcon style={iconStyle}/>, height)}
                            {userOtherDetail(<HandsPrayIcon style={iconStyle}/>, religion)}
                            {userOtherDetail(<SchoolOutlineIcon style={iconStyle}/>, education)}
                            {userOtherDetail(<BriefcaseOutlineIcon style={iconStyle}/>, occupation)}
                            {userOtherDetail(<MapMarkerOutlineIcon style={iconStyle}/>, countryOfLiving)}
                            {userOtherDetail(<BookAccountOutlineIcon style={iconStyle}/>, caste)}
                        </Stack>

                        <LoadingButtonComponent 
                            text={t(`${blocked_locale}.action`)}
                            onClick={requestUnblock}
                            inLoading={isLoadingUnblockInterest}
                            sx={{
                                width: {xs: '100%', md: '175px'},
                            }}
                            textSx={{fontSize: {xs: 11, md: 14}}}
                        />
                    </Stack>
                </Grid>
            </Card>

            <DialogBox 
                title={t(`${blocked_locale}.action`)}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {t(`${blocked_locale}.unblock_confirmation`)}
                    </Typography>
                }
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
                onSuccess={confirmUnblock}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            /> 
        </>
    );
}

export default BlockedProfileCard;

BlockedProfileCard.propType = {
    data: PropTypes.object,
    successCallback: PropTypes.func
}