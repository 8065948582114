import PropTypes from 'prop-types';

import { 
    Grid,
    Typography,
    useTheme,
    Link,
    Box,
    Stack
} from "@mui/material";

import ButtonComponent from '../../../components/form/button-component';

const ProfileFeedWarningCard = ({title, caption, actionTxt, action, needHeart=true}) => {
    const theme = useTheme();

    return (
        <>
            <Box>
                <Grid
                    xs
                    container
                    direction='column'
                    display='flex'
                    justifyContent='center'
                    alignItems='start'
                    sx={{
                        mt: 5,
                        backgroundColor: theme.palette.primary.main,
                        py: 5,
                        pl: 4,
                        borderRadius: '16px',
                        border: '4px solid rgba(255, 255, 255, .5)',
                        boxSizing: "border-box"
                    }}
                >
                    <Grid 
                        xs={12}
                    >
                        <Typography
                            textAlign='left'
                            sx={{
                                fontWeight: 600,
                                fontSize: {xs: 16, md: 16, lg: 24},
                                color: 'white',
                                pb: 2
                            }}
                        >
                            {title}
                        </Typography>
                    </Grid>

                    {
                        caption !== ""
                        ?
                        <Grid 
                            xs={12}
                        >
                            <Typography
                                textAlign='left'
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 16,
                                    lineHeight: '24px',
                                    color: 'white',
                                    pb: 3
                                }}
                            >
                                {caption}
                            </Typography>
                        </Grid>
                        :
                        <></>
                    }

                    <Grid 
                        xs={12}
                        sx={{zIndex: 1}}
                    >
                        <Link href={action} underline="none">
                            <ButtonComponent 
                                text={actionTxt}
                                onClick={() => {}}
                                width={136}
                                sx={{
                                    color: 'black',
                                    backgroundColor: 'white',
                                    cursor: 'pointer',
                                    "&:hover": {
                                        backgroundColor: "#FFFFFF",
                                        transform: 'scale(1.03)'
                                    }
                                }}
                                textSx={{
                                    textTransform: 'capitalize'
                                }}
                            />
                        </Link>
                    </Grid>

                    {/* Heart Illustration */}
                    {
                        needHeart
                        ?
                        <Grid
                            xs={10}
                            container
                            direction='row'
                            justifyContent='space-between'
                            position='absolute'
                            px={{xs: 1, md: 5, lg: 10}}
                            sx={{
                                transform: {xs: 'translateY(50px)', md: 'translateY(40px)', lg: 'translateY(50px)'}
                            }}
                        >
                            <Grid 
                                xs={6}
                            >
                                <Stack>
                                    <Box
                                        component="img"
                                        sx={{
                                            content: `url('/assets/icons/home-reminder-heart-1.png')`,
                                            width: {xs: 150, md: 207},
                                            position: 'absolute',
                                            zIndex: -2,
                                            animation: 'heartOpacity 1s linear infinite',
                                            display: {sm:'block' ,xs:'none'}
                                        }}
                                        alt="icon"
                                    />

                                    <Box
                                        component="img"
                                        sx={{
                                            content: `url('/assets/icons/home-reminder-heart-1.png')`,
                                            width: {xs: 90, md: 122},
                                            position: 'absolute',
                                            zIndex: -1,
                                            transform: 'translate(200px, 100px)'
                                        }}
                                        alt="icon"
                                    />
                                </Stack>
                            </Grid>

                            <Grid 
                                xs={6} 
                                display='flex'
                                justifyContent='end'
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        content: `url('/assets/icons/home-reminder-heart-2.png')`,
                                        width: {xs: 150, md: 207},
                                        position: 'absolute',
                                        zIndex: -2,
                                        animation: 'heartOpacity 1s linear infinite'
                                    }}
                                    alt="icon"
                                />

                                <Box
                                    component="img"
                                    sx={{
                                        content: `url('/assets/icons/home-reminder-heart-2.png')`,
                                        width: {xs: 90, md: 122},
                                        position: 'absolute',
                                        zIndex: -1,
                                        transform: 'translate(-200px, 100px)'
                                    }}
                                    alt="icon"
                                />
                            </Grid>
                        </Grid>
                        :
                        <></>
                    }
                </Grid>
            </Box>
        </>
    );
}

export default ProfileFeedWarningCard;

ProfileFeedWarningCard.propType = {
    title: PropTypes.string, 
    caption: PropTypes.string, 
    actionTxt: PropTypes.string, 
    action: PropTypes.string,
    needHeart: PropTypes.bool
}