import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ProfileValueText from './profile-value-text';
import SkeletonComponent from '../../components/loading/skeleton-component';

const ProfileEditBody = ({dataList, children, privateView, callback, isLoading}) => {
    const getData = (item, index) => <Stack
        key={index}
        direction='column'
        justifyContent='center'
        alignItems='start'
        sx={{width: '100%'}}
        spacing={1}
    >
        <Stack
            direction='row'
            justifyContent='start'
            alignItems='center'
            spacing={1}
        >
            {item.icon}

            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#6D6D6D'
                }}
            >
                {item.label}
            </Typography>
        </Stack>

        <Box sx={{pl: 3}}>
            <ProfileValueText 
                value={item.value} 
                label={item.label} 
                callback={() => callback(item)} 
                privateView={privateView}
                restrictedText={item.restrictedText}
            />
        </Box>
    </Stack>

    return (
        <>
            <Stack
                spacing={3}
                sx={{
                    direction: 'column',
                    justifyContent: 'center',
                    alignItems: 'start',
                    width: '100%'
                }}
            >
                <Grid xs container sx={{display: {xs: 'flex', md: 'none'}}}>
                    <Stack spacing={2}>
                        {
                            isLoading
                            ?
                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                                return <SkeletonComponent key={index} width='200px' height='40px'/>
                            })
                            :
                            dataList.map((item, index) => {
                                return getData(item, index)
                            })
                        }
                    </Stack>
                </Grid>

                <Grid xs container sx={{display: {xs: 'none', md: 'flex'}}}>
                    <Grid xs={6}>
                        <Stack spacing={2}>
                            {
                                isLoading
                                ?
                                [0, 1, 2, 3, 4].map((item, index) => {
                                    return <SkeletonComponent key={index} width='200px' height='40px'/>
                                })
                                :
                                dataList.slice(0, dataList.length %2 === 0 ? (dataList.length * 0.5 | 0) : (dataList.length * 0.5 | 0) + 1).map((item, index) => {
                                    return getData(item, index)
                                })
                            }
                        </Stack>
                    </Grid>

                    <Grid xs={6}>
                        <Stack spacing={2}>
                            {
                                isLoading
                                ?
                                [0, 1, 2, 3, 4].map((item, index) => {
                                    return <SkeletonComponent key={index} width='200px' height='40px'/>
                                })
                                :
                                dataList.slice(dataList.length %2 === 0 ? (dataList.length * 0.5 | 0) : (dataList.length * 0.5 | 0) + 1, dataList.length).map((item, index) => {
                                    return getData(item, index)
                                })
                            }
                        </Stack>
                    </Grid>
                </Grid>

                {children ? children : <></>}
            </Stack>
        </>
    );
}

export default ProfileEditBody;

ProfileEditBody.propType = {
    dataList: PropTypes.array,
    children: PropTypes.object,
    privateView: PropTypes.bool,
    callback: PropTypes.func,
    isLoading: PropTypes.bool
}