import PropTypes from 'prop-types';

import { 
    Card,
    Box,
    Stack,
    Typography,
    IconButton,
    Link
} from "@mui/material";

import { useTranslation } from 'react-i18next';

import ChevronRightIcon from '../../../assets/icons/chevron-right-icon';
import ChevronLeftIcon from '../../../assets/icons/chevron-left-icon';
import EmptyLayout from '../../error/empty/empty-layout';
import SkeletonComponent from '../../../components/loading/skeleton-component';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
 
const ProfileFeedListViewBody = ({list, id, isLoading}) => {
    const { t } = useTranslation();
    const profile_feed_locale = "profile_feed";

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const navigator = useNavigate();

    const viewProfile = useCallback((user) => {
        console.log(user);
        navigator(`/profile/${user.memberId}`);
    }, []);

    return(
        <>
            <Box sx={{pb: 2}}>
                <Stack
                    direction='row'
                    justifyContent='start'
                    alignItems='center'
                    spacing={2}
                    sx={{ overflowX:'scroll', display: 'flex', scrollBehavior: 'smooth' }}
                    className='hideScroll'
                    id={id}
                >
                    {
                        isLoading
                        ?
                        <>
                            {
                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                                    return <SkeletonComponent key={item} variant='rectangular' width='280px' height='280px'/>
                                })
                            }
                        </>
                        :
                        <>
                        {
                            list.length > 0
                            ?
                            list.map((item, index) => {
                                return <Link key={index} onClick={() => viewProfile(item)} underline='none' sx={{cursor: 'pointer'}}>
                                    <Card
                                        key={index}
                                        elevation={0}
                                        sx={{
                                            borderRadius: '8px',
                                            mx: 1,
                                            minWidth: '280px !important',
                                            minHeight: '350px !important'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundImage: item.profileImages.length > 0 ? `url(${item.profileImages[0]})` : `url('/assets/images/profile/profile.jpg')`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                backgroundPositionX: '50%',
                                                width: '280px',
                                                minHeight: '280px',
                                                maxHeight: '280px',
                                                borderRadius: '8px'
                                            }}
                                        />

                                        <Stack direction='column' sx={{pt: 2}}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: 16,
                                                    color: '#121212'
                                                }}
                                            >
                                                {item.firstName}
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: '#6D6D6D'
                                                }}
                                            >
                                                {`${t(`${profile_feed_locale}.age`)} ${item.age} ${t(`${profile_feed_locale}.age_height_join`)} ${t(`${profile_feed_locale}.height`)} ${item.height} cm`}
                                            </Typography>
                                        </Stack>
                                    </Card>
                                </Link>
                            })
                            :
                            <EmptyLayout />
                        }
                        </>
                    }
                </Stack>

                {
                    !isLoading && list.length > 0
                    ?
                    <>
                        <IconButton
                            sx={{
                                borderRadius: '50%',
                                width: '40px',
                                height: '40px',
                                backgroundColor: 'white',
                                boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.16)',
                                zIndex: 2,
                                transform: 'translate(20px, -225px)',
                                left: 0,
                                "&:hover": {
                                    backgroundColor: 'white'
                                }
                            }}
                            onClick={() => {
                                document.getElementById(id).scrollLeft -= 200;
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>

                        <IconButton
                            sx={{
                                position: 'absolute',
                                borderRadius: '50%',
                                width: '40px',
                                height: '40px',
                                backgroundColor: 'white',
                                boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.16)',
                                transform: 'rotate(180deg)',
                                zIndex: 2,
                                transform: {xs: 'translate(-50px, -225px)', md: 'translate(-75px, -225px)'},
                                right: 0,
                                "&:hover": {
                                    backgroundColor: 'white'
                                }
                            }}
                            onClick={() => {
                                document.getElementById(id).scrollLeft += 200;
                            }}
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </>
                    :
                    <></>
                }
            </Box>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            /> 
        </>
    );
}

export default ProfileFeedListViewBody;

ProfileFeedListViewBody.propType = {
    list: PropTypes.array,
    id: PropTypes.string,
    isLoading: PropTypes.bool
}