import PropTypes from 'prop-types';

import { 
    Box,
    Divider, 
    Stack,
    TextField,
    InputAdornment,
    IconButton
} from "@mui/material";

import { useTranslation } from "react-i18next";

import SendIcon from '../../assets/icons/send-icon';
import { useCallback, useState } from 'react';

const ChatField = ({submit}) => {
    const { t } = useTranslation();
    const chat_locale = "chat";

    const [message, setMessage] = useState('');

    const sendMessage = useCallback(() => {
        if(message.trim().length > 0){
            submit(message.trim());
            setMessage('');
        }
    }, [message]);

    const onKeyDownHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <>
            <Box sx={{
                position: 'fixed', 
                bottom: 0, 
                paddingBottom: {xs: '75px', md:'15px'}, 
                backgroundColor: 'white', 
                paddingRight: '16px', 
                width: {xs:'100%', md: 515}
            }}>
                <Divider/>

                <Box
                    sx={{
                        backgroundColor: '#F6F6F6',
                        borderRadius: '50px 50px 50px 50px',
                        mt: 2,
                        py: 1,
                        px: 2,
                        mx: 2
                    }}
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <TextField 
                            name='chat'
                            label=''
                            variant="standard" 
                            value={message}
                            fullWidth
                            placeholder={t(`${chat_locale}.start_a_message`)}
                            onChange={(event) => setMessage(event.target.value)}
                            onKeyDown={onKeyDownHandler}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: 'transparent'
                                    },
                                    "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: 'transparent'
                                        },
                                    },
                                    "&.Mui-hover": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: 'transparent'
                                        },
                                    },
                                    "&.Mui-before": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: 'transparent'
                                        },
                                    },
                                }
                            }}
                            InputLabelProps={{
                                style: { color: 'rgba(145, 158, 171, 1)' },
                            }}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment : (
                                    <InputAdornment position="end">
                                        <IconButton onClick={sendMessage}>
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                </Box>
            </Box>
        </>
    );
}

export default ChatField;

ChatField.propType = {
    submit: PropTypes.func
}