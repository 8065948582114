import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../profile-settings-header";
import SettingsBody from "./settings-body";

import Footer from '../../footer/footer';

const SettingsLayout = () => {
    const { t } = useTranslation();
    const settings_locale = "profile_settings.settings";

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader heading={t(`${settings_locale}.heading`)}/>

            <SettingsBody />

            <Footer />
        </>
    );
}

export default SettingsLayout;