export const profileCreatorList = [
    {id: 0, label: 'Myself', value: 'MYSELF'},
    {id: 1, label: 'Father', value: 'FATHER'},
    {id: 2, label: 'Mother', value: 'MOTHER'},
    {id: 3, label: 'Sibling', value: 'SIBLING'}
];

export const genderList = [
    {id: 0, label: 'Male', value: 'MALE'},
    {id: 1, label: 'Female', value: 'FEMALE'}
];

export const physicalStatusList = [
    {id: 0, label: 'Normal', value: 'NORMAL'},
    {id: 1, label: 'Physically challenged', value: 'PHYSICALLY_CHALLENGED'}
];

export const bodyTypeList = [
    {id: 0, label: 'Slim', value: 'SLIM'},
    {id: 1, label: 'Average', value: 'AVERAGE'},
    {id: 2, label: 'Athletic', value: 'ATHLETIC'},
    {id: 3, label: 'Heavy', value: 'HEAVY'}
];

export const educationLevelList = [
    {id: 0, label: 'No formal education', value: 'No Formal Education'},
    {id: 1, label: 'High school / Secondary school', value: 'High School / Secondary School'},
    {id: 2, label: 'Higher secondary / Senior secondary', value: 'Higher Secondary / Senior Secondary'},
    {id: 3, label: 'Certificate course', value: 'Certificate Course'},
    {id: 3, label: 'Diploma', value: 'Diploma'},
    {id: 3, label: "Bachelor's degree", value: "Bachelor's Degree"},
    {id: 3, label: 'Associate degree', value: 'Associate Degree'},
    {id: 3, label: "Master's degree", value: "Master's Degree"},
    {id: 3, label: 'Postgraduate diploma', value: 'Postgraduate Diploma'},
    {id: 3, label: 'PhD / Doctorate', value: 'PhD / Doctorate'},
    {id: 3, label: 'Postdoctoral research', value: 'Postdoctoral Research'},
    {id: 3, label: 'Law degree (LLB, JD)', value: 'Law Degree (LLB, JD)'},
    {id: 3, label: 'Medical degree (MBBS, MD, DO)', value: 'Medical Degree (MBBS, MD, DO)'}
];

export const starList = [
    {id: 0, label: 'Aswini', value: 'Aswini'},
    {id: 1, label: 'Bharani', value: 'Bharani'},
    {id: 2, label: 'Karthikai', value: 'Karthikai'},
    {id: 3, label: 'Rohini', value: 'Rohini'},
    {id: 4, label: 'Mirugasiridam', value: 'Mirugasiridam'},
    {id: 5, label: 'Thiruvathirai', value: 'Thiruvathirai'},
    {id: 6, label: 'Punarpoosam', value: 'Punarpoosam'},
    {id: 7, label: 'Poosam', value: 'Poosam'},
    {id: 8, label: 'Aayiliyam', value: 'Aayiliyam'},
    {id: 9, label: 'Magham', value: 'Magham'},
    {id: 10, label: 'Pooram', value: 'Pooram'},
    {id: 11, label: 'Uttaram', value: 'Uttaram'},
    {id: 12, label: 'Avittam', value: 'Avittam'},
    {id: 13, label: 'Chithirai', value: 'Chithirai'},
    {id: 14, label: 'Swathi', value: 'Swathi'},
    {id: 15, label: 'Visakam', value: 'Visakam'},
    {id: 16, label: 'Swathasi', value: 'Swathasi'},
    {id: 17, label: 'Keattai', value: 'Keattai'},
    {id: 18, label: 'Moolam', value: 'Moolam'},
    {id: 19, label: 'Pooradam', value: 'Pooradam'},
    {id: 20, label: 'Uthiram', value: 'Uthiram'},
    {id: 21, label: 'Thiruvonam', value: 'Thiruvonam'},
    {id: 22, label: 'Sadayam', value: 'Sadayam'},
    {id: 23, label: 'Poorattathi', value: 'Poorattathi'},
    {id: 24, label: 'Revathy', value: 'Revathy'},
    {id: 25, label: 'Uthiratathi', value: 'Uthiratathi'},
    {id: 26, label: 'Punar poosam', value: 'Punar poosam'}
];

export const raasiList = [
    {id: 0, label: 'Aries', value: 'ARIES'},
    {id: 1, label: 'Taurus', value: 'TAURUS'},
    {id: 2, label: 'Gemini', value: 'GEMINI'},
    {id: 3, label: 'Cancer', value: 'CANCER'},
    {id: 4, label: 'Leo', value: 'LEO'},
    {id: 5, label: 'Virgo', value: 'VIRGO'},
    {id: 6, label: 'Libra', value: 'LIBRA'},
    {id: 7, label: 'Scorpio', value: 'SCORPIO'},
    {id: 8, label: 'Sagittarius', value: 'SAGITTARIUS'},
    {id: 9, label: 'Capricorn', value: 'CAPRICORN'},
    {id: 10, label: 'Aquarius', value: 'AQUARIUS'},
    {id: 11, label: 'Pisces', value: 'PISCES'}
];

export const decisionList = [
    {id: 0, label: 'Yes', value: 'YES'},
    {id: 1, label: 'No', value: 'NO'}
];

export const eatingHabitList = [
    {id: 0, label: 'Vegetarian', value: 'VEGETARIAN'},
    {id: 1, label: 'Non-Vegetarian', value: 'NON_VEGETARIAN'},
    {id: 2, label: 'Eggetarian', value: 'EGGETARIAN'}
];

export const drinkingHabitList = [
    {id: 0, label: 'No', value: 'NO'},
    {id: 1, label: 'drinks socially', value: 'DRINKS_SOCIALLY'},
    {id: 2, label: 'Yes', value: 'YES'}
];

export const smokingHabitList = [
    {id: 0, label: 'No', value: 'NO'},
    {id: 1, label: 'Occasionally', value: 'OCCASIONALLY'},
    {id: 2, label: 'Yes', value: 'YES'}
];