import { Button, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import ButtonComponent from '../../components/form/button-component';
import EditIcon from '../../assets/icons/edit-icon';

import { useTranslation } from 'react-i18next';

const ProfileEditHeader = ({heading, privateView, openEditAllCallback}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_locale = "profile";

    return (
        <>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{width: '100%', mb: 5}}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: {xs: 20, md: 26},
                        color: '#121212'
                    }}
                >
                    {heading}
                </Typography>

                {
                    !privateView || !openEditAllCallback
                    ?
                    <></>
                    :
                    <ButtonComponent 
                        text={t(`${profile_locale}.edit`)}
                        onClick={() => openEditAllCallback(`${t(`${profile_locale}.edit`)} ${heading.toLowerCase()}`)}
                        startIcon={<EditIcon />}
                        width={91}
                        sx={{
                            backgroundColor: '#FB39571A',
                            color: theme.palette.primary.main,
                            boxShadow: 'none',
                            "&:hover": {
                                backgroundColor: '#FB39571A'
                            }
                        }}
                    />
                }
            </Stack>
        </>
    );
}

export default ProfileEditHeader;

ProfileEditHeader.propType = {
    heading: PropTypes.string,
    privateView: PropTypes.bool,
    openEditAllCallback: PropTypes.func
}