import { Helmet } from "react-helmet";
import ResetPasswordLayout from '../../sections/auth/reset-password/reset-password-layout';
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const ResetPasswordPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_FORGOT_PASSWORD_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>

            <ResetPasswordLayout />
        </>
    );
}

export default ResetPasswordPage;