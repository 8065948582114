import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const verifyEmailAPI = createApi({
    reducerPath: "verifyEmailAPI",
    baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_BASE_URL}),
    endpoints: (builder) => ({
        verifyEmail: builder.mutation({
            query: (email) => {
                return {
                    url: `user/email/verification?email=${email}`,
                    method: "POST"
                };
            },
            transformResponse: (response) => response
        }),
        authorizeEmail: builder.mutation({
            query: (token) => {
                return {
                    url: `user/email/verify?token=${token}`,
                    method: "GET"
                };
            },
            transformResponse: (response) => response
        })
    })
});

export const { 
    useVerifyEmailMutation,
    useAuthorizeEmailMutation
} = verifyEmailAPI;

export default verifyEmailAPI;