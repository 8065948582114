import CarouselComponent from "../../components/corousel/carousel-component";

import { 
    Card,
    Box
} from "@mui/material";

const HomeCarousel = () => {
    const imageCard = (id) => {
        return <Card
            sx={{
                borderRadius: 8,
                mx: 2
            }}
        >
            <Box
                component="img"
                sx={{
                    content: `url('/assets/images/home/carousel-${id}.png')`,
                    width: '100%'
                }}
                alt="image"
            />
        </Card>
    }

    return(
        <>
            <Box
                sx={{
                    mt: 5
                }}
            >
                <CarouselComponent list={[imageCard(1), imageCard(2), imageCard(3), imageCard(4), imageCard(5)]}/>
            </Box>
        </>
    );
}

export default HomeCarousel;