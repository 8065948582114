import { Helmet } from "react-helmet";
import HomeLayout from "../../sections/main/home/home-layout";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const HomePage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_HOME_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>

            <HomeLayout />
        </>
    );
}

export default HomePage;