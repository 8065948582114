import PropTypes from 'prop-types';

import { 
    Divider,
    Stack,
    Avatar,
    Typography
} from '@mui/material';

import CalendarMonthOutlineIcon from '../../assets/icons/calender-month-outline-icon';
import SchoolOutlineIcon from '../../assets/icons/school-outline-icon';
import BriefcaseOutlineIcon from '../../assets/icons/briefcase-outline-icon';
import MapMarkerOutlineIcon from '../../assets/icons/map-marker-outline-icon';
import { useTranslation } from 'react-i18next';

const ChatMessageHeader = ({data}) => {
    const { t } = useTranslation();
    
    const {
        image,
        firstName,
        memberId,
        age,
        education,
        occupation,
        countryOfLiving,
    } = data;

    const iconStyle = {width: '20px', height: '20px', color: '#6D6D6D'};

    const userOtherDetail = (customIcon, value) => <Stack direction='row' alignItems='center' spacing={1}>
        {customIcon}
        
        <Typography
            sx={{
                fontWeight: 500,
                fontSize: 14,
                color: '#6D6D6D'
            }}
        >
            {value ? value : t('profile_feed.unknown')}
        </Typography>
    </Stack>

    return (
        <>
            <Divider />

            <Stack
                direction='column'
                alignItems='center'
                sx={{
                    width: '100%',
                    my: 3
                }}
                spacing={0.5}
            >
                <Avatar
                    alt={firstName}
                    src={image}
                    sx={{ width: 88, height: 88 }}
                />

                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 16
                    }}
                >
                    {firstName}
                </Typography>

                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: '#6D6D6D'
                    }}
                >
                    {memberId}
                </Typography>
            </Stack>

            <Stack 
                direction='row' 
                sx={{pb: 3, pl: 1}} 
                useFlexGap 
                flexWrap="wrap"
                gap={2}
            >
                {userOtherDetail(<CalendarMonthOutlineIcon style={iconStyle}/>, age)}
                {userOtherDetail(<SchoolOutlineIcon style={iconStyle}/>, education)}
                {userOtherDetail(<BriefcaseOutlineIcon style={iconStyle}/>, occupation)}
                {userOtherDetail(<MapMarkerOutlineIcon style={iconStyle}/>, countryOfLiving)}
            </Stack>

            <Divider />
        </>
    );
}

export default ChatMessageHeader;

ChatMessageHeader.propType = {
    data: PropTypes.object
}