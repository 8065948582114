import { Helmet } from "react-helmet";
import VerifyIDLayout from "../../sections/profile-verification/verify-id/verify-id-layout";

const IDProofPage = () => {
    return(
        <>
            <Helmet>
                <title>ID Proof Submission</title>
            </Helmet>

            <VerifyIDLayout />
        </>
    );
}

export default IDProofPage;