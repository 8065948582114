import CryptoJS from 'crypto-js';

function jwtDecode(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const jsonPayload = decodeURIComponent(
        window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
  
    return JSON.parse(jsonPayload);
}

export const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
  
    const decoded = jwtDecode(accessToken);
  
    const currentTime = Date.now() / 1000;
  
    return decoded.exp > currentTime;
};

export const tokenExpired = (exp) => {
    // eslint-disable-next-line prefer-const
    let expiredTimer;
  
    const currentTime = Date.now();
  
    // console.log("Current time: ");
    // console.log(currentTime);
    // console.log(exp);
  
    // Test token expires after 30 minutes
    // const timeLeft = currentTime + 1800000 - currentTime;
    const timeLeft = exp * 1000 - currentTime;
  
    // console.log("timeLeft: ");
    // console.log(timeLeft);
  
    clearTimeout(expiredTimer);
  
    expiredTimer = setTimeout(() => {
        alert('Token expired');
    
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
    
        window.location.href = '/login';
    }, timeLeft);
};

export const checkSessionValidity = () => {
    // This function below will handle when token is expired
    const { exp } = jwtDecode(getSessionToken());
    tokenExpired(exp);
}

export const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
      checkSessionValidity();
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
    }
};
  
export const getSessionToken = () => {
    return localStorage.getItem('accessToken');
};

export function encrypt(data){
    const cipherText = CryptoJS.AES.encrypt(data, process.env.REACT_APP_CRYPTO);
    console.log(cipherText);

    return cipherText;
}

export function decrypt(cipherText){
    const bytes = CryptoJS.AES.decrypt(cipherText, process.env.REACT_APP_CRYPTO);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    
    console.log(originalText);

    return originalText;
}