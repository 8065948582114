import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../contexts/auth/auth-provider"
import { useEffect, useState } from "react";

const PrivateRoute = () => {
    const authState = useAuth();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if(!checked && authState && authState.userAuthenticationVerified !== -1){
            setChecked(true);
        }
    }, [authState]);

    if(checked){
        if(authState?.currentUser === null){
            return <Navigate to='/login' />
        }
    
        return <Outlet />
    }
    else{
        return null;
    }
}

export default PrivateRoute;