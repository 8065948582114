import React from 'react';

export default function ChevronRightIcon(props) {
	return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            {...props}
        >
            <path fill="currentColor" d="M12.6 12L8 7.4L9.4 6l6 6l-6 6L8 16.6z"></path>
        </svg>
    );
}