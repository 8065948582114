import { Box } from "@mui/material";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileFeedListViewLayout from "./profile-feed-list-view-layout";
import ProfileFeedWarningCard from "./profile-feed-warning-card";

import { useTranslation } from "react-i18next";
import BottomNavigationBar from "../../navigation/bottom-navigation-bar";

import Footer from '../../footer/footer';
import { useGetAllMatchesMutation, useGetDailyRecommendationsMutation } from "../../../services/matches-api";
import { useCallback, useEffect, useState } from "react";
import APIErrorLayout from "../../error/api-error/api-error-layout";
import { useAuth } from "../../../contexts/auth/auth-provider";
import { getProfileFilledPercentage, shuffleArray } from "../../../utils/custom-functions";
import { useGetProfileDetailsMutation } from "../../../services/profile-api";

const ProfileFeedLayout = () => {
    const { t } = useTranslation();
    const profile_feed_locale = "profile_feed";

    const [profileCompletePercentage, setProfileCompletePercentage] = useState(t(`${profile_feed_locale}.profile_fill.error`));

    const auth = useAuth();
    const getUserID = useCallback(() => {
        return auth?.currentUser?.uuid;
    }, [auth]);

    const [allMatchesList, setAllMatchesList] = useState([]);
    const [recommendationList, setRecommendationList] = useState([]);

    const [
        getMyProfileDetails, 
        {
            isLoading: isLoadingMyProfileDetails, 
            isSuccess: isSuccessMyProfileDetails, 
            data: dataMyProfileDetails, 
            isError: isErrorMyProfileDetails, 
            error: errorMyProfileDetails
        }
    ] = useGetProfileDetailsMutation();

    useEffect(() => {
        if(isSuccessMyProfileDetails && dataMyProfileDetails){
            console.log("Profile data...");
            console.log(dataMyProfileDetails);

            const percentage = getProfileFilledPercentage(dataMyProfileDetails.data);
            const title = `${t(`${profile_feed_locale}.profile_fill.title_1`)} ${percentage}% ${t(`${profile_feed_locale}.profile_fill.title_2`)}`;

            setProfileCompletePercentage(title);
        }
        else if(isErrorMyProfileDetails){
            setProfileCompletePercentage(t(`${profile_feed_locale}.profile_fill.error`));
        }
    }, [isSuccessMyProfileDetails, dataMyProfileDetails, isErrorMyProfileDetails]);

    const [
        getAllMatches,
        {
            isLoading: isLoadingMatches, 
            isSuccess: isSuccessMatches, 
            data: dataMatches, 
            isError: isErrorMatches, 
            error: errorMatches
        }
    ] = useGetAllMatchesMutation();

    useEffect(() => {
        if(isSuccessMatches && dataMatches){
            console.log("All Matches data...");
            console.log(dataMatches);

            const shuffledArray = shuffleArray(dataMatches.data.content);
            setAllMatchesList(shuffledArray);
        }
    }, [isSuccessMatches, dataMatches]);

    let matchesBody;

    if(isLoadingMatches){
        matchesBody = <ProfileFeedListViewLayout 
            title={t(`${profile_feed_locale}.all_matches.title`)}
            caption={t(`${profile_feed_locale}.all_matches.caption`)}
            isLoading={isLoadingMatches}
            list={[]}
        />
    }
    else if(isErrorMatches && errorMatches){
        console.log(errorMatches);
        matchesBody = <APIErrorLayout error={errorMatches}/>
    }
    else if (isSuccessMatches && dataMatches){
        matchesBody = <ProfileFeedListViewLayout 
            title={t(`${profile_feed_locale}.all_matches.title`)}
            results={dataMatches.data.page.totalElements > 0 ? `${dataMatches.data.page.totalElements} ${t(`${profile_feed_locale}.results`)}` : ''}
            caption={t(`${profile_feed_locale}.all_matches.caption`)}
            viewAllLink="/matches"
            list={allMatchesList}
            id='all_matches'
        />
    }

    const [
        getDailyRecommendations,
        {
            isLoading: isLoadingDailyRecommendations, 
            isSuccess: isSuccessDailyRecommendations, 
            data: dataDailyRecommendations, 
            isError: isErrorDailyRecommendations, 
            error: errorDailyRecommendations
        }
    ] = useGetDailyRecommendationsMutation();

    useEffect(() => {
        if(isSuccessDailyRecommendations && dataDailyRecommendations){
            console.log("Daily recommendations data...");
            console.log(dataDailyRecommendations);

            const shuffledArray = shuffleArray(dataDailyRecommendations.data.content);
            setRecommendationList(shuffledArray);
        }
    }, [isSuccessDailyRecommendations, dataDailyRecommendations]);

    let recommendationsBody;

    if(isLoadingDailyRecommendations){
        recommendationsBody = <ProfileFeedListViewLayout 
            title={t(`${profile_feed_locale}.daily_recommendations.title`)}
            caption={t(`${profile_feed_locale}.daily_recommendations.caption`)}
            list={[]}
            isLoading={isLoadingDailyRecommendations}
        />
    }
    else if(isErrorDailyRecommendations && errorDailyRecommendations){
        console.log(errorDailyRecommendations);
        recommendationsBody = <APIErrorLayout error={errorDailyRecommendations}/>
    }
    else if (isSuccessDailyRecommendations && dataDailyRecommendations){
        recommendationsBody = <ProfileFeedListViewLayout 
            title={t(`${profile_feed_locale}.daily_recommendations.title`)}
            results={dataDailyRecommendations.data.page.totalElements > 0 ? `${dataDailyRecommendations.data.page.totalElements} ${t(`${profile_feed_locale}.results`)}` : ''}
            caption={t(`${profile_feed_locale}.daily_recommendations.caption`)}
            viewAllLink="/daily-recommendations"
            list={recommendationList}
            id='daily_recommendations'
        />
    }

    useEffect(() => {
        getMyProfileDetails();
        getAllMatches(0);
        getDailyRecommendations(0);
    }, []);

    return(
        <>
            <ProfileNavigationBar needSmallScreenDesign={true}/>

            <Box sx={{px: {xs: 3, md: 7}, pb: 10}}>
                <ProfileFeedWarningCard 
                    title={t(`${profile_feed_locale}.header.title`)}
                    caption={t(`${profile_feed_locale}.header.caption`)}
                    actionTxt={t(`${profile_feed_locale}.header.action`)}
                    action="/partner-preference"
                />

                {matchesBody}

                <ProfileFeedWarningCard 
                    title={profileCompletePercentage}
                    caption={t(`${profile_feed_locale}.profile_fill.caption`)}
                    actionTxt={t(`${profile_feed_locale}.profile_fill.action`)}
                    action={`/profile/${getUserID()}`}
                />

                {recommendationsBody}

                <BottomNavigationBar selectedValue={0}/>
            </Box>

            <Footer />
        </>
    );
}

export default ProfileFeedLayout;