import PropTypes from 'prop-types';

import { 
    Box,
    Card,
    Grid,
    Stack,
    Typography,
    Link,
    useTheme
} from "@mui/material";

import CheckDecagramIcon from '../../../assets/icons/check-decagram-icon';
import CalendarMonthOutlineIcon from '../../../assets/icons/calender-month-outline-icon';
import HumanHeightIcon from '../../../assets/icons/human-height-icon';
import HandsPrayIcon from '../../../assets/icons/hands-pray-icon';
import SchoolOutlineIcon from '../../../assets/icons/school-outline-icon';
import BriefcaseOutlineIcon from '../../../assets/icons/briefcase-outline-icon';
import MapMarkerOutlineIcon from '../../../assets/icons/map-marker-outline-icon';
import CheckIcon from '../../../assets/icons/check-icon';
import CancelIcon from '../../../assets/icons/cancel-icon';

import LoadingButtonComponent from '../../../components/form/loading-button-component';

import { useTranslation } from 'react-i18next';
import DialogBox from '../../../components/Dialog/dialog-box';
import { useCallback, useEffect, useState } from 'react';
import BookAccountOutlineIcon from '../../../assets/icons/book-account-outline-icon';
import { useAcceptInterestMutation, useSendInterestMutation } from '../../../services/interest-api';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useBlockMutation, useUnblockMutation } from '../../../services/block-api';
import { useRemoveFromShortListMutation } from '../../../services/shortlisted-api';
import { useNavigate } from 'react-router-dom';
import ChatOutlineIcon from '../../../assets/icons/chat-outline-icon';
import { useChat } from '../../../contexts/chat/chat-provider';
import { useGetUserInboxDetailsMutation } from '../../../services/chat-api';
import ChatPersonal from '../../chat/chat-personal';
import { useAnalytics } from '../../../contexts/analytics/analytics-provider';

const ProfileMatchCard = ({data, filterType, successCallback}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_matches_locale = "profile_matches";
    const profile_locale = "profile";
    const blocked_locale = "profile_settings.blocked_profile";
    const profile_interests_locale = "profile_interests";

    const navigate = useNavigate();
    const useChatContext = useChat();

    const {logCustomEvent, logCustomEventWithParams} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const {
        userId,
        memberId,
        profileImages,
        firstName,
        verified,
        age,
        height,
        religion,
        education,
        occupation,
        countryOfLiving,
        caste,
        blocked,
        connected,
        interestReceived,
        interestSent
    } = data;

    const iconStyle = {width: '20px', height: '20px', color: '#6D6D6D'};

    const [openDialog, setOpenDialog] = useState({
        title: "",
        message: "",
        open: false,
        close: null,
        successCallback: null
    });

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const userOtherDetail = (customIcon, value) => <Stack direction='row' alignItems='center' spacing={1}>
        {customIcon}
        
        <Typography
            sx={{
                fontWeight: 500,
                fontSize: 14,
                color: '#6D6D6D'
            }}
        >
            {value ? value : t('profile_feed.unknown')}
        </Typography>
    </Stack>



    const getInterestActionText = useCallback(() => {
        if(interestReceived){
            return t(`${profile_locale}.accept_interest`);
        }
        else if(!interestSent){
            return t(`${profile_locale}.sent_interest`);
        }
        else{
            return t(`${profile_locale}.pending_interest`);
        }
    }, [interestReceived, interestSent]);

    const getInterestActionWidth = useCallback(() => {
        if(interestReceived || !interestSent) {
            return '160px';
        }
        else{
            return '200px';
        }
    }, [interestReceived, interestSent]);

    const isInterestActionDisables = useCallback(() => {
        return interestSent;
    }, [interestSent]);

    const confirmSendInterestRequest = useCallback(async () => {
        setOpenDialog(false);
        await sendInterest(memberId);
    }, [memberId]);

    const requestInterestSend = useCallback(() => {
        if(blocked){
            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.errors.unblock_first`),
                color: "red"
            });

            return;
        }

        setOpenDialog({
            title: t(`${profile_matches_locale}.interest.dialog_title`),
            message: t(`${profile_matches_locale}.interest.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmSendInterestRequest
        });
    }, [blocked, memberId]);

    const confirmInterestAcceptRequest = useCallback(async () => {
        setOpenDialog(false);
        await acceptInterest(userId);
    }, [userId]);

    const requestInterestAccept = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_interests_locale}.interest.dialog_title`),
            message: t(`${profile_interests_locale}.interest.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmInterestAcceptRequest
        });
    }, [userId]);

    const getInterestAction = useCallback(() => {
        if(interestReceived){
            return requestInterestAccept();
        }
        else if(!interestSent){
            return requestInterestSend();
        }
        else{
            return () => {}
        }
    }, [interestReceived, interestSent]);

    const [
        sendInterest,
        {
            isLoading: isLoadingSendInterest,
            isSuccess: isSuccessSendInterest, 
            isError: isErrorSendInterest, 
            error: errorSendInterest
        }
    ] = useSendInterestMutation();

    useEffect(() => {
        if(isSuccessSendInterest){
            logAnalytics('NEW_INTEREST_SENT');

            setShowSnackBar({
                state: true,
                message: t(`${profile_matches_locale}.interest.interest_sent`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1500);
        }
    }, [isSuccessSendInterest]);

    useEffect(() => {
        if(isErrorSendInterest && errorSendInterest){
            console.log(errorSendInterest);

            setShowSnackBar({
                state: true,
                message: `${errorSendInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorSendInterest, errorSendInterest]);

    const [
        acceptInterest,
        {
            isLoading: isLoadingAcceptInterest,
            isSuccess: isSuccessAcceptInterest, 
            isError: isErrorAcceptInterest, 
            error: errorAcceptInterest
        }
    ] = useAcceptInterestMutation();

    useEffect(() => {
        if(isSuccessAcceptInterest){
            useChatContext.listenNewlyAcceptedUserChat();

            logAnalytics('NEW_INTEREST_ACCEPT');
            
            setShowSnackBar({
                state: true,
                message: t(`${profile_matches_locale}.interest.interest_accepted`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1500);
        }
    }, [isSuccessAcceptInterest]);

    useEffect(() => {
        if(isErrorAcceptInterest && errorAcceptInterest){
            console.log(errorAcceptInterest);

            setShowSnackBar({
                state: true,
                message: `${errorAcceptInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorAcceptInterest, errorAcceptInterest]);

    


    const [
        blockUser,
        {
            isLoading: isLoadingBlock,
            isSuccess: isSuccessBlock, 
            isError: isErrorBlock, 
            error: errorBlock
        }
    ] = useBlockMutation();

    useEffect(() => {
        if(isSuccessBlock){
            logAnalytics('NEW_USER_BLOCKED');

            setShowSnackBar({
                state: true,
                message: t(`${profile_matches_locale}.block.blocked`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1500);
        }
    }, [isSuccessBlock]);

    useEffect(() => {
        if(isErrorBlock && errorBlock){
            console.log(errorBlock);

            setShowSnackBar({
                state: true,
                message: `${errorBlock.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorBlock, errorBlock]);

    const requestBlock = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_matches_locale}.block.dialog_title`),
            message: t(`${profile_matches_locale}.block.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmBlock
        });
    }, [userId]);

    const confirmBlock = useCallback(async () => {
        setOpenDialog(false);
        await blockUser(userId);
    }, [userId]);

    const [
        unblockUser,
        {
            isLoading: isLoadingUnblockInterest,
            isSuccess: isSuccessUnblockInterest, 
            isError: isErrorUnblockInterest, 
            error: errorUnblockInterest
        }
    ] = useUnblockMutation();

    useEffect(() => {
        if(isSuccessUnblockInterest){
            logAnalytics('USER_UNBLOCKED');

            setShowSnackBar({
                state: true,
                message: t(`${blocked_locale}.unblocked`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1500);
        }
    }, [isSuccessUnblockInterest]);

    useEffect(() => {
        if(isErrorUnblockInterest && errorUnblockInterest){
            console.log(errorUnblockInterest);

            setOpenDialog(false);

            setShowSnackBar({
                state: true,
                message: `${errorUnblockInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorUnblockInterest, errorUnblockInterest]);

    const requestUnblock = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_locale}.alert.unblock_dialog_title`),
            message: t(`${profile_locale}.alert.unblock_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmUnBlock
        });
    }, [userId]);

    const confirmUnBlock = useCallback(async () => {
        setOpenDialog(false);
        await unblockUser(userId);
    }, [userId]);



    const [
        removeFromShortlisted,
        {
            isLoading: isLoadingRemoveShortListed,
            isSuccess: isSuccessRemoveShortListed, 
            isError: isErrorRemoveShortListed, 
            error: errorRemoveShortListed
        }
    ] = useRemoveFromShortListMutation();

    useEffect(() => {
        if(isSuccessRemoveShortListed){
            logAnalytics('USER_REMOVED_FROM_SHORTLISTED');

            setShowSnackBar({
                state: true,
                message: t(`${profile_matches_locale}.shortlist.remove_shortlisted`),
                color: "green"
            });

            setTimeout(() => {
                successCallback();
            }, 1500);
        }
    }, [isSuccessRemoveShortListed]);

    useEffect(() => {
        if(isErrorRemoveShortListed && errorRemoveShortListed){
            console.log(errorRemoveShortListed);

            setShowSnackBar({
                state: true,
                message: `${errorRemoveShortListed.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorRemoveShortListed, errorRemoveShortListed]);

    const requestRemoveShortList = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_matches_locale}.shortlist.dialog_title`),
            message: t(`${profile_matches_locale}.shortlist.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmRemoveShortList
        });
    }, []);

    const confirmRemoveShortList = useCallback(async () => {
        setOpenDialog(false);
        await removeFromShortlisted(memberId);
    }, [memberId]);



    const [
        getUserInboxDetails,
        {
            isLoading: isLoadingAllDirectChats,
            isSuccess: isSuccessAllDirectChats,
            data: dataAllDirectChats,
            isError: isErrorAllDirectChats,
            error: errorAllDirectChats
        }
    ] = useGetUserInboxDetailsMutation();

    const requestMessage = useCallback(async () => {
        getUserInboxDetails({userID: userId});
    }, [userId]);

    useEffect(() => {
        if (isSuccessAllDirectChats) {
            console.log("user chat inbox data...");
            console.log(dataAllDirectChats); 

            useChatContext.setActiveUserChat(dataAllDirectChats.data);
            useChatContext.setChatBoxBody(<ChatPersonal />);

            if(window.innerWidth < 800){
                navigate('/chat');
            }
            else{
                useChatContext.openChatBox(true);
            }
        }
    }, [isSuccessAllDirectChats, dataAllDirectChats]);

    useEffect(() => {
        if(isErrorAllDirectChats && errorAllDirectChats){
            console.log(errorAllDirectChats);

            setShowSnackBar({
                state: true,
                message: `${errorAllDirectChats.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorAllDirectChats, errorAllDirectChats]);



    const viewProfile = useCallback(() => {
        console.log(memberId);
        navigate(`/profile/${memberId}`);
    }, [memberId]);



    return (
        <>
            <Card 
                sx={{
                    width: '100%', 
                    my: 1,
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)'
                }}
            >
                <Grid
                    xs
                    container
                    direction={{xs: 'column', md: 'row'}}
                    p={2}
                >
                    <Stack alignItems='center'>
                        <Box 
                            sx={{
                                width: {xs: '300px', md: '205px'}, 
                                height: {xs: '300px', md: '205px'}
                            }}
                        >
                            <img 
                                src={profileImages.length > 0 ? profileImages[0]: '/assets/images/profile/profile.jpg'} 
                                alt={firstName} 
                                style={{ 
                                    width: '100%', 
                                    height: '100%', 
                                    borderRadius: '4px'
                                }}
                            />
                        </Box>
                    </Stack>

                    <Stack
                        display='flex'
                        direction='column'
                        justifyContent='start'
                        alignItems='start'
                        spacing={0.5}
                        sx={{
                            pl: {xs: 0, md: 2},
                            pt: {xs: 1, md: 0}
                        }}
                    >
                        <Link onClick={() => viewProfile()} underline='none' sx={{cursor: 'pointer'}}>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#121212'
                                }}
                            >
                                {firstName} <CheckDecagramIcon style={{color: '#00AAC4', marginBottom: -4, display: verified ? 'inline-block' : 'none'}}/>
                            </Typography>
                        </Link>

                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: '#6D6D6D'
                            }}
                        >
                            {memberId}
                        </Typography>

                        <Stack 
                            direction='row' 
                            spacing={3} 
                            sx={{
                                pb: 2
                            }} 
                            useFlexGap 
                            flexWrap="wrap"
                            gap={1}
                        >
                            {userOtherDetail(<CalendarMonthOutlineIcon style={iconStyle}/>, age, false)}
                            {userOtherDetail(<HumanHeightIcon style={iconStyle}/>, height)}
                            {userOtherDetail(<HandsPrayIcon style={iconStyle}/>, religion)}
                            {userOtherDetail(<SchoolOutlineIcon style={iconStyle}/>, education)}
                            {userOtherDetail(<BriefcaseOutlineIcon style={iconStyle}/>, occupation)}
                            {userOtherDetail(<MapMarkerOutlineIcon style={iconStyle}/>, countryOfLiving)}
                            {userOtherDetail(<BookAccountOutlineIcon style={iconStyle}/>, caste)}
                        </Stack>

                        {
                            connected
                            ?
                            <></>
                            :
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 14,
                                    color: '#121212'
                                }}
                            >
                                {`${t(`${profile_matches_locale}.interest.message`)}`}
                            </Typography>
                        }

                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: 14,
                                color: '#6D6D6D',
                                pb: 1
                            }}
                        >
                            {connected ? t(`${profile_matches_locale}.interest.connected`) : t(`${profile_matches_locale}.interest.connect`)}
                        </Typography>

                        <Stack 
                            direction='row' 
                            alignItems='center' 
                            spacing={2}
                            justifyContent={{xs: 'center', md: 'start'}} 
                            sx={{
                                width: '100%'
                            }}
                        >
                            {
                                connected
                                ?
                                <LoadingButtonComponent 
                                    text={t(`profile_interests.actions.message`)}
                                    onClick={requestMessage}
                                    inLoading={isLoadingAllDirectChats}
                                    startIcon={<ChatOutlineIcon />}
                                    sx={{
                                        width: {xs: '100%', md: '150px'},
                                        height: '45px'
                                    }}
                                    textSx={{
                                        fontSize: {xs: 11, md: 14},
                                        textTransform: 'capitalize'
                                    }}
                                />
                                :
                                <LoadingButtonComponent 
                                    text={getInterestActionText()}
                                    onClick={getInterestAction}
                                    inLoading={isLoadingSendInterest || isLoadingAcceptInterest}
                                    startIcon={<CheckIcon />}
                                    sx={{
                                        width: {xs: '100%', md: getInterestActionWidth()},
                                        height: '45px',
                                        cursor: isInterestActionDisables() ? 'auto' : 'pointer',
                                        "&:hover": {
                                            backgroundColor: theme.palette.primary.main,
                                            transform: isInterestActionDisables() ? 'scale(1)' : 'scale(1.03)'
                                        },
                                    }}
                                    textSx={{
                                        fontSize: {xs: 11, md: 14},
                                        textTransform: 'capitalize'
                                    }}
                                />
                            }
                            
                            {
                                filterType === 'SHORTLISTED'
                                ?
                                <LoadingButtonComponent 
                                    text={t(`${profile_matches_locale}.actions.removed_from_shortlist`)}
                                    onClick={() => requestRemoveShortList()}
                                    inLoading={isLoadingRemoveShortListed}
                                    startIcon={<></>}
                                    sx={{
                                        width: {xs: '100%', md: '200px'},
                                        height: '45px'
                                    }}
                                    textSx={{
                                        fontSize: {xs: 11, md: 14},
                                        textTransform: 'capitalize'
                                    }}
                                />
                                :
                                <LoadingButtonComponent 
                                    text={blocked ? t(`${profile_matches_locale}.actions.unblock`) : t(`${profile_matches_locale}.actions.block`)}
                                    onClick={blocked ? () => requestUnblock() : () => requestBlock()}
                                    startIcon={<CancelIcon />}
                                    inLoading={isLoadingBlock || isLoadingUnblockInterest}
                                    outlined
                                    sx={{
                                        width: {xs: '100%', md: '100px'},
                                        height: '45px'
                                    }}
                                    textSx={{
                                        fontSize: {xs: 11, md: 14},
                                        textTransform: 'capitalize'
                                    }}
                                />
                            }
                        </Stack>
                    </Stack>
                </Grid>
            </Card>

            <DialogBox 
                title={openDialog.title}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {openDialog.message}
                    </Typography>
                }
                open={openDialog.open} 
                onClose={openDialog.close}
                onSuccess={openDialog.successCallback}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />     
        </>
    );
}

export default ProfileMatchCard;

ProfileMatchCard.propType = {
    data: PropTypes.object,
    filterType: PropTypes.string,
    successCallback: PropTypes.func
}