import { Helmet } from "react-helmet";

const EditProfilePage = () => {
    return(
        <>
            <Helmet>
                <title>Edit Profile</title>
            </Helmet>


        </>
    );
}

export default EditProfilePage;