import { 
    Grid,
    Typography,
    useTheme,
    Box
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { ButtonComponent } from "../../components/form";
import ArrowLeft from "../../assets/icons/arrow-left";
import { useNavigate } from "react-router-dom";

const ContactUsHeader = () =>{
    const theme = useTheme();

    const { t } = useTranslation();
    const contact_us_locale = "contact_us";

    const navigate = useNavigate();

    return (
        <>
            <Grid
                xs
                container
                direction='column'
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                    pt: 3,
                    pb: 5,
                    backgroundColor: theme.palette.primary.main
                }}
            >
                <Grid 
                    xs={12}
                    justifyContent='start'
                    direction='row'
                    sx={{
                        width: '100%', 
                        pl: 3
                    }}
                >
                    <ButtonComponent 
                        text={t(`${contact_us_locale}.back`)}
                        onClick={() => navigate(-1)}
                        width={124}
                        startIcon={<ArrowLeft style={{width: '22px', color: 'white'}}/>}
                        variant='text'
                        sx={{
                            color: 'white',
                            textTransform: 'capitalize',
                            mb: 2,
                            textSize: 16
                        }}
                    />
                </Grid>

                <Grid 
                    xs={12}
                >
                    <Typography
                        textAlign='center'
                        sx={{
                            fontWeight: 600,
                            fontSize: {xs: 25, md: 32, lg: 48},
                            letterSpacing: -2,
                            pb: 3,
                            pt: 6,
                            px: 1,
                            color: 'white'
                        }}
                    >
                        {t(`${contact_us_locale}.heading`)}
                    </Typography>
                </Grid>

                <Grid 
                    xs={12}
                >
                    <Typography
                        textAlign='center'
                        sx={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: '24px',
                            color: 'white',
                            px: 2,
                            pb: {xs: 2, md: 5}
                        }}
                    >
                        {t(`${contact_us_locale}.caption`)}
                    </Typography>
                </Grid>

                <Grid 
                    xs={12}
                >
                    <Box sx={{mx: {xs: 1.5, md: 5}}}>
                        <img 
                            src="/assets/images/contact-us/header.png" 
                            alt="banner"
                            width='100%'
                            style={{
                                borderRadius: '16px'
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ContactUsHeader;