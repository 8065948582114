import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../profile-settings-header";
import DeleteProfileBody from "./delete-profile-body";
import { useCallback, useState } from "react";

import Footer from '../../footer/footer';

const DeleteProfileLayout = () => {
    const { t } = useTranslation();
    const settings_locale = "profile_settings.delete_profile";

    const [step, setStep] = useState(1);

    const nextStep = useCallback(() => {
        setStep((value) => value + 1);
    }, []);

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${settings_locale}.heading`)} 
                caption={t(`${settings_locale}.caption`)}
                showText={step === 1}
                callback={() => {setStep((value) => value - 1);}}
            />

           <DeleteProfileBody step={step} updateStep={nextStep}/>

           <Footer />
        </>
    );
}

export default DeleteProfileLayout;