import i18n from 'i18next';

import enJson from './assets/locales/en.json';
import taJson from './assets/locales/ta.json';

i18n.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        en: {translation: enJson}, // English
        ta: {translation: taJson}, // Tamil
    }
});

export default i18n; 