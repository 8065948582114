import { 
    Card,
    Box,
    Tab,
    Stack
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileSearchByFilter from "./profile-search-by-filter-layout";
import ProfileSavedSearches from "./profile-saved-searches";

const ProfileSearchLandingSection = ({onSearch}) => {
    const { t } = useTranslation();
    const search_locale = "profile_feed.search";

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <>
        <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{
                mb: 5
            }}
        >
            <Card 
                sx={{
                    width: {xs: '90%', md: '50%'}, 
                    borderRadius: '8px 8px 8px 8px',
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)'
                }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth">
                            <Tab 
                                label={t(`${search_locale}.tabs.search_by_filter`)} 
                                value="1" 
                                sx={{
                                    textTransform: 'capitalize'
                                }}
                            />
                            <Tab 
                                label={t(`${search_locale}.tabs.saved_searches`)} 
                                value="2" 
                                sx={{
                                    textTransform: 'capitalize'
                                }}
                            />
                        </TabList>
                    </Box>

                    <TabPanel value="1">
                        <ProfileSearchByFilter onSearch={onSearch}/>
                    </TabPanel>

                    <TabPanel value="2">
                        <ProfileSavedSearches onSearch={onSearch}/>
                    </TabPanel>
                </TabContext>
            </Card>
        </Stack>
    </>
}

export default ProfileSearchLandingSection;