import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const profileAPI = createApi({
    reducerPath: "profileAPI",
    baseQuery,
    endpoints: (builder) => ({
        getProfileDetails: builder.mutation({
            query: () => {
                const apiUrl = `profile`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["my_profile"]
        }),
        getOtherProfileDetails: builder.mutation({
            query: (memberID) => {
                const apiUrl = `profile/${memberID}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["other_profile"]
        }),
        deactivate: builder.mutation({
            query: () => {
                const apiUrl = `profile/deactivate`;

                return {
                    url: apiUrl,
                    method: "PUT",
                };
            },
            transformResponse: (response) => response
        }),
        deleteAccount: builder.mutation({
            query: (reason) => {
                const apiUrl = `profile/delete?reason=${reason}`;

                return {
                    url: apiUrl,
                    method: "DELETE",
                };
            },
            transformResponse: (response) => response
        }),
        updateProfile: builder.mutation({
            query: (body) => {
                const apiUrl = `profile/update`;

                return {
                    url: apiUrl,
                    method: "PUT",
                    body
                };
            },
            transformResponse: (response) => response,
            invalidatesTags: ["my_profile"]
        }),
    }),
});

export const { 
    useGetProfileDetailsMutation,
    useGetOtherProfileDetailsMutation,
    useDeactivateMutation,
    useDeleteAccountMutation,
    useUpdateProfileMutation
} = profileAPI;

export default profileAPI;