import PropTypes from 'prop-types';

import { Stack } from "@mui/material";
import MyProfessionalDetails from "./my-professional-details";

const ProfileProfessionalLayout = ({privateView, data, isLoading, editCallback, updateData}) => {
    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='start'
                spacing={5}
            >
                <MyProfessionalDetails 
                    privateView={privateView}
                    responseData={data}
                    isLoading={isLoading}
                    editCallback={editCallback}
                    updateData={updateData}
                />
            </Stack>
        </>
    );
}

export default ProfileProfessionalLayout;

ProfileProfessionalLayout.propType = {
    privateView: PropTypes.bool,
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func,
    updateData: PropTypes.object
}