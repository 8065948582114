import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import { useCallback, useState } from "react";
import VerifyIDBody from "./verify-id-body";

import Footer from '../../footer/footer';
import { useAuth } from "../../../contexts/auth/auth-provider";

const VerifyIDLayout = () => {
    const { t } = useTranslation();
    const verification_id_locale = "profile_verification.verify_id";

    const auth = useAuth();
    const email = auth?.currentUser?.email;

    const [step, setStep] = useState(1);

    const nextStep = useCallback(() => {
        setStep((value) => value + 1);
    }, []);

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${verification_id_locale}.heading`)} 
                caption={`${t(`${verification_id_locale}.caption`)} ${email}`}
                showText={step === 1}
                callback={() => {setStep((value) => value - 1);}}
            />

            <VerifyIDBody step={step} updateStep={nextStep}/>

            <Footer />
        </>
    );
}

export default VerifyIDLayout;