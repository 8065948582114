import { 
    Stack, 
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";

const APIErrorLayout = ({error}) => {
    const [message, setMessage] = useState("");

    useEffect(() => {
        const msg = error?.data?.error?.message;
        setMessage(msg === undefined ? "Something went wrong" : msg);
    }, [error])

    return(
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={2}
                height='100%'
                py={5}
            >
                <img src="/assets/images/error/error.png" width='50%'/>

                <Typography
                    fontWeight={600}
                    fontSize={16}
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    {message}
                </Typography>
            </Stack>
        </>
    );
}

export default APIErrorLayout;