import React from 'react';

export default function BeerOutlineIcon(props) {
	return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            {...props}
        >
            <path fill="currentColor" d="m4 2l2 20h11l2-20zm2.2 2h10.6l-.3 3.23c-3.32 1.27-4.65.44-5.12.08c-.25-.19-.61-.62-1.27-.92c-.66-.31-1.56-.39-2.61-.07c-.41.11-.73.29-1 .47zm2.66 4.11c.19 0 .3.04.41.09c.23.1.44.35.9.7c.86.66 2.86 1.46 6.09.51L15.2 20H7.8L6.71 9.06c.05-.06.2-.17.46-.35c.33-.21.74-.43.83-.46h.03c.38-.11.64-.15.83-.14"></path>
        </svg>
    );
}