import PropTypes from 'prop-types';

import { 
    Typography
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

const LoadingButtonComponent = ({
    text, 
    onClick, 
    outlined=false, 
    inLoading=false,
    width=0,
    startIcon,
    sx,
    textSx,
    ...other
}) => {
    return (
        <>
            <LoadingButton 
                loading={inLoading}
                variant={outlined ? "outlined" : "contained"}
                sx={{
                    borderRadius: '8px',
                    height: 52,
                    width: `${width === 0 ? '100%' : width}`,
                    '&:hover' : {
                        transform: 'scale(1.03)'
                    },
                    ...sx
                }}  
                onClick={onClick}
                startIcon={startIcon ?? <></>}
                {...other}
            >
                <Typography
                    fontWeight={600}
                    fontSize={16}
                    sx={{...textSx}}
                >
                    {text}
                </Typography>
            </LoadingButton>
        </>
    );
}

export default LoadingButtonComponent;

LoadingButtonComponent.propTypes = {
    text: PropTypes.string,
    outlined: PropTypes.bool,
    inLoading: PropTypes.bool,
    onClick: PropTypes.func,
    width: PropTypes.number,
    startIcon: PropTypes.object,
    sx: PropTypes.object,
    textSx: PropTypes.object
}