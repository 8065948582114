import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export default function SnackbarComponent({message, open, handleClose, color='green'}) {
  return (
    <div>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            message={message}
            key={message}
            autoHideDuration={3000}
            ContentProps={{
                sx: {
                    backgroundColor: color
                }
            }}
        />
    </div>
  );
}