import {
    Tabs,
    Tab,
    Box
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import { useState, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import ProfileReceivedInterestsBody from "./profile-received-interests-body";
import ProfileSendInterestsBody from "./profile-send-interests-body";
import ResultFilter from "../../../components/filters/result-filter";
import APIErrorLayout from '../../error/api-error/api-error-layout';

const ProfileInterestTab = ({onChange, data, error, successCallback, isLoading=false}) => {
    const { t } = useTranslation();
    const profile_interests_locale = "profile_interests";

    const [value, setValue] = useState(0);

    const [selectedFilter, setSelectedFilter] = useState('RECEIVED');

    const receivedInterestFiltersList = [
        {label: t(`${profile_interests_locale}.received_filters.received_pending_interests`), value: 'RECEIVED'},
        {label: t(`${profile_interests_locale}.received_filters.accepted_profiles`), value: 'ACCEPTED'},
        {label: t(`${profile_interests_locale}.received_filters.declined_profiles`), value: 'DECLINED'},
        {label: t(`${profile_interests_locale}.received_filters.blocked_profiles`), value: 'BLOCKED'}
    ];

    const sendInterestFiltersList = [
        {label: t(`${profile_interests_locale}.sent_filters.sent_pending_interests`), value: 'PENDING'},
        {label: t(`${profile_interests_locale}.sent_filters.accepted_profiles`), value: 'ACCEPTED'},
        {label: t(`${profile_interests_locale}.sent_filters.declined_profiles`), value: 'DECLINED'}
    ];

    useEffect(() => {
        // Pagination page starts with 1 based on the UI
        onChange(value, selectedFilter, 1);
    }, [selectedFilter, value]);

    const handleChange = useCallback((event, newValue) => {
        if(newValue === 0){
            setSelectedFilter('RECEIVED');
        }
        else{
            setSelectedFilter('PENDING');
        }

        setValue(newValue);
    });

    const changeFilter = useCallback((selectedFilter) => {
        setSelectedFilter(selectedFilter);
    }, []);

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Box 
                sx={{ 
                    width: '100%' 
                }}
            >
                <TabContext value={value}>
                    <Box 
                        sx={{ 
                            borderBottom: 1, 
                            borderColor: 'divider',
                            px: {xs: 2, md: 7},
                        }}
                    >
                        <Tabs 
                            value={value} 
                            onChange={handleChange} 
                            aria-label="chat tabs"
                            variant="fullWidth"
                            sx={{
                                justifyContent: 'center',
                                width: {xs: '100%', lg: '40%'}
                            }}
                        >
                            <Tab label={t(`${profile_interests_locale}.tabs.received`)} {...a11yProps(0)} sx={{textTransform: 'capitalize'}}/>
                            <Tab label={t(`${profile_interests_locale}.tabs.send`)} {...a11yProps(1)} sx={{textTransform: 'capitalize'}}/>
                        </Tabs>
                    </Box>

                    <TabPanel value={0} sx={{m: 0, pb: 0, px: 0}}>
                        <ResultFilter 
                            list={receivedInterestFiltersList}
                            defaultFilterIndex={0}
                            onChange={changeFilter}
                        />

                        {
                            error
                            ?
                            <APIErrorLayout error={error}/>
                            :
                            <ProfileReceivedInterestsBody 
                                filterType={selectedFilter} 
                                data={data} 
                                isLoading={isLoading}
                                successCallback={successCallback}
                            />
                        }
                        
                    </TabPanel>

                    <TabPanel value={1} sx={{m: 0, pb: 0, px: 0}}>
                        <ResultFilter 
                            list={sendInterestFiltersList}
                            defaultFilterIndex={0}
                            onChange={changeFilter}
                        />

                        {
                            error
                            ?
                            <APIErrorLayout error={error}/>
                            :
                            <ProfileSendInterestsBody 
                                filterType={selectedFilter} 
                                data={data} 
                                isLoading={isLoading}
                                successCallback={successCallback}
                            />
                        }
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    );
}

export default ProfileInterestTab;