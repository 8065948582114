import { 
    Card,  
    Stack,
    Typography,
    IconButton,
    Box
} from "@mui/material";

import TrashCanOutline from '../../../assets/icons/trash-can-outline';
import PencilIcon from '../../../assets/icons/pencil-icon';
import CalendarMonthOutlineIcon from "../../../assets/icons/calender-month-outline-icon";
import { ButtonComponent } from "../../../components/form";
import { useTranslation } from "react-i18next";
import DialogBox from "../../../components/Dialog/dialog-box";
import ProfileSearchEditFilter from "./profile-search-edit-filter";
import { useCallback, useEffect, useState } from "react";
import { useDeleteSearchFilterMutation, useGetSearchFiltersMutation } from "../../../services/search-api";
import APIErrorLayout from "../../error/api-error/api-error-layout";
import SkeletonComponent from "../../../components/loading/skeleton-component";
import EmptyLayout from "../../error/empty/empty-layout";
import SnackbarComponent from "../../../components/snack-bar/snack-bar-component";

const ProfileSavedSearches = ({onSearch}) => {
    const { t } = useTranslation();
    const search_locale = "profile_feed.search";

    const [savedSearches, setSavedSearches] = useState([]);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [getAllSavedFilters, { isLoading, isSuccess, data, isError, error }] = useGetSearchFiltersMutation();

    useEffect(() => {
        getAllSavedFilters();
    }, []);

    useEffect(() => {
        if(isSuccess && data){
            console.log("Saved search filters data...")
            console.log(data);
            setSavedSearches(data.data);
        }
    }, [isSuccess, data]);

    const [openDialog, setOpenDialog] = useState({
        state: false,
        title: '',
        body: null,
        onClose: null,
        onSuccess: null,
        closeTxt: '',
        approveTxt: '',
        customFooter: false
    });


    const onEditSuccess = useCallback(async () => {
        setOpenDialog(false);
        getAllSavedFilters();
    }, []);

    const openEditSearchFilter = useCallback((obj) => {
        setOpenDialog({
            state: true,
            title: t(`${search_locale}.edit_search_filter.heading`),
            body: <ProfileSearchEditFilter filterObj={obj} onClose={onEditSuccess} needSearch={false}/>,
            onClose: () => setOpenDialog(false),
            customFooter: true
        });
    }, []);

   
    const [
        deleteFilter,
        {
            isLoading: isLoadingDeleteFilter,
            isSuccess: isSuccessDeleteFilter, 
            isError: isErrorDeleteFilter, 
            error: errorDeleteFilter
        }
    ] = useDeleteSearchFilterMutation();

    useEffect(() => {
        if(isSuccessDeleteFilter){
            setShowSnackBar({
                state: true,
                message: t(`${search_locale}.removed_saved_search`),
                color: "green"
            });

            getAllSavedFilters();
        }
    }, [isSuccessDeleteFilter]);

    useEffect(() => {
        if(isErrorDeleteFilter && errorDeleteFilter){
            console.log(errorDeleteFilter);

            setShowSnackBar({
                state: true,
                message: `${errorDeleteFilter.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorDeleteFilter, errorDeleteFilter]);

    const confirmDelete = useCallback(async (id) => {
        setOpenDialog(false);

        await deleteFilter(id);
    }, []);

    const openDelete = useCallback((id) => {
        setOpenDialog({
            state: true,
            title: t(`${search_locale}.remove_saved_search_heading`),
            body: t(`${search_locale}.remove_saved_search_body`),
            onClose: () => setOpenDialog(false),
            onSuccess: () => confirmDelete(id),
            closeTxt: "",
            approveTxt: ""
        });
    }, []);


    const modifySavingObj = useCallback((savedObj) => {
        console.log(savedObj);

        const obj = {};
        
        for (var key in savedObj) {
            if (savedObj.hasOwnProperty(key)) {
                if(savedObj[key] && savedObj[key].constructor === Array && savedObj[key].length === 0){
                    obj[key] = null;
                }
                else{
                    if(!isNaN(savedObj[key]) && savedObj[key] === 0){
                        obj[key] = null;
                    }
                    else{
                        obj[key] = savedObj[key] === "" ? null : savedObj[key];
                    }
                }
            }
        }
        
        return obj;
    }, []);


    let body;

    if(isLoading){
        body = <>
            {
                [0, 1, 2, 3, 4].map((item, index) => {
                    return <Card
                        key={index}
                        sx={{
                            borderRadius: '8px 8px 8px 8px',
                            boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                            width: '100%'
                        }}
                    >
                        <SkeletonComponent variant='rounded' width="100%" height="150px"/>
                    </Card>
                })
            }
        </>
    }
    else if(isError && error){
        console.log(error);
        body = <APIErrorLayout error={error} />
    }
    else if(isSuccess){
        body = <>
            {
                savedSearches.length > 0
                ?
                savedSearches.map((item, index) => {
                    return <Card
                        key={index}
                        sx={{
                            borderRadius: '8px 8px 8px 8px',
                            boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                            width: '100%'
                        }}
                    >
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            px={2}
                            pt={2}
                        >
                            <Stack
                                direction='row'
                                justifyContent='start'
                                alignItems='center'
                                spacing={1}
                            >
                                <Typography
                                    fontWeight={600}
                                    fontSize={{xs: 12, md: 16}}
                                    color='#121212'
                                >
                                    {item.name}
                                </Typography>
                            </Stack>
            
                            <Stack
                                direction='row'
                                justifyContent='end'
                                alignItems='center'
                                spacing={{xs: 0, md: 1}}
                            >
                                <IconButton aria-label="delete" onClick={() => openDelete(item.id)}>
                                    <TrashCanOutline />
                                </IconButton>
            
                                <IconButton aria-label="delete" onClick={() => openEditSearchFilter(item)}>
                                    <PencilIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
            
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            px={2}
                            pb={2}
                        >
                            <Stack
                                direction='row'
                                justifyContent='start'
                                alignItems='center'
                                spacing={1}
                            >
                                <CalendarMonthOutlineIcon />
            
                                <Typography
                                    fontWeight={500}
                                    fontSize={{xs: 12, md: 16}}
                                    color='#6D6D6D'
                                >
                                    {item.createdAt.split("T")[0]}
                                </Typography>
                            </Stack>
            
                            <Stack
                                direction={{xs: 'column', md: 'row'}}
                                justifyContent='end'
                                alignItems='center'
                                spacing={{xs: 0, md: 1}}
                            >
                                <ButtonComponent 
                                    text={t(`${search_locale}.search_by_filter.show_matches`)}
                                    onClick={() => onSearch(modifySavingObj(item))}
                                    variant='text'
                                    textSx={{
                                        textTransform: 'capitalize',
                                        fontSize: {xs: 12, md: 16}
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Card>
                })
                :
                <Box sx={{py: 10, width: '100%'}}>
                    <EmptyLayout />
                </Box>
            } 
        </>
    }

    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='start'
                sx={{width: '100%'}}
                spacing={2}
            >
                {body}
            </Stack>

            <DialogBox 
                title={openDialog.title}
                children={openDialog.body}
                open={openDialog.state} 
                onClose={openDialog.onClose}
                onSuccess={openDialog.onSuccess}
                closeText={openDialog.closeTxt}
                approveText={openDialog.approveTxt}
                customFooter={openDialog.customFooter}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />  
        </>
    );
}

export default ProfileSavedSearches;