import { Helmet } from "react-helmet";
import SignupLayout from "../../sections/auth/signup/signup-layout";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const SignupPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_SIGNUP_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Signup</title>
            </Helmet>

            <SignupLayout />
        </>
    );
}

export default SignupPage;