import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import PartnerProfessionalPreferenceBody from "./partner-professional-preference-body";

import Footer from '../../footer/footer';

const PartnerProfessionalPreferenceLayout = () => {
    const { t } = useTranslation();
    const partner_professional_locale = "partner_preference.professional";

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${partner_professional_locale}.heading`)}
                caption={t(`${partner_professional_locale}.caption`)}
            />

            <PartnerProfessionalPreferenceBody />

            <Footer />
        </>
    );
}

export default PartnerProfessionalPreferenceLayout;