import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../profile-settings-header";
import { useCallback, useState } from "react";
import DeactivateProfileBody from "./deactivate-profile-body";

import Footer from '../../footer/footer';

const DeactivateProfileLayout = () => {
    const { t } = useTranslation();
    const deactivate_locale = "profile_settings.deactivate_profile";

    const [step, setStep] = useState(1);

    const nextStep = useCallback(() => {
        setStep((value) => value + 1);
    }, []);

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${deactivate_locale}.heading`)} 
                showText={step === 1}
                callback={() => {setStep((value) => value - 1);}}
            />

           <DeactivateProfileBody step={step} updateStep={nextStep}/>

           <Footer />
        </>
    );
}

export default DeactivateProfileLayout;