import { Helmet } from "react-helmet";
import ChatBoxMobileLayout from "../../sections/chat/chat-box-mobile-layout";

const ChatPage = () => {
    return(
        <>
            <Helmet>
                <title>Chat</title>
            </Helmet>

            <ChatBoxMobileLayout />
        </>
    );
}

export default ChatPage;