import { Helmet } from "react-helmet";
import ProfileDailyRecommendationsLayout from "../../sections/profile-general/profile-daily-recommendations/profile-daily-recommendations-layout";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const ProfileDailyRecommendationPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_DAILY_RECOMMENDATIONS_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Daily Recommendations</title>
            </Helmet>

            <ProfileDailyRecommendationsLayout />
        </>
    );
}

export default ProfileDailyRecommendationPage;