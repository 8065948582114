import PropTypes from 'prop-types';

import { 
    Grid,
    Typography,
    Box,
    Chip,
    useTheme
} from '@mui/material';

const HowItWorkStep = ({data, reverse=false, last=false, iconTranslateY=20}) => {
    const theme = useTheme();

    const {step, heading, caption, topIcon, banner} = data;

    return(
        <>
            <Grid
                xs={12}
                direction={reverse ? 'row-reverse' : 'row'}
                container
            >
                <Grid
                    xs={12} md={6}
                    display='flex'
                    justifyContent='start'
                    container
                >
                    {/* Top Illustration */}
                    <Grid
                        xs={12}
                        display='flex'
                        direction='row'
                        justifyContent='start'
                        sx={{height: {xs: 90, md: 140},}}
                    >
                        <Box
                            component="img"
                            sx={{
                                content: `url(${topIcon})`,
                                width: {xs: 90, md: 140},
                                height: {xs: 90, md: 140},
                                transform: 'translate(80px, 30px)',
                                zIndex: -1
                            }}
                            alt="icon"
                        />
                    </Grid>

                    {/* Body */}
                    <Grid
                        xs={12}
                        display='flex'
                        direction='column'
                        sx={{
                            transform: `translateY(-${iconTranslateY}px)`
                        }}
                    >
                        <Chip 
                            label={step}
                            sx={{
                                width: 96,
                                mb: 1,
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                                fontWeight: 600,
                                fontSize: 16
                            }}
                        /> 

                        <Typography
                            fontWeight={600}
                            fontSize={{xs: 30, lg: 48}}
                            letterSpacing={-2}
                        >
                            {heading}
                        </Typography>

                        <Typography
                            fontWeight={400}
                            fontSize={{xs: 14, lg: 16}}
                            sx={{pt: 2}}
                            color='#6D6D6D'
                        >
                            {caption}
                        </Typography>
                    </Grid>

                    {/* Arrow Illustration */}
                    {
                        !last
                        ?
                        <Grid
                            xs={12}
                            display={{xs: 'none', md: 'flex'}}
                            direction='row'
                            justifyContent={reverse ? 'start' : 'flex-end'}
                        >
                            <Box
                                component="img"
                                sx={{
                                    content: `url('/assets/icons/how-it-works-arrow.png')`,
                                    width: 294,
                                    zIndex: -1,
                                    transform: reverse ? 'scaleX(-1)' : 'scaleX(1)'
                                }}
                                alt="icon"
                            />
                        </Grid>
                        :
                        <></>
                    }
                </Grid>

                <Grid
                    xs={12} md={6}
                    display='flex'
                    justifyContent={{xs: 'center', md: reverse ? 'start' : 'end'}}
                >
                    <img 
                        src={banner} 
                        alt='banner' 
                        width='80%'
                        style={{
                            borderRadius: '24px'
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default HowItWorkStep;

HowItWorkStep.propType = {
    data: PropTypes.object,
    reverse: PropTypes.bool,
    last: PropTypes.bool,
    iconTranslateY: PropTypes.number
}