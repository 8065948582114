import PropTypes from 'prop-types';

import { 
    Stack, 
    Typography
} from "@mui/material";

import { useTranslation } from "react-i18next";

import LoadingButtonComponent from '../../components/form/loading-button-component';
import DialogBox from '../../components/Dialog/dialog-box';
import SnackbarComponent from '../../components/snack-bar/snack-bar-component';
import { useCallback, useEffect, useState } from 'react';
import { useAcceptInterestMutation, useDeclineInterestMutation } from '../../services/interest-api';
import { useBlockMutation, useUnblockMutation } from '../../services/block-api';
import { useDispatch } from 'react-redux';
import { homeActions } from '../../store/store';
import ChatInterestOverviewList from './chat-interest-overview-list';
import { useChat } from '../../contexts/chat/chat-provider';

const ChatRequestAccept = ({data}) => {
    const { t } = useTranslation();
    const chat_locale = "chat";
    const profile_locale = "profile";
    const profile_daily_recommendations_locale = "profile_daily_recommendations";
    const profile_interests_locale = "profile_interests";
    const blocked_locale = "profile_settings.blocked_profile";

    const dispatch = useDispatch();
    const useChatContext = useChat();

    const [isUserBlocked, setIsUserBlocked] = useState(false);

    const [openDialog, setOpenDialog] = useState({
        title: "",
        message: "",
        open: false,
        close: null,
        successCallback: null
    });

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const {
        userId,
        firstName,
        blocked
    } = data;

    useEffect(() => {
        setIsUserBlocked(blocked);
    }, [blocked]);



    const goBack = useCallback(() => {
        dispatch(
            homeActions.setChatBoxBody(<ChatInterestOverviewList />)
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            homeActions.setChatBoxBackCallback(goBack)
        );
    }, []);



    const confirmInterestAcceptRequest = useCallback(async () => {
        setOpenDialog(false);
        await acceptInterest(userId);
    }, [userId]);

    const requestInterestAccept = useCallback(() => {
        if(blocked){
            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.errors.unblock_first`),
                color: "red"
            });

            return;
        }

        setOpenDialog({
            title: t(`${profile_locale}.alert.interest_dialog_title`),
            message: t(`${profile_locale}.alert.interest_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmInterestAcceptRequest
        });
    }, [blocked, userId]);

    const [
        acceptInterest,
        {
            isLoading: isLoadingAcceptInterest,
            isSuccess: isSuccessAcceptInterest, 
            isError: isErrorAcceptInterest, 
            error: errorAcceptInterest
        }
    ] = useAcceptInterestMutation();

    useEffect(() => {
        if(isSuccessAcceptInterest){
            useChatContext.listenNewlyAcceptedUserChat();

            setShowSnackBar({
                state: true,
                message: t(`${profile_daily_recommendations_locale}.interest.interest_accepted`),
                color: "green"
            });

            goBack();
        }
    }, [isSuccessAcceptInterest]);

    useEffect(() => {
        if(isErrorAcceptInterest && errorAcceptInterest){
            console.log(errorAcceptInterest);

            setShowSnackBar({
                state: true,
                message: `${errorAcceptInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorAcceptInterest, errorAcceptInterest]);



    const [
        blockUser,
        {
            isLoading: isLoadingBlock,
            isSuccess: isSuccessBlock, 
            isError: isErrorBlock, 
            error: errorBlock
        }
    ] = useBlockMutation();

    useEffect(() => {
        if(isSuccessBlock){
            setShowSnackBar({
                state: true,
                message: t(`${profile_daily_recommendations_locale}.block.blocked`),
                color: "green"
            });

            setIsUserBlocked(true);
        }
    }, [isSuccessBlock]);

    useEffect(() => {
        if(isErrorBlock && errorBlock){
            console.log(errorBlock);

            setShowSnackBar({
                state: true,
                message: `${errorBlock.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorBlock, errorBlock]);

    const requestBlock = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_daily_recommendations_locale}.block.dialog_title`),
            message: t(`${profile_daily_recommendations_locale}.block.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmBlock
        });
    }, [userId]);

    const confirmBlock = useCallback(async () => {
        setOpenDialog(false);
        await blockUser(userId);
    }, [userId]);

    const [
        unblockUser,
        {
            isLoading: isLoadingUnblockInterest,
            isSuccess: isSuccessUnblockInterest, 
            isError: isErrorUnblockInterest, 
            error: errorUnblockInterest
        }
    ] = useUnblockMutation();

    useEffect(() => {
        if(isSuccessUnblockInterest){
            setShowSnackBar({
                state: true,
                message: t(`${blocked_locale}.unblocked`),
                color: "green"
            });

            setIsUserBlocked(false);
        }
    }, [isSuccessUnblockInterest]);

    useEffect(() => {
        if(isErrorUnblockInterest && errorUnblockInterest){
            console.log(errorUnblockInterest);

            setOpenDialog(false);

            setShowSnackBar({
                state: true,
                message: `${errorUnblockInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorUnblockInterest, errorUnblockInterest]);

    const requestUnblock = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_locale}.alert.unblock_dialog_title`),
            message: t(`${profile_locale}.alert.unblock_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmUnBlock
        });
    }, [userId]);

    const confirmUnBlock = useCallback(async () => {
        setOpenDialog(false);
        await unblockUser(userId);
    }, [userId]);



    const [
        declineInterest,
        {
            isLoading: isLoadingDeclineInterest,
            isSuccess: isSuccessDeclineInterest, 
            isError: isErrorDeclineInterest, 
            error: errorDeclineInterest
        }
    ] = useDeclineInterestMutation();

    useEffect(() => {
        if(isSuccessDeclineInterest){
            setShowSnackBar({
                state: true,
                message: t(`${profile_interests_locale}.decline_interest.declined`),
                color: "green"
            });

            goBack();
        }
    }, [isSuccessDeclineInterest]);

    useEffect(() => {
        if(isErrorDeclineInterest && errorDeclineInterest){
            console.log(errorDeclineInterest);

            setShowSnackBar({
                state: true,
                message: `${errorDeclineInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorDeclineInterest, errorDeclineInterest]);

    const requestDeclinedInterest = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_interests_locale}.decline_interest.dialog_title`),
            message: t(`${profile_interests_locale}.decline_interest.dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmDeclinedInterest
        });
    }, [userId]);

    const confirmDeclinedInterest = useCallback(async () => {
        setOpenDialog(false);
        await declineInterest(userId);
    }, [userId]);



    return (
        <>
            <Stack
                direction='column'
                width='100%'
                alignItems='center'
                spacing={2}
                sx={{mb: {xs: 10, md: 2}}}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                        textAlign: 'left'
                    }}
                >
                    {t(`${chat_locale}.request_accept_question`)} {firstName}?
                </Typography>

                <LoadingButtonComponent 
                    text={t(`${chat_locale}.accept_request`)}
                    onClick={requestInterestAccept}
                    inLoading={isLoadingAcceptInterest}
                />

                <Stack
                    direction='row'
                    width='100%'
                    spacing={3}
                >
                    <LoadingButtonComponent 
                        text={isUserBlocked ? t(`${profile_daily_recommendations_locale}.actions.unblock`) : t(`${profile_daily_recommendations_locale}.actions.block`)}
                        onClick={isUserBlocked ? () => requestUnblock() : () => requestBlock()}
                        outlined
                        color='secondary'
                        sx={{color: 'black'}}
                        inLoading={isLoadingBlock || isLoadingUnblockInterest}
                    />

                    <LoadingButtonComponent 
                        text={t(`${chat_locale}.decline_request`)}
                        onClick={requestDeclinedInterest}
                        outlined
                        color='secondary'
                        sx={{color: 'black'}}
                        inLoading={isLoadingDeclineInterest}
                    />
                </Stack>
            </Stack>

            <DialogBox 
                title={openDialog.title}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {openDialog.message}
                    </Typography>
                }
                open={openDialog.open} 
                onClose={openDialog.close}
                onSuccess={openDialog.successCallback}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            /> 
        </>
    );
}

export default ChatRequestAccept;

ChatRequestAccept.propType = {
    data: PropTypes.object
}