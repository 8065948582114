import { Helmet } from "react-helmet";
import AboutUsLayout from "../../sections/about/about-us-layout";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const AboutUsPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_ABOUT_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>About us</title>
            </Helmet>

            <AboutUsLayout />
        </>
    );
}

export default AboutUsPage;