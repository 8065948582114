import { Helmet } from "react-helmet";
import DeleteProfileLayout from '../../sections/profile-settings/delete/delete-profile-layout';
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const DeleteProfilePage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_DELETE_MY_PROFILE_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Delete Profile</title>
            </Helmet>

            <DeleteProfileLayout />
        </>
    );
}

export default DeleteProfilePage;