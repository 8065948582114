export const currencies = [
    {id: 0, label: 'Indian Rupee', value: 'Indian Rupee'},
    {id: 1, label: 'Sri Lankan Rupee', value: 'Sri Lankan Rupee'},
    {id: 2, label: 'Singapore Dollar', value: 'Singapore Dollar'},
    {id: 3, label: 'Malaysian Ringgit', value: 'Malaysian Ringgit'},
    {id: 4, label: 'Australian Dollar', value: 'Australian Dollar'},
    {id: 5, label: 'Canadian Dollar', value: 'Canadian Dollar'},
    {id: 6, label: 'Pound Sterling', value: 'Pound Sterling'},
    {id: 7, label: 'US Dollar', value: 'US Dollar'},
    {id: 8, label: 'Euro', value: 'Euro'},
    {id: 9, label: 'Swiss Franc', value: 'Swiss Franc'},
    {id: 10, label: 'Yuan Renminbi', value: 'Yuan Renminbi'},
    {id: 11, label: 'Yen', value: 'Yen'},
    {id: 12, label: 'Afghani', value: 'Afghani'},
    {id: 13, label: 'Lek', value: 'Lek'},
    {id: 14, label: 'Algerian Dinar', value: 'Algerian Dinar'},
    {id: 15, label: 'Kwanza', value: 'Kwanza'},
    {id: 16, label: 'East Caribbean Dollar', value: 'East Caribbean Dollar'},
    {id: 17, label: 'Argentine Peso', value: 'Argentine Peso'},
    {id: 18, label: 'Armenian Dram', value: 'Armenian Dram'},
    {id: 19, label: 'Aruban Florin', value: 'Aruban Florin'},
    {id: 20, label: 'Azerbaijani Manat', value: 'Azerbaijani Manat'},
    {id: 21, label: 'Bahamian Dollar', value: 'Bahamian Dollar'},
    {id: 22, label: 'Bahraini Dinar', value: 'Bahraini Dinar'},
    {id: 23, label: 'Taka', value: 'Taka'},
    {id: 24, label: 'Barbados Dollar', value: 'Barbados Dollar'},
    {id: 25, label: 'Belarusian Ruble', value: 'Belarusian Ruble'},
    {id: 26, label: 'Belize Dollar', value: 'Belize Dollar'},
    {id: 27, label: 'CFA Franc (BCEAO)', value: 'CFA Franc (BCEAO)'},
    {id: 28, label: 'Bermudian Dollar', value: 'Bermudian Dollar'},
    {id: 29, label: 'Ngultrum', value: 'Ngultrum'},
    {id: 30, label: 'Boliviano', value: 'Boliviano'},
    {id: 31, label: 'Convertible Mark', value: 'Convertible Mark'},
    {id: 32, label: 'Pula', value: 'Pula'},
    {id: 33, label: 'Brazilian Real', value: 'Brazilian Real'},
    {id: 34, label: 'Brunei Dollar', value: 'Brunei Dollar'},
    {id: 35, label: 'Bulgarian Lev', value: 'Bulgarian Lev'},
    {id: 36, label: 'Burundi Franc', value: 'Burundi Franc'},
    {id: 37, label: 'Cabo Verde Escudo', value: 'Cabo Verde Escudo'},
    {id: 38, label: 'Riel', value: 'Riel'},
    {id: 39, label: 'CFA Franc (BEAC)', value: 'CFA Franc (BEAC)'},
    {id: 40, label: 'Cayman Islands Dollar', value: 'Cayman Islands Dollar'},
    {id: 41, label: 'Chilean Peso', value: 'Chilean Peso'},
    {id: 42, label: 'Hong Kong Dollar', value: 'Hong Kong Dollar'},
    {id: 43, label: 'Pataca', value: 'Pataca'},
    {id: 44, label: 'New Taiwan Dollar', value: 'New Taiwan Dollar'},
    {id: 45, label: 'Colombian Peso', value: 'Colombian Peso'},
    {id: 46, label: 'Comoro Franc', value: 'Comoro Franc'},
    {id: 47, label: 'Congolese Franc', value: 'Congolese Franc'},
    {id: 48, label: 'New Zealand Dollar', value: 'New Zealand Dollar'},
    {id: 49, label: 'Costa Rican Colón', value: 'Costa Rican Colón'},
    {id: 50, label: 'Croatian Kuna', value: 'Croatian Kuna'},
    {id: 51, label: 'Cuban Peso', value: 'Cuban Peso'},
    {id: 52, label: 'Netherlands Antillian Guilder', value: 'Netherlands Antillian Guilder'},
    {id: 53, label: 'Czech Koruna', value: 'Czech Koruna'},
    {id: 54, label: 'Danish Krone', value: 'Danish Krone'},
    {id: 55, label: 'Djibouti Franc', value: 'Djibouti Franc'},
    {id: 56, label: 'Dominican Peso', value: 'Dominican Peso'},
    {id: 57, label: 'Egyptian Pound', value: 'Egyptian Pound'},
    {id: 58, label: 'Nakfa', value: 'Nakfa'},
    {id: 59, label: 'Ethiopian Birr', value: 'Ethiopian Birr'},
    {id: 60, label: 'Falkland Islands Pound', value: 'Falkland Islands Pound'},
    {id: 61, label: 'Fiji Dollar', value: 'Fiji Dollar'},
    {id: 62, label: 'Gambian Dalasi', value: 'Gambian Dalasi'},
    {id: 63, label: 'Lari', value: 'Lari'},
    {id: 64, label: 'Ghana Cedi', value: 'Ghana Cedi'},
    {id: 65, label: 'Gibraltar Pound', value: 'Gibraltar Pound'},
    {id: 66, label: 'Guinea Franc', value: 'Guinea Franc'},
    {id: 67, label: 'Guyana Dollar', value: 'Guyana Dollar'},
    {id: 68, label: 'Gourde', value: 'Gourde'},
    {id: 69, label: 'Lempira', value: 'Lempira'},
    {id: 70, label: 'Forint', value: 'Forint'},
    {id: 71, label: 'Iceland Króna', value: 'Iceland Króna'},
    {id: 72, label: 'Rupiah', value: 'Rupiah'},
    {id: 73, label: 'Iranian Rial', value: 'Iranian Rial'},
    {id: 74, label: 'Iraqi Dinar', value: 'Iraqi Dinar'},
    {id: 75, label: 'New Israeli Shekel', value: 'New Israeli Shekel'},
    {id: 76, label: 'Jamaican Dollar', value: 'Jamaican Dollar'},
    {id: 77, label: 'Jordanian Dinar', value: 'Jordanian Dinar'},
    {id: 78, label: 'Tenge', value: 'Tenge'},
    {id: 79, label: 'Kenyan Shilling', value: 'Kenyan Shilling'},
    {id: 80, label: 'North Korean Won', value: 'North Korean Won'},
    {id: 81, label: 'Won', value: 'Won'},
    {id: 82, label: 'Kuwaiti Dinar', value: 'Kuwaiti Dinar'},
    {id: 83, label: 'Som', value: 'Som'},
    {id: 84, label: 'Kip', value: 'Kip'},
    {id: 85, label: 'Lebanese Pound', value: 'Lebanese Pound'},
    {id: 86, label: 'Loti', value: 'Loti'},
    {id: 87, label: 'Liberian Dollar', value: 'Liberian Dollar'},
    {id: 88, label: 'Libyan Dinar', value: 'Libyan Dinar'},
    {id: 89, label: 'Denar', value: 'Denar'},
    {id: 90, label: 'Malagasy Ariary', value: 'Malagasy Ariary'},
    {id: 91, label: 'Malawi Kwacha', value: 'Malawi Kwacha'},
    {id: 92, label: 'Rufiyaa'}
];