import React from 'react';

export default function HumanFemaleFemaleIcon(props) {
	return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            {...props}
        >
            <path fill="currentColor" d="M7.5 2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2M6 22v-6H3l2.6-7.6c.3-.8 1-1.4 1.9-1.4s1.7.6 1.9 1.4L12 16l2.6-7.6c.3-.8 1-1.4 1.9-1.4s1.7.6 1.9 1.4L21 16h-3v6h-3v-6H9v6zM16.5 2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2"></path>
        </svg>
    );
}