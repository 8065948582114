import { Helmet } from "react-helmet";
import ProfileLayout from "../../sections/profile/profile-layout";

const Profile = () => {
    return(
        <>
            <Helmet>
                <title>Profile</title>
            </Helmet>

            <ProfileLayout />
        </>
    );
}

export default Profile;