import {
    Avatar,
    Stack,
    List,
    Divider,
    Typography,
    Link,
    useTheme,
    Chip
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ChatPersonal from './chat-personal';
import { useGetAllChatsListMutation } from '../../services/chat-api';
import APIErrorLayout from '../error/api-error/api-error-layout';
import { useAuth } from '../../contexts/auth/auth-provider';
import { useTranslation } from 'react-i18next';
import SkeletonComponent from '../../components/loading/skeleton-component';
import EmptyLayout from '../error/empty/empty-layout';
import { useChat } from '../../contexts/chat/chat-provider';

const ChatMessageOverviewList = ({pageTrigger}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const validation_locale = "validation";

    const [chatUserList, setChatUserList] = useState([]);

    const [pageIndex, setPageIndex] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [needToCallList, setNeedToCallList] = useState(true);

    const authState = useAuth();
    const useChatContext = useChat();


    useEffect(() => {
        if(useChatContext.newInterestAccepted){
            useChatContext.setNewInterestAccepted(false);
            setNeedToCallList(true);
        }
    }, [useChatContext]);

    useEffect(() => {
        console.log(pageTrigger);
        changePage();
    }, [pageTrigger]);

    const changePage = useCallback(() => {
        let page = pageIndex;
        page = page >= totalPages - 1 ? totalPages - 1 : page + 1;
        page = page < 0 ? 0:

        setPageIndex(page);
    }, [totalPages, pageIndex]);



    const chatWithPerson = useCallback((item) => {
        useChatContext.setActiveUserChat(item);
        useChatContext.setChatBoxBody(<ChatPersonal />);
    }, [useChatContext]);

    const [
        getAllChats,
        {
            isLoading,
            isSuccess,
            data,
            isError,
            error
        }
    ] = useGetAllChatsListMutation();

    useEffect(() => {
        if(needToCallList && authState?.currentUser){
            setChatUserList([]);
            setPageIndex(0);

            setNeedToCallList(false);

            getAllChats(0);
        }
    }, [needToCallList, authState]);

    useEffect(() => {
        if(!needToCallList){
            console.log(pageIndex);
            getAllChats(pageIndex);
        }
    }, [pageIndex]);

    useEffect(() => {
        if(!authState.currentUser && chatUserList.length > 0){
            setChatUserList([]);
            setNeedToCallList(true);
        }
    }, [authState]);

    useEffect(() => {
        if (isSuccess) {
            console.log("Personal chat list data....");
            console.log(data);

            const array = [...chatUserList, ...data.data.content];
            setChatUserList(array);
            
            setTotalPages(data.data.page.totalPages);
        }
    }, [isSuccess, data]);



    const getChatUserListView = () => {
        return <>
            {
                chatUserList.length > 0
                ?
                chatUserList.map((item, index) => {
                    return <div 
                        key={index}
                        style={{
                            marginBottom: '5px',
                            marginTop: '5px'
                        }}
                    >
                        <Link 
                            underline='none'
                            sx={{
                                cursor: 'pointer'
                            }}
                            onClick={() => chatWithPerson(item)}
                        >
                            <Stack
                                key={index}
                                direction='row'
                                alignItems='center'
                                py={2}
                                sx={{
                                    backgroundColor: useChatContext?.getTotalUnreadSpecificUserMessagesCount(item.id) > 0 ? 'rgba(242, 166, 174, 0.2)' : 'transparent',
                                    borderRadius: '8px'
                                }}
                                pl={2}
                            >
                                <Avatar 
                                    alt={item.firstName} 
                                    src={item.profileImg} 
                                    sx={{
                                        width: 88,
                                        height: 88
                                    }}
                                />

                                <Stack
                                    direction='column'
                                    pl={2}
                                    spacing={0.5}
                                >
                                    <Stack direction='row' spacing={3} alignItems='center'>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: 18,
                                                color: 'black'
                                            }}
                                        >
                                            {item.firstName}
                                        </Typography>

                                        <Chip 
                                            size='small' 
                                            label={useChatContext?.getTotalUnreadSpecificUserMessagesCount(item.id)} 
                                            sx={{
                                                backgroundColor: theme.palette.primary.main, color: 'white',
                                                display: useChatContext?.getTotalUnreadSpecificUserMessagesCount(item.id) === 0 ? 'none': 'flex'
                                            }}
                                        />
                                    </Stack>

                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: '#6D6D6D'
                                        }}
                                    >
                                        {item.memberId}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Link>

                        <Divider sx={{opacity: 0.5}}/>
                    </div>
                })
                :
                <Stack sx={{mt: 20}}>
                    <EmptyLayout />
                </Stack>
            }
        </>
    }

    let body;

    if (isLoading) {
        {
            chatUserList.length > 0
            ?
            body = getChatUserListView()
            :
            body = <Stack
                display='flex'
                direction='column'
            >
                {
                    [0, 1, 2, 3, 4].map((item, index) => {
                        return <SkeletonComponent 
                            key={index}
                            variant='rounded' 
                            height="125px"
                            sx={{
                                my: 1
                            }}
                        />
                    })
                }
            </Stack>
        }
    }
    else if (isError) {
        console.log(error);

        body = (
            <>
                {
                    authState.currentUser !== null
                    ?
                    <APIErrorLayout error={error}/>
                    :
                    <APIErrorLayout error={{
                        data: {
                            error:{
                                message: t(`${validation_locale}.please_login_and_continue`)
                            }
                        }
                    }}/>
                }
            </>
        );        
    }
    else if(isSuccess){
        body = getChatUserListView()
    }

    return (
        <>
            <List
                sx={{
                    '& ul': { padding: 0 },
                    pb: 5
                }}
            >
                {body}
            </List>
        </>
    );
}

export default ChatMessageOverviewList;