import React from 'react';

export default function CityVariantOutlineIcon(props) {
	return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            {...props}
        >
            <path fill="currentColor" d="M15 23h-2v-2h2zm4-2h-2v2h2zm-4-4h-2v2h2zm-8 4H5v2h2zm0-4H5v2h2zm12 0h-2v2h2zm-4-4h-2v2h2zm4 0h-2v2h2zm2-4a2 2 0 0 1 2 2v12h-2V11H11v12H9v-8H3v8H1v-8a2 2 0 0 1 2-2h6v-2a2 2 0 0 1 2-2V7a2 2 0 0 1 2-2h2V1h2v4h2a2 2 0 0 1 2 2zm-2 0V7h-6v2z"></path>
        </svg>
    );
}