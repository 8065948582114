import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const verificationAPI = createApi({
    reducerPath: "verificationAPI",
    baseQuery,
    endpoints: (builder) => ({
        getIDVerificationStatus: builder.query({
            query: () => {
                let apiUrl = `user/id/proof`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ['id_verification_state']
        }),
        getOnCallIDVerificationStatus: builder.mutation({
            query: () => {
                let apiUrl = `user/id/proof`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ['id_verification_state']
        }),
        getEmailVerificationStatus: builder.query({
            query: () => {
                let apiUrl = `profile/home-page`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ['email_verification_state']
        }),
        verifyID: builder.mutation({
            query: (body) => {
                return {
                    url: `user/id/verify`,
                    method: "POST",
                    body: body
                };
            },
            transformResponse: (response) => response,
            invalidatesTags: ['id_verification_state']
        }),
        verifyIDAfterRejection: builder.mutation({
            query: (body) => {
                return {
                    url: `user/id/verify`,
                    method: "PUT",
                    body: body
                };
            },
            transformResponse: (response) => response,
            invalidatesTags: ['id_verification_state']
        })
    }),
});

export const { 
    useGetIDVerificationStatusQuery,
    useGetOnCallIDVerificationStatusMutation,
    useGetEmailVerificationStatusQuery,
    useVerifyIDMutation,
    useVerifyIDAfterRejectionMutation
} = verificationAPI;

export default verificationAPI;