import { Helmet } from "react-helmet";
import VerifyEmailLayout from '../../sections/profile-verification/verify-email/verify-email-layout';

const EmailVerificationPage = () => {
    return(
        <>
            <Helmet>
                <title>Email Verification</title>
            </Helmet>

            <VerifyEmailLayout />
        </>
    );
}

export default EmailVerificationPage;