import React from 'react';

export default function CigarIcon(props) {
	return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            {...props}
        >
            <path fill="currentColor" d="M16 10.2h-1.5a2 2 0 0 1-1.85-2a1.78 1.78 0 0 1 1.85-1.75V5a3.35 3.35 0 1 0 0 6.7H16a1.93 1.93 0 0 1 2 2V15h1.5v-1.64A3.31 3.31 0 0 0 16 10.2m2.82-2.47A3.34 3.34 0 0 0 16.5 2v1.5a1.85 1.85 0 1 1 0 3.7v1.5a4 4 0 0 1 4 4.07V15H22v-2.24a5.55 5.55 0 0 0-3.15-5.03M18 16v6h1.5v-6m1 0v6H22v-6m-10 0v1.27a2 2 0 0 1 0 3.46V22h5v-6m-14.12.88A3 3 0 0 0 5 22h5v-1.27a2 2 0 0 1 0-3.46V16H5a3 3 0 0 0-2.12.88M12 19a1 1 0 1 1-1-1a1 1 0 0 1 1 1"></path>
        </svg>
    );
}