import { useTranslation } from "react-i18next";

import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";

import PaginationComponent from "../../../components/pagination/pagination-component";
import ProfileInterestTab from "./profile-interest-tab";
import BottomNavigationBar from "../../navigation/bottom-navigation-bar";

import Footer from '../../footer/footer';
import { useCallback, useEffect, useState } from "react";
import { useGetAllReceivedInterestsMutation, useGetAllSentInterestsMutation } from "../../../services/interest-api";
import { useGetAllBlockedProfilesMutation } from "../../../services/block-api";

const ProfileInterestsLayout = () => {
    const { t } = useTranslation();
    const profile_interests_locale = "profile_interests";

    const [selectedMainFilter, setSelectedMainFilter] = useState('');
    const [selectedSubFilter, setSelectedSubFilter] = useState('');

    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [dataLoading, setDataLoading] = useState(false);
    const [dataError, setDataError] = useState(null);
    const [data, setData] = useState([]);

    const [getAllReceivedInterests, {isLoading: isLoadingReceivedInterests, isSuccess: isSuccessReceivedInterests, data: dataReceivedInterests, isError: isErrorReceivedInterests, error: errorReceivedInterests}] = useGetAllReceivedInterestsMutation();
    const [getAllBlockedProfiles, {isLoading: isLoadingBlockedProfiles, isSuccess: isSuccessBlockedProfiles, data: dataBlockedProfiles, isError: isErrorBlockedProfiles, error: errorBlockedProfiles}] = useGetAllBlockedProfilesMutation();
    const [getAllSentInterests, {isLoading: isLoadingSentInterests, isSuccess: isSuccessSentInterests, data: dataSentInterests, isError: isErrorSentInterests, error: errorSentInterests}] = useGetAllSentInterestsMutation();

    useEffect(() => {
        if(isSuccessReceivedInterests && dataReceivedInterests){
            console.log("Received interest data...");
            console.log(dataReceivedInterests);

            setData(dataReceivedInterests.data.content);
            setTotalPages(dataReceivedInterests.data.page.totalPages);

            setDataLoading(false);
        }
        else if(isErrorReceivedInterests && errorReceivedInterests){
            console.log("Received interest data error...");
            console.log(errorReceivedInterests);

            setDataError(errorReceivedInterests);
            setDataLoading(false);
        }
    }, [isSuccessReceivedInterests, dataReceivedInterests, isErrorReceivedInterests, errorReceivedInterests]);

    useEffect(() => {
        if(isSuccessBlockedProfiles && dataBlockedProfiles){
            console.log("Blocked interest data...");
            console.log(dataBlockedProfiles);

            setData(dataBlockedProfiles.data.content);
            setTotalPages(dataBlockedProfiles.data.page.totalPages);

            setDataLoading(false);
        }
        else if(isErrorBlockedProfiles && errorBlockedProfiles){
            console.log("Blocked interest data error...");
            console.log(errorBlockedProfiles);

            setDataError(errorBlockedProfiles);
            setDataLoading(false);
        }
    }, [isSuccessBlockedProfiles, dataBlockedProfiles, isErrorBlockedProfiles, errorBlockedProfiles]);

    useEffect(() => {
        if(isSuccessSentInterests && dataSentInterests){
            console.log("Sent interest data...");
            console.log(dataSentInterests);

            setData(dataSentInterests.data.content);
            setTotalPages(dataSentInterests.data.page.totalPages);

            setDataLoading(false);
        }
        else if(isErrorSentInterests && errorSentInterests){
            console.log("Sent interest data error...");
            console.log(errorSentInterests);

            setDataError(errorSentInterests);
            setDataLoading(false);
        }
    }, [isSuccessSentInterests, dataSentInterests, isErrorSentInterests, errorSentInterests]);

    const onChangeFilter = useCallback((mainType, subType) => {
        setSelectedPage(1);

        setSelectedMainFilter(mainType);
        setSelectedSubFilter(subType);
    }, []);

    const callAPI = useCallback(async () => {
        setDataLoading(true);
        setDataError(null);

        console.log(selectedMainFilter + ", " + selectedSubFilter + ", " + selectedPage);
        const page = selectedPage - 1 < 0 ? 0 : selectedPage - 1;

        // Received
        if(selectedMainFilter === 0){
            switch(selectedSubFilter){
                case 'RECEIVED':
                    await getAllReceivedInterests({page: page, status: 'PENDING'});
                    break;
    
                case 'ACCEPTED':
                    await getAllReceivedInterests({page: page, status: 'ACCEPTED'});
                    break;
    
                case 'DECLINED':
                    await getAllReceivedInterests({page: page, status: 'REJECTED'});
                    break;
    
                case 'BLOCKED':
                    await getAllBlockedProfiles(page);
                    break;
    
                default:
                    await getAllReceivedInterests({page: page, status: 'PENDING'});
                    break;
            }
        }
        // Sent
        else{
            switch(selectedSubFilter){
                case 'PENDING':
                    await getAllSentInterests({page: page, status: 'PENDING'});
                    break;
    
                case 'ACCEPTED':
                    await getAllSentInterests({page: page, status: 'ACCEPTED'});
                    break;
    
                case 'DECLINED':
                    await getAllSentInterests({page: page, status: 'REJECTED'});
                    break;
    
                default:
                    await getAllSentInterests({page: page, status: 'PENDING'});
                    break;
            }
        }
    }, [selectedMainFilter, selectedSubFilter, selectedPage]);

    const changePage = useCallback((event, page) => {
        setSelectedPage(page);
    }, []);

    useEffect(() => {
        if(selectedMainFilter !== '' && selectedSubFilter !== ''){
            callAPI();
        }
    }, [selectedMainFilter, selectedSubFilter, selectedPage]);

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${profile_interests_locale}.heading`)} 
            />

            <ProfileInterestTab 
                onChange={onChangeFilter} 
                data={data} 
                error={dataError} 
                isLoading={dataLoading}
                successCallback={() => {
                    callAPI();
                }}
            />

            {
                data.length > 0 && !dataError
                ?
                <PaginationComponent 
                    totalPageCount={totalPages} 
                    onChange={changePage}
                    page={selectedPage}
                />
                :
                <></>
            }

            <BottomNavigationBar selectedValue={3}/>

            <Footer />
        </>
    );
}

export default ProfileInterestsLayout;