import { 
    Card,
    Stack,
    Typography,
    Link,
    IconButton
} from "@mui/material";

import ChevronRightIcon from '../../../assets/icons/chevron-right-icon';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth/auth-provider";
import { useNavigate } from "react-router-dom";

const SettingsBody = () => {
    const { t } = useTranslation();
    const settings_locale = "profile_settings.settings";

    const options = [
        // {id: 0, label: t(`${settings_locale}.edit`), link: "/profile"}, //Do not change this id; Use to add user ID below
        {id: 1, label: t(`${settings_locale}.delete`), link: "/delete-profile"},
        {id: 2, label: t(`${settings_locale}.deactivate`), link: "/deactivate-profile"},
        {id: 3, label: t(`${settings_locale}.blocked`), link: "/blocked-profiles"}
    ];

    const auth = useAuth();

    const navigate = useNavigate();

    return (
        <>
            <Card 
                sx={{
                    mx: {xs: 2.5, md: 5},
                    borderRadius: '8px',
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                    mb: 10
                }}
            >
                <Stack>
                    {
                        options.map((item, index) => {
                            return <Stack 
                                key={index}
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center' 
                                sx={{
                                    px: 5,
                                    py: 2.5
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '500px',
                                        fontSize: '16px',
                                        color: '#3D3D3D'
                                    }}
                                >
                                    {item.label}
                                </Typography>

                                <IconButton 
                                    aria-label="arrow" 
                                    size="small" 
                                    onClick={item.id === 0 ? () => navigate(`${item.link}/${auth?.currentUser?.uuid}`) : () => navigate(item.link)}
                                >
                                    <ChevronRightIcon style={{width: '24px', height: '24px', color: '#3D3D3D'}}/>
                                </IconButton>
                            </Stack>
                        })
                    }
                </Stack>
            </Card>
        </>
    );
}

export default SettingsBody;