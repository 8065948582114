import PropTypes from 'prop-types';

import { Chip, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

const ResultFilter = ({
    list, 
    defaultFilterIndex = -1,
    onChange
}) => {
    const [selectedFilterIndex, setSelectedFilterIndex] = useState(-1);

    useEffect(() => {
        if(defaultFilterIndex !== -1){
            setSelectedFilterIndex(defaultFilterIndex);
        }
    }, [defaultFilterIndex]);

    const selectFilter = useCallback((index) => {
        setSelectedFilterIndex(index);
        onChange(list[index].value);
    }, [onChange, list]);

    return (
        <>
            <Stack
                direction='row'
                justifyContent='start'
                alignItems='center'
                className='hideScroll'
                sx={{
                    overflow: 'scroll',
                    px: {xs: 2, md: 7}
                }}
                spacing={2}
            >
                {
                    list.map((item, index) => {
                        return <Chip 
                            key={index} 
                            variant={selectedFilterIndex === index ? "filled" : "outlined"}
                            label={item.label}
                            color='secondary'
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                cursor: 'pointer'
                            }}
                            onClick={() => selectFilter(index)}
                        />
                    })
                }
            </Stack>
        </>
    );
}

export default ResultFilter;

ResultFilter.propType = {
    list: PropTypes.array,
    defaultFilterIndex: PropTypes.number,
    onChange: PropTypes.func
}