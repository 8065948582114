import { Helmet } from "react-helmet";
import ProfileInterestsLayout from "../../sections/profile-general/profile-interests/profile-interests-layout";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const ProfileInterestsPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_INTEREST_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Profile Interests</title>
            </Helmet>

            <ProfileInterestsLayout />
        </>
    );
}

export default ProfileInterestsPage;