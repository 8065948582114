import { Helmet } from "react-helmet";
import ProfileFeedLayout from '../../sections/profile-general/profile-feed/profile-feed-layout';
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const ProfileFeedPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_FEED_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Profile Feed</title>
            </Helmet>

            <ProfileFeedLayout />
        </>
    );
}

export default ProfileFeedPage;