import {
    Box,
    Link,
    Stack,
    Typography
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '../../assets/icons/facebook-icon';
import InstagramIcon from '../../assets/icons/instagram-icon';
import EmailIcon from '../../assets/icons/email-icon';
import { useAnalytics } from '../../contexts/analytics/analytics-provider';

const ContactUsBody = () => {
    const { t } = useTranslation();
    const contact_us_locale = "contact_us";

    const {logCustomEvent, logCustomEventWithParams} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const capitalizeFirstLetter = useCallback((value) => {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }, []);

    const getHeading = useCallback((heading) => {
        return <Typography
            sx={{
                fontWeight: 600,
                fontSize: {xs: 16, md: 24},
                pb: 3
            }}
        >
            {capitalizeFirstLetter(heading)}
        </Typography>
    }, []);

    const getContent = useCallback((content) => {
        return <Typography
            sx={{
                fontWeight: 400,
                fontSize: 16,
                textAlign: 'justify',
                color: '#6D6D6D',
                lineHeight: '24px',
                pt: 5
            }}
        >
            {content}
        </Typography>
    }, []);

    return (
        <>
            <Box sx={{p: {xs: 4, md: 10}}}>
                {getHeading(t(`${contact_us_locale}.body2.heading`))}

                <Stack
                    direction='row'
                    justifyContent='start'
                    alignItems='center'
                    spacing={3}
                >
                    <Link 
                        href="https://www.facebook.com/share/Y18VNUVcuBRz9T1y" 
                        target="_blank"
                        onClick={() => logAnalytics('CLICK_FACEBOOK')}
                    >
                        <FacebookIcon 
                            style={{
                                color: 'black',
                                width: '80px',
                                height: '80px'  
                            }}
                        />
                    </Link>

                    <Link 
                        href="https://www.instagram.com/eelam.maangalyam" 
                        target="_blank"
                        onClick={() => logAnalytics('CLICK_INSTAGRAM')}
                    >
                        <InstagramIcon 
                            style={{
                                color: 'black',
                                width: '80px',
                                height: '80px'  
                            }}
                        />
                    </Link>

                    <Link 
                        href="mailto:support@eelammaangalyam.com" 
                        target="_blank"
                        onClick={() => logAnalytics('CLICK_MAIL')}
                    >
                        <EmailIcon 
                            style={{
                                color: 'black',
                                width: '80px',
                                height: '80px'  
                            }}
                        />
                    </Link>
                </Stack>

                {getContent(t(`${contact_us_locale}.body3.content`))}
            </Box>
        </>
    );
}

export default ContactUsBody;