import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authAPI = createApi({
    reducerPath: "authAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (body) => ({
                url: `auth/register`,
                method: "POST",
                body
            }),
            transformResponse: (response) => response
        }),
        getCustomEmail: builder.mutation({
            query: (userName) => {
                let apiUrl = `auth/firebase/email`;

                if (userName) {
                    apiUrl += `?username=${userName}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        customLogin: builder.mutation({
            query: (idToken) => {
                let apiUrl = `auth/login`;
                
                if (idToken) {
                    apiUrl += `/${idToken}`;
                }

                return {
                    url: apiUrl,
                    method: "POST"
                };
            },
            transformResponse: (response) => response
        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: `auth/forgot-password?email=${email}`,
                method: "POST"
            }),
            transformResponse: (response) => response
        }),
        verifyUserRegistered: builder.mutation({
            query: (body) => ({
                url: `auth/verify/mobile`,
                method: "POST",
                body
            }),
            transformResponse: (response) => response
        }),
        requestOtp: builder.mutation({
            query: (body) => ({
                url: `otp/send`,
                method: "POST",
                body
            }),
            transformResponse: (response) => response
        }),
        reRequestOtp: builder.mutation({
            query: (body) => ({
                url: `otp/resend`,
                method: "POST",
                body
            }),
            transformResponse: (response) => response
        }),
        verifyOtp: builder.mutation({
            query: (body) => ({
                url: `otp/verify`,
                method: "POST",
                body
            }),
            transformResponse: (response) => response
        }),
    }),
});

export const { 
    useRegisterMutation, 
    useGetCustomEmailMutation, 
    useCustomLoginMutation, 
    useForgotPasswordMutation,
    useVerifyUserRegisteredMutation,
    useRequestOtpMutation,
    useReRequestOtpMutation,
    useVerifyOtpMutation
} = authAPI;

export default authAPI;