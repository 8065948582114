import { Stack} from "@mui/material";
import ProfileSearchEditFilter from "./profile-search-edit-filter";

const ProfileSearchByFilter = ({onSearch}) => {
    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='start'
                spacing={2}
            >
                <ProfileSearchEditFilter onSearch={onSearch}/>
            </Stack>
        </>
    );
}

export default ProfileSearchByFilter;