import { Helmet } from "react-helmet";
import ProfileMatchLayout from '../../sections/profile-general/profile-match/profile-match-layout';
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const ProfileMatchPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_MATCHES_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Profile Matches</title>
            </Helmet>

            <ProfileMatchLayout />
        </>
    );
}

export default ProfileMatchPage;