import { Helmet } from "react-helmet";
import PartnerProfessionalPreferenceLayout from "../../sections/partner-preference/professional/partner-professional-preference-layout";

const PartnerProfessionalPreferencePage = () => {
    return(
        <>
            <Helmet>
                <title>Partner Professional Preference</title>
            </Helmet>

            <PartnerProfessionalPreferenceLayout />
        </>
    );
}

export default PartnerProfessionalPreferencePage;