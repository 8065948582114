import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { 
    Stack,
    Slider,
    Typography,
    FormHelperText
} from '@mui/material';

const SliderComponent = ({
    name, 
    label, 
    range,
    required=false,
    ...other
}) => {
    const { control } = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Stack>
                        <Slider
                            name={name}
                            aria-label="Always visible"
                            value={field.value.length > 0 ? field.value : [range[0], range[1]]}
                            onChange={(event) => {
                                field.onChange(event.target.value);
                            }}
                            valueLabelDisplay="on"
                            min={range[0]}
                            max={range[1]}
                            sx={{my: 3}}
                            {...other}
                        />

                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: 16,
                                color: '#6D6D6D'
                            }}
                        >
                            {required ? `${label} *` : `${label}`}
                        </Typography>

                        {
                            error
                            ?
                            <FormHelperText
                                sx={{
                                    color: 'rgba(255, 0, 0, 1)'
                                }}
                            >
                                {error.message}
                            </FormHelperText>
                            :
                            <></>
                        }
                    </Stack>
                )}
            />
        </>
    );
}

export default SliderComponent;

SliderComponent.propType = {
    name: PropTypes.string, 
    label: PropTypes.string, 
    range: PropTypes.array,
    required: PropTypes.bool
}