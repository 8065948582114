import { 
    Stack,
    Divider,
    Typography,
    Link
} from "@mui/material";

import MessageArrowLeftOutlineIcon from "../../assets/icons/message-arrow-left-outline-icon";
import { useTranslation } from "react-i18next";

import { useCallback, useEffect, useState } from "react";

import ChatInterestOverviewList from './chat-interest-overview-list';
import { useGetAllReceivedInterestsMutation } from "../../services/interest-api";
import SkeletonComponent from "../../components/loading/skeleton-component";
import { useAuth } from "../../contexts/auth/auth-provider";
import APIErrorLayout from "../error/api-error/api-error-layout";
import { useChat } from "../../contexts/chat/chat-provider";

const ChatInterestRequestSummary = () => {
    const { t } = useTranslation();
    const chat_locale = "chat";

    const [pendingRequestCount, setPendingRequestCount] = useState(0);

    const authState = useAuth();
    const useChatContext = useChat();

    const openRequestPendingSection = useCallback(() => {
        useChatContext.setChatBoxBody(<ChatInterestOverviewList />);
    }, [useChatContext]);

    const [
        getAllReceivedInterests, 
        {
            isLoading: isLoadingReceivedInterests, 
            isSuccess: isSuccessReceivedInterests, 
            data: dataReceivedInterests, 
            isError: isErrorReceivedInterests, 
            error: errorReceivedInterests
        }
    ] = useGetAllReceivedInterestsMutation();

    useEffect(() => {
        if(authState?.currentUser){
            getAllReceivedInterests({page: 0, status: 'PENDING'});
        }
    }, [authState]);

    useEffect(() => {
        if(isSuccessReceivedInterests && dataReceivedInterests){
            console.log("Received interest data...");
            console.log(dataReceivedInterests);

            setPendingRequestCount(dataReceivedInterests.data.page.totalElements);
        }
    }, [isSuccessReceivedInterests, dataReceivedInterests]);

    let body;

    if (isLoadingReceivedInterests) {
        body = <Stack
            display='flex'
            direction='column'
        >
            <SkeletonComponent 
                variant='rounded' 
                height="100px"
                sx={{
                    my: 1
                }}
            />
        </Stack>
    }
    else if (isErrorReceivedInterests) {
        console.log(errorReceivedInterests);

        body = (
            <>
                {
                    authState.currentUser !== null
                    ?
                    <APIErrorLayout error={errorReceivedInterests}/>
                    :
                    <></>
                }
            </>
        );
    }
    else if(isSuccessReceivedInterests){
        body = <Link 
            underline="none"
            onClick={
                pendingRequestCount === 0 
                ? 
                () => {} 
                : 
                () => openRequestPendingSection()
            }
            sx={{
                cursor: 'pointer'
            }}
        >
            <Stack
                direction='row'
                alignItems='center'
                py={2}
            >
                <MessageArrowLeftOutlineIcon 
                    style={{
                        width: '24px',
                        height: '24px',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        padding: '10px',
                        borderRadius: '50%',
                        color: 'black'
                    }}
                />

                <Stack
                    direction='column'
                    pl={2}
                    spacing={0.25}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            color: 'black'
                        }}
                    >
                        {t(`${chat_locale}.pending_request_summary_heading`)}
                    </Typography>

                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: 14,
                            color: '#6D6D6D'
                        }}
                    >
                        {
                            pendingRequestCount === 0
                            ?
                            t(`${chat_locale}.no_pending_request`)
                            :
                            `${pendingRequestCount} ${t(`${chat_locale}.pending_request_count`)}`
                        }
                    </Typography>
                </Stack>
            </Stack>
        </Link>
    }

    return (
        <>
            {body}

            <Divider sx={{opacity: 0.5}}/>
        </>
    );
}

export default ChatInterestRequestSummary;