import PropTypes from 'prop-types';

import { useCallback, useEffect, useState } from "react";

import { 
    Grid, 
    useTheme,
    Typography,
    Button,
    Badge,
    Avatar,
    Link,
    Stack,
    Menu,
    MenuItem,
    IconButton,
    Divider,
    Card
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AccountMultipleIcon from "../../assets/icons/account-multiple-icon";
import MagnifyIcon from '../../assets/icons/magnify-icon';
import HeartOutlineIcon from '../../assets/icons/heart-outline-icon';
import BellOutlineIcon from '../../assets/icons/bell-outline-icon';
import DownArrow from '../../assets/icons/down-arrow';
import AccountIcon from "../../assets/icons/account-icon";
import CheckDecagramIcon from "../../assets/icons/check-decagram-icon";
import AccountBoxEditOutlineIcon from "../../assets/icons/account-box-edit-outline";
import CogOutlineIcon from "../../assets/icons/cog-outline-icon";
import LogoutIcon from "../../assets/icons/logout-icon";
import DialogBox from "../../components/Dialog/dialog-box";
import NotificationPanel from "../notification/notification-panel";
import CloseIcon from '../../assets/icons/close-icon';
import MenuIcon from '../../assets/icons/menu-icon';
import HomeIcon from '../../assets/icons/home-icon';
import { useAuth } from '../../contexts/auth/auth-provider';
import { useNotification } from '../../contexts/notification/notification-provider';
import SnackbarComponent from '../../components/snack-bar/snack-bar-component';
import { useChat } from '../../contexts/chat/chat-provider';
import { useGetAllNotificationsMutation } from '../../services/notification-api';

const main_nav_options = [
    {label: 'feed', icon: <HomeIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: '/feed'},
    {label: 'matches', icon: <AccountMultipleIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: '/matches'},
    {label: 'search', icon: <MagnifyIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: '/search'},
    {label: 'interests', icon: <HeartOutlineIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: '/interests'}
];

const sub_nav_options_1 = [
    {id: 0, label: 'view_profile', icon: <AccountIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: '/profile'}, //Do not change this id; Use to add user ID below
    {id: 1, label: 'verify_account', icon: <CheckDecagramIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: '/verification'},
    {id: 2, label: 'partner_preference', icon: <AccountBoxEditOutlineIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: '/partner-preference'}
];

const sub_nav_options_2 = [
    {label: 'settings', icon: <CogOutlineIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: '/settings'},
    {label: 'logout', icon: <LogoutIcon style={{color: 'black', height: '24px', width: '24px'}}/>, redirect: 'logout'}
];

const ProfileNavigationBar = ({needSmallScreenDesign=false}) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const profile_navigation_locale = "profile_navigation";

    const auth = useAuth();

    let userName = auth?.currentUser?.firstName;
    let profileImg = auth?.currentUser?.profile;
    let memberId = auth?.currentUser?.memberID;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [anchorNotificationEl, setAnchorNotificationEl] = useState(null);
    const openNotification = Boolean(anchorNotificationEl);

    const [openDialog, setOpenDialog] = useState(false);

    const notificationState = useNotification();
    const useChatContext = useChat();

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    useEffect(() => {console.log(auth)}, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickNotification = (event) => {
        console.log(event.currentTarget)
        setAnchorNotificationEl(event.currentTarget);
    };

    const logout = useCallback(() => {
        setOpenDialog(true);
    }, []);

    const confirmLogout = useCallback(() => {
        setOpenDialog(false);
        auth.logOut();
    }, []);

    const handleClose = useCallback((newPage) => {
        setAnchorEl(null);

        if(typeof newPage === 'string'){
            if(newPage === 'logout'){
                logout();
            }
            else{
                navigate(newPage);
            }
        }
    }, [setAnchorEl, logout, navigate]);

    const handleClosNotification = useCallback((newPage) => {
        setAnchorNotificationEl(null);
    }, [setAnchorNotificationEl]);

    const navigateCallback = useCallback((redirectUrl) => {
        navigate(redirectUrl);
    }, []);

    const clearNotificationCount = useCallback(() => {
        notificationState.clearUnreadNotificationCount();
    }, []);

    const [
        getNotifications,
        {isSuccess, data}
    ] = useGetAllNotificationsMutation();

    useEffect(() => {
        if(isSuccess && data){
            console.log("Notification data...");
            console.log(data);
            console.log(notificationState?.unreadNotificationCount);

            if(data.length > 0 && data.length <= notificationState?.unreadNotificationCount){
                for(let i = 0; i < notificationState?.unreadNotificationCount; i++){
                    if(data[i].module.includes("/interest/accepted")){
                        useChatContext.listenNewlyAcceptedUserChat();
                        break;
                    }
                }
            }
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if(notificationState?.unreadNotificationCount > 0){
            getNotifications();

            setShowSnackBar({
                state: true,
                message: t(`${profile_navigation_locale}.new_notification`),
                color: "blue"
            });
        }
    }, [notificationState]);

    return (
        <>
            <Grid
                xs
                container
                direction='row'
                alignItems='center'
                sx={{
                    py: 3, 
                    px: {xs: 2, md: 6},
                    backgroundColor: 'white',
                    display: {xs: 'none', md: 'flex'}
                }}
            >
                <Grid xs={3}>
                    <Link href="/">
                        <img 
                            src='/assets/images/logo/color-logo.png'
                            alt='logo' 
                            style={{width: 150}}
                        />
                    </Link>
                </Grid>

                <Grid 
                    xs={6} 
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    {
                        main_nav_options.map((item, index) => {
                            return <Button 
                                variant="text"
                                sx={{textTransform: 'none'}}
                                onClick={() => navigateCallback(item.redirect)}
                            >
                                {item.icon}

                                <Typography 
                                    key={index}
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: '#3D3D3D',
                                        px: 2
                                    }}
                                >
                                    {t(`${profile_navigation_locale}.${item.label}`)}
                                </Typography>
                            </Button>
                        })
                    }
                </Grid>

                <Grid xs={3}>
                    <Stack direction="row" alignItems='center' spacing={2}>
                        <IconButton onClick={handleClickNotification}>
                            <Badge badgeContent={notificationState?.unreadNotificationCount} color="primary">
                                <Avatar sx={{ backgroundColor: '#F6F6F6' }}>
                                    <BellOutlineIcon style={{color: '#121212', width: '24px', height: '28px'}}/>
                                </Avatar>
                            </Badge>
                        </IconButton>

                        <Avatar alt={userName} src={profileImg} />

                        <Typography 
                            sx={{
                                fontWeight: 600,
                                fontSize: 16,
                                color: 'black'
                            }}
                        >
                            {userName?.length > 10 ? `${userName.substring(0, 10)}...` : userName}
                        </Typography>

                        <IconButton onClick={handleClick} sx={{mr: 5}}>
                            <DownArrow style={{color: 'black', width: '20px', height: '20px'}}/>
                        </IconButton>
                    </Stack>
                </Grid>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <Stack
                        direction='column'
                        alignItems='center'
                        spacing={1}
                        sx={{
                            pt: 2,
                            px: 7
                        }}
                    >
                        <Avatar 
                            alt={userName} 
                            src={profileImg} 
                            sx={{
                                width: '149px',
                                height: '149px'
                            }}
                        />

                        <Typography 
                            sx={{
                                fontWeight: 600,
                                fontSize: 16,
                                color: '#121212',
                                textAlign: 'left'
                            }}
                        >
                            {userName?.length > 10 ? `${userName.substring(0, 10)}...` : userName}
                        </Typography>

                        <Typography 
                            sx={{
                                fontWeight: 400,
                                fontSize: 16,
                                color: '#121212'
                            }}
                        >
                            {memberId}
                        </Typography>
                    </Stack>

                    <Divider sx={{mx: 2, mt: 2}}/>
                    
                    <Stack
                        direction='column'
                        spacing={1}
                        sx={{
                            pt: 2,
                            px: 1
                        }}
                    >
                        {
                            sub_nav_options_1.map((item, index) => {
                                return <MenuItem 
                                    key={index}
                                    onClick={item.id === 0 ? () => handleClose(`${item.redirect}/${auth?.currentUser?.uuid}`) : () => handleClose(item.redirect)}
                                >
                                    {item.icon}

                                    <Typography 
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: 16,
                                            color: '#3D3D3D',
                                            px: 2
                                        }}
                                    >
                                        {t(`${profile_navigation_locale}.${item.label}`)}
                                    </Typography>
                                </MenuItem>
                            })
                        }
                    </Stack>

                    <Divider sx={{mx: 2, mt: 2}}/>

                    <Stack
                        direction='column'
                        spacing={1}
                        sx={{
                            pt: 2,
                            px: 1,
                            pb: 1
                        }}
                    >
                        {
                            sub_nav_options_2.map((item, index) => {
                                return <MenuItem 
                                    key={index}
                                    onClick={() => handleClose(item.redirect)}
                                >
                                    {item.icon}

                                    <Typography 
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: 16,
                                            color: '#3D3D3D',
                                            px: 2
                                        }}
                                    >
                                        {t(`${profile_navigation_locale}.${item.label}`)}
                                    </Typography>
                                </MenuItem>
                            })
                        }
                    </Stack>
                </Menu>

                <NotificationPanel 
                    anchor={anchorNotificationEl}
                    open={openNotification}
                    handleClose={handleClosNotification}
                    clearNotificationCount={clearNotificationCount}
                />
            </Grid>

            {
                needSmallScreenDesign
                ?
                <Card
                    elevation={0}
                    sx={{
                        display: {xs: 'flex', md: 'none'},
                        boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                        m: 3,
                        py: 3,
                        px: 1.5
                    }}
                >
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Stack direction='row' alignItems='center' justifyContent='start' spacing={2}>
                            <IconButton
                                size="medium"
                                onClick={handleClick}
                                sx={{
                                    backgroundColor: '#E7E7E7'
                                }}
                            >
                                {
                                    open
                                    ?
                                    <CloseIcon 
                                        style={{
                                            color: 'black'
                                        }}
                                    />
                                    :
                                    <MenuIcon 
                                        style={{
                                            color: 'black'
                                        }}
                                    />
                                }
                            </IconButton>

                            <Avatar alt={userName} src={profileImg} sx={{width: '90px', height: '90px'}}/>

                            <Stack direction='column' justifyContent='center' alignItems='start'>
                                <Typography 
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: 'black'
                                    }}
                                >
                                    {userName?.length > 10 ? `${userName.substring(0, 10)}...` : userName}
                                </Typography>

                                <Typography 
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: 16,
                                        color: 'black'
                                    }}
                                >
                                    {memberId}
                                </Typography>
                            </Stack>
                        </Stack>

                        <IconButton onClick={handleClickNotification}>
                            <Badge badgeContent={notificationState?.unreadNotificationCount} color="primary" max={10} showZero>
                                <Avatar sx={{ backgroundColor: '#F6F6F6' }}>
                                    <BellOutlineIcon style={{color: '#121212', width: '24px', height: '28px'}}/>
                                </Avatar>
                            </Badge>
                        </IconButton>
                    </Stack>
                </Card>
                :
                <></>
            }

            <DialogBox 
                title={t(`${profile_navigation_locale}.logout`)}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {t(`${profile_navigation_locale}.logout_confirmation`)}
                    </Typography>
                }
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
                onSuccess={confirmLogout}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default ProfileNavigationBar;

ProfileNavigationBar.propType = {
    needSmallScreenDesign: PropTypes.bool
}