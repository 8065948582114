import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const matchesAPI = createApi({
    reducerPath: "matchesAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllMatches: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `search/match?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["all_matches"]
        }),
        getRecentlyJoined: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `search/match?${queryParams.toString()}&filter=RECENT`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["recently_joined"]
        }),
        getViewedProfilesByMe: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `search/match?${queryParams.toString()}&filter=VIEWED`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["viewed_profiles"]
        }),
        getDailyRecommendations: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `search/daily?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["daily_recommendations"]
        })
    })
});

export const { 
    useGetAllMatchesMutation,
    useGetRecentlyJoinedMutation,
    useGetViewedProfilesByMeMutation,
    useGetDailyRecommendationsMutation
} = matchesAPI;

export default matchesAPI;