import PropTypes from 'prop-types';

import { 
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    Box,
    Typography,
    FormHelperText
} from '@mui/material';

import { useTheme } from '@emotion/react';
import { useFormContext, Controller } from 'react-hook-form';

const SelectionComponent = ({
    name,
    label, 
    list, 
    required=false, 
    selectedValue="",
    onChange,
    sx,
    ...other
}) => {
    const theme = useTheme();

    const { control } = useFormContext();

    return(
        <>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Box sx={{my: 2}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                <Typography color='rgba(145, 158, 171, 1)'>
                                    {label !== '' ? `${label} ${required ? '*' : ''}` : ''}
                                </Typography>
                            </InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name={name} 
                                value={field.value}
                                label={label !== '' ? `${label} ${required ? '*' : ''}` : ''}
                                onChange={(event) => {
                                    field.onChange(event.target.value);

                                    if(onChange) onChange(event.target.value);
                                }}
                                sx={{
                                    borderRadius: '8px',
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: error ? 'rgba(255, 0, 0, 1)' : 'rgba(145, 158, 171, 0.4)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.primary.main
                                    },
                                    ...sx
                                }}
                                {...other}
                            >
                                {
                                    list?.map((item, index) => {
                                        return <MenuItem key={index} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>

                            {
                                error
                                ?
                                <FormHelperText
                                    sx={{
                                        color: 'rgba(255, 0, 0, 1)'
                                    }}
                                >
                                    {error.message}
                                </FormHelperText>
                                :
                                <></>
                            }
                        </FormControl>
                    </Box>
                )}
            />
        </>
    );
}

export default SelectionComponent;

SelectionComponent.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    list: PropTypes.array,
    required: PropTypes.bool,
    selectedValue: PropTypes.string,
    sx: PropTypes.object,
    onChange: PropTypes.func
}