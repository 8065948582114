import React, { useState, useContext, useCallback, useEffect } from "react";

import { NotificationContext } from "./notification-context";
import { getDatabase, onValue, limitToLast, ref, query } from "firebase/database";
import { useAuth } from "../auth/auth-provider";
import { useGetAllNotificationsMutation } from "../../services/notification-api";

export function NotificationProvider({children}){
    const authState = useAuth();
    const database = getDatabase(authState?.firebaseApp);

    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

    useEffect(() => {
        if(authState?.currentUser){
            const userID = authState?.currentUser.uuid;

            console.log("Start to listen the notifications");

            const notificationRef = query(ref(database, 'notification/' + userID), limitToLast(1));

            onValue(notificationRef, (snapshot) => {
                if(snapshot.exists()){
                    console.log("Received new notification");

                    snapshot.forEach((notification) => {
                        console.log(notification.val());
                        setUnreadNotificationCount(notification.val());
                    });
                }
            });
        }
    }, [authState]);

    const clearUnreadNotificationCount = useCallback(() => {
        setUnreadNotificationCount(0);
    }, []);

    const value = {
        unreadNotificationCount,
        clearUnreadNotificationCount
    }

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
}

export const useNotification = () => {
    return useContext(NotificationContext);
};