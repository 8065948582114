import { Helmet } from "react-helmet";
import PartnerReligiousPreferenceLayout from "../../sections/partner-preference/religious/partner-religious-preference-layout";

const PartnerReligiousPreferencePage = () => {
    return(
        <>
            <Helmet>
                <title>Partner Religious Preference</title>
            </Helmet>

            <PartnerReligiousPreferenceLayout />
        </>
    );
}

export default PartnerReligiousPreferencePage;