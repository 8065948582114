import { useCallback, useEffect, useRef, useState } from "react";
import ChatField from "./chat-field";
import ChatMessageBody from "./chat-message-body";
import { Box, Stack } from "@mui/material";
import { useReadAdminMessageMutation, useReplyToAdminMutation } from "../../services/chat-api";
import SkeletonComponent from "../../components/loading/skeleton-component";
import APIErrorLayout from "../error/api-error/api-error-layout";
import { useAuth } from "../../contexts/auth/auth-provider";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../../components/snack-bar/snack-bar-component";
import { getDatabase, ref, onValue, limitToLast, query, endBefore } from "firebase/database";
import { useChat } from "../../contexts/chat/chat-provider";

const ChatAdmin = () => {
    const { t } = useTranslation();
    const validation_locale = "validation";

    const [chat, setChat] = useState([]);

    const [pageIndex, setPageIndex] = useState(0);

    const chatRef = useRef(null);

    const authState = useAuth();
    const chatState = useChat();

    const database = getDatabase(authState?.firebaseApp);
    const [topMessageKey, setTopMessageKey] = useState(null);

    const scrollBottomOfChat = useCallback(() => {
        chatRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }, [chatRef]);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [loadPrevMessageState, setLoadPrevMessageState] = useState({
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: null
    });

    const loadPreviousMessagesAtTheTop = useCallback(async (page) => {
        setLoadPrevMessageState({
            isLoading: true
        });

        try{
            const id = authState?.currentUser.uuid;

            let chatQuery;

            if(topMessageKey){
                chatQuery = query(ref(database, 'admin/chat/' + id), endBefore(null, topMessageKey), limitToLast(10));
            }
            else{
                chatQuery = query(ref(database, 'admin/chat/' + id), limitToLast(10));
            }
        
            let topKey = null;

            onValue(chatQuery, (snapshot) => {
                if(snapshot.exists()){
                    console.log("Received message list from the user " + id + ", for page " + page);
    
                    const messageArray = [];
    
                    snapshot.forEach((item) => {
                        const message = item.val();
                        const found = chat.some(el => el.id === message.id);

                        if(!found) messageArray.push(message);

                        if(!topKey) topKey = item.key;
                    });
                    
                    const array = [...messageArray, ...chat];
                    console.log(array);
                    setChat(array);

                    console.log(topKey);
                    setTopMessageKey(topKey);
                }
    
                setLoadPrevMessageState({
                    isLoading: false,
                    isError: false,
                    isSuccess: true
                });
            }, {onlyOnce: true});
        }
        catch(e){
            setLoadPrevMessageState({
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: "Unable to load the chat"
            });
        }
    }, [chat, topMessageKey, authState]);

    const loadNewMessageAtTheBottom = useCallback((message) => {
        const array = [...chat];

        console.log(array);
        console.log(message);

        const found = array.some(el => el.id === message.id);

        if(!found){
            array.push(message);
            setChat(array);
        }
    }, [chat]);

    // Get All
    useEffect(() => {
        loadPreviousMessagesAtTheTop(pageIndex);
    }, [pageIndex]);

    useEffect(() => {
        if(chatState?.newlyReceivedAdminMessageList.length > 0 && authState){
            const newlyReceivedMessageList = [...chatState?.newlyReceivedAdminMessageList];
            const id = authState?.currentUser?.uuid;

            if(id){
                console.log("New message noted... " + id);
                console.log(newlyReceivedMessageList);
    
                for(let i = 0; i < newlyReceivedMessageList.length; i++){
                    console.log("This is " + id + "'s message");
                    console.log("Message sender id is " + newlyReceivedMessageList[i].senderId);
                    loadNewMessageAtTheBottom(newlyReceivedMessageList[i]);
                }
    
                setTimeout(() => {
                    scrollBottomOfChat();
                }, 250);
            }
        }
    }, [chatState, authState]);

    const changePage = useCallback(() => {
        let page = pageIndex;
        page += 1;

        setPageIndex(page);
    }, [pageIndex]);

    const sendMessage = useCallback((message) => {
        const data = {
            "message": message
        }

        replyToAdmin(data);
    }, []);

    let body;

    if (loadPrevMessageState.isLoading) {
        {
            authState?.currentUser && chat.length > 0
            ?
            body = <>
                <ChatMessageBody chats={chat}/>
                <ChatField submit={sendMessage}/>
            </>
            :
            body = <Stack
                display='flex'
                direction='column'
            >
                <Stack 
                    direction='row' 
                    justifyContent='start'
                    sx={{width: '50%'}}
                >
                    <SkeletonComponent 
                        variant='rounded' 
                        height="200px"
                        width="230px"
                        sx={{
                            mt: 3,
                            ml: 3,
                            borderRadius: '50px 50px 50px 0px',
                        }}
                    />
                </Stack>

                <Stack 
                    direction='row' 
                    justifyContent='end'
                    sx={{width: '100%'}}
                >
                    <SkeletonComponent 
                        variant='rounded' 
                        width="230px"
                        height="200px"
                        sx={{
                            mt: 3,
                            mr: 3,
                            borderRadius: '50px 50px 0px 50px',
                        }}
                    />
                </Stack>
            </Stack>
        }
    }
    else if (loadPrevMessageState.isError) {
        console.log(loadPrevMessageState.error);

        body = (
            <>
                {
                    authState.currentUser !== null
                    ?
                    <APIErrorLayout error={loadPrevMessageState.error}/>
                    :
                    <APIErrorLayout error={{
                        data: {
                            error:{
                                message: t(`${validation_locale}.please_login_and_continue`)
                            }
                        }
                    }}/>
                }
            </>
        );
    }
    else if(loadPrevMessageState.isSuccess){
        body =
            authState?.currentUser
            ? 
            <>
                <ChatMessageBody chats={chat} changePage={changePage} chatRef={chatRef}/>
                <ChatField submit={sendMessage}/>
            </>
            :
            <></>
    }

    const [
        replyToAdmin,
        {
            isLoading: isLoadingReplyToAdmin,
            isSuccess: isSuccessReplyToAdmin,
            data: dataReplyToAdmin,
            isError: isErrorReplyToAdmin,
            error: errorReplyToAdmin
        }
    ] = useReplyToAdminMutation();

    useEffect(() => {
        if (isSuccessReplyToAdmin) {
            console.log("Sent...");
        }
    }, [isSuccessReplyToAdmin]);

    useEffect(() => {
        if (isErrorReplyToAdmin && errorReplyToAdmin) {
            console.log(errorReplyToAdmin);
            const errorMsg = errorReplyToAdmin?.data?.error?.message;

            setShowSnackBar({
                state: true,
                message: errorMsg === undefined ? "Something went wrong" : errorMsg,
                color: "red"
            });
        }
    }, [isErrorReplyToAdmin, errorReplyToAdmin]);


    useEffect(() => {
        if(chatState.getTotalUnreadAdminMessagesCount() > 0){
            readAdminMessage();
        }
    }, [chatState]);
    
    const [
        readAdminMessage,
        {
            isLoading: isLoadingReadAdminMessage,
            isSuccess: isSuccessReadAdminMessage,
            isError: isErrorReadAdminMessage,
            error: errorReadAdminMessage
        }
    ] = useReadAdminMessageMutation();

    useEffect(() => {
        if (isSuccessReadAdminMessage) {
            console.log("Admin message successfully read");
            chatState.clearAdminUnreadStatus();
        }
        else if(isErrorReadAdminMessage && errorReadAdminMessage){
            console.log("Admin message failed to read");
            console.log(errorReadAdminMessage);
        }
    }, [isSuccessReadAdminMessage, isErrorReadAdminMessage, errorReadAdminMessage]);


    useEffect(() => {
        if(!authState.currentUser){
            console.log(">>>>>>>>> CLEAR ADMIN MESSAGES");
            setChat([]);
        }
    }, [authState]);


    return (
        <>
            <Box
                className='hideScroll'
                sx={{
                    overflow: 'scroll',
                    height: '60vh',
                    mb: {xs: 20, md: 0}
                }} 
            >
                {body}
            </Box>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default ChatAdmin;