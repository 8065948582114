import PropTypes from 'prop-types';
import ProfileFeedListViewHeader from './profile-feed-list-view-header';
import ProfileFeedListViewBody from './profile-feed-list-view-body';

const ProfileFeedListViewLayout = ({title, results, caption, viewAllLink, list, id, isLoading=false}) => {
    return (
        <>
            <ProfileFeedListViewHeader 
                title={title}
                results={results}
                caption={caption}
                viewAllLink={viewAllLink}
            />

            <ProfileFeedListViewBody list={list} id={id} isLoading={isLoading}/>
        </>
    );
}

export default ProfileFeedListViewLayout;

ProfileFeedListViewLayout.propType = {
    title: PropTypes.string, 
    results: PropTypes.number, 
    caption: PropTypes.string, 
    viewAllLink: PropTypes.string, 
    list: PropTypes.array,
    id: PropTypes.string,
    isLoading: PropTypes.bool
}