import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import PartnerLocationPreferenceBody from "./partner-location-preference-body";

import Footer from '../../footer/footer';

const PartnerLocationPreferenceLayout = () => {
    const { t } = useTranslation();
    const partner_location_locale = "partner_preference.location";

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${partner_location_locale}.heading`)}
                caption={t(`${partner_location_locale}.caption`)}
            />

            <PartnerLocationPreferenceBody />

            <Footer />
        </>
    );
}

export default PartnerLocationPreferenceLayout;