import { 
    Grid,
    Box,
    Typography,
    CircularProgress
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";

import { useAuthorizeEmailMutation } from '../../../services/verify-email-api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ParkSolidIcon from '../../../assets/icons/park-solid-icon';
import ErrorIcon from '../../../assets/icons/error-icon';

const AuthorizeEmailLayout = () => {
    const { t } = useTranslation();
    const verify_email_locale = "profile_verification.verify_email.authorize";

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [
        authorizeEmail,
        {
            isLoading: isLoading,
            isSuccess: isSuccess,
            isError: isError,
            error: error
        }
    ] = useAuthorizeEmailMutation();

    const callVerification = useCallback(async (token) => {
        await authorizeEmail(token)
    }, []);

    useEffect(() => {
        if(searchParams){
            let token = searchParams.get('token');

            console.log(token);

            if(token){
                console.log("Call API");

                setTimeout(() => {
                    callVerification(token);
                }, 1000);
            }
            else{
                navigate('*');
            }
        }
    }, [searchParams]);

    return(
        <>
            <Grid
                xs
                container
                sx={{height: '100vh', overflow: 'hidden'}}
            >
                {/* Arrow Illustration */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='row'
                    justifyContent={{xs: 'center', md: 'flex-end'}}
                >
                    <Box
                        component="img"
                        sx={{
                            content: `url('/assets/icons/authentication-2.png')`,
                            width: {xs: 150, md: 260, lg: 336}
                        }}
                        alt="icon"
                    />
                </Grid>

                {/* Body */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{px: {xs: 5, md: 25, lg: 50}}}
                >
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: 30, lg: 48}}
                        letterSpacing={-2}
                    >
                        {t(`${verify_email_locale}.title`)}
                    </Typography>

                    <Typography
                        fontWeight={400}
                        fontSize={14}
                        sx={{py: 3, textAlign: 'center'}}
                    >
                        {
                            isSuccess
                            ?
                            t(`${verify_email_locale}.success`)
                            :
                            isError && error
                            ?
                            `${error?.data?.error?.message ? error.data.error.message : 'Something went wrong'}`
                            :
                            t(`${verify_email_locale}.wait`)
                        }
                    </Typography>

                    {
                        isSuccess || isError
                        ?
                        <></>
                        :
                        <CircularProgress />
                    }

                    {
                        isSuccess
                        ?
                        <ParkSolidIcon style={{color: 'green', width: '100px', height: '100px'}}/>
                        :
                        <></>
                    }

                    {
                        isError
                        ?
                        <ErrorIcon style={{color: 'red', width: '100px', height: '100px'}}/>
                        :
                        <></>
                    }
                </Grid>

                {/* Heart Illustration */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='row'
                    justifyContent={{xs: 'center', md: 'start'}}
                >
                    <Box
                        component="img"
                        sx={{
                            content: `url('/assets/icons/authentication-7.png')`,
                            width: {xs: 277, lg: 407}
                        }}
                        alt="icon"
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default AuthorizeEmailLayout;

AuthorizeEmailLayout.propType = {
}