import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import PartnerInfoBoxView from "../partner-info-box-view";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { countries } from "../../../assets/data/countries";
import MultipleSelectionComponent from "../../../components/form/multiple-selection-component";

import { useGetOnCallPartnerPreferenceDetailsMutation } from '../../../services/partner-preference-api';
import FormProviderComponent from "../../../components/form/form-provider-component";
import APIErrorLayout from '../../error/api-error/api-error-layout';

const PartnerLocationPreferenceBody = () => {
    const { t } = useTranslation();
    const partner_location_locale = "partner_preference.location";

    const edit_title = t(`${partner_location_locale}.edit_title`);

    const [partnerData, setPartnerData] = useState({});

    const formSchema = yup.object().shape({
        country: yup.array()
    });

    const defaultValues = {
        country: []
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    const formValue = watch();

    const [
        getPartnerPreferenceDetails,
        {
            isLoading: isLoadingPartnerPreference, 
            isSuccess: isSuccessPartnerPreference, 
            data: dataPartnerPreference, 
            isError: isErrorPartnerPreference, 
            error: errorPartnerPreference
        }
    ]= useGetOnCallPartnerPreferenceDetailsMutation();

    const getMultiValueAssignedValues = useCallback((valuesArray) => {
        const newArray = [];

        for(let i = 0; i < valuesArray?.length; i++){
            newArray.push({label: valuesArray[i], value: valuesArray[i]});
        }
        
        return newArray;
    }, []);

    const setDefaultValues = useCallback((data) => {
        setFormValue("country", getMultiValueAssignedValues(data.country));
    }, []);

    useEffect(() => {
        if(isSuccessPartnerPreference && dataPartnerPreference){
            console.log("Partner preference data...");
            console.log(dataPartnerPreference);

            setPartnerData(dataPartnerPreference.data);
            setDefaultValues(dataPartnerPreference.data);
        }
        else if(isErrorPartnerPreference && errorPartnerPreference){
            console.log("Partner preference data error...");
            console.log(errorPartnerPreference);
        }
    }, [isSuccessPartnerPreference, dataPartnerPreference, isErrorPartnerPreference, errorPartnerPreference]);

    const callPartnerPreferenceDetails = useCallback(async () => {
        await getPartnerPreferenceDetails();
    }, []);

    useEffect(() => {
        callPartnerPreferenceDetails();
    }, []);

    const editCallback = useCallback((edited) => {
        if(edited){
            callPartnerPreferenceDetails();
        }
        else{
            setDefaultValues(partnerData);
        }
    }, [partnerData]);

    return (
        <>
            {
                isErrorPartnerPreference && errorPartnerPreference
                ?
                <APIErrorLayout error={errorPartnerPreference} />
                :
                <Stack
                    direction='column'
                    sx={{
                        width: {xs: '100%', lg: '60%'},
                        my: 5
                    }}
                >
                    <FormProviderComponent methods={formMethods} onSubmit={() => {}}>
                        <PartnerInfoBoxView 
                            label={t(`${partner_location_locale}.country`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='country'
                                    label={t(`${partner_location_locale}.country`)}
                                    list={countries}
                                />
                            }
                            editedValue={formValue.country}
                            modifiedAttribute={["country"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />
                    </FormProviderComponent>
                </Stack>
            }
        </>
    );
}

export default PartnerLocationPreferenceBody;