import { Helmet } from "react-helmet";
import ProfileSearchLayout from '../../sections/profile-general/profile-search/profile-search-layout';
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const ProfileSearchPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_SEARCH_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Profile Search</title>
            </Helmet>

            <ProfileSearchLayout />
        </>
    );
}

export default ProfileSearchPage;