import PropTypes from 'prop-types';

import { 
    Card,
    Stack,
    Typography,
    Link,
    Chip,
    IconButton
} from "@mui/material";

import ChevronRightIcon from '../../../assets/icons/chevron-right-icon';
import { useTranslation } from "react-i18next";
import { useCallback } from 'react';
import CheckDecagramIcon from '../../../assets/icons/check-decagram-icon';
import InformationOutlineIcon from '../../../assets/icons/information-outline-icon';
import CloseIcon from '../../../assets/icons/close-icon';
import { useNavigate } from 'react-router-dom';

const VerificationBody = ({emailStatus, idStatus}) => {
    const { t } = useTranslation();
    const verification_locale = "profile_verification.verification";

    const APPROVED = "ID_APPROVED";
    const SUBMITTED = 'AWAITING_RESPONSE';
    const REJECTED = 'UPLOAD_CLEAR_IMAGES';
    const PENDING = 'PENDING';

    const options = [
        {type: 'EMAIL', label: t(`${verification_locale}.verify_email`), link: "/verify-email"},
        {type: 'ID', label: t(`${verification_locale}.verify_id`), link: "/verify-id"},
    ];

    const navigate = useNavigate();

    const getChipColor = useCallback((status) => {
        if(status === APPROVED){
            return "success";
        }
        else if(status === SUBMITTED){
            return "warning";
        }
        else if(status === REJECTED){
            return "error";
        }
    }, []);

    const getChipLabel = useCallback((status, isEmail) => {
        if(status === APPROVED){
            if(isEmail){
                return t(`${verification_locale}.verified`);
            }
            else {
                return t(`${verification_locale}.approved`);
            }
        }
        else if(status === SUBMITTED){
            return t(`${verification_locale}.awaiting_review`);
        }
        else if(status === REJECTED){
            return t(`${verification_locale}.rejected`);
        }
    }, []);

    const getChipDisplayStatus = useCallback((status) => {
        if(status === PENDING){
            return false;
        }
        else{
            return true;
        }
    }, []);

    const getVerifyActionStatus = useCallback((status) => {
        if(status === REJECTED || status === PENDING){
            return true;
        }
        else{
            return false;
        }
    }, []);

    const getChipIcon = useCallback((status) => {
        if(status === APPROVED){
            return <CheckDecagramIcon style={{width: '12px', height: '12px'}}/>;
        }
        else if(status === SUBMITTED){
            return <InformationOutlineIcon style={{width: '12px', height: '12px'}}/>;
        }
        else if(status === REJECTED){
            return <CloseIcon style={{width: '12px', height: '12px'}}/>;
        }
    }, []);


    const getStatusColor = useCallback((type) => {
        if(type === 'EMAIL'){
            return getChipColor(emailStatus);
        }
        else if(type === 'ID'){
            return getChipColor(idStatus);
        }
    }, [getChipColor]);

    const getLabel = useCallback((type) => {
        if(type === 'EMAIL'){
            return getChipLabel(emailStatus, true);
        }
        else if(type === 'ID'){
            return getChipLabel(idStatus, false);
        }
    }, [getChipLabel]);

    const showChipDisplay = useCallback((type) => {
        if(type === 'EMAIL'){
            return getChipDisplayStatus(emailStatus);
        }
        else if(type === 'ID'){
            return getChipDisplayStatus(idStatus);
        }
    }, [getChipDisplayStatus]);

    const showVerifyAction = useCallback((type) => {
        if(type === 'EMAIL'){
            return getVerifyActionStatus(emailStatus);
        }
        else if(type === 'ID'){
            return getVerifyActionStatus(idStatus);
        }
    }, [getVerifyActionStatus]);

    const getStatusIcon = useCallback((type) => {
        if(type === 'EMAIL'){
            return getChipIcon(emailStatus);
        }
        else if(type === 'ID'){
            return getChipIcon(idStatus);
        }
    }, [getChipIcon]);

    return (
        <>
            <Card 
                sx={{
                    mx: {xs: 2.5, md: 5},
                    borderRadius: '8px',
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                    mb: 10
                }}
            >
                <Stack>
                    {
                        options.map((item, index) => {
                            return <Stack 
                                key={index}
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center' 
                                sx={{
                                    px: 5,
                                    py: 2.5
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '500px',
                                        fontSize: '16px',
                                        color: '#3D3D3D'
                                    }}
                                >
                                    {item.label} 
                                    
                                    {
                                        showChipDisplay(item.type)
                                        ?
                                        <Chip 
                                            label={getLabel(item.type)} 
                                            color={getStatusColor(item.type)} 
                                            size="small" 
                                            icon={getStatusIcon(item.type)}
                                            sx={{
                                                ml: 2,
                                                fontSize: '12px'
                                            }}
                                        />  
                                        :
                                        <></>    
                                    }
                                </Typography>

                                {
                                    showVerifyAction(item.type)
                                    ?
                                    <IconButton aria-label="arrow" size="small" onClick={() => navigate(item.link)}>
                                        <ChevronRightIcon style={{width: '24px', height: '24px', color: '#3D3D3D'}}/>
                                    </IconButton>
                                    :
                                    <></>
                                }
                            </Stack>
                        })
                    }
                </Stack>
            </Card>
        </>
    );
}

export default VerificationBody;

VerificationBody.propType = {
    emailStatus: PropTypes.string, 
    idStatus: PropTypes.string
}