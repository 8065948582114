import ProfileSettingsHeader from "../profile-settings/profile-settings-header";
import BottomNavigationBar from "../navigation/bottom-navigation-bar";
import { useChat } from "../../contexts/chat/chat-provider";

const ChatBoxMobileLayout = () => {
    const useChatContext = useChat();

    return (
        <>
            <ProfileSettingsHeader 
                heading={useChatContext?.chatBoxBar?.heading} 
                caption={useChatContext?.chatBoxBar?.caption}
                mb={0}
                callback={useChatContext?.chatBoxBar?.back}
                needCustomCallback={useChatContext?.chatBoxBar?.back !== null}
            />

            {useChatContext?.chatBoxBody}

            <BottomNavigationBar selectedValue={4}/>
        </>
    );
}

export default ChatBoxMobileLayout;