import PropTypes from 'prop-types';

import { 
    Box, 
    Stack, 
    Typography,
    IconButton
} from "@mui/material";

import EditIcon from '../../assets/icons/edit-icon';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import DialogBox from '../../components/Dialog/dialog-box';
import SkeletonComponent from '../../components/loading/skeleton-component';
import { useUpdatePartnerPreferenceMutation } from '../../services/partner-preference-api';
import SnackbarComponent from '../../components/snack-bar/snack-bar-component';

const PartnerInfoBoxView = ({
    label, 
    editTitle, 
    editCallback,
    editChildren,
    editedValue,
    modifiedAttribute,
    requestObj,
    isLoading,
    editCallValidation,
    validation,
    errorMessage,
    hideEditIcon=false,
    specialText
}) => {
    const { t } = useTranslation();
    const partner_preference_locale = "partner_preference";

    const isEmptyObj = (obj) => {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }
      
        return true;
    }

    const [existingValue, setExistingValue] = useState('');

    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        if(!isEmptyObj(requestObj) && modifiedAttribute){
            
            // Array
            if(modifiedAttribute.length === 1){
                setExistingValue(requestObj[modifiedAttribute[0]]);
            }
            // Slider
            else {
                setExistingValue(`${requestObj[modifiedAttribute[0]]} to ${requestObj[modifiedAttribute[1]]}`);
            }
        }
    }, [requestObj, modifiedAttribute]);

    const [openEdit, setOpenEdit] = useState(false);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const openEditForm = useCallback(() => {
        if(editCallValidation){
            const error = editCallValidation();

            if(error){
                setShowSnackBar({
                    state: true,
                    message: error,
                    color: "red"
                });
            }
            else{
                setOpenEdit(true);
            }
        }
        else{
            setOpenEdit(true);
        }
    }, [editCallValidation]);

    const getArrayValue = useCallback((array) => {
        const requestArray = [];

        for(let i = 0; i < array.length; i++){
            requestArray.push(array[i].value);
        }

        return requestArray;
    }, []);

    const [
        updatePartnerPreference,
        {
            isLoading: isLoadingUpdate,
            isSuccess,
            isError,
            error
        }
    ] = useUpdatePartnerPreferenceMutation();

    useEffect(() => {
        if(isSuccess){
            setOpenEdit(false);
            editCallback(true);
        }
        else if(isError && error){
            setShowSnackBar({
                state: true,
                message: `${error.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccess, isError, error]);

    const callEditCallback = useCallback(async () => {
        if(validation && !validation(editedValue)){
            setErrorMsg(errorMessage);
            return;
        };

        setErrorMsg('');

        console.log(editedValue);
        console.log(requestObj);

        const obj = {};
        let counter;

        // Single value or Array
        if(modifiedAttribute.length === 1){
            counter = 0;

            for (var key in requestObj) {
                if (requestObj.hasOwnProperty(key)) {
                    if(key === modifiedAttribute[0]){
                        if(editedValue.constructor === Array){
                            obj[key] = getArrayValue(editedValue);
                        }
                        else{
                            obj[key] = editedValue;
                        }
                    }
                    else{
                        obj[key] = requestObj[key];
                    }

                    counter++;
                }
            }
        }
        // Slider
        else{
            counter = 0;

            for (var key in requestObj) {
                if (requestObj.hasOwnProperty(key)) {
                    if(modifiedAttribute.includes(key)){
                        for(let j = 0; j < modifiedAttribute.length; j++){
                            if(key === modifiedAttribute[j]){
                                obj[key] = editedValue[counter];
                                counter++;

                                break;
                            }
                        }
                    }
                    else{
                        obj[key] = requestObj[key];
                    }
                }
            }
        }

        console.log("Edit partner preference obj...");
        console.log(obj);

        await updatePartnerPreference(obj);

    }, [editedValue, requestObj, modifiedAttribute]);

    const capitalizeFirstLetter = useCallback((value) => {
        if(!isNaN(value) || value.length <= 1){
            return value;
        }

        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }, []);

    const getModifiedValues = useCallback((value) => {
        if(value.constructor === Array){
            let v = "";

            for(let i = 0; i < value.length; i++){
                v += capitalizeFirstLetter(value[i]);

                if(i < value.length - 1){
                    v += ", ";
                }
            }

            return v;
        }

        return capitalizeFirstLetter(value);
    }, []);

    return (
        <>
            <Box
                sx={{
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                    marginLeft: {xs: '15px', lg: '30px'},
                    marginRight: {xs: '15px', lg: '0px'},
                    my: 1.5
                }}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    py={2}
                    px={1.5}
                >
                    <Stack
                        direction='column'
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: 16
                            }}
                        >
                            {label}
                        </Typography>

                        {
                            isLoading
                            ?
                            <SkeletonComponent width='200px' height='20px' sx={{mt: 1}}/>
                            :
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: '#6D6D6D'
                                }}
                            >
                                {!existingValue || existingValue.length === 0 ? specialText ? specialText : t(`${partner_preference_locale}.not_specified_yet`) : getModifiedValues(existingValue)}
                            </Typography>
                        }
                    </Stack>

                    {
                        isLoading || hideEditIcon
                        ?
                        <></>
                        :
                        <IconButton onClick={openEditForm}>
                            <EditIcon style={{width: '24px', height: '24px'}}/>
                        </IconButton>
                    }
                </Stack>
            </Box>

            <DialogBox 
                title={editTitle}
                children={
                    <>
                        {editChildren}
                        <p style={{color: 'red', fontSize: '12px'}}>{errorMsg}</p>
                    </>
                }
                open={openEdit} 
                onClose={() => {
                    setOpenEdit(false);
                    editCallback(false);
                    setErrorMsg('');
                }}
                onSuccess={callEditCallback}
                approveText={t(`${partner_preference_locale}.save`)}
                submitInLoading={isLoadingUpdate}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default PartnerInfoBoxView;

PartnerInfoBoxView.propType = {
    label: PropTypes.string,
    editTitle: PropTypes.string,
    editChildren: PropTypes.object,
    isLoading: PropTypes.bool,
    modifiedAttribute: PropTypes.array,
    requestObj: PropTypes.object,
    editCallValidation: PropTypes.func,
    validation: PropTypes.func,
    errorMessage: PropTypes.string,
    hideEditIcon: PropTypes.bool,
    specialText: PropTypes.string
}