import PropTypes from 'prop-types';

import {
    Skeleton,
    Stack
} from '@mui/material';

/*
    VARIANTS = text/circular/rectangular/rounded
*/

const SkeletonComponent = ({
    variant='rounded', 
    width='100%', 
    height='100%', 
    fontSize='1px',
    ...sx
}) => {
    return (
        <>
            <Stack>
            {
                variant === 'text'
                ?
                <Skeleton 
                    variant="text" 
                    sx={{ 
                        fontSize: fontSize, 
                        ...sx 
                    }} 
                />
                :
                <Skeleton 
                    variant={variant} 
                    width={width} 
                    height={height} 
                    {...sx}
                />
            }
            </Stack>
        </>
    );
}

export default SkeletonComponent;

SkeletonComponent.propType = {
    variant: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    fontSize: PropTypes.string
}