import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import VerificationBody from "./verification-body";

import Footer from '../../footer/footer';
import { useGetEmailVerificationStatusQuery, useGetOnCallIDVerificationStatusMutation } from "../../../services/verification-api";
import { useEffect } from "react";
import SkeletonComponent from "../../../components/loading/skeleton-component";
import { Stack } from "@mui/material";
import APIErrorLayout from "../../error/api-error/api-error-layout";

const VerificationLayout = () => {
    const { t } = useTranslation();
    const verification_locale = "profile_verification.verification";

    const [
        checkIDVerification,
        {
            isLoading: isLoadingOnCallIDVerification, 
            isSuccess: isSuccessOnCallIDVerification, 
            data: dataOnCallIDVerification, 
            isError: isErrorOnCallIDVerification, 
            error: errorOnCallIDVerification
        }
    ] = useGetOnCallIDVerificationStatusMutation();

    useEffect(() => {
        checkIDVerification();
    }, []);

    useEffect(() => {
        if(isSuccessOnCallIDVerification && dataOnCallIDVerification){
            console.log("ID verification data...");
            console.log(dataOnCallIDVerification);
        }
    }, [isSuccessOnCallIDVerification, dataOnCallIDVerification]);

    const {
        isLoading: isLoadingEmailVerification, 
        isSuccess: isSuccessEmailVerification, 
        data: dataEmailVerification, 
        isError: isErrorEmailVerification, 
        error: errorEmailVerification
    } = useGetEmailVerificationStatusQuery();

    useEffect(() => {
        if(isSuccessEmailVerification && dataEmailVerification){
            console.log("Email verification data...");
            console.log(dataEmailVerification);
        }
    }, [isSuccessEmailVerification, dataEmailVerification]);

    let body;

    if(isLoadingOnCallIDVerification || isLoadingEmailVerification){
        body = <Stack 
            justifyContent='center' 
            sx={{mb: 5, mx: 5}}
        >
            <SkeletonComponent 
                variant='rectangular' 
                width="100%" 
                height="200px"
            />
        </Stack>
    }
    else if(isErrorEmailVerification){
        console.log(errorEmailVerification);

        body = <APIErrorLayout error={errorEmailVerification}/>
    }
    else if(isErrorOnCallIDVerification){
        console.log(errorOnCallIDVerification);

        if(isSuccessEmailVerification && dataEmailVerification){
            // Not verified
            if(errorOnCallIDVerification.status === 400){
                body = <VerificationBody 
                    emailStatus={dataEmailVerification.data.emailVerified ? 'ID_APPROVED' : 'PENDING'} 
                    idStatus='PENDING'
                />;
            }
            else{
                body = <APIErrorLayout error={errorOnCallIDVerification}/>
            }
        }
    }
    else if(isSuccessOnCallIDVerification && isSuccessEmailVerification){
        body = <VerificationBody 
            emailStatus={dataEmailVerification.data.emailVerified ? 'ID_APPROVED' : 'PENDING'} 
            idStatus={dataOnCallIDVerification.data.status}
        />
    }

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${verification_locale}.heading`)}
                caption={t(`${verification_locale}.caption`)}
            />

            {body}

            <Footer />
        </>
    );
}

export default VerificationLayout;