import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Chip,
    Stack
} from '@mui/material';

import { useTheme } from '@emotion/react';

import InfoIcon from '../../assets/icons/info-icon';

const TextFieldComponent = ({
    name,
    label, 
    type,
    required=false, 
    fullWidth=true, 
    startUnit,
    endIcon,
    endIconCallback,
    helper,
    sx,
    ...other
}) => {
    const theme = useTheme();

    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Box sx={{my: 2}}>
                    <TextField 
                        label={required ? `${label} *` : label}
                        variant="outlined" 
                        value={type === 'number' && field.value === 0 ? '' : field.value}
                        type={type ? type : 'text'}
                        onChange={(event) => {
                            if (type === 'number') {
                                field.onChange(Number(event.target.value));
                            } else {
                                field.onChange(event.target.value);
                            }
                        }}
                        fullWidth={fullWidth}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: error ? 'rgba(255, 0, 0, 1)' : 'rgba(145, 158, 171, 0.4)',
                                    borderWidth: "1px",
                                    borderRadius: '8px'
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: "2px",
                                    },
                                },
                            },
                            ...sx
                        }}
                        InputLabelProps={{
                            style: { color: 'rgba(145, 158, 171, 1)' },
                        }}
                        error={!!error}
                        helperText={
                            error
                            ?
                            error.message
                            :
                            <>
                                {
                                    helper
                                    ?
                                    <Stack direction='row'>
                                        <InfoIcon style={{width: '13px', paddingTop: 3, paddingRight: 5}}/> 
                                        {helper}
                                    </Stack>
                                    :
                                    null
                                }
                            </>
                        }
                        FormHelperTextProps={{
                            sx: {
                                color: error ? 'rgba(255, 0, 0, 1)' : '#637381'
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                startUnit
                                ?
                                <InputAdornment 
                                    position="start"
                                >
                                    <Chip 
                                        label={startUnit}
                                        sx={{
                                            backgroundColor: '#E7E7E7',
                                            color: '#3D3D3D',
                                            px: 1
                                        }}
                                    />
                                </InputAdornment>
                                :
                                null
                            ),
                            endAdornment : (
                                endIcon
                                ?
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={endIconCallback}
                                    >
                                        {endIcon}
                                    </IconButton>
                                </InputAdornment>
                                :
                                null
                            )
                        }}
                        {...other}
                    />
                </Box>
            )}
        />
    );
}

export default TextFieldComponent;

TextFieldComponent.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    startUnit: PropTypes.object,
    endIcon: PropTypes.object,
    endIconCallback: PropTypes.func,
    helper: PropTypes.string,
    sx: PropTypes.object
}
