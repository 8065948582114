import { 
    Grid,
    Typography
} from "@mui/material";

import { useTranslation } from "react-i18next";

import AccordionComponent from '../../components/accordion/accordion-component';

const HomeFaq = () => {
    const { t } = useTranslation();
    const faq_locale = "landing_page.faq";

    return (
        <>
            <Grid
                xs
                container
                direction='column'
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                    mt: 10,
                    pb: 5,
                    backgroundColor: '#F6F6F6'
                }}
            >
                <Grid 
                    xs={12}
                >
                    <Typography
                        textAlign='center'
                        sx={{
                            fontWeight: 600,
                            fontSize: {xs: 25, md: 32, lg: 48},
                            letterSpacing: -2,
                            pb: 3,
                            pt: 6
                        }}
                    >
                        {t(`${faq_locale}.heading`)}
                    </Typography>
                </Grid>

                <Grid 
                    xs={12}
                >
                    <Typography
                        textAlign='center'
                        sx={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: '24px',
                            color: '#6D6D6D',
                            px: 2,
                            pb: {xs: 2, md: 5}
                        }}
                    >
                        {t(`${faq_locale}.caption`)}
                    </Typography>
                </Grid>

                <Grid 
                    xs={12}
                    container
                    direction='column'
                    px={{xs: 5, lg: 7}}
                >
                    <AccordionComponent list={[
                        {
                            summary: t(`${faq_locale}.question1.question`),
                            detail: t(`${faq_locale}.question1.answer`)
                        },
                        {
                            summary: t(`${faq_locale}.question2.question`),
                            detail: t(`${faq_locale}.question2.answer`)
                        },
                        {
                            summary: t(`${faq_locale}.question3.question`),
                            detail: t(`${faq_locale}.question3.answer`)
                        },
                        {
                            summary: t(`${faq_locale}.question4.question`),
                            detail: t(`${faq_locale}.question4.answer`)
                        },
                        {
                            summary: t(`${faq_locale}.question5.question`),
                            detail: t(`${faq_locale}.question5.answer`)
                        },
                        {
                            summary: t(`${faq_locale}.question6.question`),
                            detail: t(`${faq_locale}.question6.answer`)
                        }
                    ]}/>
                </Grid>
            </Grid>
        </>
    );
}

export default HomeFaq;