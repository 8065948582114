import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import PropTypes from 'prop-types';

import { useCallback, useEffect, useState } from "react";

import { 
    Stack,
    Typography,
    Box
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { ButtonComponent, LoadingButtonComponent, RadioButton } from "../../../components/form";
import { useNavigate } from "react-router-dom";
import FileUpload from '../../../components/form/file-upload';
import DialogBox from '../../../components/Dialog/dialog-box';
import FormProviderComponent from '../../../components/form/form-provider-component';
import { useVerifyIDAfterRejectionMutation, useVerifyIDMutation } from '../../../services/verification-api';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';

const verificationFileList = [
    {id: 0, label: 'NIC', value: 'NIC'},
    {id: 1, label: 'Passport', value: 'Passport'}
];

const VerifyIDBody = ({step, updateStep}) => {
    const { t } = useTranslation();
    const verify_id_locale = "profile_verification.verify_id";

    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);

    const [selectedImage, setSelectedImage] = useState([]);
    const [isImagesUnderUploadState, setIsImagesUnderUploadState] = useState(false);
    const [resetUploadedFile, setResetUploadedFile] = useState(0);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const schema = yup.object().shape({
        file_type: yup.string(),
        image: yup.array().test('isValidProfile', t(`${verify_id_locale}.errors.id_limit_invalid`), () => {
            const isValid = (isNIC() && selectedImage.length === 2) || (!isNIC() && selectedImage.length === 1);
            return isValid;
        }),
    });

    const defaultValues = {
        file_type: 'NIC',
        image: []
    };

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    const {
        reset: resetForm,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch
    } = methods;

    const formValues = watch();

    const getIDImageList = useCallback(() => {
        const array = [];

        for(let i = 0; i < selectedImage.length; i++){
            array.push(selectedImage[i].downloadURL);
        }

        return array;
    }, [selectedImage]);

    const [
        verifyIDAfterRejection,
        {
            isLoading: isLoadingVerifyIDAfterRejection,
            isSuccess: isSuccessVerifyIDAfterRejection,
            data: dataVerifyIDAfterRejection,
            isError: isErrorVerifyIDAfterRejection,
            error: errorVerifyIDAfterRejection
        }
    ] = useVerifyIDAfterRejectionMutation();

    useEffect(() => {
        if(isSuccessVerifyIDAfterRejection){
            setOpenDialog(true);
        }
        else if(isErrorVerifyIDAfterRejection && errorVerifyIDAfterRejection){
            console.log(errorVerifyIDAfterRejection);

            setShowSnackBar({
                state: true,
                message: `${errorVerifyIDAfterRejection.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessVerifyIDAfterRejection, isErrorVerifyIDAfterRejection, errorVerifyIDAfterRejection]);

    const [
        verifyID,
        {
            isLoading: isLoading,
            isSuccess: isSuccess,
            data: data,
            isError: isError,
            error: error
        }
    ] = useVerifyIDMutation();

    const callIDUpdateAPI = useCallback(async () => {
        const imageArray = [...getIDImageList()];

        const obj = {
            "frontImageUrl": imageArray[0],
            "rearImageUrl": imageArray.length > 1 ? imageArray[1] : ""
        };

        console.log(obj);

        await verifyIDAfterRejection(obj);
    }, [getIDImageList]);

    useEffect(() => {
        if(isSuccess){
            setOpenDialog(true);
        }
        else if(isError && error){
            console.log(error);

            if(error.status === 400){
                callIDUpdateAPI();
            }
            else{
                setShowSnackBar({
                    state: true,
                    message: `${error.data.error.message}`,
                    color: "red"
                });
            }
        }
    }, [isSuccess, isError, error]);

    const onNextStep = handleSubmit(async(data) => {
        if(isImagesUnderUploadState) {
            setShowSnackBar({
                state: true,
                message: t(`${verify_id_locale}.wait_until_upload`),
                color: "blue"
            });

            return;
        }

        console.log(data);

        try{
            if(step === 1){
                console.log(selectedImage);
                updateStep();
            }
            else{
                const imageArray = [...getIDImageList()];

                const obj = {
                    "frontImageUrl": imageArray[0],
                    "rearImageUrl": imageArray.length > 1 ? imageArray[1] : ""
                };

                console.log(obj);

                await verifyID(obj);
            }
        }
        catch(error){
            console.log(error);
        }
    });

    const isNIC = useCallback(() => {
        return formValues.file_type === 'NIC';
    }, [formValues]);

    const clearAllUploadedImages = useCallback(() => {
        if(selectedImage.length > 0){
            setSelectedImage([]);
            setResetUploadedFile((count) => count + 1);
        }
    }, [selectedImage]);

    const closeSubmissionMessage = useCallback(() => {
        setOpenDialog(false);
        navigate(-1);
    }, []);

    let body;

    if(step === 1){
        body = <>
            <RadioButton 
                label={t(`${verify_id_locale}.step${step}.radio_title`)}
                name='file_type'
                list={verificationFileList}
                selectedValue={isNIC() ? 'NIC' : 'Passport'}
                disabledValue={isImagesUnderUploadState ? isNIC() ? 'Passport' : 'NIC' : ''}
                switchCallback={clearAllUploadedImages}
            />

            <Box sx={{width: {xs: '100%', md: '50%'}}}>
                <FileUpload 
                    name="image"
                    label={
                        isNIC()
                        ?
                        t(`${verify_id_locale}.step${step}.image_upload_label_nic`)
                        :
                        t(`${verify_id_locale}.step${step}.image_upload_label_passport`)
                    }
                    min={isNIC() ? 2 : 1}
                    max={isNIC() ? 2 : 1}
                    imageList={selectedImage}
                    fileExtensionList={['png', 'jpeg', 'jpg']}
                    setImageList={setSelectedImage}
                    uploadType='ID_IMG'
                    setIsImagesUnderUploadState={setIsImagesUnderUploadState}
                    listenToResetAll={resetUploadedFile}
                />
            </Box>
        </>
    }
    else if(step === 2){
        body = <>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: {xs: 20, md: 24},
                    color: '#6D6D6D',
                    pb: 3
                }}
            >
                {`${t(`${verify_id_locale}.step${step}.question_prefix`)} ${isNIC() ? t(`${verify_id_locale}.step${step}.nic`) : t(`${verify_id_locale}.step${step}.passport`)} ${t(`${verify_id_locale}.step${step}.question_suffix`)}`}
            </Typography>

            <Stack
                direction={{xs: 'column', md: 'row'}}
                className='hideScroll'
                sx={{
                    width: '100%',
                    overflowX: 'scroll',

                }}
                spacing={2}
            >
                <img 
                    src={selectedImage[0].downloadURL}
                    alt='id'
                    style={{
                        width: '50%',
                        borderRadius: '8px'
                    }}
                />

                {
                    isNIC()
                    ?
                    <img 
                        src={selectedImage[1].downloadURL}
                        alt='id'
                        style={{
                            width: '50%',
                            borderRadius: '8px'
                        }}
                    />
                    :
                    <></>
                }
            </Stack>
        </>
    }

    return (
        <>
            <FormProviderComponent methods={methods} onSubmit={onNextStep}>
                <Stack 
                    sx={{
                        mx: {xs: 2.5, md: 5},
                        height: '80%'
                    }} 
                    spacing={3}
                >
                    <Box sx={{height: '80%'}}>
                        {body}
                    </Box>

                    <Stack 
                        direction={{xs: 'column', md: 'row'}}
                        spacing={2}
                        display='flex'
                        sx={{
                            position: {xs: 'sticky', md: 'relative'},
                            bottom: 0,
                            pb: {xs: 3, md: 10},
                            backgroundColor: 'white',
                            width: '100%'
                        }}
                    >
                        <LoadingButtonComponent 
                            text={step === 2 ? t(`${verify_id_locale}.confirm`)  : t(`${verify_id_locale}.continue`)}
                            type="submit"
                            inLoading={isLoading || isLoadingVerifyIDAfterRejection}
                            width={103}
                            sx={{
                                color: 'white',
                                backgroundColor: 'black'
                            }}
                        />

                        <ButtonComponent 
                            text={t(`${verify_id_locale}.cancel`)}
                            onClick={() => navigate(-1)}
                            outlined
                            color="secondary"
                            width={103}
                            sx={{
                                color: 'black'
                            }}
                        />
                    </Stack>
                </Stack>
            </FormProviderComponent>

            <DialogBox 
                title={t(`${verify_id_locale}.submitted_heading`)}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {t(`${verify_id_locale}.submitted_message`)}
                    </Typography>
                }
                approveText={t(`${verify_id_locale}.ok`)}
                hideClose
                open={openDialog} 
                onSuccess={closeSubmissionMessage}
                onClose={closeSubmissionMessage}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />  
        </>
    );
}

export default VerifyIDBody;

VerifyIDBody.propType = {
    step: PropTypes.number,
    updateStep: PropTypes.func
}