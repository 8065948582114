import NavigationBar from "../../navigation/navigation-bar";
import Hero from "../hero";
import HomeCarousel from "../home-carousel";
import HomeFaq from "../home-faq";
import Footer from "../../footer/footer";
import HomeIntroduction from "../home-introduction";
import HomeReminder from "../home-reminder";
import HowItWorksLayout from "../how-it-works/how-it-works-layout";

const HomeLayout = () => {
    return(
        <>
            <NavigationBar />

            <Hero />

            <HomeIntroduction />

            <HomeCarousel />

            <HowItWorksLayout />

            <HomeReminder />

            <HomeFaq />

            <Footer />
        </>
    );
}

export default HomeLayout;