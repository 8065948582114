import { 
    Grid,
    Typography,
    useTheme,
    Link,
    Box,
    Stack
} from "@mui/material";

import { useTranslation } from "react-i18next";

import ButtonComponent from '../../components/form/button-component';
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback } from "react";

const HomeIntroduction = () => {
    const theme = useTheme();

    const { t } = useTranslation();
    const introduction_locale = "landing_page.introduction";

    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback(() => {
        logCustomEvent('CLICK_START_FOR_FREE');
    }, []);

    return (
        <>
            <Grid
                xs
                container
                direction='column'
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                    mt: 10
                }}
            >
                <Grid 
                    xs={12}
                >
                    <Typography
                        textAlign='center'
                        sx={{
                            fontWeight: 600,
                            fontSize: {xs: 25, md: 32, lg: 48},
                            letterSpacing: -2,
                            lineHeight: 2,
                            pb: 3
                        }}
                    >
                        {t(`${introduction_locale}.heading1`)}

                        <span
                            style={{
                                padding: 15,
                                marginLeft: 15,
                                marginRight: 15,
                                color: theme.palette.primary.main,
                                backgroundColor: 'rgba(251, 57, 87, 0.1)',
                                fontWeight: 800,
                                borderRadius: 16
                            }}
                        >
                            <i>{t(`${introduction_locale}.heading2`)}</i>
                        </span>

                        {t(`${introduction_locale}.heading3`)}
                    </Typography>
                </Grid>

                <Grid 
                    xs={12}
                >
                    <Typography
                        textAlign='center'
                        sx={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: '24px',
                            color: '#6D6D6D',
                            px: 2,
                            pb: 5
                        }}
                    >
                        {t(`${introduction_locale}.caption`)}
                    </Typography>
                </Grid>

                <Grid 
                    xs={12}
                >
                    <Link href="#hero" underline="none">
                        <ButtonComponent 
                            text={t(`${introduction_locale}.action`)}
                            onClick={() => logAnalytics()}
                            width={136}
                        />
                    </Link>
                </Grid>

                {/* Heart Illustration */}
                <Grid
                    xs={12}
                    container
                    direction='row'
                    justifyContent='space-between'
                    position='absolute'
                    px={{xs: 1, md: 5, lg: 10}}
                    sx={{
                        transform: {xs: 'translate(0px, 75px)', lg: 'translate(0px, -75px)'}
                    }}
                >
                    <Grid 
                        xs={6}
                    >
                        <Stack>
                            <Box
                                component="img"
                                sx={{
                                    content: `url('/assets/icons/introduction-heart-1.png')`,
                                    width: {xs: 150, md: 207},
                                    position: 'absolute',
                                    zIndex: -2,
                                    animation: 'heartAnim3 1s linear infinite'
                                }}
                                alt="icon"
                            />

                            <Box
                                component="img"
                                sx={{
                                    content: `url('/assets/icons/introduction-heart-1.png')`,
                                    width: {xs: 90, md: 122},
                                    position: 'absolute',
                                    zIndex: -1,
                                    transform: 'translate(200px, 100px)'
                                }}
                                alt="icon"
                            />
                        </Stack>
                    </Grid>

                    <Grid 
                        xs={6} 
                        display='flex'
                        justifyContent='end'
                    >
                        <Box
                            component="img"
                            sx={{
                                content: `url('/assets/icons/introduction-heart-2.png')`,
                                width: {xs: 150, md: 207},
                                position: 'absolute',
                                zIndex: -2,
                                animation: 'heartAnim3 1s linear infinite'
                            }}
                            alt="icon"
                        />

                        <Box
                            component="img"
                            sx={{
                                content: `url('/assets/icons/introduction-heart-2.png')`,
                                width: {xs: 90, md: 122},
                                position: 'absolute',
                                zIndex: -1,
                                transform: 'translate(-200px, 100px)',
                            }}
                            alt="icon"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default HomeIntroduction;