import { 
    Grid,
    Box,
    Typography
} from "@mui/material";

import ButtonComponent from '../../../components/form/button-component';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../../../assets/icons/home-icon";

import Footer from '../../footer/footer'

const NotFoundLayout = () => {
    const { t } = useTranslation();
    const not_found_locale = "not_found";

    const navigate = useNavigate();

    return(
        <>
            <Grid
                xs
                container
                sx={{height: '100vh'}}
            >
                {/* Body */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{px: {xs: 5, md: 25, lg: 50}, textAlign: 'center'}}
                >
                    {/* Heart Illustration */}
                    <Box
                        component="img"
                        sx={{

                            content: `url('/assets/icons/authentication-5.png')`,
                            width: {xs: 375, md: 536, lg: 466},
                            position: 'absolute',
                            zIndex: -1,
                            mb: 20
                        }}
                        alt="icon"
                    />

                    {/* Title */}
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: 30, lg: 48}}
                        letterSpacing={-2} 
                    >
                        {t(`${not_found_locale}.heading`)}
                    </Typography>

                    <Typography
                        fontWeight={600}
                        fontSize={20}
                        sx={{pt: 3}}
                        color='#6D6D6D'
                    >
                        {t(`${not_found_locale}.caption`)}
                    </Typography>

                    <ButtonComponent 
                        text={t(`${not_found_locale}.action`)}
                        onClick={() => navigate('/')}
                        startIcon={<HomeIcon />}
                        sx={{
                            mt: 3,
                            mb: 0,
                            width: 175,
                        }}
                    />
                </Grid>
            </Grid>

            <Footer />
        </>
    );
}

export default NotFoundLayout;