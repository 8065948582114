import { Helmet } from "react-helmet";
import PrivacyPolicy from "../../sections/legal/privacy-policy";

const PrivacyPolicyPage = () => {
    return(
        <>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>

            <PrivacyPolicy />
        </>
    );
}

export default PrivacyPolicyPage;