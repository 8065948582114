import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useTheme } from "@emotion/react";

import ReCAPTCHA from "react-google-recaptcha";

import { 
    Grid,
    Typography,
    Card,
    CardActions,
    CardContent,
    ImageList,
    ImageListItem,
    Link
} from "@mui/material";

import { useCallback, useEffect, useState } from "react";

import { 
    SelectionComponent, 
    ButtonComponent,
    TextFieldComponent,
    LoadingButtonComponent
} from "../../components/form";

import { profileCreatorList } from "../../assets/data/profile";
import ArrowLeft from "../../assets/icons/arrow-left";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FormProviderComponent from '../../components/form/form-provider-component';
import { validateMobileNumber, validateCountryCode } from '../../utils/custom-functions';
import { useRequestOtpMutation, useVerifyUserRegisteredMutation } from '../../services/auth-api';
import SnackbarComponent from '../../components/snack-bar/snack-bar-component';

import { encrypt } from "../../contexts/utils";

import { useCountries } from 'use-react-countries'
import MobileOtpFormComponent from '../../components/auth/mobile-otp-form-component';
import { useAnalytics } from '../../contexts/analytics/analytics-provider';

const Hero = () => {
    const { t } = useTranslation();

    const hero_locale = 'landing_page.hero';
    const mobile_form_locale = 'authentication.mobile.number';
    const otp_form_locale = 'authentication.mobile.otp';

    const { countries } = useCountries();

    const theme = useTheme();
    const navigate = useNavigate();

    const [inOtpVerification, SetInOtpVerification] = useState(false);
    const [mobileNum, setMobileNum] = useState('');

    const [recaptchaValidated, setRecaptchaValidated] = useState(true);

    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [
        verifyUserAlreadyRegistered,
        {
            isLoading: isLoadingVerifyUserRegistration,
            isSuccess: isRequestVerifyUserRegistration,
            data: requestVerifyUserRegistrationData,
            isError: isErrorVerifyUserRegistration,
            error: requestVerifyUserRegistration
        }
    ] = useVerifyUserRegisteredMutation();

    const [
        requestOtpAPI,
        {
            isLoading: isLoadingRequestOtp,
            isSuccess: isRequestOtpSuccess,
            data: requestOtpData,
            isError: isErrorRequestOtp,
            error: requestOtpError
        }
    ] = useRequestOtpMutation();

    const mobileSchema = yup.object().shape({
        profile: yup.string().required(t(`${mobile_form_locale}.errors.profile_is_required`)),
        countryCode: yup.string().required(t(`${mobile_form_locale}.errors.country_code_is_required`)).test('isValidCountryCode', t(`${mobile_form_locale}.errors.invalid_country_code`), () => {
            const isValid = validateCountryCode(mobileFormValue.countryCode);
            return isValid;
        }),
        mobile: yup.string().required(t(`${mobile_form_locale}.errors.mobile_number_is_required`)).test('isValidMobileNumber', t(`${mobile_form_locale}.errors.invalid_mobile_number`), () => {
            const isValid = IsMobileNumberValid();
            return isValid;
        })
    });

    const defaultMobileValues = {
        profile: '',
        countryCode: '+94',
        mobile: ''
    };

    const mobileMethods = useForm({
        resolver: yupResolver(mobileSchema),
        defaultValues: defaultMobileValues
    });

    const {
        reset: resetMobileNumForm,
        handleSubmit: handleMobileNumSubmit,
        formState: { isSubmitting: isSubmittingMobileNum },
        watch: mobileFormWatch
    } = mobileMethods;

    const mobileFormValue = mobileFormWatch();

    const getModifiedMobileNum = useCallback(() => {
        return mobileFormValue.countryCode.concat(mobileFormValue.mobile);
    }, [mobileFormValue]);

    const onMobileSubmit = handleMobileNumSubmit(async(data) => {
        console.log(data);
        
        try{
            const mobileNumber = getModifiedMobileNum();
            console.log(mobileNumber);
            const regObj = {"created_by": data.profile, "mobile": mobileNumber}
            const encryptedData = encrypt(JSON.stringify(regObj));
            localStorage.setItem('creator', encryptedData);

            setMobileNum(mobileNumber);

            logAnalytics('REQUEST_OTP');
        }
        catch(error){
            console.log(error);
        }
    });

    const IsMobileNumberValid = () => {
        if(mobileFormValue.countryCode.trim() === ""){
            return false;
        }

        const mobileNumber = getModifiedMobileNum();

        console.log(mobileNumber);

        return validateMobileNumber(mobileNumber);
    }

    const backToMobileNumForm = useCallback(() => {
        SetInOtpVerification(false);
        setMobileNum('');
    }, []);

    const callUserRegistrationVerificationAPI = useCallback(async () => {
        const body = {
            "mobileNumber": mobileNum
        }

        await verifyUserAlreadyRegistered(body);
    }, [mobileNum]);

    const callRequestOtpAPI = useCallback(async () => {
        const body = {
            "mobileNumber": mobileNum
        }

        await requestOtpAPI(body);
    }, [mobileNum]);

    useEffect(() => {
        if(mobileNum !== ''){
            callUserRegistrationVerificationAPI();
        }
    }, [mobileNum]);

    useEffect(() => {
        if(isRequestVerifyUserRegistration && requestVerifyUserRegistrationData){
            console.log(requestVerifyUserRegistrationData);
            console.log(requestVerifyUserRegistrationData.status);
            callRequestOtpAPI();
        }
    }, [isRequestVerifyUserRegistration, requestVerifyUserRegistrationData]);

    useEffect(() => {
        if(isErrorVerifyUserRegistration && requestVerifyUserRegistration){
            console.log(requestVerifyUserRegistration);
            console.log(requestVerifyUserRegistration.status);

            //User already registered
            if(requestVerifyUserRegistration.status === 409){
                setShowSnackBar({
                    state: true,
                    message: `${requestVerifyUserRegistration.data.error?.message}`,
                    color: "success"
                });
                
                setTimeout(() => {
                    navigate("/login");
                }, 1000);
            }
            else{
                setShowSnackBar({
                    state: true,
                    message: `${requestVerifyUserRegistration.data.error?.message}`,
                    color: "success"
                });
            }
        }
    }, [isErrorVerifyUserRegistration, requestVerifyUserRegistration]);

    useEffect(() => {
        if(isRequestOtpSuccess && requestOtpData){
            console.log(requestOtpData);
            console.log(requestOtpData.status);

            const otpSessionID = encrypt(requestOtpData.data);
            localStorage.setItem('otpSessionID', otpSessionID);

            setShowSnackBar({
                state: true,
                message: t(`${mobile_form_locale}.otp_sent`),
                color: "green"
            });

            resetMobileNumForm();

            SetInOtpVerification(true);
        }
    }, [isRequestOtpSuccess, requestOtpData, resetMobileNumForm]);

    useEffect(() => {
        if(isErrorRequestOtp && requestOtpError){
            console.log(requestOtpError);
            console.log(requestOtpError.status);

            let message = "Something went wrong";
            let color = "green";

            //Mobile number already verified
            if(requestOtpError.status === 409){
                message = t(`${mobile_form_locale}.errors.mobile_number_already_verified`);

                resetMobileNumForm();
                navigate("/signup");
            }
            //Otp already delivered and still valid
            else if(requestOtpError.status === 429){
                message = t(`${mobile_form_locale}.errors.otp_still_active`);

                resetMobileNumForm();
                SetInOtpVerification(true);
            }
            else{
                message = requestOtpError.data.error?.message;
                color = "red";
            }

            setShowSnackBar({
                state: true,
                message: `${message}`,
                color: color
            });
        }
    }, [isErrorRequestOtp, requestOtpError]);

    const getDefaultTitle = () => <>
        {/* Main title */}
        <Grid 
            xs={12}
            display='flex'
            alignItems='end'
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: {xs: 40, md: 72, xl: 96},
                    letterSpacing: {xs: -2, md: -6},
                    color: 'white',
                    lineHeight: 1
                }}
            >
                {t(`${hero_locale}.heading1`)} <i>{t(`${hero_locale}.heading2`)}</i>

                <span style={{marginLeft: 15}}>
                    <img 
                        src='/assets/icons/hero-heart-2.png' 
                        alt='heart icon'
                        style={{
                            transform: 'translate(0px, -10px)',
                            animation: 'heartAnim 1s linear infinite'
                        }}
                    />

                    <img 
                        src='/assets/icons/hero-heart-1.png' 
                        alt='heart icon'
                        style={{
                            // transform: 'translate(-10px, 0px)',
                            animation: 'heartAnim2 1s linear infinite'
                        }}
                    />
                </span>
            </Typography>
        </Grid>

        {/* Sub title */}
        <Grid 
            xs={12}
            display='flex'
            alignItems='start'
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: 'white',
                    mt: 1
                }}
            >
                {t(`${hero_locale}.caption`)}
            </Typography>
        </Grid>
    </>

    const getMobileVerificationTitle = () => <>
        {/* Main title */}
        <Grid 
            xs={12}
            display='flex'
            alignItems='center'
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: {xs: 30, md: 60},
                    letterSpacing: {xs: -1, md: -4},
                    color: 'white',
                    lineHeight: 1,
                    pt: 5
                }}
            >
                {t(`${otp_form_locale}.heading`)}

                <span style={{marginRight: '40px'}}>
                    <img 
                        src='/assets/icons/hero-heart-2.png' 
                        alt='heart icon'
                        style={{
                            transform: 'translate(0px, -165px)', 
                            opacity: 0.5
                        }}
                    />

                    <img 
                        src='/assets/icons/hero-heart-1.png' 
                        alt='heart icon'
                        style={{
                            transform: 'translate(0px, -160px)', 
                            opacity: 0.5
                        }}
                    />
                </span>
            </Typography>
        </Grid>
    </>

    const recaptchaOnChange = useCallback((value) => {
        console.log("Captcha value:", value);
    }, []);

    const getCountryCodeList = () => {
        const list = [];

        countries.map(({emoji, countryCallingCode}, index) => {
            list.push({id: index, label: `${emoji} ${countryCallingCode}`, value: countryCallingCode});
        });

        return list;
    }

    const mobileNumForm = () => <div style={{display: inOtpVerification ? 'none': 'block'}}>
        <FormProviderComponent methods={mobileMethods} onSubmit={onMobileSubmit}>
            <CardContent 
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '16px 16px 0px 0px'
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 34,
                        letterSpacing: -2,
                        textAlign: 'center',
                        color: 'white'
                    }}
                >
                    {t(`${mobile_form_locale}.main_heading`)}
                </Typography>
            </CardContent>

            <CardContent>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 28,
                        letterSpacing: -2,
                        color: theme.palette.secondary.main,
                        mb: 4
                    }}
                >
                    {t(`${mobile_form_locale}.heading`)}
                </Typography>

                <SelectionComponent 
                    label={t(`${mobile_form_locale}.profile_label`)}
                    name='profile'
                    list={profileCreatorList} 
                />

                <Grid xs={12} container direction='row'> 
                    <Grid xs={12} md={3} sx={{pr: {xs: 0, md: 3}}}>
                        <SelectionComponent 
                            label={t(`${mobile_form_locale}.country_code_label`)}
                            name='countryCode'
                            list={getCountryCodeList()}
                        />
                    </Grid>

                    <Grid xs={12} md={9}>
                        <TextFieldComponent 
                            label={t(`${mobile_form_locale}.mobile_number_label`)}
                            name='mobile'
                        />
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions>
                <LoadingButtonComponent 
                    text={t(`${mobile_form_locale}.action`)}
                    inLoading={isLoadingVerifyUserRegistration || isSubmittingMobileNum}
                    type='submit'
                    disabled={!recaptchaValidated}
                />
            </CardActions>

            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: 'gray',
                    textAlign: 'center',
                    m: 2
                }}
            >
                {t(`${mobile_form_locale}.caption1`)} <Link href="/terms-and-conditions" target="_blank" sx={{fontWeight: 600}}>{t(`${mobile_form_locale}.caption2`)}</Link> {t(`${mobile_form_locale}.caption3`)} <Link href="/privacy-policy" target="_blank" sx={{fontWeight: 600}}>{t(`${mobile_form_locale}.caption4`)}</Link> {t(`${mobile_form_locale}.caption5`)}
            </Typography>

            {/* <Stack mt={2} direction='row' display='flex' justifyContent='center'>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_ID}
                    onChange={recaptchaOnChange}
                />
            </Stack> */}
        </FormProviderComponent>
    </div>

    return (
        <>
            <Grid 
                container 
                xs 
                direction='column'
                id='hero'
                sx={{
                    backgroundColor: theme.palette.primary.main
                }}
            >
                {/* Back to mobile number form */}
                {
                    inOtpVerification
                    ?
                    <Grid 
                        xs={12} 
                        sx={{
                            pt: 3,
                            px: {xs: 2, lg: 7}
                        }}
                    >
                        <ButtonComponent 
                            text={t(`${otp_form_locale}.form.back`)}
                            onClick={backToMobileNumForm}
                            width={124}
                            startIcon={<ArrowLeft style={{width: '25px', color: 'black'}}/>}
                            sx={{
                                color: 'black',
                                backgroundColor: 'white',
                                "&:hover": {
                                    backgroundColor: "#FFFFFF",
                                    transform: 'scale(1.03)'
                                }
                            }}
                        />
                    </Grid>
                    :
                    <></>
                }

                {/* Title and Form */}
                <Grid 
                    xs={12} 
                    container
                    direction='row'
                    sx={{
                        pt: inOtpVerification ? 0 : 2,
                        pb: 5,
                        px: {xs: 2, lg: 7}
                    }}
                >
                    {/* Title */}
                    <Grid 
                        container 
                        xs={12} 
                        lg={6} 
                        pr={10}
                    >
                        {
                            !inOtpVerification
                            ?
                            getDefaultTitle()
                            :
                            getMobileVerificationTitle()
                        }
                    </Grid>

                    {/* Form */}
                    <Grid
                        xs={12} 
                        lg={6}
                        sx={{zIndex: 2}}
                    >
                        <Card 
                            sx={{ 
                                borderRadius: '16px',
                                outline: '4px solid rgba(255, 255, 255, .5)',
                                p: 2,
                                mt: {xs: 5, lg: 0}
                            }}
                        >
                            {mobileNumForm()}

                            <div style={{display: !inOtpVerification ? 'none': 'block'}}>
                                <MobileOtpFormComponent mobileNum={mobileNum}/>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                
                {/* Images */}
                <Grid 
                    xs={12}
                    display='flex'
                    justifyContent='center'
                    sx={{
                        mt: -8,
                        zIndex: 1
                    }}
                >
                    <ImageList 
                        sx={{ 
                            width: 1885, 
                            height: 315, 
                            py: 0, 
                            my: 0,
                            overflow: 'hidden'
                        }} 
                        cols={5} 
                        rowHeight={255}
                    >
                        {[1, 2, 3, 4, 5].map((item) => (
                            <ImageListItem sx={{transform: 'translateX(-50%)'}} key={item}>
                                <img
                                    src={`/assets/images/hero/hero-image-${item}.png`}
                                    alt={`hero image ${item}`}
                                    loading="lazy"
                                    style={{
                                        width: 377,
                                        marginTop: item % 2 !== 0 ? 100 : 0,
                                        borderRadius: 16
                                    }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
            </Grid>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default Hero;

