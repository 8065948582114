import { 
    Grid,
    Box,
    Typography
} from "@mui/material";

import ButtonComponent from '../../../components/form/button-component';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Footer from '../../footer/footer';

const SignupConfirmation = () => {
    const { t } = useTranslation();
    const congratulations_locale = "authentication.congratulation";

    const navigate = useNavigate();

    return(
        <>
            <Grid
                xs
                container
                sx={{height: '100vh'}}
            >
                {/* Body */}
                <Grid
                    xs={12}
                    display='flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{px: {xs: 5, md: 25, lg: 50}, textAlign: 'center'}}
                >

                    {/* Bird Illustration */}
                    <Box
                        component="img"
                        sx={{

                            content: `url('/assets/icons/authentication-4.png')`,
                            width: {xs: 225, md: 291, lg: 402},
                            position: 'absolute',
                            right: 50,
                            top: 50,
                            zIndex: -1,
                        }}
                        alt="icon"
                    />

                    {/* Heart Illustration */}
                    <Box
                        component="img"
                        sx={{

                            content: `url('/assets/icons/authentication-5.png')`,
                            width: {xs: 375, md: 536, lg: 691},
                            position: 'absolute',
                            zIndex: -1,
                        }}
                        alt="icon"
                    />

                    {/* Title */}
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: 30, lg: 48}}
                        letterSpacing={-2} 
                    >
                        {t(`${congratulations_locale}.heading`)}
                    </Typography>

                    <Typography
                        fontWeight={600}
                        fontSize={20}
                        sx={{pt: 3}}
                        color='#6D6D6D'
                    >
                        {t(`${congratulations_locale}.caption`)}
                    </Typography>

                    <ButtonComponent 
                        text={t(`${congratulations_locale}.action`)}
                        onClick={() => navigate('/login')}
                        sx={{
                            mt: {xs: 0, lg: 3},
                            position: {xs: 'absolute', md: 'relative'},
                            bottom: 0,
                            mb: {xs: 2, lg: 0},
                            width: {xs: '75%', lg: 175},
                        }}
                    />
                </Grid>
            </Grid>

            <Footer />
        </>
    );
}

export default SignupConfirmation;