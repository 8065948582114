import { Helmet } from "react-helmet";
import PartnerBasicPreferenceLayout from "../../sections/partner-preference/basic/partner-basic-preference-layout";

const PartnerBasicPreferencePage = () => {
    return(
        <>
            <Helmet>
                <title>Partner Basic Preference</title>
            </Helmet>

            <PartnerBasicPreferenceLayout />
        </>
    );
}

export default PartnerBasicPreferencePage;