import PropTypes from 'prop-types';
//
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
//
import {
    Grid,
    Chip,
    Typography,
    Checkbox,
    Button,
    Divider
} from '@mui/material';
import { useCallback } from 'react';

import { useTheme } from '@emotion/react';

import CloseIcon from '../../assets/icons/close-icon';
import { useTranslation } from 'react-i18next';
//============================================

const HoroscopePlanetForm = ({gridInfo, planetList, unavailablePlanets, onSelect}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const signup_locale = "authentication.sign_up.step3.form";

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const [selectedPlanets, setSelectedPlanets] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        if(gridInfo){
            setSelectedPlanets(gridInfo.data);
            console.log(unavailablePlanets);
        }
    }, [gridInfo]);

    const getSelectedStatus = useCallback((item) => {
        return selectedPlanets.includes(item);
    }, [selectedPlanets])

    const handleDelete = useCallback((item) => {
        let array = [...selectedPlanets];
        array = array.filter(value => value !== item);

        setSelectedPlanets(array);
    }, [selectedPlanets]);

    const handleSelect = useCallback((item) => {
        if(selectedPlanets.includes(item)){
            handleDelete(item);
        }
        else if(selectedPlanets.length === 6){
            setError(true);

            setTimeout(() => {
                setError(false);
            }, 3000);
        }
        else{
            setSelectedPlanets([...selectedPlanets, item]);
        }
    }, [selectedPlanets]);

    const selectionDisabled = useCallback((item) => {
        if(selectedPlanets?.includes(item)){
            return false;
        }

        if(gridInfo?.data.includes(item)){
            return false;
        }
        
        return unavailablePlanets?.includes(item);
    }, [selectedPlanets, unavailablePlanets]);

    return(
        <>
            <Grid
                xs
                container
                direction='column'
                sx={{mt: 1}}
            >
                <Grid
                    xs={12}
                    direction='row'
                >
                    {
                        selectedPlanets?.map((item, index) => {
                            return <Chip 
                                key={index} 
                                label={item.label}
                                deleteIcon={<CloseIcon style={{width: 15, color: 'white'}}/>}
                                onDelete={() => handleDelete(item)} 
                                sx={{
                                    borderRadius: '50px', 
                                    m: 0.25,
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'white'
                                }}
                            />
                        })
                    }
                </Grid>

                {
                    error
                    ?
                    <Grid
                        xs={12}
                        direction='row'
                    >
                        <Typography
                            color='error'
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: 12,
                                fontWeight: 500,
                                p: 1
                            }}
                        >
                            {t(`${signup_locale}.restriction`)}
                        </Typography>
                    </Grid>
                    :
                    <></>
                }
                
                <Grid
                    xs={12}
                    direction='column'
                    sx={{mt: 2}}
                >
                    {
                        planetList?.map((item, index) => {
                            return <Grid
                                key={index}
                                xs={12}
                                direction='row'
                                container
                                sx={{
                                    backgroundColor: getSelectedStatus(item) ? theme.palette.primary.main : '#F6F6F6',
                                    borderRadius: '10px',
                                    px: 1,
                                    my: 1
                                }}
                            >
                                <Grid
                                    xs={10}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Inter',
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: getSelectedStatus(item) ? 'white' : '#121212',
                                            p: 1
                                        }}
                                    >
                                        {
                                            selectionDisabled(item)
                                            ?
                                            <s>{item.label}</s>
                                            :
                                            item.label
                                        }
                                    </Typography>
                                </Grid>
        
                                <Grid
                                    xs={2}
                                    display='flex'
                                    justifyContent='flex-end'
                                >
                                    <Checkbox 
                                        {...label} 
                                        onChange={() => handleSelect(item)}
                                        checked={getSelectedStatus(item)}
                                        disabled={selectionDisabled(item)}
                                        sx={{
                                            color: getSelectedStatus() ? 'white' : 'black',
                                            '&.Mui-checked': {
                                                color: '#F6F6F6',
                                            },
                                        }} 
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                </Grid>

                <Grid
                    xs={12}
                    display='flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Divider sx={{mt: 5, mb: 2}}/>

                    <Button 
                        variant="contained" 
                        fullWidth 
                        onClick={() => {onSelect(gridInfo, selectedPlanets)}}
                        sx={{backgroundColor: theme.palette.secondary.main}}
                    >
                        {t(`${signup_locale}.apply_selected_options`)}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default HoroscopePlanetForm;

HoroscopePlanetForm.propTypes = {
    gridInfo: PropTypes.object,
    planetList: PropTypes.array,
    unavailablePlanets: PropTypes.array,
    onSelect: PropTypes.func
}