import {
    Box,
    Typography
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const AboutUsBody = () => {
    const { t } = useTranslation();
    const about_us_locale = "about_us";

    const capitalizeFirstLetter = useCallback((value) => {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }, []);

    const getHeading = useCallback((heading) => {
        return <Typography
            sx={{
                fontWeight: 600,
                fontSize: {xs: 16, md: 24},
                py: 3
            }}
        >
            {capitalizeFirstLetter(heading)}
        </Typography>
    }, []);

    const getContent = useCallback((content) => {
        return <Typography
            sx={{
                fontWeight: 400,
                fontSize: 16,
                textAlign: 'justify',
                color: '#6D6D6D',
                lineHeight: '24px',
                pb: 2
            }}
        >
            {content}
        </Typography>
    }, []);

    const getBulletPoints = useCallback((list) => {
        const items = [];

        list.split('\n').map((body, bodyIndex) => {
            items.push(
                <Typography
                    key={bodyIndex}
                    sx={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: '#6D6D6D',
                        pb: 2,
                        pl: 4
                    }}
                >
                    {body}
                </Typography>
            )
        })

        return items;
    }, []);

    return (
        <>
            <Box sx={{p: {xs: 4, md: 10}}}>
                {getContent(t(`${about_us_locale}.body1`))}

                {getHeading(t(`${about_us_locale}.body2.heading`))}

                {getContent(t(`${about_us_locale}.body2.content`))}

                {getHeading(t(`${about_us_locale}.body3.heading`))}

                {getBulletPoints(t(`${about_us_locale}.body3.content`))}

                {getHeading(t(`${about_us_locale}.body4.heading`))}

                {getContent(t(`${about_us_locale}.body4.content1`))}

                {getBulletPoints(t(`${about_us_locale}.body4.content2`))}

                {getHeading(t(`${about_us_locale}.body5.heading`))}

                {getContent(t(`${about_us_locale}.body5.content`))}

                {getHeading(t(`${about_us_locale}.body6.heading`))}

                {getContent(t(`${about_us_locale}.body6.content`))}

                {getHeading(t(`${about_us_locale}.body7.heading`))}
            </Box>
        </>
    );
}

export default AboutUsBody;