import React from 'react';

export default function FlagIcon(props) {
	return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            {...props}
        >
            <path fill="currentColor" d="M5 21V4h9l.4 2H20v10h-7l-.4-2H7v7z"></path>
        </svg>
    );
}