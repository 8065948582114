import { Helmet } from "react-helmet";
import BlockedProfileLayout from "../../sections/profile-settings/blocked/blocked-profile-layout";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const BlockedProfilesPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_BLOCKED_PROFILES_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Blocked Profiles</title>
            </Helmet>

            <BlockedProfileLayout />
        </>
    );
}

export default BlockedProfilesPage;