import { useCallback, useState } from "react";

import SignupConfirmation from './signup-confirmation';
import SignupForm from "./signup-form";
import VerifyEmailLayout from '../../profile-verification/verify-email/verify-email-layout';
import { useNavigate } from "react-router-dom";

const SignupLayout = () => {
    const navigate = useNavigate();

    const [bodyIndex, setBodyIndex] = useState(0);
    const [email, setEmail] = useState('');

    const updateBody = useCallback(() => {
        setBodyIndex((id) => id + 1);
    }, []);

    let body;

    if(bodyIndex === 0){
        body = <SignupForm signupSuccessCallback={updateBody} setEmail={setEmail}/>
    }
    else if(bodyIndex === 1){
        body = <VerifyEmailLayout verifiedCallback={updateBody} skipCallback={updateBody} userEmail={email}/>
    }
    else{
        body = <SignupConfirmation />
    }

    return(
        <>
            {
                body
            }
        </>
    );
}

export default SignupLayout;