import PropTypes from 'prop-types';

import * as React from 'react';

import {
    Checkbox,
    FormControlLabel,
    useTheme,
    Box,
    Typography,
    FormHelperText,
    Stack
} from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { useEffect } from 'react';

const CheckBoxComponent = ({
    list,
    label,
    required=false,
    disabledValue=[],
    selectedValue=[],
    onChange,
    error,
    sx
}) => {
    return (
        <>
            <Stack>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: {xs: 20, md: 24},
                        color: '#6D6D6D',
                        pb: 3
                    }}
                >
                    {required ? `${label} *` : label}
                </Typography>

                <Stack>
                    {
                        list?.map((item, index) => {
                            return <CheckBoxController 
                                key={index}
                                label={item.label}
                                name={item.name}
                                disabledValue={disabledValue}
                                selectedValue={selectedValue}
                                onChange={onChange}
                                sx={sx}
                                value={item.value}
                            />
                        })
                    }
                </Stack>

                {
                    error
                    ?
                    <FormHelperText sx={{color: 'rgba(255, 0, 0, 1)'}}>{error}</FormHelperText>
                    :
                    <></>
                }
            </Stack>
        </>
    );
}

export default CheckBoxComponent;

const CheckBoxController = ({
    label, 
    name, 
    disabledValue=[],
    selectedValue=[],
    value,
    onChange,
    sx
}) => {
    const theme = useTheme();

    const { control } = useFormContext();

    const [state, setState] = useState(false);

    useEffect(() => {
        if(selectedValue.includes(value)){
            setState(true);
            onChange(name, true);
        }
    }, [value, selectedValue, name]);

    return <>
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Box sx={{...sx}}>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                onChange={(event) => {
                                    const checked = state;
                                    
                                    field.onChange(!checked);
                                    setState(!checked);
                                    
                                    if(onChange) onChange(name, !checked);
                                }}
                                disabled={disabledValue.includes(value)}
                                defaultChecked={selectedValue.includes(value)}
                                value={state}
                                sx={{
                                    color: theme.palette.primary.main,
                                    "&.Mui-checked": {
                                        color: theme.palette.primary.main
                                    },
                                    ...sx
                                }}
                            />
                        }
                        label={
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: {xs: 16, md: 18},
                                    color: '#6D6D6D'
                                }}
                            >
                                {label}
                            </Typography>
                        }
                    />
                </Box>
            )}
        />
    </>
}

CheckBoxComponent.propTypes = {
    list: PropTypes.array,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabledValue: PropTypes.array,
    selectedValue: PropTypes.array,
    sx: PropTypes.object,
    onChange: PropTypes.func
}