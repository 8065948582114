import PropTypes from 'prop-types';

import { 
    Grid,
    Typography,
    useTheme,
    Box
} from "@mui/material";

import { ButtonComponent } from "../../components/form";
import ArrowLeft from "../../assets/icons/arrow-left";
import { useNavigate } from "react-router-dom";
import Footer from '../footer/footer';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

const LegalLayout = ({heading, content}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const navigate = useNavigate();

    /***
     * Guidelines:
     * Use "|"" to divide headings
     * Use "\n" to break the lines in paragraph
     * Use "*" for bullet points
    ***/

    const capitalizeFirstLetter = useCallback((value) => {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }, []);

    return (
        <>
            <Grid
                xs={12}
                container
                display='flex'
                justifyContent='start'
                alignItems='center'
                sx={{
                    py: {xs: 1, md: 3},
                    backgroundColor: theme.palette.primary.main
                }}
            >
                <ButtonComponent 
                    text={heading}
                    onClick={() => navigate(-1)}
                    width={124}
                    startIcon={<ArrowLeft style={{width: '30px', color: 'white'}}/>}
                    variant='text'
                    sx={{
                        color: 'white',
                        textTransform: 'capitalize',
                        ml: 3
                    }}
                    textSx={{
                        fontWeight: 600,
                        fontSize: {xs: 16, md: 20}
                    }}
                />
            </Grid>

            <Grid
                xs={12}
                container
                display='flex'
                direction='column'
                sx={{px: 3, pb: 5}}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: {xs: 24, md: 36},
                        letterSpacing: -2,
                        py: 3
                    }}
                >
                    {heading}
                </Typography>

                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: {xs: 16, md: 24},
                        py: 3
                    }}
                >
                    {t('legal.published')}
                </Typography>

                {
                    content.split('|').map((item, index) => {
                        return <>
                            {
                                index %2 === 0
                                ?
                                // Heading
                                <Typography
                                    key={index}
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: {xs: 16, md: 24},
                                        py: 3
                                    }}
                                >
                                    {capitalizeFirstLetter(item)}
                                </Typography>
                                :
                                // body
                                item.split('\n').map((body, bodyIndex) => {
                                    return <Typography
                                        key={bodyIndex}
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: '#6D6D6D',
                                            pb: 2
                                        }}
                                    >
                                        {
                                            body.substring(0, 1) === "*"
                                            ?
                                            <ul style={{marginTop: 0, marginBottom: 0}}>
                                                <li>{body.substring(1, body.length)}</li>
                                            </ul>
                                            :
                                            body
                                        }
                                    </Typography>
                                })
                            }
                        </>
                    })
                }
            </Grid>

            <Footer />
        </>
    );
}

export default LegalLayout;

LegalLayout.propType = {
    heading: PropTypes.string,
    content: PropTypes.string
}