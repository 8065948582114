import { Helmet } from "react-helmet";
import TermsAndConditions from "../../sections/legal/terms-and-conditions";

const TermsAndConditionsPage = () => {
    return(
        <>
            <Helmet>
                <title>Terms and Conditions</title>
            </Helmet>

            <TermsAndConditions />
        </>
    );
}

export default TermsAndConditionsPage;