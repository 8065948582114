import { Helmet } from "react-helmet";
import SettingsLayout from "../../sections/profile-settings/settings/settings-layout";

const SettingsPage = () => {
    return(
        <>
            <Helmet>
                <title>Settings</title>
            </Helmet>

            <SettingsLayout />
        </>
    );
}

export default SettingsPage;