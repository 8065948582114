import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { 
    Chip,
    Autocomplete,
    TextField,
    useTheme
} from '@mui/material';
import { useCallback } from 'react';

const MultipleSelectionComponent = ({
    name, 
    label, 
    list,
    placeholder='',
    required=false,
    fullWidth=true,
    onlyAllowedValues=[],
    onChange,
    sx,
    ...other
}) => {
    const theme = useTheme();

    const { control, setValue } = useFormContext();

    const getDefaultValues = useCallback((values) => {
        const array = [];

        for(let i = 0; i < values.length; i++){
            for(let j = 0; j < list.length; j++){
                if(values[i].value === list[j].value){
                    array.push(list[j]);
                }
            }
        }

        return array;
    }, [list]);

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    list.length > 0
                    ?
                    <Autocomplete
                        multiple
                        options={list}
                        getOptionLabel={(option) => option.label}
                        defaultValue={getDefaultValues(field.value)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });

                                return (
                                    <Chip 
                                        label={option.label} 
                                        key={key} 
                                        {...tagProps} 
                                    />
                                );
                            }
                        )}
                        onChange={(event, value) => {
                            setValue(name, value, { shouldValidate: true })

                            if(onChange) onChange(name, value);
                        }}
                        getOptionDisabled={(option) =>
                            onlyAllowedValues.length === 0 ? false : !onlyAllowedValues.includes(option.value)
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={required ? `${label} *` : label}
                                placeholder={placeholder !== '' ? `+ ${placeholder}` : ''}
                                fullWidth={fullWidth}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: error ? 'rgba(255, 0, 0, 1)' : 'rgba(145, 158, 171, 0.4)',
                                            borderWidth: "1px",
                                            borderRadius: '8px'
                                        },
                                        "&.Mui-focused": {
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: theme.palette.primary.main,
                                                borderWidth: "2px",
                                            },
                                        },
                                    },
                                    ...sx
                                }}
                                InputLabelProps={{
                                    style: { color: 'rgba(145, 158, 171, 1)' },
                                }}
                                error={!!error}
                                helperText={
                                    error
                                    ?
                                    error.message
                                    :
                                    <></>
                                }
                                {...other}
                            />
                        )}
                    />
                    :
                    <></>
                )}
            />
        </>
    );
}

export default MultipleSelectionComponent;

MultipleSelectionComponent.propType = {
    name: PropTypes.string, 
    label: PropTypes.string,
    list: PropTypes.array,  
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onlyAllowedValues: PropTypes.array,
    sx: PropTypes.object,
    onChange: PropTypes.func
}