import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import ProfileEditHeader from "../profile-edit-header";
import ProfileValueText from "../profile-value-text";
import { Box } from '@mui/material';
import SkeletonComponent from '../../../components/loading/skeleton-component';
import DialogBox from '../../../components/Dialog/dialog-box';
import { TextFieldComponent } from '../../../components/form';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormProviderComponent from '../../../components/form/form-provider-component';
import { useUpdateProfileMutation } from '../../../services/profile-api';
import SnackBarComponent from '../../../components/snack-bar/snack-bar-component';

const About = ({
    heading, 
    value, 
    updateData, 
    privateView, 
    mappingValue,
    validation,
    errorMessage,
    isLoading=false,
    editCallback
}) => {
    const { t } = useTranslation();

    const [errorMsg, setErrorMsg] = useState('');

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const formSchema = yup.object().shape({
        about: yup.string()
    });

    const defaultValues = {
        about: ''
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    useEffect(() => {
        if(value){
            setFormValue("about", value);
        }
    }, [value]);

    const formValue = watch();

    const [openEditBox, setOpenEditBox] = useState({
        title: '',
        children: <></>,
        open: false
    });

    const modifyRequestObj = useCallback((value) => {
        const obj = {};
        
        for (var key in updateData) {
            if (updateData.hasOwnProperty(key)) {
                if(key === mappingValue){
                    obj[key] = value;
                }
                else{
                    obj[key] = updateData[key];
                }
            }
        }

        return obj;
    }, [updateData, mappingValue]);

    const [
        updateProfile, 
        {
          isLoading: isLoadingUpdateProfile,
          isSuccess: isSuccessUpdateProfile,
          isError: isErrorUpdateProfile,
          error: errorUpdateProfile
        }
    ] = useUpdateProfileMutation();

    useEffect(() => {
        if (isSuccessUpdateProfile){
            setOpenEditBox({
                open: false
            });

            editCallback();
        }
        else if (isErrorUpdateProfile && errorUpdateProfile) {
            setShowSnackBar({
                state: true,
                message: `${errorUpdateProfile.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessUpdateProfile, isErrorUpdateProfile, errorUpdateProfile, editCallback]);

    const onSubmit = useCallback(async () => {
        if(validation && !validation(formValue.about)){
            setErrorMsg(errorMessage);
            return;
        };

        setErrorMsg('');

        const obj = modifyRequestObj(formValue.about);
        console.log(obj);

        await updateProfile(obj);
    }, [formValue, errorMessage, validation]);

    const closeSingleEditItemModal = useCallback(() => {
        setOpenEditBox({
            open: false
        });

        setFormValue("about", value);

        setErrorMsg('');
    }, [value]);

    const openParaEditItemModal = useCallback(() => {
        setOpenEditBox({
            open: true
        });
    }, []);

    return (
        <>
            <ProfileEditHeader 
                heading={heading} 
                privateView={privateView} 
                openEditAllCallback={() => openParaEditItemModal()}
            />

            <Box sx={{width: {xs: '100%', md: '85%'}}}>
                {
                    isLoading
                    ?
                    <SkeletonComponent variant='rounded' width='100%' height='100px'/>
                    :
                    <ProfileValueText value={value} label={heading} callback={() => openParaEditItemModal(heading)} privateView={privateView}/>
                }
            </Box>

            <DialogBox 
                title={heading}
                children={
                    <FormProviderComponent methods={formMethods} onSubmit={() => {}}>
                        <TextFieldComponent 
                            name='about'
                            label={heading}
                            multiline
                            rows={4}
                        />

                        <p style={{color: 'red', fontSize: '12px'}}>{errorMsg}</p>
                    </FormProviderComponent>
                }
                open={openEditBox.open}
                onClose={closeSingleEditItemModal}
                onSuccess={onSubmit}
                approveText={t(`profile.save`)}
                submitInLoading={isLoadingUpdateProfile}
            />

            <SnackBarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default About;

About.propType = {
    heading: PropTypes.string,
    value: PropTypes.string,
    updateData: PropTypes.object,
    privateView: PropTypes.bool,
    mappingValue: PropTypes.string,
    validation: PropTypes.func,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func
}