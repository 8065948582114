import PropTypes from 'prop-types';

import { 
    Box,
    Grid,
    Link,
    Stack,
    Typography,
    useTheme,
    Chip,
    IconButton,
    Tabs,
    Tab,
    Menu,
    MenuItem
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import ArrowLeft from "../../assets/icons/arrow-left";
import InformationOutlineIcon from "../../assets/icons/information-outline-icon";
import CalendarMonthOutlineIcon from '../../assets/icons/calender-month-outline-icon';
import SchoolOutlineIcon from '../../assets/icons/school-outline-icon';
import BriefcaseOutlineIcon from '../../assets/icons/briefcase-outline-icon';
import MapMarkerOutlineIcon from '../../assets/icons/map-marker-outline-icon';
import DotsVerticalIcon from '../../assets/icons/dots-vertical-icon';
import EyeIcon from '../../assets/icons/eye-icon';
import EditIcon from '../../assets/icons/edit-icon';

import { useTranslation } from "react-i18next";
import ButtonComponent from "../../components/form/button-component";
import { useCallback, useEffect, useState } from "react";
import ChevronLeftIcon from '../../assets/icons/chevron-left-icon';
import ChevronRightIcon from '../../assets/icons/chevron-right-icon';
import ImageIcon from '../../assets/icons/image-icon';
import CheckIcon from '../../assets/icons/check-icon';
import CancelIcon from '../../assets/icons/cancel-icon';
import DialogBox from '../../components/Dialog/dialog-box';
import AccountPlusOutlineIcon from '../../assets/icons/account-plus-outline-icon';
import AccountMinusOutlineIcon from '../../assets/icons/account-minus-outline-icon';
import CheckDecagramIcon from '../../assets/icons/check-decagram-icon';
import SkeletonComponent from '../../components/loading/skeleton-component';
import { useGetOnCallIDVerificationStatusMutation } from '../../services/verification-api';
import { getProfileFilledPercentage } from '../../utils/custom-functions';
import SnackbarComponent from '../../components/snack-bar/snack-bar-component';
import { useBlockMutation, useUnblockMutation } from '../../services/block-api';
import { useRemoveFromShortListMutation, useShortListProfileMutation } from '../../services/shortlisted-api';
import { LoadingButtonComponent } from '../../components/form';
import { useAcceptInterestMutation, useSendInterestMutation } from '../../services/interest-api';
import ChatOutlineIcon from '../../assets/icons/chat-outline-icon';
import { useChat } from '../../contexts/chat/chat-provider';
import { useGetUserInboxDetailsMutation } from '../../services/chat-api';
import ChatPersonal from '../chat/chat-personal';
import { useAnalytics } from '../../contexts/analytics/analytics-provider';

const ProfileHeader = ({
    tabValue, 
    setTabValue, 
    privateView, 
    changePrivateView, 
    myAccount, 
    responseData,
    isLoading,
    editCallback
}) => {
    const [userDetails, setUserDetails] = useState({});

    const { t } = useTranslation();
    const verification_locale = "profile_verification.verification";
    const profile_locale = "profile";
    const blocked_locale = "profile_settings.blocked_profile";

    const theme = useTheme();
    const navigate = useNavigate();
    const useChatContext = useChat();

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const {logCustomEvent, logCustomEventWithParams} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [
        checkIDVerification,
        {
            isLoading: isLoadingOnCallIDVerification, 
            isSuccess: isSuccessOnCallIDVerification, 
            data: dataOnCallIDVerification, 
            isError: isErrorOnCallIDVerification, 
            error: errorOnCallIDVerification
        }
    ] = useGetOnCallIDVerificationStatusMutation();

    const convertDob = useCallback((dob) => {
        const inputDate = new Date(dob);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }, []);

    const getAge = useCallback((dob) => {
        const today = new Date();
        const birthDate = new Date(convertDob(dob));
        const m = today.getMonth() - birthDate.getMonth();

        var age = today.getFullYear() - birthDate.getFullYear();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }, []);

    const modifyLastOnline = useCallback((lastOnline) => {
        if(!lastOnline){
            return null;
        }

        const diff = new Date() - new Date(lastOnline);
        
        const seconds = diff * 0.001;

        if(seconds < 60){
            return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.minute`)}`;
        }
        else{
            const minutes = seconds / 60;

            if(minutes < 60){
                if(minutes <= 5){
                    return `${t(`${profile_locale}.online.5`)} ${t(`${profile_locale}.online.minutes`)}`;
                }
                else if(minutes <= 10){
                    return `${t(`${profile_locale}.online.10`)} ${t(`${profile_locale}.online.minutes`)}`;
                }
                else if(minutes <= 15){
                    return `${t(`${profile_locale}.online.15`)} ${t(`${profile_locale}.online.minutes`)}`;
                }
                else if(minutes <= 30){
                    return `${t(`${profile_locale}.online.30`)} ${t(`${profile_locale}.online.minutes`)}`;
                }
                else if(minutes <= 45){
                    return `${t(`${profile_locale}.online.45`)} ${t(`${profile_locale}.online.minutes`)}`;
                }

                return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.hour`)}`;
            }
            else{
                const hrs = minutes / 60;

                if(hrs < 24){
                    if(hrs <= 2){
                        return `${t(`${profile_locale}.online.2`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 3){
                        return `${t(`${profile_locale}.online.3`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 4){
                        return `${t(`${profile_locale}.online.4`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 5){
                        return `${t(`${profile_locale}.online.5`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 6){
                        return `${t(`${profile_locale}.online.6`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 7){
                        return `${t(`${profile_locale}.online.7`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 8){
                        return `${t(`${profile_locale}.online.8`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 9){
                        return `${t(`${profile_locale}.online.9`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 10){
                        return `${t(`${profile_locale}.online.10`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 11){
                        return `${t(`${profile_locale}.online.11`)} ${t(`${profile_locale}.online.hours`)}`;
                    }
                    else if(hrs <= 12){
                        return `${t(`${profile_locale}.online.12`)} ${t(`${profile_locale}.online.hours`)}`;
                    }

                    return `${t(`${profile_locale}.online.few`)} ${t(`${profile_locale}.online.hours`)}`;
                }
                else{
                    const days = hrs / 24;

                    if(days < 7){
                        if(days <= 1){
                            return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.day`)}`;
                        }
                        else if(days <= 2){
                            return `${t(`${profile_locale}.online.2`)} ${t(`${profile_locale}.online.days`)}`;
                        }
                        else if(days <= 3){
                            return `${t(`${profile_locale}.online.3`)} ${t(`${profile_locale}.online.days`)}`;
                        }
                        else if(days <= 4){
                            return `${t(`${profile_locale}.online.4`)} ${t(`${profile_locale}.online.days`)}`;
                        }
                        else if(days <= 5){
                            return `${t(`${profile_locale}.online.5`)} ${t(`${profile_locale}.online.days`)}`;
                        }
                        else if(days <= 6){
                            return `${t(`${profile_locale}.online.6`)} ${t(`${profile_locale}.online.days`)}`;
                        }
    
                        return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.week`)}`;
                    }
                    else{
                        const weeks = days / 7;

                        if(weeks < 4){
                            if(weeks <= 2){
                                return `${t(`${profile_locale}.online.2`)} ${t(`${profile_locale}.online.weeks`)}`;
                            }
                            else if(weeks <= 3){
                                return `${t(`${profile_locale}.online.3`)} ${t(`${profile_locale}.online.weeks`)}`;
                            }

                            return `${t(`${profile_locale}.online.1`)} ${t(`${profile_locale}.online.month`)}`;
                        }
                        else{
                            const months = weeks / 4;

                            if(months <= 2){
                                return `${t(`${profile_locale}.online.2`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 3){
                                return `${t(`${profile_locale}.online.3`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 4){
                                return `${t(`${profile_locale}.online.4`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 5){
                                return `${t(`${profile_locale}.online.5`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 6){
                                return `${t(`${profile_locale}.online.6`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 7){
                                return `${t(`${profile_locale}.online.7`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 8){
                                return `${t(`${profile_locale}.online.8`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 9){
                                return `${t(`${profile_locale}.online.9`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 10){
                                return `${t(`${profile_locale}.online.10`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 11){
                                return `${t(`${profile_locale}.online.11`)} ${t(`${profile_locale}.online.months`)}`;
                            }
                            else if(months <= 12){
                                return `${t(`${profile_locale}.online.12`)} ${t(`${profile_locale}.online.months`)}`;
                            }

                            return `${t(`${profile_locale}.online.few`)} ${t(`${profile_locale}.online.years`)}`;
                        }
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if(responseData){
            setUserDetails(
                {
                    userId: responseData.userId,
                    name: responseData.firstName,
                    image: responseData.profileImages,
                    member_id: responseData.memberId,
                    age: getAge(responseData.dateOfBirth),
                    education_level: responseData.educationLevel,
                    occupation: responseData.occupation,
                    location: responseData.countryOfLiving,
                    email_verified: responseData.emailVerified,
                    id_verified: responseData.nicverified,
                    profile_verification_percentage: getProfileFilledPercentage(responseData),
                    last_online: myAccount ? "" : modifyLastOnline(responseData.lastOnline),
                    blocked: responseData.blocked,
                    interestSent: responseData.interestSent,
                    interestReceived: responseData.interestReceived,
                    connected: responseData.connected,
                    shortListed: responseData.shortListed,
                    hide_profile: false
                }
            );
        }
    }, [responseData]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [imageIndex, setImageIndex] = useState(0);

    const changeImage = useCallback((increment) => {
        let newIndex = imageIndex;

        newIndex += increment;
        newIndex = newIndex > userDetails?.image.length - 1 ? userDetails?.image.length - 1 : newIndex;
        newIndex = newIndex < 0 ? 0 : newIndex;

        setImageIndex(newIndex);
    }, [imageIndex, userDetails]);

    const getStatusChip = (text) => <Chip 
        icon={<InformationOutlineIcon style={{color: '#00AAC4', fontWeight: 600}}/>} 
        label={text}
        sx={{
            backgroundColor: 'white',
            color: '#00AAC4',
            px: 0.5,
            fontWeight: 600,
            fontSize: 13
        }}
    />

    const iconStyle = {width: '20px', height: '20px', color: 'white'};

    const userOtherDetail = (customIcon, value) => <Stack direction='row' alignItems='center' spacing={0.5}>
        {customIcon}
        
        {
            isLoading
            ?
            <SkeletonComponent width='100px' height='20px'/>
            :
            <>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: 'white',
                        lineHeight: '2px'
                    }}
                >
                    {value ? value : t('profile_feed.unknown')}
                </Typography>
            </>
        }
    </Stack>

    const verificationAlertBox = (caption, buttonText, action) => <Box
        sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderRadius: '8px',
            p: '24px',
            minWidth: {xs: '250px', sm: '429px', md: '50%'}
        }}
    >
        {
            isLoading
            ?
            <SkeletonComponent width='200px' height='20px'/>
            :
            <>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: 'white'
                    }}
                >
                    {caption}
                </Typography>

                {
                    action
                    ?
                    <ButtonComponent 
                        text={buttonText}
                        onClick={action}
                        width={20}
                        sx={{
                            color: 'black',
                            backgroundColor: 'white',
                            "&:hover": {
                                backgroundColor: "#FFFFFF",
                                transform: 'scale(1.03)'
                            },
                            height: 45,
                            mt: 1.5
                        }}
                        textSx={{
                            textTransform: 'capitalize'
                        }}
                    />
                    :
                    <div style={{height: 58}}></div>
                }
            </>
        }
        
    </Box>

    const getTabs = (label, id) => <Tab 
        label={label} 
        {...a11yProps(id)} 
        sx={{
            textTransform: 'capitalize',
            color: 'white'
        }}
    />

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [viewType, setViewType] = useState({
        action: t(`${profile_locale}.profile_view`),
        action_icon: <EyeIcon style={{width: '22px', color: 'black'}}/>
    });

    const switchPublicView = useCallback(() => {
        const view = privateView;

        if(view){
            setViewType({
                action: t(`${profile_locale}.edit_view`),
                action_icon: <EditIcon style={{width: '22px', color: 'black'}}/>
            });

            changePrivateView(false);
        }
        else{
            setViewType({
                action: t(`${profile_locale}.profile_view`),
                action_icon: <EyeIcon style={{width: '22px', color: 'black'}}/>
            });

            changePrivateView(true);
        }

        setTabValue(null, 0);
    }, [privateView]);

    const [openDialog, setOpenDialog] = useState({
        title: "",
        message: "",
        open: false,
        close: null,
        successCallback: null
    });



    const getInterestActionText = useCallback(() => {
        if(userDetails.interestReceived){
            return t(`${profile_locale}.accept_interest`);
        }
        else if(!userDetails.interestSent){
            return t(`${profile_locale}.sent_interest`);
        }
        else{
            return t(`${profile_locale}.pending_interest`);
        }
    }, [userDetails]);

    const isInterestActionDisables = useCallback(() => {
        return userDetails.interestSent;
    }, [userDetails])

    const requestInterestSend = useCallback(() => {
        if(userDetails.blocked){
            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.errors.unblock_first`),
                color: "red"
            });

            return;
        }
        
        setOpenDialog({
            title: t(`${profile_locale}.alert.interest_dialog_title`),
            message: t(`${profile_locale}.alert.interest_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmSendInterestRequest
        });
    }, [userDetails]);

    const requestInterestAccept = useCallback(() => {
        if(userDetails.blocked){
            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.errors.unblock_first`),
                color: "red"
            });

            return;
        }

        setOpenDialog({
            title: t(`${profile_locale}.alert.interest_dialog_title`),
            message: t(`${profile_locale}.alert.accept_interest_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmAcceptInterestRequest
        });
    }, [userDetails]);

    const getInterestAction = useCallback(() => {
        if(userDetails.interestReceived){
            return requestInterestAccept();
        }
        else if(!userDetails.interestSent){
            return requestInterestSend();
        }
        else{
            return () => {}
        }
    }, [userDetails]);



    const [
        sendInterest,
        {
            isLoading: isLoadingSendInterest,
            isSuccess: isSuccessSendInterest, 
            isError: isErrorSendInterest, 
            error: errorSendInterest
        }
    ] = useSendInterestMutation();

    useEffect(() => {
        if(isSuccessSendInterest){
            logAnalytics('NEW_INTEREST_SENT');

            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.alert.interest_sent`),
                color: "green"
            });

            setOpenDialog(false);

            setTimeout(() => {
                editCallback(userDetails.member_id);
            }, 1500);
        }
    }, [isSuccessSendInterest]);

    useEffect(() => {
        if(isErrorSendInterest && errorSendInterest){
            console.log(errorSendInterest);

            setShowSnackBar({
                state: true,
                message: `${errorSendInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorSendInterest, errorSendInterest]);

    const confirmSendInterestRequest = useCallback(async () => {
        await sendInterest(userDetails.member_id);
    }, [userDetails]);

    const [
        acceptInterest,
        {
            isLoading: isLoadingAcceptInterest,
            isSuccess: isSuccessAcceptInterest, 
            isError: isErrorAcceptInterest, 
            error: errorAcceptInterest
        }
    ] = useAcceptInterestMutation();

    useEffect(() => {
        if(isSuccessAcceptInterest){
            useChatContext.listenNewlyAcceptedUserChat();

            logAnalytics('NEW_INTEREST_ACCEPT');
            
            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.alert.interest_accepted`),
                color: "green"
            });

            setOpenDialog(false);

            setTimeout(() => {
                editCallback(userDetails.member_id);
            }, 1500);
        }
    }, [isSuccessAcceptInterest]);

    useEffect(() => {
        if(isErrorAcceptInterest && errorAcceptInterest){
            console.log(errorAcceptInterest);

            setShowSnackBar({
                state: true,
                message: `${errorAcceptInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorAcceptInterest, errorAcceptInterest]);

    const confirmAcceptInterestRequest = useCallback(async () => {
        await acceptInterest(userDetails.userId);
    }, [userDetails]);



    const requestBlock = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_locale}.alert.block_dialog_title`),
            message: t(`${profile_locale}.alert.block_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmBlock
        });
    }, [userDetails]);

    const requestUnblock = useCallback(() => {
        setOpenDialog({
            title: t(`${profile_locale}.alert.unblock_dialog_title`),
            message: t(`${profile_locale}.alert.unblock_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmUnBlock
        });
    }, [userDetails]);

    const [
        blockUser,
        {
            isLoading: isLoadingBlockInterest,
            isSuccess: isSuccessBlockInterest, 
            isError: isErrorBlockInterest, 
            error: errorBlockInterest
        }
    ] = useBlockMutation();

    useEffect(() => {
        if(isSuccessBlockInterest){
            logAnalytics('NEW_USER_BLOCKED');

            setShowSnackBar({
                state: true,
                message: t(`${blocked_locale}.blocked`),
                color: "green"
            });

            setOpenDialog(false);

            setTimeout(() => {
                editCallback(userDetails.member_id);
            }, 1500);
        }
    }, [isSuccessBlockInterest]);

    useEffect(() => {
        if(isErrorBlockInterest && errorBlockInterest){
            console.log(errorBlockInterest);

            setOpenDialog(false);

            setShowSnackBar({
                state: true,
                message: `${errorBlockInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorBlockInterest, errorBlockInterest]);

    const confirmBlock = useCallback(async () => {
        await blockUser(userDetails.userId);
    }, [userDetails]);

    const [
        unblockUser,
        {
            isLoading: isLoadingUnblockInterest,
            isSuccess: isSuccessUnblockInterest, 
            isError: isErrorUnblockInterest, 
            error: errorUnblockInterest
        }
    ] = useUnblockMutation();

    useEffect(() => {
        if(isSuccessUnblockInterest){
            logAnalytics('USER_UNBLOCKED');

            setShowSnackBar({
                state: true,
                message: t(`${blocked_locale}.unblocked`),
                color: "green"
            });

            setOpenDialog(false);

            setTimeout(() => {
                editCallback(userDetails.member_id);
            }, 1500);
        }
    }, [isSuccessUnblockInterest]);

    useEffect(() => {
        if(isErrorUnblockInterest && errorUnblockInterest){
            console.log(errorUnblockInterest);

            setOpenDialog(false);

            setShowSnackBar({
                state: true,
                message: `${errorUnblockInterest.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorUnblockInterest, errorUnblockInterest]);

    const confirmUnBlock = useCallback(async () => {
        await unblockUser(userDetails.userId);
    }, [userDetails]);



    const requestShortlist = useCallback(() => {
        handleClose();

        if(userDetails.blocked){
            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.errors.unblock_first`),
                color: "red"
            });

            return;
        }

        setOpenDialog({
            title: t(`${profile_locale}.alert.shortlist_dialog_title`),
            message: t(`${profile_locale}.alert.shortlist_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmShortlist
        });
    }, [userDetails]);

    const [
        shortListUser,
        {
            isLoading: isLoadingShortlist,
            isSuccess: isSuccessShortlist, 
            isError: isErrorShortlist, 
            error: errorShortlist
        }
    ] = useShortListProfileMutation();

    useEffect(() => {
        if(isSuccessShortlist){
            logAnalytics('NEW_USER_SHORTLISTED');

            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.alert.shortlisted`),
                color: "green"
            });

            setOpenDialog(false);

            setTimeout(() => {
                editCallback(userDetails.member_id);
            }, 1500);
        }
    }, [isSuccessShortlist]);

    useEffect(() => {
        if(isErrorShortlist && errorShortlist){
            console.log(errorShortlist);

            setOpenDialog(false);

            setShowSnackBar({
                state: true,
                message: `${errorShortlist.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorShortlist, errorShortlist]);

    const confirmShortlist = useCallback(async () => {
        await shortListUser(userDetails.member_id);
    }, [userDetails]);

    const [
        removeShortlistedUser,
        {
            isLoading: isLoadingRemoveShortlistedUser,
            isSuccess: isSuccessRemoveShortlistedUser, 
            isError: isErrorRemoveShortlistedUser, 
            error: errorRemoveShortlistedUser
        }
    ] = useRemoveFromShortListMutation();

    useEffect(() => {
        if(isSuccessRemoveShortlistedUser){
            logAnalytics('USER_REMOVED_FROM_SHORTLISTED');

            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.alert.shortlisted_removed`),
                color: "green"
            });

            setOpenDialog(false);

            setTimeout(() => {
                editCallback(userDetails.member_id);
            }, 1500);
        }
    }, [isSuccessRemoveShortlistedUser]);

    useEffect(() => {
        if(isErrorRemoveShortlistedUser && errorRemoveShortlistedUser){
            console.log(errorRemoveShortlistedUser);

            setOpenDialog(false);

            setShowSnackBar({
                state: true,
                message: `${errorRemoveShortlistedUser.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorRemoveShortlistedUser, errorRemoveShortlistedUser]);

    const confirmRemoveShortlist = useCallback(async () => {
        await removeShortlistedUser(userDetails.member_id);
    }, [userDetails]);

    const requestRemoveShortlist = useCallback(() => {
        handleClose();

        if(userDetails.blocked){
            setShowSnackBar({
                state: true,
                message: t(`${profile_locale}.errors.unblock_first`),
                color: "red"
            });

            return;
        }

        setOpenDialog({
            title: t(`${profile_locale}.alert.shortlist_dialog_title`),
            message: t(`${profile_locale}.alert.remove_shortlist_dialog_message`),
            open: true,
            close: () => setOpenDialog(false),
            successCallback: confirmRemoveShortlist
        });
    }, [userDetails]);



    const [
        getUserInboxDetails,
        {
            isLoading: isLoadingAllDirectChats,
            isSuccess: isSuccessAllDirectChats,
            data: dataAllDirectChats,
            isError: isErrorAllDirectChats,
            error: errorAllDirectChats
        }
    ] = useGetUserInboxDetailsMutation();

    const requestMessage = useCallback(async () => {
        getUserInboxDetails({userID: userDetails.userId});
    }, [userDetails]);

    useEffect(() => {
        if (isSuccessAllDirectChats) {
            console.log("user chat inbox data...");
            console.log(dataAllDirectChats); 

            useChatContext.setActiveUserChat(dataAllDirectChats.data);
            useChatContext.setChatBoxBody(<ChatPersonal />);

            if(window.innerWidth < 800){
                navigate('/chat');
            }
            else{
                useChatContext.openChatBox(true);
            }
        }
    }, [isSuccessAllDirectChats, dataAllDirectChats]);

    useEffect(() => {
        if(isErrorAllDirectChats && errorAllDirectChats){
            console.log(errorAllDirectChats);

            setShowSnackBar({
                state: true,
                message: `${errorAllDirectChats.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorAllDirectChats, errorAllDirectChats]);



    const canBlurImage = () => {
        if(myAccount || imageIndex < 3){
            return false;
        }

        if(privateView && userDetails?.connected){
            return false;
        }

        return true;
    }

    useEffect(() => {
        checkIDVerification();
    }, []);

    const getVerificationLabel = useCallback(() => {
        if(isSuccessOnCallIDVerification && dataOnCallIDVerification){
            console.log("ID verification data...");
            console.log(dataOnCallIDVerification);

            switch(dataOnCallIDVerification.data.status){
                case 'ID_APPROVED':
                    return t(`${verification_locale}.approved`);
                case 'AWAITING_RESPONSE':
                    return t(`${verification_locale}.awaiting_review`);
                case 'UPLOAD_CLEAR_IMAGES':
                    return t(`${verification_locale}.rejected`);
                default:
                    return t(`${verification_locale}.not_verified`);
            }
        }
        else if(isErrorOnCallIDVerification && errorOnCallIDVerification){
            console.log("ID verification error...");
            console.log(errorOnCallIDVerification);
            return t(`${verification_locale}.not_verified`);
        }

        return "";
    }, [isSuccessOnCallIDVerification, dataOnCallIDVerification, isErrorOnCallIDVerification, errorOnCallIDVerification]);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    px: {xs: 2, md: 7},
                    py: {xs: 2, md: 2}
                }}
            >
                <Stack
                    direction='column'
                    justifyContent='start'
                    alignItems='start'
                    sx={{
                        width: '100%',
                    }}
                >
                    {/* Back */}
                    <Link onClick={privateView || !myAccount ? () => navigate(-1) : () => switchPublicView()}>
                        <Stack 
                            direction='row' 
                            alignItems='center'
                            sx={{
                                cursor: 'pointer'
                            }}
                        >
                            <ArrowLeft style={{width: window.innerWidth > 800 ? '30px' : '20px', color: 'white'}}/>

                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: {xs: 14, md: 26},
                                    color: 'white'
                                }}
                            >
                                {t(`${profile_locale}.back`)}
                            </Typography>
                        </Stack>
                    </Link>

                    {/* Details */}
                    <Grid
                        xs={12}
                        direction={{xs: 'column', md: 'row'}}
                        justifyContent='start'
                        alignItems='start'
                        container
                        mt={2}
                    >
                        {/* Profile Image */}
                        <Grid xs={12} md={3}>
                            {
                                isLoading
                                ?
                                <SkeletonComponent 
                                    width={{xs: 'calc(100% - 12px)', md: '300px'}}
                                    height={{xs: 'calc(100% - 12px)', md: '300px'}}
                                />
                                :
                                <Box 
                                    display='flex'
                                    sx={{
                                        width: {xs: 'calc(100% - 12px)', md: '300px'}, 
                                        height: {xs: 'calc(100% - 12px)', md: '300px'},
                                        backgroundColor: 'white',
                                        border: '6px solid white',
                                        borderRadius: '20px',
                                        position: 'relative'
                                    }}
                                >
                                    <img 
                                        src={
                                            userDetails?.image?.length > 0 && !userDetails?.hide_profile
                                            ? 
                                            userDetails.image[imageIndex] 
                                            : '/assets/images/profile/profile.jpg'
                                        } 
                                        alt="profile"
                                        style={{ 
                                            width: '100%', 
                                            height: '100%', 
                                            borderRadius: '16px',
                                            filter: canBlurImage() ? 'blur(8px)' : 'none'
                                        }}
                                    />

                                    {
                                        !userDetails?.hide_profile
                                        ?
                                        <>
                                            {/* Online status */}
                                            <Box
                                                display='flex'
                                                direction='row'
                                                justifyContent='center'
                                                alignItems='center'
                                                sx={{
                                                    position: 'absolute',
                                                    backgroundColor: '#22C55E',
                                                    zIndex: 2,
                                                    borderRadius: '4px',
                                                    pl: 1,
                                                    margin: '12px',
                                                    right: 0
                                                }}
                                            >
                                                <Box 
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        borderRadius: '50%',
                                                        p: 0.1,
                                                        width: '5px',
                                                        height: '5px'
                                                    }}
                                                />

                                                <Typography
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: 10,
                                                        color: 'white',
                                                        p: 1
                                                    }}
                                                >
                                                    {
                                                        myAccount
                                                        ?
                                                        t(`${profile_locale}.online.active`)
                                                        :
                                                        <>
                                                            {
                                                                userDetails?.last_online
                                                                ?
                                                                t(`${profile_locale}.online.online`).concat(" ").concat(userDetails.last_online).concat(" ").concat(t(`${profile_locale}.online.ago`))
                                                                :
                                                                t(`${profile_locale}.online.not_active`)
                                                            }
                                                        </>
                                                    }
                                                </Typography>
                                            </Box>

                                            {/* Previous */}
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    borderRadius: '50%',
                                                    width: '40px',
                                                    height: '40px',
                                                    backgroundColor: '#888481',
                                                    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.16)',
                                                    color: 'white',
                                                    zIndex: 2,
                                                    "&:hover": {
                                                        backgroundColor: '#888481'
                                                    },
                                                    alignSelf: 'center',
                                                    margin: '16px',
                                                    left: 0,
                                                    display: userDetails?.image?.length > 0 ? 'block' : 'none'
                                                }}
                                                onClick={() => changeImage(-1)}
                                            >
                                                <ChevronLeftIcon />
                                            </IconButton>

                                            {/* Next */}
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    borderRadius: '50%',
                                                    width: '40px',
                                                    height: '40px',
                                                    backgroundColor: '#888481',
                                                    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.16)',
                                                    color: 'white',
                                                    zIndex: 2,
                                                    "&:hover": {
                                                        backgroundColor: '#888481'
                                                    },
                                                    alignSelf: 'center',
                                                    margin: '16px',
                                                    right: 0,
                                                    display: userDetails?.image?.length > 0 ? 'block' : 'none'
                                                }}
                                                onClick={() => changeImage(1)}
                                            >
                                                <ChevronRightIcon />
                                            </IconButton>

                                            {/* Images */}
                                            <Box
                                                display='flex'
                                                direction='row'
                                                justifyContent='center'
                                                alignItems='center'
                                                sx={{
                                                    position: 'absolute',
                                                    backgroundColor: 'white',
                                                    zIndex: 2,
                                                    borderRadius: '500px',
                                                    pl: 1,
                                                    bottom: 0,
                                                    margin: '12px',
                                                    right: 0,
                                                    display: userDetails?.image?.length > 0 ? 'flex' : 'none'
                                                }}
                                            >
                                                <ImageIcon />

                                                <Typography
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: 12,
                                                        color: '#000000',
                                                        p: 1
                                                    }}
                                                >
                                                    {
                                                        userDetails?.image?.length > 0
                                                        ?
                                                        `${imageIndex + 1} / ${userDetails?.image.length} ${t(`${profile_locale}.photos`)}`
                                                        :
                                                        ''
                                                    }
                                                </Typography>
                                            </Box>
                                        </>
                                        :
                                        <></>
                                    }
                                </Box>
                            }
                        </Grid>

                        {/* Profile Details */}
                        <Grid 
                            xs={12} 
                            md={9}
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='start'
                        >
                            <Stack
                                direction='column'
                                justifyContent='start'
                                alignItems='start'
                                sx={{width: '100%'}}
                            >
                                {/* Name and Status */}
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='start'
                                    sx={{width: '100%'}}
                                >
                                    <Stack
                                        direction='row'
                                        justifyContent='start'
                                        alignItems='center'
                                        spacing={1}
                                        sx={{
                                            mt: {xs: 2, md: 0}
                                        }}
                                    >
                                        {
                                            isLoading
                                            ?
                                            <SkeletonComponent width='200px' height='20px' sx={{mb: 1}}/>
                                            :
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        fontSize: {xs: 24, md: 34},
                                                        color: 'white'
                                                    }}
                                                >
                                                    {userDetails?.name}
                                                </Typography>

                                                {
                                                    privateView
                                                    ?
                                                    getStatusChip(getVerificationLabel())
                                                    :
                                                    <>
                                                        {
                                                            userDetails?.id_verified && !userDetails?.hide_profile
                                                            ?
                                                            <CheckDecagramIcon style={{color: 'white', width: '30px', height: '30px', paddingTop: '5px'}}/>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </Stack>

                                    {/* Options */}
                                    {
                                        !privateView && !userDetails?.hide_profile
                                        ?
                                        <IconButton 
                                            aria-label="options"
                                            sx={{
                                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                                width: 40,
                                                height: 40,
                                                mt: {xs: 2, md: 0},
                                                "&:hover": {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                                }
                                            }}
                                            onClick={handleClick}
                                        >
                                            <DotsVerticalIcon style={{color: 'white'}}/>
                                        </IconButton>
                                        :
                                        <></>
                                    }
                                </Stack>

                                {/* Member ID */}
                                {
                                    isLoading
                                    ?
                                    <SkeletonComponent width='200px' height='20px'/>
                                    :
                                    <>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: {xs: 20, md: 24},
                                                color: 'white'
                                            }}
                                        >
                                            {userDetails?.member_id}
                                        </Typography>
                                    </>
                                }

                                {
                                    !userDetails?.hide_profile
                                    ?
                                    <>
                                        {/* Other Details */}
                                        <Stack 
                                            direction='row' 
                                            useFlexGap 
                                            flexWrap="wrap"
                                            gap={2.5}
                                            sx={{pt: 2}}
                                        >
                                            {userOtherDetail(<CalendarMonthOutlineIcon style={iconStyle}/>, userDetails?.age)}
                                            {userOtherDetail(<SchoolOutlineIcon style={iconStyle}/>, userDetails?.education_level)}
                                            {userOtherDetail(<BriefcaseOutlineIcon style={iconStyle}/>, userDetails?.occupation)}
                                            {userOtherDetail(<MapMarkerOutlineIcon style={iconStyle}/>, userDetails?.location)}
                                        </Stack>

                                        {/* Public View Label */}
                                        {
                                            isLoading
                                            ?
                                            <></>
                                            :
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: {xs: 16, md: 20},
                                                        color: 'white',
                                                        pt: {xs: 4, md: 10},
                                                        mb: 1
                                                    }}
                                                >
                                                    {

                                                        !privateView
                                                        ?
                                                        <>
                                                            {
                                                                myAccount
                                                                ?
                                                                <></>
                                                                :
                                                                <>
                                                                    {
                                                                        userDetails.connected
                                                                        ?
                                                                        t(`${profile_locale}.connected`)
                                                                        :
                                                                        t(`${profile_locale}.connect_now`)
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        t(`${profile_locale}.how_your_profile_looks_to_others`)
                                                    }
                                                </Typography>

                                                {/* Public view action */}
                                                {
                                                    !privateView
                                                    ?
                                                    <>
                                                        {
                                                            myAccount
                                                            ?
                                                            <></>
                                                            :
                                                            <Stack
                                                                direction='row'
                                                                justifyContent='start'
                                                                alignItems='center'
                                                                spacing={2}
                                                            >
                                                                {
                                                                    !userDetails.connected
                                                                    ?
                                                                    <LoadingButtonComponent 
                                                                        text={getInterestActionText()}
                                                                        onClick={getInterestAction}
                                                                        width={20}
                                                                        startIcon={<CheckIcon style={{width: '22px', color: 'black'}}/>}
                                                                        inLoading={isLoadingSendInterest || isLoadingAcceptInterest}
                                                                        sx={{
                                                                            color: 'black',
                                                                            backgroundColor: 'white',
                                                                            "&:hover": {
                                                                                backgroundColor: "#FFFFFF",
                                                                                transform: isInterestActionDisables() ? 'scale(1)' : 'scale(1.03)'
                                                                            },
                                                                            cursor: isInterestActionDisables() ? 'auto' : 'pointer'
                                                                        }}
                                                                        textSx={{
                                                                            textTransform: 'capitalize'
                                                                        }}
                                                                    />
                                                                    :
                                                                    <LoadingButtonComponent 
                                                                        text={t(`profile_interests.actions.message`)}
                                                                        onClick={requestMessage}
                                                                        inLoading={isLoadingAllDirectChats}
                                                                        width={20}
                                                                        startIcon={<ChatOutlineIcon />}
                                                                        sx={{
                                                                            color: 'black',
                                                                            backgroundColor: 'white',
                                                                            "&:hover": {
                                                                                backgroundColor: "#FFFFFF",
                                                                                transform: 'scale(1.03)'
                                                                            },
                                                                        }}
                                                                        textSx={{
                                                                            textTransform: 'capitalize'
                                                                        }}
                                                                    />
                                                                }

                                                                <LoadingButtonComponent 
                                                                    text={userDetails?.blocked ? t(`${profile_locale}.unblock_profile`) : t(`${profile_locale}.block_profile`)}
                                                                    onClick={userDetails?.blocked ? () => requestUnblock() : () => requestBlock()}
                                                                    inLoading={isLoadingBlockInterest || isLoadingUnblockInterest}
                                                                    outlined
                                                                    width={20}
                                                                    startIcon={<CancelIcon style={{width: '22px', color: 'white'}}/>}
                                                                    sx={{
                                                                        color: 'white',
                                                                        borderColor: 'white',
                                                                        "&:hover": {
                                                                            transform: 'scale(1.03)',
                                                                            borderColor: 'white',
                                                                        }
                                                                    }}
                                                                    textSx={{
                                                                        textTransform: 'capitalize'
                                                                    }}
                                                                />
                                                            </Stack>
                                                        }
                                                    </>
                                                    :
                                                    <ButtonComponent 
                                                        text={viewType.action}
                                                        onClick={() => switchPublicView()}
                                                        width={20}
                                                        startIcon={viewType.action_icon}
                                                        sx={{
                                                            color: 'black',
                                                            backgroundColor: 'white',
                                                            "&:hover": {
                                                                backgroundColor: "#FFFFFF",
                                                                transform: 'scale(1.03)'
                                                            }
                                                        }}
                                                        textSx={{
                                                            textTransform: 'capitalize'
                                                        }}
                                                    />
                                                }
                                            </>
                                        }
                                    </>
                                    :
                                    <></>
                                }
                            </Stack>
                        </Grid>
                    </Grid>

                    {/* Verification Alert */}
                    <Stack
                        direction='row'
                        justifyContent='start'
                        alignItems='center'
                        mt={3}
                        width='100%'
                        spacing={2}
                        className='hideScroll'
                        sx={{
                            overflow: 'scroll',
                            display: !privateView ? 'none' : 'flex'
                        }}
                    >
                        {
                            userDetails?.email_verified && userDetails?.id_verified
                            ?
                            <></>
                            :
                            verificationAlertBox(t(`${verification_locale}.caption`), t(`${verification_locale}.verify_account`), () => navigate("/verification"))
                        }

                        {
                            userDetails?.profile_verification_percentage === 100
                            ?
                            <></>
                            :
                            verificationAlertBox(`${t(`${profile_locale}.id_verification_alert.message_1`)} ${userDetails?.profile_verification_percentage}% ${t(`${profile_locale}.id_verification_alert.message_2`)}`, t(`${profile_locale}.start_completing`))
                        }
                    </Stack>

                    {/* Tabs */}
                    {
                        !userDetails?.hide_profile
                        ?
                        <Tabs 
                            id='profile_details'
                            value={tabValue} 
                            onChange={setTabValue} 
                            aria-label="chat tabs"
                            variant="scrollable"
                            sx={{
                                mt: 5,
                                width: '100%'
                            }}
                            textColor='white'
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor:'white'
                                }
                            }}
                        >
                            {
                                getTabs(t(`${profile_locale}.tabs.basic_details`), 0)
                            }

                            {
                                getTabs(t(`${profile_locale}.tabs.professional_details`), 1)
                            }

                            {
                                getTabs(t(`${profile_locale}.tabs.family_details`), 2)
                            }

                            {
                                getTabs(t(`${profile_locale}.tabs.horoscope_details`), 3)
                            }

                            {
                                getTabs(t(`${profile_locale}.tabs.hobbies_interests`), 4)
                            }

                            {
                                privateView
                                ?
                                getTabs(t(`${profile_locale}.tabs.gallery`), 5)
                                :
                                <div></div>
                            }
                        </Tabs>
                        :
                        <Box my={1}></Box>
                    }
                </Stack>
            </Box>

            <DialogBox 
                title={openDialog.title}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {openDialog.message}
                    </Typography>
                }
                open={openDialog.open} 
                onClose={openDialog.close}
                onSuccess={openDialog.successCallback}
            />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    style: {
                        transform: 'translateX(-50px)',
                    }
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: 'rgba(255, 162, 173, 1)',
                        borderRadius: '8px'
                    }
                }}
            >
                {
                    myAccount
                    ?
                    <MenuItem 
                        onClick={() => {
                            handleClose();
                            switchPublicView();
                        }}
                        sx={{
                            color: 'white'
                        }}
                    >
                        <Stack direction='row' alignItems='center' spacing={1} sx={{p: 1}}>
                            <EditIcon />
                            
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '16px'
                                }}
                            >
                                {t(`${profile_locale}.edit_view`)}
                            </Typography>
                        </Stack>
                    </MenuItem>
                    :
                    <MenuItem 
                        onClick={userDetails.shortListed ? () => requestRemoveShortlist() : () => requestShortlist()}
                        sx={{
                            color: 'white',
                        }}
                    >
                        <Stack direction='row' alignItems='center' spacing={1} sx={{p: 1}}>
                            {userDetails.shortListed ? <AccountMinusOutlineIcon /> : <AccountPlusOutlineIcon />}
                            
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '16px'
                                }}
                            >
                                {
                                    userDetails.shortListed
                                    ?
                                    t(`${profile_locale}.remove_shortlist`)
                                    :
                                    t(`${profile_locale}.Shortlist`)
                                }
                            </Typography>
                        </Stack>
                    </MenuItem>
                }
            </Menu>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            /> 
        </>
    );
}

export default ProfileHeader;

ProfileHeader.propType = {
    tabValue: PropTypes.number, 
    setTabValue: PropTypes.func,
    privateView: PropTypes.bool,
    changePrivateView: PropTypes.func,
    myAccount: PropTypes.bool,
    responseData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func
}