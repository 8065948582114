import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const shortlistedAPI = createApi({
    reducerPath: "shortlistedAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllShortlistedProfiles: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `search/match?${queryParams.toString()}&filter=SHORTLISTED`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["shortlisted"]
        }),
        shortListProfile: builder.mutation({
            query: (memberID) => ({
                url: `profile/shortlist/${memberID}`,
                method: "PUT"
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['shortlisted']
        }),
        removeFromShortList: builder.mutation({
            query: (memberID) => ({
                url: `profile/shortlist/${memberID}`,
                method: "DELETE"
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['shortlisted']
        })
    })
});

export const { 
    useGetAllShortlistedProfilesMutation,
    useShortListProfileMutation,
    useRemoveFromShortListMutation
} = shortlistedAPI;

export default shortlistedAPI;