import PropTypes from 'prop-types';

import {
    Grid,
    useTheme,
    Typography,
    Stack
} from '@mui/material';

import ButtonComponent from '../../components/form/button-component';
import ArrowLeft from '../../assets/icons/arrow-left';
import { useNavigate } from 'react-router-dom';

const ProfileSettingsHeader = ({
    heading, 
    caption,
    callback,
    showText=true,
    needCustomCallback=false,
    endIcon,
    mb=5
}) => {
    const theme = useTheme();

    const navigate = useNavigate();

    return (
        <>
            <Grid
                xs={12}
                display='flex'
                justifyContent='center'
                alignItems='start'
                direction='column'
                sx={{
                    py: {xs: 1, md: caption && showText ? 3 : 1.5},
                    px: 3,
                    backgroundColor: theme.palette.primary.main,
                    width: '100%',
                    mb: mb
                }}
            >
                <Stack direction={{xs: 'column', md: 'row'}} justifyContent={{xs: 'center', md: 'space-between'}} alignItems={{xs: 'start', md: 'center'}} sx={{width: '100%'}}>
                    <Stack direction='column' justifyContent='center' alignItems='start'>
                        <ButtonComponent 
                            text={showText ? heading : ''}
                            onClick={() => showText && !needCustomCallback ? navigate(-1) : callback()}
                            width={124}
                            startIcon={<ArrowLeft style={{width: '30px', color: 'white'}}/>}
                            variant='text'
                            sx={{
                                color: 'white',
                                textTransform: 'capitalize',
                                mb: 0,
                                p: 0
                            }}
                            textSx={{
                                fontWeight: 600,
                                fontSize: {xs: 16, md: 24}
                            }}
                        />

                        {
                            caption && showText
                            ?
                            <Typography
                                sx={{
                                    fontWeight: '500px',
                                    fontSize: '16px',
                                    color: 'white',
                                    pb: {xs: 2, md: 0},
                                    mt: -1
                                }}
                            >
                                {caption}
                            </Typography>
                            :
                            <></>
                        }
                    </Stack>

                    {
                        endIcon
                        ?
                        <Stack 
                            direction='row' 
                            justifyContent={{xs: 'start', md: 'end'}} 
                            alignItems='center'
                            sx={{
                                mb: {xs: 2, md: 0}
                            }}
                        >
                            {endIcon}
                        </Stack>
                        :
                        <></>
                    }
                </Stack>
            </Grid>
        </>
    );
}

export default ProfileSettingsHeader;

ProfileSettingsHeader.propType = {
    heading: PropTypes.string,
    caption: PropTypes.string,
    callback: PropTypes.func,
    showText: PropTypes.bool,
    endIcon: PropTypes.object,
    mb: PropTypes.number,
    needCustomCallback: PropTypes.func
}