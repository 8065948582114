import { Route, Routes } from "react-router-dom";

import Layout from "../layouts/layout";

import PrivateRoute from "./private-route";

import HomePage from "../pages/main/home";
import AboutUsPage from "../pages/about/about";
import ContactUsPage from "../pages/contact/contact";

import LoginPage from "../pages/auth/login";
import SignupPage from "../pages/auth/signup";
import ResetPasswordPage from "../pages/auth/reset-password";

import ChatPage from "../pages/chat/chat";

import NotFoundPage from "../pages/error/not-found";

import TermsAndConditionsPage from "../pages/legal/terms-and-conditions";
import PrivacyPolicyPage from "../pages/legal/privacy-policy";

import Profile from "../pages/profile/profile";
import ProfileFeedPage from "../pages/profile-general/profile-feed";
import ProfileSearchPage from "../pages/profile-general/profile-search";
import ProfileMatchPage from "../pages/profile-general/profile-match";
import ProfileInterestsPage from "../pages/profile-general/profile-interests";
import ProfileDailyRecommendationPage from "../pages/profile-general/profile-daily-recommendation";

import SettingsPage from "../pages/profile-settings/settings";
import EditProfilePage from "../pages/profile-settings/edit-profile";
import DeleteProfilePage from "../pages/profile-settings/delete-profile";
import DeactivateProfilePage from "../pages/profile-settings/deactivate-profile";
import BlockedProfilesPage from "../pages/profile-settings/blocked-profiles";

import IDProofPage from "../pages/profile-verification/id-proof";
import VerificationPage from "../pages/profile-verification/verification";
import EmailVerificationPage from "../pages/profile-verification/verify-email";

import PartnerPreferencePage from '../pages/partner-preferences/partner-preference';
import PartnerAboutPreferencePage from '../pages/partner-preferences/partner-about-preference';
import PartnerBasicPreferencePage from '../pages/partner-preferences/partner-basic-preference';
import PartnerLocationPreferencePage from '../pages/partner-preferences/partner-location-preference';
import PartnerProfessionalPreferencePage from '../pages/partner-preferences/partner-professional-preference';
import PartnerReligiousPreferencePage from '../pages/partner-preferences/partner-religious-preference';
import AuthorizeEmailPage from "../pages/profile-verification/authorize-email";

const Router = () => {
    return(
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<HomePage />} />

                <Route path="about-us" element={<AboutUsPage />} />
                <Route path="contact-us" element={<ContactUsPage />} />

                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route path="reset-password" element={<ResetPasswordPage />} />

                <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />

                <Route path="authorize/email" element={<AuthorizeEmailPage />} />

                <Route element={<PrivateRoute />}>
                    <Route path="chat" element={<ChatPage />} />

                    <Route path="profile/:id" element={<Profile />} />
                    <Route path="feed" element={<ProfileFeedPage />} />
                    <Route path="search" element={<ProfileSearchPage />} />
                    <Route path="matches" element={<ProfileMatchPage />} />
                    <Route path="interests" element={<ProfileInterestsPage />} />
                    <Route path="daily-recommendations" element={<ProfileDailyRecommendationPage />} />

                    <Route path="settings" element={<SettingsPage />} />
                    <Route path="edit-profile" element={<EditProfilePage />} />
                    <Route path="delete-profile" element={<DeleteProfilePage />} />
                    <Route path="deactivate-profile" element={<DeactivateProfilePage />} />
                    <Route path="blocked-profiles" element={<BlockedProfilesPage />} />

                    <Route path="verification" element={<VerificationPage />} />
                    <Route path="verify-id" element={<IDProofPage />} />
                    <Route path="verify-email" element={<EmailVerificationPage />} />

                    <Route path="partner-preference" element={<PartnerPreferencePage />} />
                    <Route path="partner-about-preference" element={<PartnerAboutPreferencePage />} />
                    <Route path="partner-basic-preference" element={<PartnerBasicPreferencePage />} />
                    <Route path="partner-location-preference" element={<PartnerLocationPreferencePage />} />
                    <Route path="partner-professional-preference" element={<PartnerProfessionalPreferencePage />} />
                    <Route path="partner-religious-preference" element={<PartnerReligiousPreferencePage />} />
                </Route>

                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
};

export default Router;