import { Helmet } from "react-helmet";
import PartnerAboutPreferenceLayout from "../../sections/partner-preference/about/partner-about-preference-layout";

const PartnerAboutPreferencePage = () => {
    return(
        <>
            <Helmet>
                <title>Partner About Preference</title>
            </Helmet>

            <PartnerAboutPreferenceLayout />
        </>
    );
}

export default PartnerAboutPreferencePage;