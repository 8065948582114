import LegalLayout from "./legal-layout";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const policy_locale = "legal.policy";

    return (
        <>
            <LegalLayout 
                heading={t(`${policy_locale}.heading`)}
                content={t(`${policy_locale}.content`)}
            />
        </>
    );
}

export default PrivacyPolicy;