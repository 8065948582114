import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import ProfileEditHeader from "../profile-edit-header";

import { useTranslation } from "react-i18next";
import { Box, Grid, Stack } from "@mui/material";
import { useCallback, useState, useEffect } from 'react';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useUpdateProfileMutation } from '../../../services/profile-api';
import FileUpload from '../../../components/form/file-upload';
import FormProviderComponent from '../../../components/form/form-provider-component';
import LoadingButtonComponent from '../../../components/form/loading-button-component';
import SkeletonComponent from '../../../components/loading/skeleton-component';

const Gallery = ({
    privateView, 
    updateData,
    isLoading, 
    editCallback,
    value
}) => {
    const { t } = useTranslation();
    const profile_locale = "profile.gallery";
    const sign_up_locale = "authentication.sign_up";
    const validation_locale = "validation";

    const [selectedImage, setSelectedImage] = useState([]);
    const [isImagesUnderUploadState, setIsImagesUnderUploadState] = useState(false);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const formSchema = yup.object().shape({
        profile: yup.array().test('isValidProfile', t(`${sign_up_locale}.errors.invalid_profile_image`), () => {
            const isValid = selectedImage.length === 0 || (selectedImage.length >= 3 && selectedImage.length <= 10);
            return isValid;
        }),
    });

    const defaultValues = {
        profile: []
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    useEffect(() => {
        if(value){
            setFormValue("profile", value);

            const array = [];

            for(let i = 0; i < value.length; i++){
                array.push({id: i, file: null, preview: value[i], extension: '', downloadURL: value[i]});
            }
            
            setSelectedImage(array);
        }
    }, [value]);

    const [
        updateProfile, 
        {
          isLoading: isLoadingUpdateProfile,
          isSuccess: isSuccessUpdateProfile,
          isError: isErrorUpdateProfile,
          error: errorUpdateProfile
        }
    ] = useUpdateProfileMutation();

    useEffect(() => {
        if (isSuccessUpdateProfile){
            editCallback();
        }
        else if (isErrorUpdateProfile && errorUpdateProfile) {
            setShowSnackBar({
                state: true,
                message: `${errorUpdateProfile.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessUpdateProfile, isErrorUpdateProfile, errorUpdateProfile, editCallback]);

    const getProfileImageList = useCallback(() => {
        const array = [];

        for(let i = 0; i < selectedImage.length; i++){
            array.push(selectedImage[i].downloadURL);
        }

        return array;
    }, [selectedImage]);

    const onSubmit = handleSubmit(async(data) => {
        if(isImagesUnderUploadState) {
            setShowSnackBar({
                state: true,
                message: t(`${validation_locale}.wait_until_upload`),
                color: "red"
            });

            return;
        }

        const obj = updateData;
        obj.profileImages = getProfileImageList();

        console.log(obj);

        await updateProfile(obj);
    });

    return (
        <>
            <ProfileEditHeader 
                heading={t(`${profile_locale}.gallery`)} 
                privateView={privateView}
            />

            {
                isLoading
                ?
                <Box width={privateView ? '100%' : '80%'} >
                    <SkeletonComponent height='500px'/>
                </Box>
                :
                <Grid
                    xs={12}
                    container
                    direction='row'
                    justifyContent='space-between'
                >
                    <Grid xs={12}>
                        <FormProviderComponent methods={formMethods} onSubmit={onSubmit}>
                            <FileUpload 
                                name="profile"
                                label={t(`${sign_up_locale}.step6.form.image_upload_label`)}
                                min={3}
                                max={10}
                                imageList={selectedImage}
                                fileExtensionList={['png', 'jpeg', 'jpg']}
                                setImageList={setSelectedImage}
                                uploadType='PROFILE_IMG'
                                setIsImagesUnderUploadState={setIsImagesUnderUploadState}
                            />

                            <Stack 
                                display='flex' 
                                direction={{xs: 'column', md: 'row'}}
                                justifyContent={{xs: 'center', md: 'end'}}
                                sx={{
                                    width: '100%',
                                    display: 'flex'
                                }}
                                spacing={2}
                            >
                                <LoadingButtonComponent 
                                    text={t(`${sign_up_locale}.action`)}
                                    type="submit"
                                    inLoading={isLoadingUpdateProfile}
                                    sx={{
                                        width: 120,
                                        mt: 1,
                                        backgroundColor: 'black'
                                    }}
                                />
                            </Stack>
                        </FormProviderComponent>
                    </Grid>
                </Grid>
            }
            
            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default Gallery;

Gallery.propType = {
    privateView: PropTypes.bool,
    updateData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func,
    value: PropTypes.string
}