import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import PartnerReligiousPreferenceBody from "./partner-religious-preference-body";

import Footer from '../../footer/footer';

const PartnerReligiousPreferenceLayout = () => {
    const { t } = useTranslation();
    const partner_religious_locale = "partner_preference.religious";

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${partner_religious_locale}.heading`)}
                caption={t(`${partner_religious_locale}.caption`)}
            />

            <PartnerReligiousPreferenceBody />

            <Footer />
        </>
    );
}

export default PartnerReligiousPreferenceLayout;