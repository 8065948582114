import ContactUsBody from "./contact-us-body";
import ContactUsHeader from "./contact-us-header";
import Footer from '../footer/footer';

const ContactUsLayout = () =>{
    return (
        <>
            <ContactUsHeader />

            <ContactUsBody />

            <Footer />
        </>
    );
}

export default ContactUsLayout;