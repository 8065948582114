import { 
    Grid,
    Typography,
    useTheme,
    Link,
    Box,
    Stack
} from "@mui/material";

import { useTranslation } from "react-i18next";
import HowItWorkStep from "./how-it-works-step";

const HowItWorksLayout = () => {
    const theme = useTheme();

    const { t } = useTranslation();
    const how_it_works_locale = "landing_page.how_it_works";

    return (
        <>
            <Grid
                xs
                container
                direction='column'
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                    mt: 10
                }}
            >
                <Grid 
                    xs={12}
                >
                    <Typography
                        textAlign='center'
                        sx={{
                            fontWeight: 600,
                            fontSize: {xs: 25, md: 32, lg: 48},
                            letterSpacing: -2,
                            pb: 3
                        }}
                    >
                        {t(`${how_it_works_locale}.heading`)}
                    </Typography>
                </Grid>

                <Grid 
                    xs={12}
                >
                    <Typography
                        textAlign='center'
                        sx={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: '24px',
                            color: '#6D6D6D',
                            px: 2,
                            pb: {xs: 0, md: 5}
                        }}
                    >
                        {t(`${how_it_works_locale}.caption`)}
                    </Typography>
                </Grid>

                <Grid 
                    xs={12}
                    container
                    direction='column'
                    px={{xs: 5, lg: 7}}
                >
                    <HowItWorkStep 
                        data={{
                            step: t(`${how_it_works_locale}.step1.step`),
                            heading: t(`${how_it_works_locale}.step1.heading`),
                            caption: t(`${how_it_works_locale}.step1.caption`),
                            topIcon: "/assets/icons/how-it-works-1.png",
                            banner: "/assets/images/home/how-it-works-1.png"
                        }}
                    />

                    <HowItWorkStep 
                        data={{
                            step: t(`${how_it_works_locale}.step2.step`),
                            heading: t(`${how_it_works_locale}.step2.heading`),
                            caption: t(`${how_it_works_locale}.step2.caption`),
                            topIcon: "/assets/icons/how-it-works-2.png",
                            banner: "/assets/images/home/how-it-works-2.png"
                        }}
                        reverse
                    />

                    <HowItWorkStep 
                        data={{
                            step: t(`${how_it_works_locale}.step3.step`),
                            heading: t(`${how_it_works_locale}.step3.heading`),
                            caption: t(`${how_it_works_locale}.step3.caption`),
                            topIcon: "/assets/icons/authentication-7.png",
                            banner: "/assets/images/home/how-it-works-3.png"
                        }}
                        last
                        // iconTranslateY={100}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default HowItWorksLayout;