import PropTypes from 'prop-types';

import { 
    Typography,
    ImageList,
    ImageListItem,
    FormHelperText,
    Stack
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SnackbarComponent from '../snack-bar/snack-bar-component';
import { Controller, useFormContext } from 'react-hook-form';
import InfoIcon from '../../assets/icons/info-icon';
import FileUploadCard from './file-upload-card';

const FileUpload = ({
    name,
    width,
    label,
    max,
    imageList,
    fileExtensionList,
    setImageList,
    uploadType,
    setIsImagesUnderUploadState,
    listenToResetAll=0 //Increase this value when ever needs to reset
}) => {
    const { t } = useTranslation();
    const validation_locale = "validation";

    const { control } = useFormContext();

    useEffect(() => {
        if(listenToResetAll > 0){
            console.log("Uploaded files cleared...");
            removeAll();
        }
    }, [listenToResetAll])

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const selectImage = useCallback((acceptedFiles) => {
        if(imageList?.length > max - 1){
            setShowSnackBar({
                state: true,
                message: `${t(`${validation_locale}.max_upload_file`)} ${max}`,
                color: "red"
            });

            return null;
        }

        const file = acceptedFiles.target.files[0];

        console.log(file);
        console.log(file.type)

        const extension = file.type.split('/')[1];

        if(fileExtensionList.includes(extension)){
            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file)
            });
    
            if (file) {
                console.log(file);
    
                const preview = file.preview;
    
                const array = [...imageList];
                array.push({id: imageList.length, file: file, preview: preview, extension: extension, downloadURL: ''});
    
                setImageList(array);
                setIsImagesUnderUploadState(true);

                return array;
            }
            else{
                return null;
            }
        }
        else{
            setShowSnackBar({
                state: true,
                message: `${t(`${validation_locale}.invalid_file_type`)}`,
                color: "red"
            });

            return null;
        }
    }, [imageList, max]);

    const removeImage = useCallback((index) => {
        const array = [...imageList];

        for(let i = 0; i < array.length; i++){
            if(array[i].id === index){
                array.splice(i, 1);
                break;
            }
        }

        for(let i = 0; i < array.length; i++){
            array[i].id = i;
        }

        setImageList(array);
    }, [imageList]);

    const removeAll = useCallback(() => {
        setImageList([]);
    }, []);

    const addImage = useCallback((index, downloadURL) => {
        const array = [...imageList];

        for(let i = 0; i < array.length; i++){
            if(array[i].id === index){
                array[i].downloadURL = downloadURL;
                setImageList(array);
                break;
            }
        }

        for(let i = 0; i < array.length; i++){
            if(array[i].downloadURL === ""){
                setIsImagesUnderUploadState(true);
                break;
            }
            else if(i === array.length - 1){
                setIsImagesUnderUploadState(false);
            }
        }
    }, [imageList]);

    return(
        <>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: width ? width : '100%',
                                height: '327px',
                                backgroundColor: '#E7E7E780',
                                borderRadius: '8px',
                                marginTop: '20px',
                                position: 'relative',
                                overflow: 'hidden'
                            }}
                        >
                            <input 
                                type="file" 
                                id="myFile" 
                                name="filename" 
                                onChange={(acceptedFiles) => {
                                    const imageArray = selectImage(acceptedFiles);
                                    console.log(imageArray);

                                    if(imageArray){
                                        field.onChange(imageArray);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                    opacity: 0,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 10
                                }}
                            />

                            <Typography
                                fontWeight={600}
                                fontSize={{xs: 14, md: 16}}
                                textAlign='center'
                                color='#6D6D6D'
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 5
                                }}
                            >
                                {label}
                            </Typography>
                        </div>

                        <Stack direction='row' justifyContent='start' alignItems='center' sx={{pt: 2}}>
                            <InfoIcon style={{width: '13px', paddingTop: 3, paddingRight: 5}}/> 

                            <FormHelperText>
                                {t(`${validation_locale}.image_help`)}
                            </FormHelperText>
                        </Stack>

                        <FormHelperText sx={{color: 'rgba(255, 0, 0, 1)', px: 1.5, pt: 2}}>{error?.message}</FormHelperText>
                    </>
                )}
            />

            <ImageList 
                sx={{ 
                    width: '100%', 
                    height: imageList?.length > 0 ? 150 : 0 
                }} 
                cols={10} 
                rowHeight={150}
            >
                {imageList?.map((item, index) => (
                    <ImageListItem 
                        key={index}
                        sx={{
                            mx: 1
                        }}
                    >
                        <FileUploadCard 
                            item={item} 
                            index={index} 
                            removeImage={removeImage} 
                            uploadType={uploadType}
                            updateUploadURL={addImage}
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default FileUpload;

FileUpload.propType = {
    name: PropTypes.string,
    width: PropTypes.string,
    label: PropTypes.string,
    max: PropTypes.number,
    imageList: PropTypes.array,
    fileExtensionList: PropTypes.array,
    setImageList: PropTypes.func,
    uploadType: PropTypes.string,
    setIsImagesUnderUploadState: PropTypes.func,
    listenToResetAll: PropTypes.number
}