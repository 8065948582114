import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary,
    Divider,
    Grid,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import DownArrow from "../../../assets/icons/down-arrow";
import { useTranslation } from "react-i18next";
import SliderComponent from "../../../components/form/slider-component";
import { useCallback, useEffect, useState } from "react";
import MultipleSelectionComponent from "../../../components/form/multiple-selection-component";

import { countries } from "../../../assets/data/countries";
import { ButtonComponent, CurrencyComponent, LoadingButtonComponent, RadioButton, SelectionComponent, TextFieldComponent } from "../../../components/form";
import FormProviderComponent from "../../../components/form/form-provider-component";
import { 
    physicalStatusList,
    bodyTypeList,
    educationLevelList,
    eatingHabitList,
    drinkingHabitList,
    smokingHabitList
} from '../../../assets/data/profile';

import { useGetSpecificMasterDataQuery } from '../../../services/master-data-api';
import DialogBox from '../../../components/Dialog/dialog-box';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useSaveSearchMutation, useUpdateSearchFilterMutation } from '../../../services/search-api';

const ProfileSearchEditFilter = ({onSearch, filterObj, onClose, needSearch=true, needSaveSearch=true, closeAfterEdit=true}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_search_locale = "profile_feed.search.edit_search_filter";
    const partner_basic_locale = "partner_preference.basic";
    const partner_religious_locale = "partner_preference.religious";
    const partner_location_locale = "partner_preference.location";
    const partner_professional_locale = "partner_preference.professional";
    const search_locale = "profile_feed.search.search_by_filter";
    const sign_up_locale = "authentication.sign_up";

    const [basicFilterOpen, setBasicFilterOpen] = useState(true);
    const [advanceFilterOpen, setAdvanceFilterOpen] = useState(false);

    const [openDialog, setOpenDialog] = useState({
        title: "",
        message: "",
        open: false,
        close: null,
        successCallback: null
    });

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [religionList, setReligionList] = useState([]);
    const {isLoading: isLoadingReligion, isSuccess: isSuccessReligion, data: dataReligion, isError: isErrorReligion, error: errorReligion} = useGetSpecificMasterDataQuery('religion');
    useEffect(() => {
        if(isSuccessReligion && dataReligion){
            console.log("Religion master data...");
            console.log(dataReligion);

            setReligionList(getListData(dataReligion.data));
        }
        else if(isErrorReligion && errorReligion){
            console.log("Religion master data error...");
            console.log(errorReligion);

            setReligionList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessReligion, dataReligion, isErrorReligion, errorReligion]);

    const [castList, setCastList] = useState([]);
    const {isLoading: isLoadingCaste, isSuccess: isSuccessCaste, data: dataCaste, isError: isErrorCaste, error: errorCaste} = useGetSpecificMasterDataQuery('caste');
    useEffect(() => {
        if(isSuccessCaste && dataCaste){
            console.log("Caste master data...");
            console.log(dataCaste);

            setCastList(getListData(dataCaste.data));
        }
        else if(isErrorCaste && errorCaste){
            console.log("Caste master data error...");
            console.log(errorCaste);

            setCastList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessCaste, dataCaste, isErrorCaste, errorCaste]);

    const [subCastList, setSubCastList] = useState([]);
    const {isLoading: isLoadingSubCaste, isSuccess: isSuccessSubCaste, data: dataSubCaste, isError: isErrorSubCaste, error: errorSubCaste} = useGetSpecificMasterDataQuery('sub-caste');
    useEffect(() => {
        if(isSuccessSubCaste && dataSubCaste){
            console.log("Sub caste master data...");
            console.log(dataSubCaste);

            setSubCastList(getListData(dataSubCaste.data));
        }
        else if(isErrorSubCaste && errorSubCaste){
            console.log("Sub caste master data error...");
            console.log(errorSubCaste);

            setSubCastList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessSubCaste, dataSubCaste, isErrorSubCaste, errorSubCaste]);

    const [motherTongueList, setMotherTongueList] = useState([]);
    const {isLoading: isLoadingMotherTongue, isSuccess: isSuccessMotherTongue, data: dataMotherTongue, isError: isErrorMotherTongue, error: errorMotherTongue} = useGetSpecificMasterDataQuery('mother-tongue');
    useEffect(() => {
        if(isSuccessMotherTongue && dataMotherTongue){
            console.log("Mother tongue master data...");
            console.log(dataMotherTongue);

            setMotherTongueList(getListData(dataMotherTongue.data));
        }
        else if(isErrorMotherTongue && errorMotherTongue){
            console.log("Mother tongue master data error...");
            console.log(errorMotherTongue);

            setMotherTongueList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessMotherTongue, dataMotherTongue, isErrorMotherTongue, errorMotherTongue]);

    const [maritalList, setMaritalList] = useState([]);
    const {isLoading: isLoadingMaritalStatus, isSuccess: isSuccessMaritalStatus, data: dataMaritalStatus, isError: isErrorMaritalStatus, error: errorMaritalStatus} = useGetSpecificMasterDataQuery('marital-status');
    useEffect(() => {
        if(isSuccessMaritalStatus && dataMaritalStatus){
            console.log("Marital status master data...");
            console.log(dataMaritalStatus);

            setMaritalList(getListData(dataMaritalStatus.data));
        }
        else if(isErrorMaritalStatus && errorMaritalStatus){
            console.log("Marital status master data error...");
            console.log(errorMaritalStatus);

            setMaritalList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessMaritalStatus, dataMaritalStatus, isErrorMaritalStatus, errorMaritalStatus]);

    const [employmentTypeList, setEmploymentTypeList] = useState([]);
    const {isLoading: isLoadingEmploymentType, isSuccess: isSuccessEmploymentType, data: dataEmploymentType, isError: isErrorEmploymentType, error: errorEmploymentType} = useGetSpecificMasterDataQuery('employment-type');
    useEffect(() => {
        if(isSuccessEmploymentType && dataEmploymentType){
            console.log("Employment type master data...");
            console.log(dataEmploymentType);

            setEmploymentTypeList(getListData(dataEmploymentType.data));
        }
        else if(isErrorEmploymentType && errorEmploymentType){
            console.log("Employment type master data error...");
            console.log(errorEmploymentType);

            setEmploymentTypeList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessEmploymentType, dataEmploymentType, isErrorEmploymentType, errorEmploymentType]);

    const [doshamList, setDoshamList] = useState([]);
    const {isLoading: isLoadingDosham, isSuccess: isSuccessDosham, data: dataDosham, isError: isErrorDosham, error: errorDosham} = useGetSpecificMasterDataQuery('dosha');
    useEffect(() => {
        if(isSuccessDosham && dataDosham){
            console.log("Dosham master data...");
            console.log(dataDosham);

            setDoshamList(getListData(dataDosham.data));
        }
        else if(isErrorDosham && errorDosham){
            console.log("Dosham master data error...");
            console.log(errorDosham);

            setDoshamList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessDosham, dataDosham, isErrorDosham, errorDosham]);

    const schema = yup.object().shape({
        age: yup.array(),
        height: yup.array(),
        marital_status: yup.array(),
        mother_tongue: yup.array(),
        religion: yup.array(),
        caste: yup.array(),
        country: yup.array(),

        physical_status: yup.array(),
        body_type: yup.array(),
        education: yup.string(),
        eating_habits: yup.array(),
        drinking_habits: yup.array(),
        smoking_habits: yup.array(),
        employment_type: yup.array(),
        sub_caste: yup.array(),
        dosham: yup.string(),
        annual_income: yup.number().test('isValidIncome', t(`${sign_up_locale}.errors.income_is_invalid`), () => {
            const isValid = !isNaN(formValue.annual_income) && ((formValue.currencyType === '' && formValue.annual_income === 0) || (formValue.currencyType !== '' && formValue.annual_income > 0));
            return isValid;
        }),
        currencyType: yup.string(),
        birthplace: yup.string()
    });

    const getModifiedMultiValues = useCallback((array) => {
        const modifiedArray = [];

        if(array && array.length > 0){
            for(let i = 0; i < array.length; i++){
                modifiedArray.push({id: i, label: array[i], value: array[i]})
            }
        }   

        return modifiedArray;
    }, []);

    useEffect(() => {
        if(filterObj){
            console.log("Filter search object...");
            console.log(filterObj);
        }
    }, [filterObj]);

    const defaultValues = {
        age: filterObj ? [filterObj.minAge, filterObj.maxAge] : [18, 75],
        height: filterObj ? [filterObj.minHeight, filterObj.maxHeight] : [100, 250],
        marital_status: filterObj ? getModifiedMultiValues(filterObj.maritalStatus) : [],
        mother_tongue: filterObj ? getModifiedMultiValues(filterObj.motherTongue) : [],
        religion: filterObj ? getModifiedMultiValues(filterObj.religion) : [],
        caste: filterObj ? getModifiedMultiValues(filterObj.caste) : [],
        country: filterObj ? getModifiedMultiValues(filterObj.countryOfLiving) : [],

        physical_status: filterObj ? getModifiedMultiValues(filterObj.physicalStatus) : [],
        body_type: filterObj ? getModifiedMultiValues(filterObj.bodyType) : [],
        education: filterObj && filterObj.educationLevel !== null ? filterObj.educationLevel : '',
        eating_habits: filterObj ? getModifiedMultiValues(filterObj.eatingHabits) : [],
        drinking_habits: filterObj ? getModifiedMultiValues(filterObj.drinkingHabits) : [],
        smoking_habits: filterObj ? getModifiedMultiValues(filterObj.smokingHabits) : [],
        employment_type: filterObj ? getModifiedMultiValues(filterObj.employmentType) : [],
        sub_caste: filterObj ? getModifiedMultiValues(filterObj.subCaste) : [],
        dosham: filterObj && filterObj.dosha !== null ? filterObj.dosha : '',
        annual_income: filterObj && filterObj.annualIncome !== null ? filterObj.annualIncome : 0,
        currencyType: filterObj && filterObj.currency !== null ? filterObj.currency : '',
        birthplace: filterObj && filterObj.birthCountry !== null ? filterObj.birthCountry : ''
    };

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    const {
        reset: resetForm,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch: watch
    } = methods;

    const formValue = watch();

    const saveSchema = yup.object().shape({
        saveSearchName: yup.string().required(t(`${search_locale}.save_search_name_required`))
    });

    const saveSearchDefaultValues = {
        saveSearchName: ''
    };

    const saveSearchNameMethods = useForm({
        resolver: yupResolver(saveSchema),
        defaultValues: saveSearchDefaultValues
    });

    const {
        reset: resetSaveSearchNameForm,
        handleSubmit: handleSaveSearchNameSubmit,
        formState: { isSubmitting: isSaveSearchNameSubmitting },
        watch: watchSaveSearchName
    } = saveSearchNameMethods;

    const getAccordionSection = (summary, children, expanded, onExpand) => <Accordion 
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={onExpand}
        sx={{
            backgroundColor: 'white',
            borderRadius: '8px',
            my: 0.5,
            '&:before': {
                display: 'none',
            }
        }}
    >
        <AccordionSummary
            expandIcon={
                <DownArrow 
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%'
                    }}
                />
            }
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{
                backgroundColor: '#FBFBFB'
            }}
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: 18,
                    color: '#121212',
                    py: 1
                }}
            >
                {summary}
            </Typography>
        </AccordionSummary>

        <AccordionDetails>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#6D6D6D',
                    py: 1
                }}
            >
                {children}
            </Typography>
        </AccordionDetails>
    </Accordion>

    const getSlider = (name, label, range) => <SliderComponent 
        name={name}
        label={label}
        range={range}
    />

    const getMultiSelection = (name, label, list) => <MultipleSelectionComponent 
        name={name}
        label={label}
        list={list}
    />

    const getBasicSearchFilter = () => <Stack direction='column' spacing={3} justifyContent='center'>
        {getSlider('age', t(`${partner_basic_locale}.age`), [18, 75])}
        {getSlider('height', `${t(`${partner_basic_locale}.height`)}`, [100, 250])}
        {getMultiSelection('marital_status', t(`${partner_basic_locale}.marital_status`), maritalList)}
        {getMultiSelection('mother_tongue', t(`${partner_basic_locale}.mother_tongue`), motherTongueList)}
        {getMultiSelection('religion', t(`${partner_religious_locale}.religion`), religionList)}
        {getMultiSelection('caste', t(`${partner_religious_locale}.caste`), castList)}
        {getMultiSelection('country', t(`${partner_location_locale}.country`), countries)}
    </Stack>

    const getDropDown = (name, label, list) => <SelectionComponent 
        name={name}
        label={label}
        list={list}
    />

    const getCurrencyField = (name, label, currencyType, currencyLabel) => <CurrencyComponent 
        name={name}
        label={label}
        currency_name={currencyType}
        currency_label={currencyLabel}
    />

    const getInputField = (name, label) => <TextFieldComponent 
        name={name}
        label={label}
    />

    const getAdvancedSearchFilter = () => <Stack direction='column' spacing={3} justifyContent='center'>
        {getMultiSelection('physical_status', t(`${partner_basic_locale}.physical_status`), physicalStatusList)}
        {getMultiSelection('body_type', t(`${partner_basic_locale}.body_type`), bodyTypeList)}
        {getDropDown('education', t(`${partner_professional_locale}.education`), educationLevelList)}
        {getMultiSelection('eating_habits', t(`${partner_basic_locale}.eating_habits`), eatingHabitList)}
        {getMultiSelection('drinking_habits', t(`${partner_basic_locale}.drinking_habits`), drinkingHabitList)}
        {getMultiSelection('smoking_habits', t(`${partner_basic_locale}.smoking_habits`), smokingHabitList)}
        {getMultiSelection('employment_type', t(`${partner_professional_locale}.employment_type`), employmentTypeList)}
        {getMultiSelection('sub_caste', t(`${partner_religious_locale}.sub_caste`), subCastList)}
        {getDropDown('dosham', t(`${partner_religious_locale}.dosham`), doshamList)}
        {getCurrencyField('annual_income', t(`${partner_professional_locale}.annual_income`), 'currencyType', t(`${partner_professional_locale}.currency_label`))}
        {getInputField('birthplace', t(`${partner_location_locale}.birth_place`))}
    </Stack>

    const getMultiSelectionValuesArray = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            if(typeof data[i] === 'object'){
                array.push(data[i].value);
            }
            else{
                array.push(data[i]);
            }
        }

        return array;
    }, []);

    const getSearchObject = useCallback((data, name) => {
        return {
            "name": name,
            "minAge": data.age[0],
            "maxAge": data.age[1],
            "minHeight": data.height[0],
            "maxHeight": data.height[1],
            "maritalStatus": getMultiSelectionValuesArray(data.marital_status),
            "motherTongue": getMultiSelectionValuesArray(data.mother_tongue),
            "religion": getMultiSelectionValuesArray(data.religion),
            "caste": getMultiSelectionValuesArray(data.caste),
            "countryOfLiving": getMultiSelectionValuesArray(data.country),
            "physicalStatus": getMultiSelectionValuesArray(data.physical_status),
            "bodyType": getMultiSelectionValuesArray(data.body_type),
            "educationLevel": data.education,
            "eatingHabits": getMultiSelectionValuesArray(data.eating_habits),
            "drinkingHabits": getMultiSelectionValuesArray(data.drinking_habits),
            "smokingHabits": getMultiSelectionValuesArray(data.smoking_habits),
            "subCaste": getMultiSelectionValuesArray(data.sub_caste),
            "dosha": data.dosham,
            "currency": data.currencyType,
            "annualIncome": data.annual_income,
            "employmentType": getMultiSelectionValuesArray(data.employment_type),
            "birthCountry": data.birthplace
        }
    }, []);

    const [
        saveSearchAPI,
        {
            isLoading,
            isSuccess, 
            isError, 
            error
        }
    ] = useSaveSearchMutation();

    useEffect(() => {
        if(isSuccess){
            setShowSnackBar({
                state: true,
                message: t(`${search_locale}.search_saved`),
                color: "green"
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if(isError && error){
            console.log(error);

            setShowSnackBar({
                state: true,
                message: `${error.data.error.message}`,
                color: "red"
            });
        }
    }, [isError, error]);

    const modifySavingObj = useCallback((savedObj) => {
        const obj = {};
        
        for (var key in savedObj) {
            if (savedObj.hasOwnProperty(key)) {
                if(savedObj[key].constructor === Array && savedObj[key].length === 0){
                    obj[key] = null;
                }
                else{
                    if(!isNaN(savedObj[key]) && savedObj[key] === 0){
                        obj[key] = null;
                    }
                    else{
                        obj[key] = savedObj[key] === "" ? null : savedObj[key];
                    }
                }
            }
        }
        
        return obj;
    }, []);

    const confirmSaveSearch = handleSaveSearchNameSubmit(async(data) => {
        console.log(data);

        try{
            setOpenDialog({open: false});

            const obj = getSearchObject(formValue, data.saveSearchName);
            console.log(obj);

            const modifiedSavingObj = modifySavingObj(obj);
            console.log(modifiedSavingObj);

            await saveSearchAPI(modifiedSavingObj);
        }
        catch(error){
            console.log(error);
        }
    });


    const [
        updateSearchFilterAPI,
        {
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate, 
            isError: isErrorUpdate, 
            error: errorUpdate
        }
    ] = useUpdateSearchFilterMutation();

    useEffect(() => {
        if(isSuccessUpdate){
            setShowSnackBar({
                state: true,
                message: t(`${search_locale}.edit_search_filter`),
                color: "green"
            });

            if(closeAfterEdit) onClose();
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if(isErrorUpdate && errorUpdate){
            console.log(errorUpdate);

            setShowSnackBar({
                state: true,
                message: `${errorUpdate.data.error.message}`,
                color: "red"
            });
        }
    }, [isErrorUpdate, errorUpdate]);

    const saveSearch = useCallback(async () => {
        // Edit existing filter
        if(filterObj && filterObj.id){
            const obj = getSearchObject(formValue, filterObj.name);
            console.log(obj);

            const modifiedSavingObj = modifySavingObj(obj);
            console.log(modifiedSavingObj);

            await updateSearchFilterAPI({id: filterObj.id, body: modifiedSavingObj});
        }
        // Save a new filter
        else{
            resetSaveSearchNameForm();

            setOpenDialog({
                title: t(`${search_locale}.save_search`),
                open: true,
                close: () => setOpenDialog(false),
                successCallback: confirmSaveSearch
            });
        }
    }, [formValue]);

    const onSubmit = handleSubmit(async(data) => {
        console.log(data);

        try{
            const obj = getSearchObject(data, "");
            console.log(obj);

            const modifiedSavingObj = modifySavingObj(obj);
            console.log(modifiedSavingObj);

            resetForm();

            onSearch(modifiedSavingObj);
        }
        catch(error){
            console.log(error);
        }
    });

    return(
        <>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{width: '100%', mb: 2}}
            >
                <Typography
                    fontWeight={600}
                    fontSize={{xs: 12, md: 16}}
                    color='#6D6D6D'
                >
                    {t(`${search_locale}.search_results_by_below_criteria`)}
                </Typography>

                {
                    needSaveSearch
                    ?
                    <LoadingButtonComponent 
                        text={t(`${search_locale}.save_search`)}
                        onClick={saveSearch}
                        inLoading={isLoading || isLoadingUpdate}
                        width={127}
                        sx={{
                            backgroundColor: '#FB39571A',
                            color: theme.palette.primary.main,
                            boxShadow: 'none',
                            "&:hover": {
                                backgroundColor: '#FB39571A'
                            }
                        }}
                        textSx={{
                            textTransform: 'capitalize'
                        }}
                    />
                    :
                    <></>
                }
            </Stack>

            <Stack sx={{width: '100%'}}>
                <FormProviderComponent methods={methods} onSubmit={onSubmit}>
                    {
                        getAccordionSection(
                            t(`${profile_search_locale}.basic_search_filter.summary`), 
                            getBasicSearchFilter(), 
                            basicFilterOpen,
                            () => setBasicFilterOpen((expand) => !expand)
                        )
                    }

                    {
                        getAccordionSection(
                            t(`${profile_search_locale}.basic_search_filter.advanced_search_filter`), 
                            getAdvancedSearchFilter(), 
                            advanceFilterOpen,
                            () => setAdvanceFilterOpen((expand) => !expand)
                        )
                    }

                    {
                        !needSearch
                        ?
                        <></>
                        :
                        <>
                            <Divider style={{width: '100%'}}/>

                            <Grid
                                xs
                                container
                                direction={{xs: 'column-reverse', md: 'row'}}
                                justifyContent={{xs: 'center', md: 'center'}}
                                alignItems={{xs: 'center', md: 'center'}}
                                sx={{mt: 3}}
                            >
                                <Stack 
                                    spacing={2}
                                    direction={{xs: 'column-reverse', md: 'row'}}
                                    justifyContent={{xs: 'center', md: 'center'}}
                                    alignItems={{xs: 'center', md: 'center'}}
                                    sx={{width: '100%'}}
                                >
                                    <Grid xs={12} md={6} sx={{width: '100%'}}>
                                        <ButtonComponent 
                                            text={t(`${search_locale}.reset_fields`)}
                                            onClick={() => resetForm()}
                                            variant='text'
                                            textSx={{
                                                textTransform: 'capitalize'
                                            }}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={6} sx={{width: '100%'}}>
                                        <LoadingButtonComponent 
                                            text={t(`${search_locale}.search_results`)}
                                            inLoading={false}
                                            type='submit'
                                            textSx={{
                                                textTransform: 'capitalize'
                                            }}
                                        />
                                    </Grid>
                                </Stack>
                            </Grid>
                        </>
                    }
                </FormProviderComponent>
            </Stack>

            <DialogBox 
                title={openDialog.title}
                children={
                    <>
                        <FormProviderComponent methods={saveSearchNameMethods} onSubmit={confirmSaveSearch}>
                            {getInputField('saveSearchName', t(`${search_locale}.save_search_name`))}

                            <Grid xs container>
                                <Stack direction='row' spacing={2} width='100%'>
                                    <Grid xs={12} md={6} sx={{width: '100%'}}>
                                        <ButtonComponent 
                                            text={t(`${search_locale}.save_search_cancel`)}
                                            onClick={() => openDialog.close()}
                                            variant='outlined'
                                            textSx={{
                                                textTransform: 'capitalize'
                                            }}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={6} sx={{width: '100%'}}>
                                        <ButtonComponent 
                                            text={t(`${search_locale}.search_results`)}
                                            type='submit'
                                            textSx={{
                                                textTransform: 'capitalize'
                                            }}
                                        />
                                    </Grid>
                                </Stack>
                            </Grid>
                        </FormProviderComponent>
                    </>
                }
                open={openDialog.open} 
                onClose={() => openDialog.close()}
                customFooter={<></>}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            /> 
        </>
    );
}

export default ProfileSearchEditFilter;