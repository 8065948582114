import { Helmet } from "react-helmet";
import LoginLayout from "../../sections/auth/login/login-layout";
import { useAnalytics } from "../../contexts/analytics/analytics-provider";
import { useCallback, useEffect } from "react";

const LoginPage = () => {
    const {logCustomEvent} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    useEffect(() => {
        logAnalytics('VISIT_LOGIN_PAGE');
    }, []);

    return(
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>

            <LoginLayout />
        </>
    );
}

export default LoginPage;