import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import PartnerAboutPreferenceBody from "./partner-about-preference-body";

import Footer from '../../footer/footer';

const PartnerAboutPreferenceLayout = () => {
    const { t } = useTranslation();
    const partner_about_locale = "partner_preference.about";

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${partner_about_locale}.heading`)}
                caption={t(`${partner_about_locale}.caption`)}
            />

            <PartnerAboutPreferenceBody />

            <Footer />
        </>
    );
}

export default PartnerAboutPreferenceLayout;