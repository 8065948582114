import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ProfileEditHeader from "../profile-edit-header";
import ProfileEditBody from "../profile-edit-body";

import { useTranslation } from "react-i18next";

import { useState, useEffect, useCallback } from 'react';

import HoroscopeGrid from '../../../components/custom-horoscope/horoscope-grid';
import SkeletonComponent from '../../../components/loading/skeleton-component';
import LoadingButtonComponent from '../../../components/form/loading-button-component';
import { Box, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ButtonComponent from '../../../components/form/button-component';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useUpdateProfileMutation } from '../../../services/profile-api';
import ProfileValueText from '../profile-value-text';

const MyHoroscopeDetails = ({
    privateView, 
    responseData, 
    updateData,
    isLoading, 
    editCallback
}) => {
    const { t } = useTranslation();
    const profile_locale = "profile.horoscope";
    const signup_locale = "authentication.sign_up.step3.form";

    const kattamMappingValue = 'horoscopes';
    const nawamsamMappingValue = 'nawamsam';

    const [horoscopeDetails, setHoroscopeDetails] = useState([]);
    const [nawamsamDetails, setNawamsamDetails] = useState([]);
    const [horoscopeError, setHoroscopeError] = useState('');
    const [nawamsamError, setNawamsamError] = useState('');

    const [horoscopeType, setHoroscopeType] = useState('kattam');

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const formSchema = yup.object().shape({
        horoscope: yup.array().test('isHoroscopeValid', t(`${profile_locale}.errors.invalid_horoscope`), () => {
            const status = isValidateHoroscope();

            if(!status){
                setHoroscopeError(t(`${profile_locale}.errors.invalid_horoscope`));
            }
            else{
                setHoroscopeError('');
            }

            return status;
        }),
        nawamsam: yup.array().test('isNawamsamValid', t(`${profile_locale}.errors.invalid_horoscope`), () => {
            const status = isValidateNawamsam();

            if(!status){
                setNawamsamError(t(`${profile_locale}.errors.invalid_horoscope`));
            }
            else{
                setNawamsamError('');
            }

            return status;
        }),
    });

    const defaultValues = {
        horoscope: [],
        nawamsam: []
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: resetForm,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch: watch
    } = formMethods;

    useEffect(() => {
        if(responseData){
            setHoroscopeDetails(responseData.horoscopes);
            setNawamsamDetails(responseData.nawamsam);
        }
    }, [responseData]);

    const isValidateHoroscope = useCallback(() => {
        console.log(horoscopeDetails);

        if(!isKattamSelected()){
            return true;
        }

        let planetsCount = 0;

        for(let i = 0; i < horoscopeDetails.length; i++){
            planetsCount += horoscopeDetails[i].planets.length;
        }

        return planetsCount === 9;
    }, [horoscopeDetails, horoscopeType]);

    const isValidateNawamsam = useCallback(() => {
        console.log(nawamsamDetails);

        if(isKattamSelected()){
            return true;
        }

        let planetsCount = 0;

        for(let i = 0; i < nawamsamDetails.length; i++){
            planetsCount += nawamsamDetails[i].planets.length;
        }

        return planetsCount === 9;
    }, [nawamsamDetails, horoscopeType]);

    const modifyRequestObj = useCallback((kattamValue, nawamsamValue) => {
        const obj = {};
        
        for (var key in updateData) {
            if (updateData.hasOwnProperty(key)) {
                if(key === kattamMappingValue){
                    obj[key] = kattamValue;
                }
                else if(key === nawamsamMappingValue){
                    obj[key] = nawamsamValue;
                }
                else{
                    obj[key] = updateData[key];
                }
            }
        }
        
        return obj;
    }, [updateData, kattamMappingValue, nawamsamMappingValue]);

    const [
        updateProfile, 
        {
          isLoading: isLoadingUpdateProfile,
          isSuccess: isSuccessUpdateProfile,
          isError: isErrorUpdateProfile,
          error: errorUpdateProfile
        }
    ] = useUpdateProfileMutation();

    useEffect(() => {
        if (isSuccessUpdateProfile){
            editCallback();
        }
        else if (isErrorUpdateProfile && errorUpdateProfile) {
            setShowSnackBar({
                state: true,
                message: `${errorUpdateProfile.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessUpdateProfile, isErrorUpdateProfile, errorUpdateProfile, editCallback]);

    const onSubmit = handleSubmit(async () => {
        setHoroscopeError('');
        setNawamsamError('');

        const obj = modifyRequestObj(horoscopeDetails, nawamsamDetails);
        console.log(obj);

        await updateProfile(obj);
    });

    const setHoroscopeList = useCallback((lagnaID, array) => {
        const horoscopeArray = [];

        for(let i = 0; i < array.length; i++){
            const planets = [];

            for(let j = 0; j < array[i].length; j++){
                planets.push(array[i][j].value);
            }

            let id = i >= lagnaID ? i - lagnaID + 1 : 12 - lagnaID + 1 + i;

            horoscopeArray.push({id: id, planets: planets});
        }

        console.log(horoscopeArray);

        setHoroscopeDetails(horoscopeArray);
    }, []);

    const setNawamsamList = useCallback((lagnaID, array) => {
        const nawamsamArray = [];

        for(let i = 0; i < array.length; i++){
            const planets = [];

            for(let j = 0; j < array[i].length; j++){
                planets.push(array[i][j].value);
            }

            let id = i >= lagnaID ? i - lagnaID + 1 : 12 - lagnaID + 1 + i;

            nawamsamArray.push({id: id, planets: planets});
        }

        console.log(nawamsamArray);

        setNawamsamDetails(nawamsamArray);
    }, []);

    const clearHoroscopeList = useCallback(() => {
        setHoroscopeError('');
        setHoroscopeDetails([]);
    }, []);

    const clearNawamsamList = useCallback(() => {
        setNawamsamError('');
        setNawamsamDetails([]);
    }, []);

    const handleChangeHoroscope = useCallback((event, newAlignment) => {
        setHoroscopeError('');
        setNawamsamError('');

        setHoroscopeType(newAlignment);
    }, []);

    const isKattamSelected = useCallback(() => {
        return horoscopeType === 'kattam';
    }, [horoscopeType]);

    return (
        <>
            <ProfileEditHeader 
                heading={t(`${profile_locale}.my_horoscope`)} 
                privateView={privateView}
            />

            {
                <ProfileEditBody 
                    dataList={[]} 
                    children={
                        isLoading
                        ?
                        <Box width={privateView ? '100%' : '80%'} >
                            <SkeletonComponent height='500px'/>
                        </Box>
                        :
                        <>
                            <ToggleButtonGroup
                                color="primary"
                                value={horoscopeType}
                                exclusive
                                onChange={handleChangeHoroscope}
                                aria-label="Platform"
                            >
                                <ToggleButton value="kattam">{t(`${profile_locale}.kattam`)}</ToggleButton>
                                <ToggleButton value="nawamsam">{t(`${profile_locale}.nawamsam`)}</ToggleButton>
                            </ToggleButtonGroup>

                            {
                                isKattamSelected()
                                ?
                                <>
                                    {
                                        privateView || horoscopeDetails.length > 0
                                        ?
                                        <>
                                            <HoroscopeGrid 
                                                view={!privateView} 
                                                horoscopeInfo={horoscopeDetails} 
                                                width={privateView ? '100%' : '80%'}
                                                setHoroscopeList={setHoroscopeList} 
                                                clearHoroscopeList={clearHoroscopeList}
                                                title={t(`${signup_locale}.horoscope_title`)}
                                            />

                                            <p style={{paddingLeft: '5px', color: 'red'}}>{horoscopeError}</p>
                                            
                                            <Stack 
                                                display='flex' 
                                                direction={{xs: 'column', md: 'row'}}
                                                justifyContent={{xs: 'center', md: 'end'}}
                                                sx={{
                                                    width: '100%',
                                                    display: privateView ? 'flex' : 'none'
                                                }}
                                                spacing={2}
                                            >
                                                <LoadingButtonComponent 
                                                    text={t('profile.save')}
                                                    inLoading={isLoadingUpdateProfile}
                                                    sx={{
                                                        width: 120,
                                                        mt: 1,
                                                        backgroundColor: 'black'
                                                    }}
                                                    onClick={onSubmit}
                                                />
                                            </Stack>
                                        </>
                                        :
                                        <ProfileValueText />
                                    }
                                </>
                                :
                                <>
                                    {
                                        privateView || nawamsamDetails.length > 0
                                        ?
                                        <>
                                            <div></div>

                                            <HoroscopeGrid 
                                                view={!privateView} 
                                                horoscopeInfo={nawamsamDetails} 
                                                width={privateView ? '100%' : '80%'}
                                                setHoroscopeList={setNawamsamList} 
                                                clearHoroscopeList={clearNawamsamList}
                                                title={t(`${signup_locale}.nawamsam_title`)}
                                            />

                                            <p style={{paddingLeft: '5px', color: 'red'}}>{nawamsamError}</p>
                                            
                                            <Stack 
                                                display='flex' 
                                                direction={{xs: 'column', md: 'row'}}
                                                justifyContent={{xs: 'center', md: 'end'}}
                                                sx={{
                                                    width: '100%',
                                                    display: privateView ? 'flex' : 'none'
                                                }}
                                                spacing={2}
                                            >
                                                <LoadingButtonComponent 
                                                    text={t('profile.save')}
                                                    inLoading={isLoadingUpdateProfile}
                                                    sx={{
                                                        width: 120,
                                                        mt: 1,
                                                        backgroundColor: 'black'
                                                    }}
                                                    onClick={onSubmit}
                                                />
                                            </Stack>
                                        </>
                                        :
                                        <ProfileValueText />
                                    }
                                </>
                            }
                        </>
                    } 
                    privateView={privateView}
                />
            }
            
            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default MyHoroscopeDetails;

MyHoroscopeDetails.propType = {
    privateView: PropTypes.bool,
    responseData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func
}