import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import ButtonComponent from "../../../components/form/button-component";
import EditIcon from '../../../assets/icons/edit-icon';
import { useCallback, useEffect, useState } from "react";
import PaginationComponent from "../../../components/pagination/pagination-component";
import ProfileSearchBody from "./profile-search-body";
import DialogBox from "../../../components/Dialog/dialog-box";
import ProfileSearchEditFilter from "./profile-search-edit-filter";
import BottomNavigationBar from "../../navigation/bottom-navigation-bar";

import Footer from '../../footer/footer';
import ProfileSearchLandingSection from "./profile-search-landing-section";
import { useSearchMutation } from "../../../services/search-api";
import APIErrorLayout from "../../error/api-error/api-error-layout";
import { useNavigate } from "react-router-dom";

const ProfileSearchLayout = () => {
    const { t } = useTranslation();
    const search_locale = "profile_feed.search";

    const [searchData, setSearchData] = useState([]);

    const [searchResultsCount, setSearchResultsCount] = useState(0);

    const [selectedPage, setSelectedPage] = useState(1);
    const [searchRequest, setSearchRequest] = useState(null);
    const [searchLandingPage, setSearchLandingPage] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);

    const navigate = useNavigate();


    const onEditSuccess = useCallback((newObj) => {
        setOpenDialog(false);

        setSelectedPage(1);
        setSearchRequest(newObj);
    }, []);

    const openEditSearchFilter = useCallback(() => {
        setOpenDialog(true);
    }, []);


    const setSearchResults = useCallback((dataObj) => {
        setSearchRequest(dataObj);
        setSearchLandingPage(false);
    }, []);

    const changePage = useCallback((event, page) => {
        setSelectedPage(page);
    }, []);


    const backToSearchFilterView = useCallback(() => {
        setSearchLandingPage(true);

        setSearchRequest(null);
        setSelectedPage(1);
    }, []);


    const [
        getSearchResults,
        {
            isLoading,
            isSuccess,
            data,
            isError,
            error
        }
    ] = useSearchMutation();

    const callSearchResultsAPI = useCallback(async () => {
        console.log("Search request body...");
        console.log(searchRequest);
        console.log(selectedPage - 1);

        await getSearchResults({filterRequest: searchRequest, page: selectedPage - 1});
    }, [searchRequest, selectedPage]);

    useEffect(() => {
        if(searchRequest){
            callSearchResultsAPI();
        }
    }, [searchRequest, selectedPage]);

    useEffect(() => {
        if(isSuccess && data){
            console.log("Search data...");
            console.log(data);

            setSearchData(data.data.content);
            setSearchResultsCount(data.data.page.totalElements)
        }
    }, [isSuccess, data]);

    let body;

    if(isLoading){
        body = <ProfileSearchBody isLoading={true}/>
    }
    else if(isError && error){
        console.log(error);
        body = <APIErrorLayout error={error} />
    }
    else if(isSuccess){
        body = <ProfileSearchBody 
            data={searchData}
            successCallback={() => {
                callSearchResultsAPI();
            }}
        />  
    }

    return(
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={searchLandingPage ? t(`${search_locale}.heading_landing`) : t(`${search_locale}.heading`)} 
                caption={searchLandingPage ? null : searchResultsCount > 0 ? `${searchResultsCount} ${t(`${search_locale}.caption`)}` : ''}
                callback={searchLandingPage ? () => navigate(-1) : () => backToSearchFilterView()}
                needCustomCallback
                endIcon={
                    !searchLandingPage
                    ?
                    <ButtonComponent 
                        text={t(`${search_locale}.heading_action`)} 
                        onClick={openEditSearchFilter}
                        width={187}
                        startIcon={<EditIcon style={{color: '#000000'}}/>}
                        sx={{
                            backgroundColor: 'white',
                            "&: hover": {
                                backgroundColor: 'white'
                            }
                        }}
                        textSx={{
                            color: '#000000',
                            textTransform: 'capitalize'
                        }}
                    />
                    :
                    null
                }
            />

            {
                searchLandingPage
                ?
                <ProfileSearchLandingSection onSearch={setSearchResults}/>
                :
                <>
                    {body}

                    {
                        !isError && searchData.length > 0
                        ?
                        <PaginationComponent 
                            totalPageCount={data?.data.page.totalPages} 
                            onChange={changePage}
                            page={selectedPage}
                        />
                        :
                        <></>
                    }
                </>
            }

            <BottomNavigationBar selectedValue={1}/>

            <DialogBox 
                title={t(`${search_locale}.edit_search_filter.heading`)}
                children={
                    <ProfileSearchEditFilter 
                        filterObj={searchRequest} 
                        onClose={() => setOpenDialog(false)} 
                        onSearch={onEditSuccess}
                        needSaveSearch
                        closeAfterEdit={false}
                    />
                }
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
                customFooter={true}
            />

            <Footer />
        </>
    );
}

export default ProfileSearchLayout;