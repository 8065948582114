import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import PartnerBasicPreferenceBody from "./partner-basic-preference-body";

import Footer from '../../footer/footer';

const PartnerBasicPreferenceLayout = () => {
    const { t } = useTranslation();
    const partner_basic_locale = "partner_preference.basic";

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${partner_basic_locale}.heading`)}
                caption={t(`${partner_basic_locale}.caption`)}
            />

            <PartnerBasicPreferenceBody />

            <Footer />
        </>
    );
}

export default PartnerBasicPreferenceLayout;