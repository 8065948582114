import { useEffect, useState } from "react";
import ChatInterestOverviewList from "./chat-interest-overview-list";
import ChatMessageHeader from "./chat-message-header";
import ChatRequestAccept from "./chat-request-accept";
import { Box, Stack } from "@mui/material";
import { useChat } from "../../contexts/chat/chat-provider";

const ChatInterestDetailView = () => {
    const [data, setData] = useState(null);

    const useChatContext = useChat();

    useEffect(() => {
        if(!data){
            const userData = useChatContext.activeInterestRequestSentUser;

            useChatContext.setChatBoxBarProperties(
                userData.firstName, 
                userData.memberId, 
                userData.profileImages > 0 ? userData.profileImages[0] : '', 
                () => {useChatContext.setChatBoxBody(<ChatInterestOverviewList />)}, 
                userData.memberId
            );

            setData(userData);
        }
    }, [useChatContext, data]);

    return (
        <>
            <Stack sx={{height: {xs: '100%', md: '60vh'}, overflow: 'scroll', px: 2}} className='hideScroll'>
                {
                    data
                    ?
                    <>
                        <ChatMessageHeader data={data}/>

                        <Box sx={{height: 100}}/>

                        <ChatRequestAccept data={data}/>
                    </>
                    :
                    <></>
                }
            </Stack>
        </>
    );
}

export default ChatInterestDetailView;