import { configureStore, createSlice } from "@reduxjs/toolkit";
import authAPI from "../services/auth-api";
import chatAPI from "../services/chat-api";
import masterDataAPI from "../services/master-data-api";
import verifyEmailAPI from "../services/verify-email-api";
import uploadAPI from "../services/upload-api";
import notificationAPI from "../services/notification-api";
import profileAPI from "../services/profile-api";
import verificationAPI from "../services/verification-api";
import matchesAPI from "../services/matches-api";
import shortlistedAPI from "../services/shortlisted-api";
import blockAPI from "../services/block-api";
import interestAPI from "../services/interest-api";
import searchAPI from "../services/search-api";
import partnerPreferenceAPI from "../services/partner-preference-api";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    chat_box_heading: "",
    chat_box_sub_heading: "",
    chat_box_heading_avatar: "",
    chat_box_back_callback: null,
    chat_box_body: null,
    chat_mobile_box_body: null,
    chat_details: null,
    chat_direct_person_uuid: "",
    chat_direct_details: null
  },
  reducers: {
    setChatBoxHeading: (state, action) => {
      state.chat_box_heading = action.payload;
    },
    setChatBoxSubHeading: (state, action) => {
      state.chat_box_sub_heading = action.payload;
    },
    setChatBoxHeadingAvatar: (state, action) => {
      state.chat_box_heading_avatar = action.payload;
    },
    setChatBoxBackCallback: (state, action) => {
      state.chat_box_back_callback = action.payload;
    },
    setChatBoxBody: (state, action) => {
      state.chat_box_body = action.payload;
    },
    setMobileChatBoxBody: (state, action) => {
      state.chat_mobile_box_body = action.payload;
    },
    setChatBoxInterestDetail: (state, action) => {
      state.chat_details = action.payload;
    },
    setChatDirectPersonUUID: (state, action) => {
      state.chat_direct_person_uuid = action.payload;
    },
    setChatDirectDetail: (state, action) => {
      state.chat_direct_details = action.payload;
    }
  }
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [chatAPI.reducerPath]: chatAPI.reducer,
    [masterDataAPI.reducerPath]: masterDataAPI.reducer,
    [verifyEmailAPI.reducerPath]: verifyEmailAPI.reducer,
    [uploadAPI.reducerPath]: uploadAPI.reducer,
    [notificationAPI.reducerPath]: notificationAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [verificationAPI.reducerPath]: verificationAPI.reducer,
    [matchesAPI.reducerPath]: matchesAPI.reducer,
    [shortlistedAPI.reducerPath]: shortlistedAPI.reducer,
    [blockAPI.reducerPath]: blockAPI.reducer,
    [interestAPI.reducerPath]: interestAPI.reducer,
    [searchAPI.reducerPath]: searchAPI.reducer,
    [partnerPreferenceAPI.reducerPath]: partnerPreferenceAPI.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
    .concat(authAPI.middleware)
    .concat(chatAPI.middleware)
    .concat(masterDataAPI.middleware)
    .concat(verifyEmailAPI.middleware)
    .concat(uploadAPI.middleware)
    .concat(notificationAPI.middleware)
    .concat(profileAPI.middleware)
    .concat(verificationAPI.middleware)
    .concat(matchesAPI.middleware)
    .concat(shortlistedAPI.middleware)
    .concat(blockAPI.middleware)
    .concat(interestAPI.middleware)
    .concat(searchAPI.middleware)
    .concat(partnerPreferenceAPI.middleware)
});

export const homeActions = homeSlice.actions;

export default store;