import AboutUsBody from "./about-us-body";
import AboutUsHeader from "./about-us-header";
import Footer from '../footer/footer';
import NavigationBar from "../navigation/navigation-bar";

const AboutUsLayout = () =>{
    return (
        <>
            <AboutUsHeader />

            <AboutUsBody />

            <Footer />
        </>
    );
}

export default AboutUsLayout;