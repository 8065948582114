import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import PartnerInfoBoxView from "../partner-info-box-view";
import { Stack } from "@mui/material";
import { useCallback, useEffect } from "react";

import { useState } from "react";
import SliderComponent from "../../../components/form/slider-component";
import MultipleSelectionComponent from "../../../components/form/multiple-selection-component";

import { useGetOnCallPartnerPreferenceDetailsMutation } from '../../../services/partner-preference-api';

import {
    physicalStatusList,
    bodyTypeList,
    eatingHabitList,
    drinkingHabitList,
    smokingHabitList
} from '../../../assets/data/profile';

import { useGetSpecificMasterDataQuery } from '../../../services/master-data-api';
import APIErrorLayout from '../../error/api-error/api-error-layout';
import FormProviderComponent from "../../../components/form/form-provider-component";
 
const PartnerBasicPreferenceBody = () => {
    const { t } = useTranslation();
    const partner_basic_locale = "partner_preference.basic";

    const edit_title = t(`${partner_basic_locale}.edit_title`);

    const partnerAgeRange = [18, 75];
    const partnerHeightRange = [100, 250];

    const formSchema = yup.object().shape({
        age: yup.array(),
        height: yup.array(),
        marital_status: yup.array(),
        mother_tongue: yup.array(),
        body_type: yup.array(),
        physical_status: yup.array(),
        eating_habits: yup.array(),
        drinking_habits: yup.array(),
        smoking_habit: yup.array()
    });

    const defaultValues = {
        age: partnerAgeRange,
        height: partnerHeightRange,
        marital_status: [],
        mother_tongue: [],
        body_type: [],
        physical_status: [],
        eating_habits: [],
        drinking_habits: [],
        smoking_habits: []
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    const formValue = watch();

    const [partnerData, setPartnerData] = useState({});

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [motherTongueList, setMotherTongueList] = useState([]);
    const {isLoading: isLoadingMotherTongue, isSuccess: isSuccessMotherTongue, data: dataMotherTongue, isError: isErrorMotherTongue, error: errorMotherTongue} = useGetSpecificMasterDataQuery('mother-tongue');
    useEffect(() => {
        if(isSuccessMotherTongue && dataMotherTongue){
            console.log("Mother tongue master data...");
            console.log(dataMotherTongue);

            setMotherTongueList(getListData(dataMotherTongue.data));
        }
        else if(isErrorMotherTongue && errorMotherTongue){
            console.log("Mother tongue master data error...");
            console.log(errorMotherTongue);

            setMotherTongueList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessMotherTongue, dataMotherTongue, isErrorMotherTongue, errorMotherTongue]);

    const [maritalList, setMaritalList] = useState([]);
    const {isLoading: isLoadingMaritalStatus, isSuccess: isSuccessMaritalStatus, data: dataMaritalStatus, isError: isErrorMaritalStatus, error: errorMaritalStatus} = useGetSpecificMasterDataQuery('marital-status');
    useEffect(() => {
        if(isSuccessMaritalStatus && dataMaritalStatus){
            console.log("Marital status master data...");
            console.log(dataMaritalStatus);

            setMaritalList(getListData(dataMaritalStatus.data));
        }
        else if(isErrorMaritalStatus && errorMaritalStatus){
            console.log("Marital status master data error...");
            console.log(errorMaritalStatus);

            setMaritalList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessMaritalStatus, dataMaritalStatus, isErrorMaritalStatus, errorMaritalStatus]);

    const [
        getPartnerPreferenceDetails,
        {
            isLoading: isLoadingPartnerPreference, 
            isSuccess: isSuccessPartnerPreference, 
            data: dataPartnerPreference, 
            isError: isErrorPartnerPreference, 
            error: errorPartnerPreference
        }
    ]= useGetOnCallPartnerPreferenceDetailsMutation();

    const getMultiValueAssignedValues = useCallback((valuesArray) => {
        const newArray = [];

        for(let i = 0; i < valuesArray?.length; i++){
            newArray.push({label: valuesArray[i], value: valuesArray[i]});
        }
        
        return newArray;
    }, []);

    const setDefaultValues = useCallback((data) => {
        setFormValue("age", data.minAge === 0 ? partnerAgeRange : [data.minAge, data.maxAge]);
        setFormValue("height", data.minHeight === 0 ? partnerHeightRange : [data.minHeight, data.maxHeight]);
        setFormValue("marital_status", getMultiValueAssignedValues(data.maritalStatus));
        setFormValue("mother_tongue", getMultiValueAssignedValues(data.motherTongue));
        setFormValue("body_type", getMultiValueAssignedValues(data.bodyType));
        setFormValue("physical_status", getMultiValueAssignedValues(data.physicalStatus));
        setFormValue("eating_habits", getMultiValueAssignedValues(data.eatingHabits));
        setFormValue("drinking_habits", getMultiValueAssignedValues(data.drinksHabits));
        setFormValue("smoking_habits", getMultiValueAssignedValues(data.smokingHabits));
    }, []);

    useEffect(() => {
        if(isSuccessPartnerPreference && dataPartnerPreference){
            console.log("Partner preference data...");
            console.log(dataPartnerPreference);

            setPartnerData(dataPartnerPreference.data);
            setDefaultValues(dataPartnerPreference.data);
        }
        else if(isErrorPartnerPreference && errorPartnerPreference){
            console.log("Partner preference data error...");
            console.log(errorPartnerPreference);
        }
    }, [isSuccessPartnerPreference, dataPartnerPreference, isErrorPartnerPreference, errorPartnerPreference]);

    const callPartnerPreferenceDetails = useCallback(async () => {
        await getPartnerPreferenceDetails();
    }, []);

    useEffect(() => {
        callPartnerPreferenceDetails();
    }, []);

    const editCallback = useCallback((edited) => {
        if(edited){
            callPartnerPreferenceDetails();
        }
        else{
            setDefaultValues(partnerData);
        }
    }, [partnerData]);

    return (
        <>
            {
                isErrorPartnerPreference && errorPartnerPreference
                ?
                <APIErrorLayout error={errorPartnerPreference} />
                :
                <Stack
                    direction='column'
                    sx={{
                        width: {xs: '100%', lg: '60%'},
                        my: 5
                    }}
                >
                    <FormProviderComponent methods={formMethods} onSubmit={() => {}}>
                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.age`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <Stack px={1}>
                                    <SliderComponent 
                                        name='age'
                                        label={t(`${partner_basic_locale}.age`)}
                                        range={partnerAgeRange}
                                    />
                                </Stack>
                            }
                            editedValue={formValue.age}
                            modifiedAttribute={["minAge", "maxAge"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.height`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <Stack px={1}>
                                    <SliderComponent 
                                        name='height'
                                        label={t(`${partner_basic_locale}.height`)}
                                        range={partnerHeightRange}
                                    />
                                </Stack>
                            }
                            editedValue={formValue.height}
                            modifiedAttribute={["minHeight", "maxHeight"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.marital_status`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='marital_status'
                                    label={t(`${partner_basic_locale}.marital_status`)}
                                    list={maritalList}
                                />
                            }
                            editedValue={formValue.marital_status}
                            modifiedAttribute={["maritalStatus"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.mother_tongue`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='mother_tongue'
                                    label={t(`${partner_basic_locale}.mother_tongue`)}
                                    list={motherTongueList}
                                />
                            }
                            editedValue={formValue.mother_tongue}
                            modifiedAttribute={["motherTongue"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.body_type`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='body_type'
                                    label={t(`${partner_basic_locale}.body_type`)}
                                    list={bodyTypeList}
                                />
                            }
                            editedValue={formValue.body_type}
                            modifiedAttribute={["bodyType"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.physical_status`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='physical_status'
                                    label={t(`${partner_basic_locale}.physical_status`)}
                                    list={physicalStatusList}
                                />
                            }
                            editedValue={formValue.physical_status}
                            modifiedAttribute={["physicalStatus"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.eating_habits`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='eating_habits'
                                    label={t(`${partner_basic_locale}.eating_habits`)}
                                    list={eatingHabitList}
                                />
                            }
                            editedValue={formValue.eating_habits}
                            modifiedAttribute={["eatingHabits"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.drinking_habits`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='drinking_habits'
                                    label={t(`${partner_basic_locale}.drinking_habits`)}
                                    list={drinkingHabitList}
                                />
                            }
                            editedValue={formValue.drinking_habits}
                            modifiedAttribute={["drinksHabits"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />

                        <PartnerInfoBoxView 
                            label={t(`${partner_basic_locale}.smoking_habits`)}
                            editTitle={edit_title}
                            editCallback={editCallback}
                            editChildren={
                                <MultipleSelectionComponent 
                                    name='smoking_habits'
                                    label={t(`${partner_basic_locale}.smoking_habits`)}
                                    list={smokingHabitList}
                                />
                            }
                            editedValue={formValue.smoking_habits}
                            modifiedAttribute={["smokingHabits"]}
                            requestObj={partnerData}
                            isLoading={isLoadingPartnerPreference}
                        />
                    </FormProviderComponent>
                </Stack>
            }
        </>
    );
}

export default PartnerBasicPreferenceBody;