import { useCallback, useEffect, useState } from "react";

import { 
    Grid, 
    useTheme,
    Typography,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Collapse,
    Box,
    Stack,
    Link
} from "@mui/material";

import ButtonComponent from '../../components/form/button-component';
import MenuIcon from "../../assets/icons/menu-icon";
import CloseIcon from '../../assets/icons/close-icon';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../contexts/auth/auth-provider";

const nav_options = [
    {label: 'aboutUs', redirect: '/about-us'},
    {label: 'contactUs', redirect: '/contact-us'}
];

const NavigationBar = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const navigation_locale = "navigation";

    const authState = useAuth();

    let isLoggedIn = authState?.currentUser ? authState.currentUser !== null : false;
    const userID = "123456789";
    
    const [drawerExpand, setDrawerExpand] = useState(false);

    const navigateCallback = useCallback((redirectUrl) => {
        navigate(redirectUrl);
    }, []);

    return (
        <>
            <Grid
                xs
                container
                direction='row'
                alignItems='center'
                sx={{
                    py: 3, 
                    px: {xs: 2, md: 6},
                    backgroundColor: drawerExpand ? 'white' : theme.palette.primary.main
                }}
            >
                <Grid xs={4}>
                    <Stack sx={{display: {xs: 'none', md: 'block'}}}>
                        <Link href="/">
                            <img 
                                src='/assets/images/logo/white-logo.png'
                                alt='logo' 
                                style={{width: 200}}
                            />
                        </Link>
                    </Stack>

                    <Stack sx={{display: {xs: 'block', md: 'none'}}}>
                        <img 
                            src={drawerExpand ? '/assets/images/logo/color-logo.png' : '/assets/images/logo/white-logo.png'}
                            alt='logo' 
                            style={{width: 150}}
                        />
                    </Stack>
                </Grid>

                <Grid 
                    xs={8} 
                    display='flex'
                    justifyContent='end'
                    alignItems='center'
                >
                    <Box sx={{display: {xs: 'none', md: 'block'}}}>
                    {
                        nav_options.map((item, index) => {
                            return <Button 
                                key={index}
                                variant="text"
                                sx={{textTransform: 'none'}}
                                onClick={() => navigateCallback(item.redirect)}
                            >
                                <Typography 
                                    key={index}
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: 'white',
                                        px: 2
                                    }}
                                >
                                    {t(`${navigation_locale}.${item.label}`)}
                                </Typography>
                            </Button>
                        })
                    }
                    </Box>
                    
                    <ButtonComponent 
                        text={isLoggedIn ? t(`${navigation_locale}.feed`) : t(`${navigation_locale}.login`)}
                        onClick={isLoggedIn ? () => navigateCallback(`/feed`) : () => navigateCallback('/login')}
                        width={101}
                        sx={{
                            mx: 2,
                            color: drawerExpand ? 'white' : 'black',
                            backgroundColor: drawerExpand ? theme.palette.primary.main : 'white',
                            "&:hover": {
                                backgroundColor: drawerExpand ? theme.palette.primary.main : 'white',
                                transform: 'scale(1.03)'
                            }
                        }}
                    />

                    <IconButton
                        size="large"
                        onClick={() => {setDrawerExpand((status) => !status);}}
                        sx={{display: {xs: 'block', md: 'none'}}}
                    >
                        {
                            drawerExpand
                            ?
                            <CloseIcon 
                                style={{
                                    color: 'black'
                                }}
                            />
                            :
                            <MenuIcon 
                                style={{
                                    color: 'white'
                                }}
                            />
                        }
                    </IconButton>
                </Grid>

                <Collapse 
                    in={drawerExpand} 
                    timeout="auto" 
                    unmountOnExit 
                    sx={{
                        pt: 2,
                        width: '100%'
                    }}
                >
                    <List>
                        {
                            nav_options.map((item, index) => {
                                return <ListItem key={index}>
                                    <ListItemButton 
                                        sx={{textAlign: 'center'}}
                                        onClick={() => navigateCallback(item.redirect)}
                                    >
                                        <ListItemText 
                                            primary={
                                                <Typography 
                                                    key={index}
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: 16,
                                                        color: theme.palette.secondary.main
                                                    }}
                                                >
                                                    {t(`${navigation_locale}.${item.label}`)}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            })
                        }
                    </List>
                </Collapse>
            </Grid>
        </>
    );
}

export default NavigationBar;