import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const masterDataAPI = createApi({
    reducerPath: "masterDataAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    endpoints: (builder) => ({
        getSpecificMasterData: builder.query({
            query: (type) => {
                let apiUrl = `admin/master-data/${type}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        getSubCasteWithCasteID: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `admin/master-data/caste/${id}/sub`,
                    method: "GET"
                };
            },
            providesTags: ['subCasteList']
        })
    })
});

export const { 
    useGetSpecificMasterDataQuery,
    useGetSubCasteWithCasteIDMutation
} = masterDataAPI;

export default masterDataAPI;