import { useTranslation } from "react-i18next";
import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import PartnerPreferenceBody from "./preference-body";

import Footer from '../../footer/footer';

const PartnerPreferenceLayout = () => {
    const { t } = useTranslation();
    const settings_locale = "partner_preference.preference";

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${settings_locale}.heading`)}
                caption={t(`${settings_locale}.caption`)}
            />

            <PartnerPreferenceBody />

            <Footer />
        </>
    );
}

export default PartnerPreferenceLayout;