import React, { useState, useContext, useCallback, useEffect } from "react";

import { ChatContext } from "./chat-context";
import { getDatabase, onValue, limitToLast, ref, query } from "firebase/database";
import { useAuth } from "../auth/auth-provider";
import { useGetAllChatsListMutation } from "../../services/chat-api";

export function ChatProvider({children}){
    const [isChatBoxExpanded, setIsChatBoxExpanded] = useState(false);

    const [chatBoxBar, setChatBoxBar] = useState({
        heading: '',
        caption: '',
        avatar: '',
        back: null,
        memberID: ''
    });
    const [chatBoxBody, setChatBoxBody] = useState(null);


    const [newInterestAccepted, setNewInterestAccepted] = useState(false);
    const [activeInterestRequestSentUser, setActiveInterestRequestSentUser] = useState(null);
    const [activeUserChat, setActiveUserChat] = useState(null);


    const [newlyReceivedAdminMessageList, setNewlyReceivedAdminMessageList] = useState([]);
    const [unreadAdminMessageCount, setUnreadAdminMessageCount] = useState(0);
    const authState = useAuth();
    const database = getDatabase(authState?.firebaseApp);

    useEffect(() => {
        if(authState?.currentUser){
            const userID = authState?.currentUser.uuid;

            console.log("Start to listen the admin chat");

            const chatRef = query(ref(database, 'admin/chat/' + userID), limitToLast(1));
            const chatAdminCountRef = query(ref(database, 'chat/admin/' + userID));

            // Listen to new messages
            onValue(chatRef, (snapshot) => {
                if(snapshot.exists()){
                    console.log("Received new message from the admin");

                    const newMsgArray = [];

                    snapshot.forEach((message) => {
                        console.log(message.val());
                        newMsgArray.push(message.val());
                    });

                    setNewlyReceivedAdminMessageList(newMsgArray);
                    setUnreadAdminMessageCount(true);
                }
            });

            // Listen to new message count
            onValue(chatAdminCountRef, (snapshot) => {
                if(snapshot.exists()){
                    console.log("Received new message count from admin");
                    console.log(snapshot.val())
                    setUnreadAdminMessageCount(snapshot.val().unreadMessage);
                }
            });
        }
    }, [authState]);

    const clearAdminUnreadStatus = useCallback(() => {
        setUnreadAdminMessageCount(0);
    }, []);

    const [initialPageLoad, setInitialPageLoad] = useState(false);
    const [initialPageLoadIndex, setInitialPageLoadIndex] = useState(0);
    const [listeningIDList, setListeningIDList] = useState([]);
    const [newListeningIDList, setNewListeningIDList] = useState([]);
    const [newlyReceivedMessageList, setNewlyReceivedMessageList] = useState([]);
    const [unreadUserMessagesExist, setUnreadUserMessagesExist] = useState([]);

    const [
        getAllChats,
        {
            isLoading,
            isSuccess,
            data,
            isError,
            error
        }
    ] = useGetAllChatsListMutation();

    useEffect(() => {
        if(!initialPageLoad && authState?.currentUser){
            setInitialPageLoad(true);

            console.log("Call chat API first time");
            getAllChats(0);
        }
    }, [authState, initialPageLoad]);

    useEffect(() => {
        if(authState?.currentUser){
            console.log("Call chat API with page " + initialPageLoadIndex);
            getAllChats(initialPageLoadIndex);
        }
    }, [initialPageLoadIndex]);

    useEffect(() => {
        if (isSuccess && data) {
            console.log("Get all chats success");
            console.log(data);
            
            const content = data.data.content;
            const idList = [];

            for(let i = 0; i < content.length; i++){
                idList.push(content[i].id);
            }

            updateListeningIDsList(idList);
        }
        else if(isError && error){
            console.log("Get all chats failed");
            console.log(error);
        }
    }, [isSuccess, data, isError, error]);

    const updateListeningIDsList = useCallback((idList) => {
        const newIdList = [];
        const unreadUserList = [];

        for(let i = 0; i < idList.length; i++){
            if(!listeningIDList.includes(idList[i])){
                newIdList.push(idList[i]);
                unreadUserList.push({id: idList[i], unRead: 0});
            }
        }

        if(newIdList.length === 0){
            console.log("No new IDs available to listen");

            if(initialPageLoadIndex < data.data.page.totalPages - 1){
                console.log("Check next page of the chats...");
                setInitialPageLoadIndex((index) => index + 1);
            }
            else if(initialPageLoadIndex === data.data.page.totalPages - 1){
                console.log("Ready to listen the chats...");
                console.log(newListeningIDList);
                listenToNewMessage();
            }
        }
        else{
            setNewListeningIDList([...newListeningIDList, ...newIdList]);
            setListeningIDList([...listeningIDList, ...newIdList]);
            setUnreadUserMessagesExist([...unreadUserMessagesExist, ...unreadUserList]);
        }        
    }, [listeningIDList, newListeningIDList, unreadUserMessagesExist, initialPageLoadIndex, data]);

    useEffect(() => {
        if(newListeningIDList.length > 0){
            console.log("New listening id list updated");
            console.log(newListeningIDList);

            if(data){
                if(initialPageLoadIndex < data.data.page.totalPages - 1){
                    console.log("Check next page of the chats...");
                    setInitialPageLoadIndex((index) => index + 1);
                }
                else if(initialPageLoadIndex === data.data.page.totalPages - 1){
                    console.log("Ready to listen the chats...");
                    console.log(newListeningIDList);
                    listenToNewMessage();
                }
            }
        }
    }, [newListeningIDList]);

    const listenMessageBasedOnUserID = useCallback((userID, interestedUserID) => {
        console.log("Start to listen the chat from the user " + interestedUserID);

        const chatRef = query(ref(database, 'user/chat/' + userID + "/" + interestedUserID), limitToLast(1));
        const chatCountRef = query(ref(database, 'chat/user/' + userID + "/" + interestedUserID));

        // Listen to new messages
        onValue(chatRef, (snapshot) => {
            if(snapshot.exists()){
                console.log("Received new message from the user " + interestedUserID);

                const newMsgArray = [];

                snapshot.forEach((message) => {
                    console.log(message.val());
                    newMsgArray.push(message.val());
                });

                let existingNewMessages = [...newlyReceivedMessageList];

                if(existingNewMessages.length === 0){
                    existingNewMessages.push({id: interestedUserID, message: newMsgArray});
                }
                else{
                    for(let i = 0; i < existingNewMessages.length; i++){
                        if(existingNewMessages[i].id === interestedUserID){
                            let array = [...existingNewMessages[i].message, ...newMsgArray];
                            existingNewMessages[i].message = array;
                            break;
                        }
                        else if(i === existingNewMessages.length - 1){
                            existingNewMessages.push({id: interestedUserID, message: newMsgArray});
                        }
                    }
                }

                setNewlyReceivedMessageList(existingNewMessages);
            }
        });

        // Listen to new message count
        onValue(chatCountRef, (snapshot) => {
            if(snapshot.exists()){
                console.log("Received new message count from the user " + interestedUserID);
                console.log(snapshot.val());

                const unreadUserMessageList = [...unreadUserMessagesExist];

                for(let i = 0; i < unreadUserMessageList.length; i++){
                    if(unreadUserMessageList[i].id === interestedUserID){
                        unreadUserMessageList[i].unRead = snapshot.val().unreadMessage;
                    }
                }

                setUnreadUserMessagesExist(unreadUserMessageList);
            }
        });
    }, [unreadUserMessagesExist, newlyReceivedMessageList]);

    const listenToNewMessage = useCallback(() => {
        const userID = authState?.currentUser.uuid;

        for(let i = 0; i < newListeningIDList.length; i++){
            const interestedUserID = newListeningIDList[i];
            listenMessageBasedOnUserID(userID, interestedUserID);

            if(i === newListeningIDList.length - 1){
                setNewListeningIDList([]);
            }
        }
    }, [newListeningIDList]);

    const clearUserUnreadStatus = useCallback((userID) => {
        const unreadUserMessageList = [...unreadUserMessagesExist];
        
        for(let i = 0; i < unreadUserMessageList.length; i++){
            if(unreadUserMessageList[i].id === userID){
                unreadUserMessageList[i].unRead = 0;
                break;
            }
        }
        
        setUnreadUserMessagesExist(unreadUserMessageList);
    }, [unreadUserMessagesExist]);

    const clearAllUserUnreadStatus = useCallback(() => {
        const unreadUserMessageList = [...unreadUserMessagesExist];
        
        for(let i = 0; i < unreadUserMessageList.length; i++){
            unreadUserMessageList[i].unRead = 0;
        }
        
        setUnreadUserMessagesExist(unreadUserMessageList);
    }, [unreadUserMessagesExist]);

    const listenNewlyAcceptedUserChat = useCallback(() => {
        setNewInterestAccepted(true);
    }, []);

    useEffect(() => {
        if(newInterestAccepted){
            setNewListeningIDList([]);
            setInitialPageLoadIndex(0);

            setInitialPageLoad(false);
        }
    }, [newInterestAccepted]);

    const getTotalUnreadMessagesCount = useCallback(() => {
        return getTotalUnreadUserMessagesCount() + unreadAdminMessageCount;
    }, [unreadUserMessagesExist, unreadAdminMessageCount]);

    const getTotalUnreadUserMessagesCount = useCallback(() => {
        let total = 0;

        for(let i = 0; i < unreadUserMessagesExist.length; i++){
            total += unreadUserMessagesExist[i].unRead;
        }

        return total;
    }, [unreadUserMessagesExist]);

    const getTotalUnreadAdminMessagesCount = useCallback(() => {
        return unreadAdminMessageCount;
    }, [unreadAdminMessageCount]);

    const getTotalUnreadSpecificUserMessagesCount = useCallback((userId) => {
        for(let i = 0; i < unreadUserMessagesExist.length; i++){
            if(unreadUserMessagesExist[i].id === userId){
                return unreadUserMessagesExist[i].unRead;
            }
        }

        return 0;
    }, [unreadUserMessagesExist]);

    const resetAllListeners = useCallback(() => {
        clearAllUserUnreadStatus();
        clearAdminUnreadStatus();

        setListeningIDList([]);
        setNewListeningIDList([]);
        setNewlyReceivedMessageList([]);
        setUnreadUserMessagesExist([]);

        setInitialPageLoad(false);
        setInitialPageLoadIndex(0);
    }, []);


    // HANDLE CHAT BOX
    const openChatBox = useCallback(() => {
        setIsChatBoxExpanded(true);
    }, []);

    const closeChatBox = useCallback(() => {
        setIsChatBoxExpanded(false);
    }, []);

    const toggleChatBox = useCallback(() => {
        setIsChatBoxExpanded((state) => !state);
    }, []);


    // CHAT BOX HEADER
    const setChatBoxBarProperties = useCallback((heading="", caption="", avatar="", back=null, memberID="") => {
        setChatBoxBar({
            heading: heading,
            caption: caption,
            avatar: avatar,
            back: back,
            memberID: memberID
        });
    }, []);


    const value = {
        newlyReceivedAdminMessageList,
        newlyReceivedMessageList,
        unreadAdminMessagesExist: unreadAdminMessageCount,
        resetAllListeners,
        clearAdminUnreadStatus,
        clearUserUnreadStatus,
        clearAllUserUnreadStatus,
        listenNewlyAcceptedUserChat,
        getTotalUnreadUserMessagesCount,
        getTotalUnreadMessagesCount,
        getTotalUnreadAdminMessagesCount,
        getTotalUnreadSpecificUserMessagesCount,


        isChatBoxExpanded,
        openChatBox,
        closeChatBox,
        toggleChatBox,


        chatBoxBar,
        chatBoxBody,
        setChatBoxBarProperties,
        setChatBoxBody,


        newInterestAccepted,
        activeInterestRequestSentUser,
        activeUserChat,
        setActiveInterestRequestSentUser,
        setActiveUserChat,
        setNewInterestAccepted
    }

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
}

export const useChat = () => {
    return useContext(ChatContext);
};