import PropTypes from 'prop-types';

import { Stack } from "@mui/material";
import MyHobbiesDetails from "./my-hobbies-details";

const ProfileHobbiesLayout = ({privateView, data, updateData, isLoading, editCallback}) => {
    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='start'
                spacing={1}
            >
                <MyHobbiesDetails 
                    privateView={privateView}
                    updateData={updateData}
                    isLoading={isLoading}
                    editCallback={editCallback}
                    value={data.hobbiesInterests}
                />
            </Stack>
        </>
    );
}

export default ProfileHobbiesLayout;

ProfileHobbiesLayout.propType = {
    privateView: PropTypes.bool,
    data: PropTypes.object,
    updateData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func
}