import PropTypes from 'prop-types';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    useTheme
} from '@mui/material';

import DownArrow from '../../assets/icons/down-arrow';

const AccordionExpandDefault = ({list}) => {
    const theme = useTheme();

    return (
        <>
            {
                list.map((item, index) => {
                    return <Accordion 
                        key={index}
                        disableGutters
                        elevation={0}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            my: 0.5,
                            '&:before': {
                                display: 'none',
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <DownArrow 
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white',
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%'
                                    }}
                                />
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 18,
                                    color: '#121212',
                                    py: 1
                                }}
                            >
                                {item.summary}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: '#6D6D6D',
                                    py: 1
                                }}
                            >
                                {item.detail}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                })
            }
        </>
    );
}

export default AccordionExpandDefault;

AccordionExpandDefault.propType = {
    list: PropTypes.array
}