import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import ProfileEditHeader from "../profile-edit-header";
import ProfileEditBody from "../profile-edit-body";

import { useTranslation } from "react-i18next";
import { Button, Divider, Stack, useTheme } from "@mui/material";

import CardAccountDetailsOutlineIcon from "../../../assets/icons/profile-professional/card-account-details-outline-icon";
import SchoolOutlineIcon from "../../../assets/icons/school-outline-icon";
import BriefcaseOutlineIcon from "../../../assets/icons/briefcase-outline-icon";
import CurrencyLkrIcon from "../../../assets/icons/profile-professional/currency-lkr-icon";
import { CurrencyComponent, LoadingButtonComponent, SelectionComponent, TextFieldComponent } from '../../../components/form';
import DialogBox from '../../../components/Dialog/dialog-box';
import { useCallback, useState, useEffect } from 'react';
import { educationLevelList } from '../../../assets/data/profile';
import { useGetSpecificMasterDataQuery } from '../../../services/master-data-api';
import FormProviderComponent from '../../../components/form/form-provider-component';
import { useUpdateProfileMutation } from '../../../services/profile-api';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';

const MyProfessionalDetails = ({privateView, responseData, isLoading, editCallback, updateData}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_locale = "profile.professional";
    const sign_up_locale = "authentication.sign_up";

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [employmentTypeList, setEmploymentTypeList] = useState([]);
    const {isLoading: isLoadingEmploymentType, isSuccess: isSuccessEmploymentType, data: dataEmploymentType, isError: isErrorEmploymentType, error: errorEmploymentType} = useGetSpecificMasterDataQuery('employment-type');
    useEffect(() => {
        if(isSuccessEmploymentType && dataEmploymentType){
            console.log("Employment type master data...");
            console.log(dataEmploymentType);

            setEmploymentTypeList(getListData(dataEmploymentType.data));
        }
        else if(isErrorEmploymentType && errorEmploymentType){
            console.log("Employment type master data error...");
            console.log(errorEmploymentType);

            setEmploymentTypeList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessEmploymentType, dataEmploymentType, isErrorEmploymentType, errorEmploymentType]);

    const formSchema = yup.object().shape({
        employmentType: yup.string().required(t(`${sign_up_locale}.errors.employment_type_is_required`)),
        educationLevel: yup.string().required(t(`${sign_up_locale}.errors.education_level_required`)),
        education: yup.string().notRequired(),
        occupation: yup.string().required(t(`${sign_up_locale}.errors.occupation_is_required`)).test('isValidOccupation', t(`${sign_up_locale}.errors.invalid_occupation`), () => {
            const isValid = formValue.occupation.trim().length <= 30;
            return isValid;
        }),
        annualIncome: yup.string().test('isValidIncome', t(`${sign_up_locale}.errors.income_is_invalid`), () => {
            const isValid = (!formValue.currency && !formValue.annualIncome) || (formValue.currency !== "" && !isNaN(formValue.annualIncome) && formValue.annualIncome > 0);
            return isValid;
        }),
        currency: yup.string()
    });

    const defaultValues = {
        employmentType: "",
        educationLevel: "",
        education: "",
        occupation: "",
        annualIncome: "",
        currency: ""
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    const formValue = watch();

    const [data, setData] = useState([]);

    const setFormValuesWithData = useCallback(() => {
        console.log(">>>>>>>>>>>>>");
        console.log(responseData);

        setFormValue("employmentType", responseData.employmentType);
        setFormValue("educationLevel", responseData.educationLevel);
        setFormValue("education", responseData.education);
        setFormValue("occupation", responseData.occupation);
        setFormValue("annualIncome", responseData.annualIncome);
        setFormValue("currency", responseData.currency);
    }, [responseData]);

    useEffect(() => {
        if(responseData){
            setData(
                [
                    {label: t(`${profile_locale}.employment_type`), value: responseData.employmentType, icon: <CardAccountDetailsOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.education_level`), value: responseData.educationLevel, icon: <SchoolOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.education`), value: responseData.education, icon: <SchoolOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: <TextFieldComponent name='education' label={t(`${sign_up_locale}.step4.form.education_label`)} />},
                    {label: t(`${profile_locale}.current_occupation`), value: responseData.occupation, icon: <BriefcaseOutlineIcon style={{color: theme.palette.primary.main}}/>, editChildren: null},
                    {label: t(`${profile_locale}.annul_income`), value: responseData.annualIncome !== 0 ? `${responseData.annualIncome} (${responseData.currency})` : null, icon: <CurrencyLkrIcon style={{color: theme.palette.primary.main}}/>, editChildren: <CurrencyComponent name='annualIncome' label={t(`${profile_locale}.annul_income`)} currency_name='currency' currency_label={t(`${profile_locale}.currency`)}/> }
                ]
            );

            setFormValuesWithData();
        }
    }, [responseData]);

    const [openEditSingleItemBox, setOpenEditSingleItemBox] = useState({
        title: '',
        children: <></>,
        open: false,
        onSuccess: () => {}
    });

    const [openEditAllBox, setOpenEditAllBox] = useState({
        title: '',
        open: false,
        onSuccess: () => {}
    });

    const openSingleEditItemModal = useCallback((data) => {
        setOpenEditSingleItemBox({
            title: data.label,
            children: data.editChildren,
            open: true,
            onSuccess: () => {}
        });
    }, []);

    const closeSingleEditItemModal = useCallback((data) => {
        setOpenEditSingleItemBox({open: false});
        setFormValuesWithData();
    }, [responseData]);

    const openEditAllModal = useCallback((heading) => {
        setOpenEditAllBox({
            title: heading,
            open: true,
            onSuccess: () => {}
        });
    }, []);

    const closeEditAllModal = useCallback((data) => {
        setOpenEditAllBox({open: false});
        setFormValuesWithData();
    }, [responseData]);

    const modifyRequestObj = useCallback((newData) => {
        const obj = {};
        
        for (var updateDataKey in updateData) {
            if (updateData.hasOwnProperty(updateDataKey)) {
                console.log(updateDataKey + " -----> NOT MODIFIED ----->  " + updateData[updateDataKey]);
                obj[updateDataKey] = updateData[updateDataKey];

                for (var newDataKey in newData){
                    if (newData.hasOwnProperty(newDataKey)){
                        if(updateDataKey !== newDataKey){
                            continue;
                        }
                    
                        if(updateData[updateDataKey] !== newData[newDataKey]){
                            console.log(newDataKey + " -----> MODIFIED ----->  " + newData[newDataKey]);
                            obj[updateDataKey] = newData[newDataKey];
                        }
                    }
                }
            }
        }

        return obj;
    }, [updateData]);

    const [
        updateProfile, 
        {
          isLoading: isLoadingUpdateProfile,
          isSuccess: isSuccessUpdateProfile,
          isError: isErrorUpdateProfile,
          error: errorUpdateProfile
        }
    ] = useUpdateProfileMutation();

    useEffect(() => {
        if (isSuccessUpdateProfile){
            setOpenEditAllBox({open: false});
            setOpenEditSingleItemBox({open: false});
            
            editCallback();
        }
        else if (isErrorUpdateProfile && errorUpdateProfile) {
            setShowSnackBar({
                state: true,
                message: `${errorUpdateProfile.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessUpdateProfile, isErrorUpdateProfile, errorUpdateProfile, editCallback]);

    const onSubmit = handleSubmit(async(data) => {
        console.log(data);
        
        const requestData = modifyRequestObj(data);
        console.log(requestData);

        await updateProfile(requestData);
    });

    return (
        <>
            <ProfileEditHeader 
                heading={t(`${profile_locale}.my_professional_details`)} 
                privateView={privateView}
                openEditAllCallback={openEditAllModal}
            />

            <ProfileEditBody 
                dataList={data} 
                privateView={privateView} 
                callback={openSingleEditItemModal}
                isLoading={isLoading}
            />

            <DialogBox 
                title={openEditSingleItemBox.title}
                children={
                    <FormProviderComponent methods={formMethods} onSubmit={onSubmit}>
                        {openEditSingleItemBox.children}

                        <Divider sx={{mt: 8, mb: 2}}/>

                        <Stack 
                            direction={{xs: 'column-reverse', md: 'row'}} 
                            spacing={2} 
                            sx={{width: '100%'}}
                            justifyContent='end'
                        >
                            <Button 
                                variant="outlined" 
                                color='primary' 
                                size="large"
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                    "&:hover": {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={closeSingleEditItemModal}
                            >
                                {t(`dialog.cancel`)}
                            </Button>
                            
                            <LoadingButtonComponent 
                                text={t(`profile.save`)}
                                type="submit"
                                inLoading={isLoadingUpdateProfile}
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                }}
                            />
                        </Stack>
                    </FormProviderComponent>
                }
                open={openEditSingleItemBox.open} 
                onClose={closeSingleEditItemModal}
                customFooter
            />

            <DialogBox 
                title={openEditAllBox.title}
                children={
                    <FormProviderComponent methods={formMethods} onSubmit={onSubmit}>
                        <Stack
                            direction='column'
                            spacing={2}
                        >
                            <SelectionComponent name='employmentType' required label={t(`${profile_locale}.employment_type`)} list={employmentTypeList} />

                            <SelectionComponent name='educationLevel' required label={t(`${profile_locale}.education_level`)} list={educationLevelList} />

                            <TextFieldComponent name='education' label={t(`${sign_up_locale}.step4.form.education_label`)} />

                            <TextFieldComponent name='occupation' required label={t(`${profile_locale}.current_occupation`)} />

                            <CurrencyComponent name='annualIncome' currency_name='currency' label={t(`${profile_locale}.annul_income`)} currency_label={t(`${profile_locale}.currency`)}/>
                        </Stack>

                        <Divider sx={{mt: 2, mb: 2}}/>

                        <Stack 
                            direction={{xs: 'column-reverse', md: 'row'}} 
                            spacing={2} 
                            sx={{width: '100%'}}
                            justifyContent='end'
                        >
                            <Button 
                                variant="outlined" 
                                color='primary' 
                                size="large"
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                    "&:hover": {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={closeEditAllModal}
                            >
                                {t(`dialog.cancel`)}
                            </Button>
                            
                            <LoadingButtonComponent 
                                text={t(`profile.save`)}
                                type="submit"
                                inLoading={isLoadingUpdateProfile}
                                sx={{
                                    width: {xs: '100%', md: 140}, 
                                }}
                            />
                        </Stack>
                    </FormProviderComponent>
                }
                open={openEditAllBox.open} 
                onClose={closeEditAllModal}
                customFooter
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default MyProfessionalDetails;

MyProfessionalDetails.propType = {
    privateView: PropTypes.bool,
    responseData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func,
    updateData: PropTypes.object
}