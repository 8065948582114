import PropTypes from 'prop-types';

import * as React from 'react';

import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    useTheme,
    Box,
    Typography,
    FormHelperText
} from '@mui/material';
import { useCallback } from 'react';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const RadioButton = ({
    label, 
    name, 
    list, 
    disabledValue="",
    selectedValue="",
    required=false,
    switchCallback,
    sx
}) => {
    const theme = useTheme();

    const { control } = useFormContext();

    const [defaultValue, setDefaultValue] = useState(selectedValue);

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Box sx={{...sx}}>
                        <FormLabel 
                            id={label}
                            sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                color: '#637381'
                            }}
                        >
                            {required ? `${label} *` : label}
                        </FormLabel>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={name}
                            onChange={(event) => {
                                console.log(event.target.value);

                                field.onChange(event.target.value);
                                setDefaultValue(event.target.value);

                                if(switchCallback) switchCallback(event.target.value);
                            }}
                        >
                            {
                                list.map((item, index) => {
                                    return <FormControlLabel 
                                        key={index}
                                        value={item.value}
                                        control={
                                            <Radio 
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    "&.Mui-checked": {
                                                        color: theme.palette.primary.main
                                                    }
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: '#637381'
                                                }}
                                            >
                                                {item.label}
                                            </Typography>
                                        }
                                        disabled={item.value === disabledValue}
                                        checked={item.value === defaultValue}
                                    />
                                })
                            }
                        </RadioGroup>

                        <FormHelperText sx={{color: 'rgba(255, 0, 0, 1)', px: 1.5}}>{error?.message}</FormHelperText>
                    </Box>
                )}
            />
        </>
    );
}

export default RadioButton;

RadioButton.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    list: PropTypes.array,
    disabledValue: PropTypes.string,
    selectedValue: PropTypes.string,
    switchCallback: PropTypes.func,
    required: PropTypes.bool,
    sx: PropTypes.object
}