import { Stack, Typography } from "@mui/material";

const EmptyLayout = () => {
    return (
        <>
            <Stack
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: {xs: 14, md: 28},
                        color: '#6D6D6D'
                    }}
                >
                    No results found
                </Typography>
            </Stack>
        </>
    )
};

export default EmptyLayout;