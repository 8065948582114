import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const partnerPreferenceAPI = createApi({
    reducerPath: "partnerPreferenceAPI",
    baseQuery,
    endpoints: (builder) => ({
        getPartnerPreferenceDetails: builder.query({
            query: () => {
                const apiUrl = `profile/partner-preference`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["partner_preference"]
        }),
        getOnCallPartnerPreferenceDetails: builder.mutation({
            query: () => {
                const apiUrl = `profile/partner-preference`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["partner_preference"]
        }),
        createPartnerPreference: builder.mutation({
            query: (body) => {
                const apiUrl = `profile/partner-preference`;

                return {
                    url: apiUrl,
                    method: "POST",
                    body: body
                };
            },
            transformResponse: (response) => response,
            invalidatesTags: ['partner_preference']
        }),
        updatePartnerPreference: builder.mutation({
            query: (body) => {
                const apiUrl = `profile/partner-preference`;

                return {
                    url: apiUrl,
                    method: "PUT",
                    body: body
                };
            },
            transformResponse: (response) => response,
            invalidatesTags: ['partner_preference']
        })
    }),
});

export const { 
    useGetPartnerPreferenceDetailsQuery,
    useGetOnCallPartnerPreferenceDetailsMutation,
    useCreatePartnerPreferenceMutation,
    useUpdatePartnerPreferenceMutation
} = partnerPreferenceAPI;

export default partnerPreferenceAPI;