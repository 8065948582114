import PropTypes from 'prop-types';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";

import ProfileEditHeader from "../profile-edit-header";
import ProfileEditBody from "../profile-edit-body";

import { useTranslation } from "react-i18next";
import ProfileValueText from "../profile-value-text";
import { Grid, useTheme } from "@mui/material";
import { useCallback, useState, useEffect } from 'react';
import DialogBox from '../../../components/Dialog/dialog-box';
import MultipleSelectionComponent from '../../../components/form/multiple-selection-component';
import FormProviderComponent from '../../../components/form/form-provider-component';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useUpdateProfileMutation } from '../../../services/profile-api';
import { useGetSpecificMasterDataQuery } from '../../../services/master-data-api';

const MyHobbiesDetails = ({
    privateView, 
    updateData,
    isLoading, 
    editCallback,
    value
}) => {
    const theme = useTheme();

    const { t } = useTranslation();
    const profile_locale = "profile.hobbies";

    const mappingValue = 'hobbiesInterests';

    const [data, setData] = useState([]);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const ERROR_LOADING_DATA = "Error Loading Data";

    const getListData = useCallback((data) => {
        const array = [];

        for(let i = 0; i < data.length; i++){
            array.push({id: data[i].id, label: data[i].name, value: data[i].name})
        }

        return array;
    }, []);

    const [hobbiesList, setHobbiesList] = useState([]);
    const {isLoading: isLoadingHobbiesList, isSuccess: isSuccessHobbiesList, data: dataHobbiesList, isError: isErrorHobbiesList, error: errorHobbiesList} = useGetSpecificMasterDataQuery('hobbies-interests');
    useEffect(() => {
        if(isSuccessHobbiesList && dataHobbiesList){
            console.log("Residence type master data...");
            console.log(dataHobbiesList);

            setHobbiesList(getListData(dataHobbiesList.data));
        }
        else if(isErrorHobbiesList && errorHobbiesList){
            console.log("Residence type master data error...");
            console.log(errorHobbiesList);

            setHobbiesList([{id: 0, label: ERROR_LOADING_DATA, value: ERROR_LOADING_DATA}]);
        }
    }, [isSuccessHobbiesList, dataHobbiesList, isErrorHobbiesList, errorHobbiesList]);

    const formSchema = yup.object().shape({
        hobbiesInterests: yup.array()
    });

    const defaultValues = {
        hobbiesInterests: []
    };

    const formMethods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch,
        setValue: setFormValue
    } = formMethods;

    const getMultiValueAssignedValues = useCallback((valuesArray) => {
        const newArray = [];

        for(let i = 0; i < valuesArray?.length; i++){
            newArray.push({label: valuesArray[i], value: valuesArray[i]});
        }
        
        return newArray;
    }, []);

    useEffect(() => {
        if(value){
            setFormValue("hobbiesInterests", getMultiValueAssignedValues(value));
            setData(value);
        }
    }, [value]);

    const formValue = watch();

    const [openEditBox, setOpenEditBox] = useState({
        title: '',
        children: <></>,
        open: false
    });

    const getArrayValue = useCallback((array) => {
        const requestArray = [];

        for(let i = 0; i < array.length; i++){
            requestArray.push(array[i].value);
        }

        return requestArray;
    }, []);

    const modifyRequestObj = useCallback((value) => {
        const obj = {};
        
        for (var key in updateData) {
            if (updateData.hasOwnProperty(key)) {
                if(key === mappingValue){
                    obj[key] = getArrayValue(value);
                }
                else{
                    obj[key] = updateData[key];
                }
            }
        }
        
        return obj;
    }, [updateData, mappingValue]);

    const [
        updateProfile, 
        {
          isLoading: isLoadingUpdateProfile,
          isSuccess: isSuccessUpdateProfile,
          isError: isErrorUpdateProfile,
          error: errorUpdateProfile
        }
    ] = useUpdateProfileMutation();

    useEffect(() => {
        if (isSuccessUpdateProfile){
            setOpenEditBox({
                open: false
            });

            editCallback();
        }
        else if (isErrorUpdateProfile && errorUpdateProfile) {
            setShowSnackBar({
                state: true,
                message: `${errorUpdateProfile.data.error.message}`,
                color: "red"
            });
        }
    }, [isSuccessUpdateProfile, isErrorUpdateProfile, errorUpdateProfile, editCallback]);

    const onSubmit = useCallback(async () => {
        const obj = modifyRequestObj(formValue.hobbiesInterests);
        console.log(obj);

        // const modifiedObj = modifySavingObj(obj);
        // console.log(modifiedObj);

        await updateProfile(obj);
    }, [formValue]);

    const getHobbiesGrid = () => {
        return <Grid
            xs
            container
        >
            <Grid xs={12} sm={6}>
                {getHobbiesList(data?.slice(0, (data?.length * 0.5 | 0) + 1))}
            </Grid>

            <Grid xs={12} sm={6}>
                {getHobbiesList(data?.slice((data?.length * 0.5 | 0) + 1, data?.length))}
            </Grid>
        </Grid>
    }

    const getHobbiesList = (list) => {
        return <ul>
            {
                list.map((item, index) => {
                    return <li 
                        key={index} 
                        style={{
                            marginBottom: '24px',
                            color: theme.palette.primary.main
                        }}
                    >
                        <ProfileValueText value={item}/>
                    </li>
                })
            }
        </ul>
    }

    const openEditAllModal = useCallback((heading) => {
        setOpenEditBox({
            open: true
        });
    }, []);

    const closeSingleEditItemModal = useCallback(() => {
        setOpenEditBox({
            open: false
        });

        setFormValue("hobbiesInterests", value);
    }, [value]);

    return (
        <>
            <ProfileEditHeader 
                heading={t(`${profile_locale}.hobbies_interests`)} 
                privateView={privateView}
                openEditAllCallback={openEditAllModal}
            />

            <ProfileEditBody 
                dataList={[]} 
                children={
                    data?.length > 0 
                    ? getHobbiesGrid() 
                    : 
                    <ProfileValueText 
                        value="" 
                        label={t(`${profile_locale}.hobbies_interests`)} 
                        callback={() => openEditAllModal()}
                        privateView={privateView}
                    />
                } 
                privateView={privateView}
                isLoading={isLoading}
            />

            <DialogBox 
                title={t(`${profile_locale}.hobbies_interests`)}
                children={
                    <FormProviderComponent methods={formMethods} onSubmit={() => {}}>
                        <MultipleSelectionComponent 
                            name='hobbiesInterests' 
                            label={t(`${profile_locale}.hobbies_interests`)} 
                            list={hobbiesList}
                        />
                    </FormProviderComponent>
                }
                open={openEditBox.open} 
                onClose={closeSingleEditItemModal}
                onSuccess={onSubmit}
                approveText={t(`profile.save`)}
                submitInLoading={isLoadingUpdateProfile}
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default MyHobbiesDetails;

MyHobbiesDetails.propType = {
    privateView: PropTypes.bool,
    updateData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func,
    value: PropTypes.string
}