import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const notificationAPI = createApi({
    reducerPath: "notificationAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllNotifications: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `notification?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["notification"]
        }),
        readNotifications: builder.mutation({
            query: () => {
                const apiUrl = `notification/read`;

                return {
                    url: apiUrl,
                    method: "PUT",
                };
            },
            transformResponse: (response) => response
        })
    }),
});

export const { 
    useGetAllNotificationsMutation,
    useReadNotificationsMutation
} = notificationAPI;

export default notificationAPI;