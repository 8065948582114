import { useTranslation } from "react-i18next";

import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import PaginationComponent from "../../../components/pagination/pagination-component";
import ProfileDailyRecommendationBody from "./profile-daily-recommendations-body";

import Footer from '../../footer/footer';
import { useCallback, useEffect, useState } from "react";
import { useGetDailyRecommendationsMutation } from "../../../services/matches-api";
import APIErrorLayout from "../../error/api-error/api-error-layout";

const ProfileDailyRecommendationsLayout = () => {
    const { t } = useTranslation();
    const profile_matches_locale = "profile_daily_recommendations";

    const [recommendationData, setRecommendationData] = useState([]);

    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const total_daily_recommendations = 100;

    const [
        getDailyRecommendations,
        {
            isLoading, 
            isSuccess, 
            data, 
            isError, 
            error
        }
    ] = useGetDailyRecommendationsMutation();

    const callRecommendationsAPI = useCallback(async () => {
        await getDailyRecommendations(selectedPage - 1);
    }, [selectedPage]);

    useEffect(() => {
        callRecommendationsAPI();
    }, [selectedPage]);

    useEffect(() => {
        if(isSuccess && data){
            console.log("Daily recommendation data...");
            console.log(data);

            setRecommendationData(data.data.content);
            setTotalPage(data.data.page.totalPages);
        }
    }, [isSuccess, data]);

    const changePage = useCallback((event, page) => {
        setSelectedPage(page);
    }, []);

    let body;

    if(isLoading){
        body = <ProfileDailyRecommendationBody isLoading={true} />
    }
    else if(isError && error){
        console.log(error);
        body = <APIErrorLayout error={error} />
    }
    else if(isSuccess){
        body = <ProfileDailyRecommendationBody 
            data={recommendationData} 
            successCallback={() => {
                callRecommendationsAPI();
            }}
        />
    }

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${profile_matches_locale}.heading`)} 
                caption={data?.data.page.totalElements > 0 ? `${data?.data.page.totalElements ?? ''} ${t(`${profile_matches_locale}.caption`)}` : ''}
            />

            {body}

            {
                !isError && recommendationData.length > 0
                ?
                <PaginationComponent 
                    totalPageCount={totalPage} 
                    onChange={changePage}
                    page={selectedPage}
                />
                :
                <></>
            }

            <Footer />
        </>
    );
}

export default ProfileDailyRecommendationsLayout;