import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Stack,
    usePagination,
    styled,
    Typography,
    Grid
} from '@mui/material';

const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
});

const PaginationComponent = ({
    totalPageCount,
    onChange,
    page=1
}) => {
    const { items } = usePagination({
        count: totalPageCount,
        onChange: onChange,
        page: page
    });

    const getMargin = (type) => {
        let marginLeft = 0;
        let marginRight = 0;

        if(type === "next"){
            marginLeft = "auto";
            marginRight = 0; 
        }
        else if(type === "previous"){
            marginLeft = 0;
            marginRight = "auto";
        }

        return {
            marginLeft: marginLeft,
            marginRight: marginRight
        }
    }

    const getMarginSmMd = (type) => {
        let marginLeft = 0;
        let marginRight = 0;

        if(type === "next"){
            marginLeft = '5px';
            marginRight = 0; 
        }
        else if(type === "previous"){
            marginLeft = 0;
            marginRight = '5px';
        }

        return {
            marginLeft: marginLeft,
            marginRight: marginRight,
            backgroundColor: type === 'next' ? '#000000' : 'transparent',
            width: '98%'
        }
    }

    return (
        <Stack 
            display='flex'
            direction='row'
            justifyContent='center'
            alignItems='center'
            mb={10}
            sx={{
                px: {xs: 5, sm: 3, md: 7},
                padding: '0px !important',
                marginLeft: {lg: 7, md: 3, xs: 3},
                marginRight: {lg: 7, md: 3, xs: 3}
            }}
        >
            <Box 
                display='flex'
                justifyContent='center'
                sx={{
                    p: 2, 
                    width: '100%',
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                    display: {md: 'block', xs:'none'}
                }}
            >
                <nav>
                    <List>
                        {items.map(({ page, type, selected, ...item }, index) => {
                            let children = null;

                            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                                children = <Typography>...</Typography>;
                            } else if (type === 'page') {
                                children = (
                                    <Button 
                                        {...item}
                                        color='secondary'
                                        sx={{
                                            backgroundColor: selected ? '#E8EDFB' : 'transparent',
                                            maxWidth: '44px', 
                                            maxHeight: '44px', 
                                            minWidth: '44px', 
                                            minHeight: '44px',
                                            margin: 0
                                        }}
                                    >
                                        {page}
                                    </Button>
                                );
                            } else {
                                children = (
                                    <>
                                        <Button 
                                            {...item}
                                            variant={type === 'next' ? "contained" : "outlined"}
                                            color='secondary'
                                            sx={{
                                                backgroundColor: type === 'next' ? '#000000' : 'transparent',
                                                maxHeight: '44px', 
                                                minHeight: '44px',
                                            }}
                                        >
                                            {type}
                                        </Button>
                                    </>
                                );
                            }

                            return <li 
                                key={index}
                                style={getMargin(type)}
                            >
                                {children}
                            </li>;
                        })}
                    </List>
                </nav>
            </Box>

            <Grid 
                xs
                container
                display='flex'
                justifyContent='center'
                alignItems='center'
                direction='column'
                sx={{
                    p: 2, 
                    width: '100%',
                    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                    display: {lg: 'none', md: 'block'}
                }}
            >
                <Grid xs={12} display='flex' justifyContent='center' sx={{mb: 1}}>
                    <nav>
                        <List>
                            {items.map(({ page, type, selected, ...item }, index) => {
                                let children = null;

                                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                                    children = <Typography>...</Typography>;
                                } else if (type === 'page') {
                                    children = (
                                        <Button 
                                            {...item}
                                            color='secondary'
                                            sx={{
                                                backgroundColor: selected ? '#E8EDFB' : 'transparent',
                                                maxWidth: '44px', 
                                                maxHeight: '44px', 
                                                minWidth: '44px', 
                                                minHeight: '44px',
                                                margin: 0
                                            }}
                                        >
                                            {page}
                                        </Button>
                                    );
                                } else {
                                    children = (<></>);
                                }

                                return <li 
                                    key={index}
                                    style={getMargin(type)}
                                >
                                    {children}
                                </li>;
                            })}
                        </List>
                    </nav>
                </Grid>

                <Grid xs={12} display='flex' justifyContent='center' sx={{width: '100%'}}>
                    <nav style={{ width: '100%'}}>
                        <List sx={{width: '100%'}}>
                            {items.map(({ page, type, selected, ...item }, index) => {
                                let children = null;

                                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                                    children = <div style={{display: 'none'}}></div>;
                                } else if (type === 'page') {
                                    children = (<div style={{display: 'none'}}></div>);
                                } else {
                                    children = (
                                        <Button 
                                            {...item}
                                            variant={type === 'next' ? "contained" : "outlined"}
                                            color='secondary'
                                            sx={getMarginSmMd(type)}
                                        >
                                            {type}
                                        </Button>
                                    );
                                }

                                return <li 
                                    key={index}
                                    style={{width: (type === 'next' || type === 'previous') ? '100%' : '0%'}}
                                >
                                    {children}
                                </li>;
                            })}
                        </List>
                    </nav>
                </Grid>
            </Grid>
        </Stack>
    );
}

export default PaginationComponent;

PaginationComponent.propType = {
    totalPageCount: PropTypes.number,
    onChange: PropTypes.func,
    page: PropTypes.number
}