import PropTypes from 'prop-types';

import { Stack } from "@mui/material";
import Gallery from "./gallery";

const ProfileGalleryLayout = ({privateView, data, updateData, isLoading, editCallback}) => {
    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='start'
                spacing={5}
            >
                <Gallery 
                    privateView={privateView}
                    updateData={updateData}
                    isLoading={isLoading}
                    editCallback={editCallback}
                    value={data.profileImages}
                />
            </Stack>
        </>
    );
}

export default ProfileGalleryLayout;

ProfileGalleryLayout.propType = {
    privateView: PropTypes.bool,
    data: PropTypes.object,
    updateData: PropTypes.object,
    isLoading: PropTypes.bool,
    editCallback: PropTypes.func
}