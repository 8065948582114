import PropTypes from 'prop-types';

import { 
    Grid,
    Typography,
    Box,
    Stack,
    Chip,
    Link
} from "@mui/material";
import { useTheme } from '@emotion/react';

const BannerLayout = ({
    title, 
    caption,
    step,
    verticalImg, 
    landscapeImg, 
    children,
    sx
}) => {
    const theme = useTheme();

    return(
        <>
            <Grid
                xs
                container
                display='flex'
                direction={{xs: 'column', lg: 'row'}}
                sx={{...sx}}
            >
                {/* Landscape banner */}
                <Grid xs={12}>
                    <Box
                        display="flex"
                        alignItems="end"
                        sx={{ 
                            backgroundImage: `linear-gradient(0deg, rgba(215, 41, 84, 1), rgba(224, 45, 85, 0.84), rgba(251, 57, 87, 0)), url(${landscapeImg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPositionX: '55%',
                            width: '100vw',
                            minHeight: 200,
                            display: {xs: 'flex', lg: 'none'}
                        }}
                    >
                        <Stack
                            sx={{
                                pl: 5,
                                pb: 3,
                                pt: 5,
                                pr: 3
                            }}
                        >
                            <Link href="/" underline="none" sx={{mb: 3}}>
                                <img src='/assets/images/logo/white-logo.png' alt="logo" width={200}/>
                            </Link>

                            {
                                step 
                                ? 
                                <Chip 
                                    label={step}
                                    sx={{
                                        width: 80,
                                        mb: 1,
                                        color: theme.palette.primary.main,
                                        backgroundColor: 'white',
                                        fontWeight: 600,
                                        fontSize: 16
                                    }}
                                /> 
                                : 
                                <></>
                            }

                            {/* Title */}
                            <Typography
                                textAlign='left'
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 34,
                                    letterSpacing: -2,
                                    color: 'white'
                                }}
                            >
                                {title}
                            </Typography>

                            {/* Caption */}
                            {
                                caption
                                ?
                                <Typography
                                    textAlign='left'
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: 'white',
                                        pt: 2
                                    }}
                                >
                                    {caption}
                                </Typography>
                                :
                                <></>
                            }
                        </Stack>
                    </Box>
                </Grid>

                {/* Portrait banner */}
                <Grid xs={12} lg={4}>
                    <Box
                        display="flex"
                        alignItems="end"
                        sx={{ 
                            backgroundImage: `linear-gradient(0deg, rgba(215, 41, 84, 1), rgba(224, 45, 85, 0.84), rgba(251, 57, 87, 0)), url(${verticalImg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            position: 'fixed',
                            height: '100vh',
                            width: '33%',
                            display: {xs: 'none', lg: 'flex'}
                        }}
                    >
                        <Stack
                            sx={{
                                pb: 20,
                                pl: 7,
                                pr: 7
                            }}
                        >
                            <Link href="/" underline="none" sx={{mb: 3}}>
                                <img src='/assets/images/logo/white-logo.png' alt="logo" width={200}/>
                            </Link>

                            {
                                step 
                                ? 
                                <Chip 
                                    label={step}
                                    sx={{
                                        width: 80,
                                        mb: 3,
                                        color: theme.palette.primary.main,
                                        backgroundColor: 'white',
                                        fontWeight: 600,
                                        fontSize: 16
                                    }}
                                /> 
                                : 
                                <></>
                            }

                            {/* Title */}
                            <Typography
                                textAlign='left'
                                sx={{
                                    fontWeight: 600,
                                    fontSize: 48,
                                    letterSpacing: -2,
                                    lineHeight: 1.25,
                                    color: 'white'
                                }}
                            >
                                {title}
                            </Typography>

                            {/* Caption */}
                            {
                                caption
                                ?
                                <Typography
                                    textAlign='left'
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: 'white',
                                        pt: 3
                                    }}
                                >
                                    {caption}
                                </Typography>
                                :
                                <></>
                            }
                        </Stack>
                    </Box>
                </Grid>

                {/* Body */}
                <Grid 
                    xs={12} lg={8}
                    sx={{
                        width: '100vw',
                        pt: 5, 
                        mb: 10
                    }}
                >
                    {children}
                </Grid>
            </Grid>
        </>
    );
}

export default BannerLayout;

BannerLayout.propTypes = {
    title: PropTypes.string,
    caption: PropTypes.string,
    step: PropTypes.string,
    verticalImg: PropTypes.string,
    landscapeImg: PropTypes.string,
    children: PropTypes.object,
    sx: PropTypes.object,
}