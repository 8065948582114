import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const interestAPI = createApi({
    reducerPath: "interestAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllReceivedInterests: builder.mutation({
            query: ({page: page, status: status}) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `interest/received?${queryParams.toString()}&status=${status}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        getAllSentInterests: builder.mutation({
            query: ({page: page, status: status}) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `interest/sent?${queryParams.toString()}&status=${status}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),

        sendInterest: builder.mutation({
            query: (memberID) => ({
                url: `interest/send/${memberID}`,
                method: "PUT"
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['sent_interests']
        }),
        acceptInterest: builder.mutation({
            query: (userID) => ({
                url: `interest/accept/${userID}`,
                method: "PUT"
            }),
            transformResponse: (response) => response
        }),
        declineInterest: builder.mutation({
            query: (userID) => ({
                url: `interest/decline/${userID}`,
                method: "PUT"
            }),
            transformResponse: (response) => response
        }),
    })
});

export const { 
    useGetAllReceivedInterestsMutation,
    useGetAllSentInterestsMutation,
    useSendInterestMutation,
    useAcceptInterestMutation,
    useDeclineInterestMutation
} = interestAPI;

export default interestAPI;