import { useCallback, useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import SkeletonComponent from "../loading/skeleton-component";
import { useGetUploadNDownloadURLMutation, useUploadToS3Mutation } from "../../services/upload-api";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../snack-bar/snack-bar-component";

const FileUploadCard = ({item, index, removeImage, uploadType, updateUploadURL}) => {
    const { t } = useTranslation();
    const validation_locale = "validation";

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const [
        uploadToS3, 
        { 
          isLoading: isLoadingUploadToS3, 
          isSuccess: isSuccessUploadToS3, 
          data: dataUploadToS3, 
          isError: isErrorUploadToS3, 
          error: errorUploadToS3
        }
    ] = useUploadToS3Mutation();
    
    const [
        getUploadNDownloadURLs, 
        { 
          isLoading: isLoadingGetUploadNDownloadURL, 
          isSuccess: isSuccessGetUploadNDownloadURL, 
          data: dataGetUploadNDownloadURL, 
          isError: isErrorGetUploadNDownloadURL, 
          error: errorGetUploadNDownloadURL
        }
    ] = useGetUploadNDownloadURLMutation();

    const callGetUploadNDownloadAPI = useCallback(async () => {
        await getUploadNDownloadURLs({ type: uploadType, extension: item.extension });
    }, [uploadType, item]);

    useEffect(() => {
        if(item){
            console.log(item);
            callGetUploadNDownloadAPI();
        }
    }, []);

    const callUploadToS3API = useCallback(async () => {
        if(item.downloadURL === ""){
            await uploadToS3({uploadUrl: dataGetUploadNDownloadURL.data.presignedUploadUrl, file: item.file});
        }
    }, [dataGetUploadNDownloadURL, item]);
    
    useEffect(() => {
        if(isSuccessGetUploadNDownloadURL && dataGetUploadNDownloadURL){
            console.log(dataGetUploadNDownloadURL);
            callUploadToS3API();
        }
        else if(isErrorGetUploadNDownloadURL && errorGetUploadNDownloadURL){
            removeImage(index);

            setShowSnackBar({
                state: true,
                message: `${errorGetUploadNDownloadURL.data.error.message}`,
                color: "red"
            });
        }
    }, [
        isSuccessGetUploadNDownloadURL, 
        dataGetUploadNDownloadURL, 
        isErrorGetUploadNDownloadURL, 
        errorGetUploadNDownloadURL, 
        callUploadToS3API
    ]);

    const updateUploadedImageState = useCallback(() => {
        if(item.downloadURL === ""){
            updateUploadURL(index, dataGetUploadNDownloadURL.data.directDownloadUrl);
        }
    }, [dataGetUploadNDownloadURL, updateUploadURL, index, item]);

    useEffect(() => {
        if(isSuccessUploadToS3){
            updateUploadedImageState();
        }
        else if(isErrorUploadToS3){
            removeImage(index);

            setShowSnackBar({
                state: true,
                message: `${t(`${validation_locale}.failed_to_upload`)}`,
                color: "red"
            });
        }
      }, [
        isSuccessUploadToS3, 
        isErrorUploadToS3, 
        updateUploadedImageState
    ]);

    return <>
        {
            isLoadingGetUploadNDownloadURL || isLoadingUploadToS3
            ?
            <Box>
                <SkeletonComponent variant='rectangular' width={75} height={75} sx={{my: 2}}/>
            </Box>
            :
            <Box
                display='flex'
                justifyContent='end'
                alignItems='start'
                sx={{
                    backgroundImage: `url(${item.preview})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: 75,
                    height: 75,
                    borderRadius: '8px',
                    backgroundPositionX: '50%',
                    my: 2
                }}
            >
                <IconButton 
                    aria-label="delete" 
                    onClick={() => removeImage(index)}
                    sx={{
                        mr: -1,
                        pr: 0,
                        mt: -1,
                        pt: 0
                    }}
                >
                    <img src="/assets/icons/remove.png" />
                </IconButton>
            </Box>
        }

        <SnackbarComponent 
            message={showSnackBar.message}
            open={showSnackBar.state}
            handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
            color={showSnackBar.color}
        />
    </>
}

export default FileUploadCard;