import { Helmet } from "react-helmet";
import PartnerLocationPreferenceLayout from "../../sections/partner-preference/location/partner-location-preference-layout";

const PartnerLocationPreferencePage = () => {
    return(
        <>
            <Helmet>
                <title>Partner Location Preference</title>
            </Helmet>

            <PartnerLocationPreferenceLayout />
        </>
    );
}

export default PartnerLocationPreferencePage;