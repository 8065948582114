import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const blockAPI = createApi({
    reducerPath: "blockAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllBlockedProfiles: builder.mutation({
            query: (page) => {
                const queryParams = new URLSearchParams({
                    'page': '' + page,
                    'size': '10'
                });

                const apiUrl = `profile/block?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["blocked"]
        }),
        block: builder.mutation({
            query: (uuid) => ({
                url: `profile/block/${uuid}`,
                method: "POST"
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['blocked']
        }),
        unblock: builder.mutation({
            query: (uuid) => ({
                url: `profile/unblock/${uuid}`,
                method: "DELETE"
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['blocked']
        }),
    })
});

export const { 
    useGetAllBlockedProfilesMutation,
    useBlockMutation,
    useUnblockMutation
} = blockAPI;

export default blockAPI;