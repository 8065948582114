import PropTypes from 'prop-types';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselComponent = ({
    list,
    slidesToShow=2,
    autoplay=true,
    speed=500
}) => {
    const NextArrow = (props) => {
        const { className, style, onClick } = props;

        return (
            <div
                className={className}
                style={{ 
                    ...style, 
                    display: "block",
                    backgroundColor: '#6D6D6D',
                    right: '30px',
                    borderRadius: '50%',
                    boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.16)',
                    zIndex: 1
                }}
                onClick={onClick}
            />
        );
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;

        return (
            <div
                className={className}
                style={{ 
                    ...style, 
                    display: "block",
                    backgroundColor: '#6D6D6D',
                    left: '30px',
                    borderRadius: '50%',
                    boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.16)',
                    zIndex: 1
                }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: speed,
        arrows: true,
        autoplay: autoplay,
        className: "center",
        centerMode: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return(
        <>
            <div className="slider-container">
                <Slider {...settings}>
                    {
                        list.map((item, index) => {
                            return <div key={index}>{item}</div>
                        })
                    }
                </Slider>
            </div>
        </>
    );
}

export default CarouselComponent;

CarouselComponent.propType = {
    list: PropTypes.array,
    slidesToShow: PropTypes.number,
    autoplay: PropTypes.bool,
    speed: PropTypes.number
}