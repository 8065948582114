import { useTranslation } from "react-i18next";

import ProfileNavigationBar from "../../navigation/profile-navigation-bar";
import ProfileSettingsHeader from "../../profile-settings/profile-settings-header";
import ProfileMatchBody from "./profile-match-body";
import PaginationComponent from "../../../components/pagination/pagination-component";
import ResultFilter from "../../../components/filters/result-filter";
import { useCallback, useEffect, useState } from "react";
import BottomNavigationBar from "../../navigation/bottom-navigation-bar";
import Footer from '../../footer/footer';
import { useGetAllMatchesMutation, useGetRecentlyJoinedMutation, useGetViewedProfilesByMeMutation } from "../../../services/matches-api";
import { useGetAllShortlistedProfilesMutation } from '../../../services/shortlisted-api';
import APIErrorLayout from "../../error/api-error/api-error-layout";

const ProfileMatchLayout = () => {
    const { t } = useTranslation();
    const profile_matches_locale = "profile_matches";

    const [selectedFilter, setSelectedFilter] = useState('ALL');
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const filtersList = [
        {label: t(`${profile_matches_locale}.filters.all_matched_profiles`), value: 'ALL'},
        {label: t(`${profile_matches_locale}.filters.recently_joined`), value: 'RECENT'},
        {label: t(`${profile_matches_locale}.filters.profiles_viewed_by_you`), value: 'VIEWED'},
        {label: t(`${profile_matches_locale}.filters.shortlisted_profiles_by_you`), value: 'SHORTLISTED'}
    ];

    const [dataLoading, setDataLoading] = useState(false);
    const [dataError, setDataError] = useState(null);
    const [data, setData] = useState([]);

    const [
        getAllMatches,
        {
            isLoading: isLoadingMatches, 
            isSuccess: isSuccessMatches, 
            data: dataMatches, 
            isError: isErrorMatches, 
            error: errorMatches
        }
    ] = useGetAllMatchesMutation();

    useEffect(() => {
        if(isSuccessMatches && dataMatches){
            console.log("Matches data...");
            console.log(dataMatches);

            setData(dataMatches.data.content);
            setTotalPage(dataMatches.data.page.totalPages);
            setDataLoading(false);
        }
        else if(isErrorMatches && errorMatches){
            console.log("Matches data error...");
            console.log(errorMatches);

            setDataError(errorMatches);
            setDataLoading(false);
        }
    }, [isSuccessMatches, dataMatches, isErrorMatches, errorMatches]);

    const [
        getRecentlyJoined,
        {
            isLoading: isLoadingRecentlyJoined, 
            isSuccess: isSuccessRecentlyJoined, 
            data: dataRecentlyJoined, 
            isError: isErrorRecentlyJoined, 
            error: errorRecentlyJoined
        }
    ] = useGetRecentlyJoinedMutation();

    useEffect(() => {
        if(isSuccessRecentlyJoined && dataRecentlyJoined){
            console.log("Recently joined data...");
            console.log(dataRecentlyJoined);

            setData(dataRecentlyJoined.data.content);
            setTotalPage(dataRecentlyJoined.data.page.totalPages);
            setDataLoading(false);
        }
        else if(isErrorRecentlyJoined && errorRecentlyJoined){
            console.log("Recently joined data error...");
            console.log(errorRecentlyJoined);

            setDataError(errorRecentlyJoined);
            setDataLoading(false);
        }
    }, [isSuccessRecentlyJoined, dataRecentlyJoined, isErrorRecentlyJoined, errorRecentlyJoined]);

    const [
        getProfileViewedByMe,
        {
            isLoading: isLoadingProfileViewedByMe, 
            isSuccess: isSuccessProfileViewedByMe, 
            data: dataProfileViewedByMe, 
            isError: isErrorProfileViewedByMe, 
            error: errorProfileViewedByMe
        }
    ] = useGetViewedProfilesByMeMutation();

    useEffect(() => {
        if(isSuccessProfileViewedByMe && dataProfileViewedByMe){
            console.log("Profile viewed by me data...");
            console.log(dataProfileViewedByMe);

            setData(dataProfileViewedByMe.data.content);
            setTotalPage(dataProfileViewedByMe.data.page.totalPages);
            setDataLoading(false);
        }
        else if(isErrorProfileViewedByMe && errorProfileViewedByMe){
            console.log("Profile viewed by me data error...");
            console.log(errorProfileViewedByMe);

            setDataError(errorProfileViewedByMe);
            setDataLoading(false);
        }
    }, [isSuccessProfileViewedByMe, dataProfileViewedByMe, isErrorProfileViewedByMe, errorProfileViewedByMe]);

    const [
        getShortlistedProfiles,
        {
            isLoading: isLoadingShortlistedProfiles, 
            isSuccess: isSuccessShortlistedProfiles, 
            data: dataShortlistedProfiles, 
            isError: isErrorShortlistedProfiles, 
            error: errorShortlistedProfiles
        }
    ] = useGetAllShortlistedProfilesMutation();

    useEffect(() => {
        if(isSuccessShortlistedProfiles && dataShortlistedProfiles){
            console.log("Shortlisted profiles data...");
            console.log(dataShortlistedProfiles);

            setData(dataShortlistedProfiles.data.content);
            setTotalPage(dataShortlistedProfiles.data.page.totalPages);
            setDataLoading(false);
        }
        else if(isErrorShortlistedProfiles && errorShortlistedProfiles){
            console.log("Shortlisted profiles data error...");
            console.log(errorShortlistedProfiles);

            setDataError(errorShortlistedProfiles);
            setDataLoading(false);
        }
    }, [isSuccessShortlistedProfiles, dataShortlistedProfiles, isErrorShortlistedProfiles, errorShortlistedProfiles]);

    const callAPI = useCallback(async (page, filterType) => {
        setDataLoading(true);
        setDataError(null);

        const p = page - 1 < 0 ? 0 : page - 1;

        switch(filterType){
            case 'ALL':
                await getAllMatches(p);
                break;

            case 'RECENT':
                await getRecentlyJoined(p);
                break;

            case 'VIEWED':
                await getProfileViewedByMe(p);
                break;

            case 'SHORTLISTED':
                await getShortlistedProfiles(p);
                break;

            default:
                await getAllMatches(p);
                break;
        }
    }, []);

    const changePage = useCallback((event, page) => {
        setSelectedPage(page);
        callAPI(page, selectedFilter);
    }, [selectedFilter]);

    const changeFilter = useCallback((selectedFilter) => {
        setSelectedFilter(selectedFilter);
        setSelectedPage(1);
    }, []);

    useEffect(() => {
        callAPI(0, selectedFilter);
    }, [selectedFilter]);

    return (
        <>
            <ProfileNavigationBar />

            <ProfileSettingsHeader 
                heading={t(`${profile_matches_locale}.heading`)} 
            />

            <ResultFilter 
                list={filtersList}
                defaultFilterIndex={0}
                onChange={changeFilter}
            />

            {
                dataError
                ?
                <APIErrorLayout error={dataError}/>
                :
                <ProfileMatchBody 
                    filterType={selectedFilter} 
                    data={data}
                    isLoading={dataLoading}
                    successCallback={() => {
                        callAPI(selectedPage, selectedFilter);
                    }}
                />
            }

            {
                data.length > 0 && !dataError
                ?
                <PaginationComponent 
                    totalPageCount={totalPage} 
                    onChange={changePage}
                    page={selectedPage}
                />
                :
                <></>
            }

            <BottomNavigationBar selectedValue={2}/>

            <Footer />
        </>
    );
}

export default ProfileMatchLayout;