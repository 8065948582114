import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCallback, useEffect, useState } from "react";

import { 
    Stack,
    Typography,
    Box
} from "@mui/material";
import { useTranslation } from "react-i18next";

import TextFieldComponent from '../../../components/form/text-field-component';
import { ButtonComponent, LoadingButtonComponent } from "../../../components/form";
import { useNavigate } from "react-router-dom";

import MdiHide from "../../../assets/icons/mdi-hide";
import MdiShow from '../../../assets/icons/mdi-show';
import { useForm } from 'react-hook-form';
import FormProviderComponent from '../../../components/form/form-provider-component';
import { useAuth } from '../../../contexts/auth/auth-provider';
import SnackbarComponent from '../../../components/snack-bar/snack-bar-component';
import { useDeactivateMutation } from '../../../services/profile-api';
import DialogBox from '../../../components/Dialog/dialog-box';
import { useAnalytics } from '../../../contexts/analytics/analytics-provider';

const DeactivateProfileBody = ({step, updateStep}) => {
    const { t } = useTranslation();
    const deactivate_profile_locale = "profile_settings.deactivate_profile";

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const auth = useAuth();

    const [openDialog, setOpenDialog] = useState(false);

    const {logCustomEvent, logCustomEventWithParams} = useAnalytics();
    const logAnalytics = useCallback((event) => {
        logCustomEvent(event);
    }, []);

    const [showSnackBar, setShowSnackBar] = useState({
        state: false,
        message: "",
        color: ''
    });

    const schema = yup.object().shape({
        otherReason: yup.string().required(t(`${deactivate_profile_locale}.errors.invalid_password`))
    });

    const defaultValues = {
        otherReason: ''
    };

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    const {
        reset: reset,
        handleSubmit: handleSubmit,
        formState: { isSubmitting: isSubmitting },
        watch: watch
    } = methods;

    const nextStep = useCallback(() => {
        if(step === 3){
            navigate('/');
        }
        else{
            updateStep();
        }
    }, [step]);

    const onSubmit = handleSubmit(async (data) => {
        console.log(data);

        try{
            const firebaseResponse = await auth?.confirmPassword(data.otherReason);
            console.log(firebaseResponse);

            updateStep();
        }
        catch(error){
            console.log(error);
    
            reset();

            setShowSnackBar({
                state: true,
                message: error.code === 'auth/invalid-credential' ? t(`${deactivate_profile_locale}.errors.invalid_firebase_password`) : `${error.code}`,
                color: "red"
            });
        }
    });

    let body;

    if(step === 1){
        body = <>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: {xs: 20, md: 24},
                    color: '#6D6D6D',
                    pb: 3
                }}
            >
                {t(`${deactivate_profile_locale}.step${step}.question`)}
            </Typography>

            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: 16,
                    color: '#6D6D6D'
                }}
            >
                {t(`${deactivate_profile_locale}.step${step}.caption`)}
            </Typography>
        </>
    }
    else if(step === 2){
        body = <>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: {xs: 20, md: 24},
                    color: '#6D6D6D',
                    pb: 3
                }}
            >
                {t(`${deactivate_profile_locale}.step${step}.question`)}
            </Typography>

            <TextFieldComponent 
                name='otherReason'
                label={t(`${deactivate_profile_locale}.step${step}.password_label`)}
                sx={{
                    width: {xs: '100%', md: '75%', lg: '50%'}
                }}
                type={showPassword ? 'text' : 'password'}
                endIcon={
                    !showPassword 
                    ? 
                    <MdiHide style={{width: '20px', color: '#637381'}}/> 
                    : 
                    <MdiShow style={{width: '20px', color: '#637381'}}/>
                }
                endIconCallback={() => setShowPassword((status) => !status)}
            />
        </>
    }
    else if(step === 3){
        body = <>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: {xs: 20, md: 24},
                    color: '#6D6D6D',
                    pb: 3
                }}
            >
                {t(`${deactivate_profile_locale}.step${step}.question`)}
            </Typography>

            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: 16,
                    color: '#6D6D6D'
                }}
            >
                {t(`${deactivate_profile_locale}.step${step}.caption`)}
            </Typography>
        </>
    }

    const [
        deactivate,
        {
            isLoading,
            isSuccess,
            isError,
            error
        }
    ] = useDeactivateMutation();

    useEffect(() => {
        if(isSuccess){
            logAnalytics('PROFILE_TEMPORARILY_DEACTIVATED');

            setShowSnackBar({
                state: true,
                message: t(`${deactivate_profile_locale}.deactivated`),
                color: "green"
            });
    
            setTimeout(() => {
                setOpenDialog(true);
            }, 1000);
        }
    }, [isSuccess])

    useEffect(() => {
        if(isError && error){
            setShowSnackBar({
                state: true,
                message: `${error.data.error.message}`,
                color: "red"
            });
        }
    }, [isError, error])

    const deactivateProfile = useCallback(async () => {
        await deactivate();
    }, []);

    const logout = useCallback(() => {
        setOpenDialog(false);

        auth?.logOut();
    }, []);

    const getPrimaryActionButton = () => {
        if(step === 1){
            return <ButtonComponent 
                text={t(`${deactivate_profile_locale}.continue`)}
                onClick={nextStep}
                width={103}
                sx={{
                    color: 'white',
                    backgroundColor: 'black'
                }}
            />
        }
        else if(step === 2){
            return <LoadingButtonComponent 
                text={t(`${deactivate_profile_locale}.continue`)}
                type="submit"
                inLoading={isSubmitting}
                width={103}
                sx={{
                    color: 'white',
                    backgroundColor: 'black'
                }}
            />
        }
        else {
            return <LoadingButtonComponent 
                text={t(`${deactivate_profile_locale}.deactivate_account`)}
                inLoading={isLoading}
                onClick={deactivateProfile}
                width={103}
                sx={{
                    color: 'white',
                    backgroundColor: 'black'
                }}
            />
        }
    }

    return (
        <>
            <Stack 
                sx={{
                    mx: {xs: 2.5, md: 5},
                    height: '80%'
                }} 
                spacing={3}
            >
                <FormProviderComponent methods={methods} onSubmit={onSubmit}>
                    <Box sx={{height: '80%'}}>
                        {body}
                    </Box>

                    <Stack 
                        direction={{xs: 'column', md: 'row'}}
                        spacing={2}
                        display='flex'
                        sx={{
                            position: {xs: 'sticky', md: 'relative'},
                            bottom: 0,
                            pt: 5,
                            pb: {xs: 3, md: 10},
                            backgroundColor: 'white',
                            width: '100%'
                        }}
                    >
                        {getPrimaryActionButton()}

                        {
                            step === 2
                            ?
                            <ButtonComponent 
                                text={t(`${deactivate_profile_locale}.forgot_password`)}
                                onClick={() => navigate('/reset-password')}
                                variant='text'
                                width={103}
                                sx={{
                                    color: 'black'
                                }}
                            />
                            :
                            <ButtonComponent 
                                text={t(`${deactivate_profile_locale}.cancel`)}
                                onClick={() => navigate(-1)}
                                outlined
                                color="secondary"
                                width={103}
                                sx={{
                                    color: 'black'
                                }}
                            />
                        }
                    </Stack>
                </FormProviderComponent>
            </Stack>

            <DialogBox 
                title={t(`${deactivate_profile_locale}.sign_out`)}
                children={
                    <Typography 
                        style={{
                            fontWeight: 500, 
                            fontSize: 14, 
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            color: '#6D6D6D',
                        }}
                    >
                        {t(`${deactivate_profile_locale}.sign_out_message`)}
                    </Typography>
                }
                open={openDialog} 
                onSuccess={logout}
                approveText={t(`${deactivate_profile_locale}.ok`)}
                hideClose
            />

            <SnackbarComponent 
                message={showSnackBar.message}
                open={showSnackBar.state}
                handleClose={() => setShowSnackBar({state: false, message: showSnackBar.message, color: showSnackBar.color})}
                color={showSnackBar.color}
            />
        </>
    );
}

export default DeactivateProfileBody;

DeactivateProfileBody.propType = {
    step: PropTypes.number,
    updateStep: PropTypes.func
}